import Tooltip from "@atlaskit/tooltip";
import Skeleton from "@mui/material/Skeleton";
import { StylesConfig, ValueContainerProps, components } from "react-select";

import { isFilledArray } from "utils";
import { Option, Select } from "../../../components/molecule/Select";

/**
 * Component for showing and changing selected customer
 */
export function CustomerSelector({
  selectedCustomerID,
  availableCustomerList,
  onChange,
  onInvalid,
  ...optionals
}: {
  selectedCustomerID: string;
  availableCustomerList: any[];
  [x: string]: any;
}) {
  const { selectedCustomerName } = optionals;

  if (!isFilledArray(availableCustomerList)) {
    // availableCustomerList is empty
    if (!selectedCustomerID) {
      // no selected customer id
      onInvalid();
    } else {
      // has selected customer id
      return (
        <h2 className={"text-xl"}>{selectedCustomerName || "Not available"}</h2>
      );
    }
  } else {
    // availableCustomerList has some element
    if (!selectedCustomerID) {
      // no selectedCustomerID,  set first element of availableCustomerList as default
      const nextSelectedCustomerID = availableCustomerList[0].customer_list_id;
      onChange && onChange(nextSelectedCustomerID);
      // return loading
      return <Skeleton variant="rounded" width={180} height={50} />;
    } else if (availableCustomerList?.length === 1) {
      // only one customer
      return (
        <h2 className={"text-xl"}>
          {availableCustomerList[0].customer_name || "Not available"}
        </h2>
      );
    }
  }

  const options = availableCustomerList?.map((customer) => {
    return {
      value: customer.customer_id,
      label: customer.customer_name,
    };
  });

  const styles: StylesConfig = {
    option: (styles) => {
      return {
        ...styles,
        // lineBreak: "anywhere",
        overflowWrap: "break-word",
      };
    },
  };

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<Option>) => {
    const { getValue } = props;
    const [value] = getValue();
    const { label } = value;
    return (
      <Tooltip content={label || ""} delay={0}>
        <components.ValueContainer {...props} className="max-w-[200px]">
          {children}
        </components.ValueContainer>
      </Tooltip>
    );
  };

  return (
    <Select
      options={options}
      selectFirst={true}
      value={selectedCustomerID}
      onChange={onChange}
      styles={styles}
      selectClassName="flex-1 lg:flex-none w-60"
      classNames={{
        control: () => "border",
      }}
      components={{ ValueContainer }}
    />
  );
}
