import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from './store';


const initialState = {
  cardID: null,
  isModalOpened: false,
};

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    updateCardID(state, action: PayloadAction<string>) {
      state.cardID = action.payload;
      return state;
    },
    updateIsModalOpened(state, action: PayloadAction<boolean>) {
      state.isModalOpened = action.payload;
      return state;
    },
    clear(state) {
      state = initialState;
      return state;
    }
  },
});

export default cardSlice.reducer;

export const {
  updateCardID,
  updateIsModalOpened,
  clear,
} = cardSlice.actions;

export const selectCardID = (state: AppState) => {
  return state[cardSlice.name].cardID;
};
export const selectIsModalOpened = (state: AppState) => {
  return state[cardSlice.name].isModalOpened;
}