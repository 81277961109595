import { REPORT_API, REPORT_SCHEDULE_API } from "./api";
import { getData, sendPost } from "features/axios";
import {
  getUnixTimeByDate,
  convertUnixTimestampToTimezonedRFC3339AtStartOfDay,
} from "features/date";
import { DEFAULT_TIME_ZONE } from "lib";

type ReportParams = {
  customerID: string;
  email: string;
  endDate: Date;
  timezone: string;
  type: string;
  docType: string;
};

type ReportScheduleRequestParams = {
  customer: string;
  email: string;
  type: string;
  docType: string;
  isScheduled: boolean;
  scheduleInterval?: string;
};

export async function sendReport({
  customerID,
  email,
  endDate,
  timezone = DEFAULT_TIME_ZONE,
  type,
  docType,
}: ReportParams) {
  const unix = getUnixTimeByDate(endDate);
  const params = {
    customer: customerID,
    email,
    endDate: convertUnixTimestampToTimezonedRFC3339AtStartOfDay(unix, timezone),
    type,
    docType,
  };
  return getData(REPORT_API, {
    params,
    timeout: 120 * 1000,
  });
}

export async function getReportSchedule(customerID: string, email: string) {
  return await getData(REPORT_SCHEDULE_API, {
    params: { customer: customerID, email },
    timeout: 120 * 1000,
  });
}

export async function saveReportSchedule(data: ReportScheduleRequestParams) {
  return await sendPost(REPORT_SCHEDULE_API, data);
}
