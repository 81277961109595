import { Icon } from "components/atomic/Icon";
import { Typography } from "components/atomic/Typography";
import { useTheme } from "styled-components";

/**
 * View for no data
 */
export function NoDataView(props) {
  // const {size = 80, ...rest} = props
  const { size = 80 } = props;
  const theme = useTheme();
  return (
    <div className="flex flex-col items-center justify-center h-72">
      <Icon name="circle-exclamation" size={size} color={theme.colors.alert} />{" "}
      <Typography className="text-lg mt-3">No available data</Typography>
    </div>
  );
}
