import { get, isNil } from 'lodash';

/**
 * 
 * @param path 
 * @returns 
 */
export function generalSortFn(path: string) {
  return (rowA, rowB) => {
    const a = get(rowA, path);
    const b = get(rowB, path);
    // equal items sort equally
    if (a === b) {
      return 0;
    }

    // nulls sort after anything else
    if (isNil(a)) {
      return 1;
    }
    if (isNil(b)) {
      return -1;
    }

    return a > b ? 1 : -1
  }
}