import { AxisBottom } from "@visx/axis"; //https://airbnb.io/visx/docs/axis#AxisBottom
import { Text } from "@visx/text";

import { always, cond, lte, T } from "ramda";
import { useSelector } from "react-redux";
// import {useTheme} from 'styled-components';
import {
  calculateDiffInHoursForDateRange,
  UnixtimeSelectedDate,
} from "features/date";
import { useCustomerDataOfDefaultUser } from "features/kaidu-config-server";
import { selectSelectedDate } from "providers/redux/globalSelectSlice";
import { formatTickByDateRange } from "../../VisitorTraffic/processors";

const tickLabelStyle = {
  fill: "#282828",
  fontSize: 12,
  fontWeight: "light" as const,
  textAnchor: "middle" as const,
  "data-cy": "graph-x-axis-label",
};

// tick format: based on width, date range
/**
 *
 */
const foo = cond([
  [lte(1200), always(8)],
  [lte(800), always(5)],
  [T, always(2)],
]);

/**
 * Num of ticks should be based on the number of bars and width
 */
function getNumOfTicks(isSingleDay, width, diffInDays?: number): number {
  // console.debug('getNumOfTicks', isSingleDay, width, diffInDays);
  if (Number.isFinite(diffInDays)) {
    if (diffInDays < 1) {
      // single day
      return width > 800 ? 24 : 6;
    } else if (diffInDays <= 7) {
      // <= week
      return diffInDays + 1;
    } else {
      // more than a week
      const result = foo(width);
      // console.debug('getNumOfTicks output', result);
      return result;
    }
  }

  const numTicksForLargeScreen = isSingleDay ? 24 : 10;
  const numTicksForSmallScreen = isSingleDay ? 6 : 2;
  return width > 800 ? numTicksForLargeScreen : numTicksForSmallScreen;
}

/**
 * timezone, startDate and endDate, width
 */
export function XAxis({ dateScale, yMax, width, ...optionals }) {
  const { isSingleDay = true, ...rest } = optionals;

  // Hooks
  const { customerTimezone } = useCustomerDataOfDefaultUser();
  // const theme = useTheme();

  // Global states
  const { startDate, endDate } = useSelector(selectSelectedDate);

  return (
    <AxisBottom
      scale={dateScale}
      top={yMax}
      //@ts-ignore
      tickFormat={(d) => formatTickByDateRange(d, width,{ startDate, endDate },customerTimezone)}
      hideAxisLine={true}
      hideZero={true}
      numTicks={getNumOfTicks(isSingleDay, width)}
      tickLabelProps={() => Object.assign(tickLabelStyle)}
      tickClassName={"x-axis-tick-test"}
      {...rest}
    />
  );
}

/**
 * the number of ticks
 * timezone -> format date
 * Date range -> startDate and endDate, change format and num of ticks
 * width -> show fewer ticks on narrow screens
 *
 */
export function DateTimeXAxis({
  yMax,
  width,
  dateRange,
  timeScale,
  ...optionals
}: {
  dateRange: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  const { timezone, left = 0, tickValues, ...rest } = optionals;

  const { endDate, startDate } = dateRange || {
    endDate: undefined,
    startDate: undefined,
  };
  const diffInDays = calculateDiffInHoursForDateRange(endDate, startDate) / 24;
  const isSingleDay = diffInDays < 1;
  // console.debug('diffInDays', diffInDays);

  const numTicks = getNumOfTicks(isSingleDay, width, diffInDays);
  const step = Math.round(tickValues?.length / numTicks);
  const displayedTickValues = tickValues.filter((e, i) => i % step === 0);

  return (
    <AxisBottom
      //@ts-ignore
      scale={timeScale}
      top={yMax}
      //@ts-ignore
      tickFormat={(d) =>formatTickByDateRange(d as string, width, dateRange, timezone)}
      tickComponent={({ formattedValue, ...rest }) => {
        const [weekDay, monthDate] = formattedValue.split("-");
        const [wd,md] = formattedValue.split(',')
        if(wd && md) {
          return (
            <>
              <Text {...rest} x={rest.x-2}className="font-bold" textAnchor="end">{wd+','}</Text>
              <Text {...rest} x={rest.x+2}textAnchor="start">{md}</Text>
            </>
          )
        }
        return (
          <>
            {weekDay && <Text {...rest} className={weekDay && monthDate ? "font-bold" : ""}>{weekDay}</Text>}
            {monthDate && <Text {...rest} y={32}>{monthDate}</Text>}
          </>
        );
      }}
      hideAxisLine={true}
      hideZero={true}
      numTicks={getNumOfTicks(isSingleDay, width, diffInDays)}
      tickLabelProps={() => tickLabelStyle}
      tickClassName={"x-axis-tick-test"}
      left={left}
      tickValues={
        displayedTickValues
        // diffInDays < 1 || diffInDays > 31 ? undefined : displayedTickValues
      }
      {...rest}
    />
  );
}
