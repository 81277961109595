import { STATS_END_POINT } from "./constants";

export const GLOBAL_DATA = `${STATS_END_POINT}globalData`;
export const GLOBAL_DATA_SPLIT = `${STATS_END_POINT}globalData_splitted`;
export const GLOBAL_HISTORY_DATA = `${STATS_END_POINT}GetData_global_gathered_data`;

export const TRAFFIC_BY_BEACON_API = `${STATS_END_POINT}trafficByBeacon`;
export const GATHERED_TRAFFIC_BY_BEACON = `${STATS_END_POINT}GetData_traffic_by_beacon_gathered_data`;
export const TRAFFIC_BY_BEACON_GROUP_BY_CUSTOMER = `${STATS_END_POINT}GetData_traffic_for_customer_beacons`;

export const TRAFFIC_BY_BUILDING_API = `${STATS_END_POINT}trafficByBuilding`;
export const REPORT_API = `${STATS_END_POINT}pdf_sender`;
export const REPORT_SCHEDULE_API = `${STATS_END_POINT}report_schedule`;
export const AVERAGE_HOURLY_API = `${STATS_END_POINT}averageHourlyData`;

export const TRIANGULATION_GRID_API = `${STATS_END_POINT}triangulation_grid`;
export const TRIANGULATION_GRID_TYPES_API = `${STATS_END_POINT}triangulation_grid_types`;

export const GATHERED_HOURLY_COUNT_BY_CUSTOMER = `${STATS_END_POINT}gathered_data_only_hourly_count`;
