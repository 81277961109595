import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout, checkIsAuthInLocalStorageValid } from "features/auth";
import { useIdle } from "react-use";
import { notifyWarning } from "lib/services/notification";

// const IDLE_TIMEOUT = 1000 * 15; // 15 seconds (for testing)
const IDLE_TIMEOUT = 1000 * 15 * 60; // 15 minutes

/**
 * Auto logout after user idle for some time
 */
export function IdleContatiner(props) {
  // const { onIdled, ...rest } = props;
  const history = useHistory();
  const isIdle = useIdle(IDLE_TIMEOUT);

  useEffect(() => {
    if (isIdle && checkIsAuthInLocalStorageValid()) {
      // console.debug('Idle logged out');
      logout();
      history.push("/login");
      notifyWarning("You have been logged out due to inactivity");
    }
  }, [history, isIdle]);

  return <></>;
}
