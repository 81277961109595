import { useMemo } from "react";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useAppState } from "hooks";
import { TrafficGraphTypes } from "types";

export function TrafficDataSelector() {
  const { appState, appDispatch } = useAppState();
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: TrafficGraphTypes
  ) => {
    event.preventDefault();
    appDispatch({ type: "SET_TRAFFIC_GRAPH_TYPE", newValue: newAlignment });
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={currentGraphType}
      exclusive
      onChange={handleChange}
      aria-label="TrafficGraphType"
      className="!grid gap-2 !grid-cols-2 border border-solid !rounded-full p-1 mt-2 max-h-12"
    >
      <ToggleButton
        size="small"
        value={TrafficGraphTypes.visitors}
        className={
          currentGraphType === TrafficGraphTypes.visitors
            ? "p-1 border border-solid !rounded-full"
            : "p-1 border border-solid !border-transparent"
        }
        sx={{
          ":hover": {
            borderRadius: "9999px !important",
          },
        }}
      >
        Visitors
      </ToggleButton>
      <ToggleButton
        size="small"
        value={TrafficGraphTypes.passerbys}
        className={
          currentGraphType === TrafficGraphTypes.passerbys
            ? "p-1 border border-solid !rounded-full"
            : "p-1 border border-solid !border-transparent"
        }
        sx={{
          ":hover": {
            borderRadius: "9999px !important",
          },
        }}
      >
        Passerbys
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
