import { Button } from "components/atomic/Button";
import { Modal, ModalBody, ModalFooter } from "components/atomic/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout } from "features/auth";
import { useAppState } from "hooks";
import { getLocalStorage } from "lib/persistence/local-storage";
import {
  resetGlobalStates,
  selectPartnerCustomer,
  setPartnerCustomer,
} from "providers/redux/globalSelectSlice";

export function LogoutDialog({
  isOpen,
  onHide,
}: {
  isOpen: boolean;
  onHide?: () => void;
}) {
  let history = useHistory();
  const dispatch = useDispatch();
  const { userDispatch, appDispatch } = useAppState();
  const partnerCustomer = useSelector(selectPartnerCustomer);
  const partnerCustomerName = getLocalStorage("partnerCustomer");

  const onLogout = () => {
    logout();
    dispatch(resetGlobalStates(null));
    dispatch(setPartnerCustomer(null));
    userDispatch({ type: "LOGOUT" });
    appDispatch({ type: "RESET_APP_STATE" });
    let nextURL = "/login";
    if (partnerCustomerName) {
      nextURL += `?customer=${encodeURIComponent(partnerCustomerName)}`;
    }
    history.push(nextURL);
  };

  return (
    <Modal
      show={isOpen}
      onHide={onHide}
      aria-labelledby="logout-dialog-modal"
      className="min-w-fit max-w-xl"
    >
      <div>
        <ModalBody>
          Log out of your {partnerCustomer?.customer_name || "Kaidu"} account?
        </ModalBody>
        <ModalFooter>
          <Button onClick={onLogout} variant="primary" autoFocus>
            Logout
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
