import {useState, useRef} from 'react';
import { useMenuState } from '@szhsin/react-menu';
import { useClickAway } from 'react-use';

/**
 * use the feature of camera
 */
export function useCamera() {
    // Local state
    const [isCameraModalOpen, setIsCameraModalOpen] = useState(false); // for camera modal
    const [timestamp, setTimestamp] = useState(null); // for camera modal
    const [selectedScanner, setSelectedScanner] = useState(''); // for camera modal
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 }); // for click menu
    const [menuProps, toggleMenu] = useMenuState({ transition: true }); // for click menu
    const menuRef = useRef(null); // for click menu

    useClickAway(menuRef, () => {
      // console.log('OUTSIDE CLICKED');
      toggleMenu(false);
    });

    return {
        isCameraModalOpen,
        setIsCameraModalOpen,
        timestamp,
        setTimestamp,
        selectedScanner,
        setSelectedScanner,
        anchorPoint,
        setAnchorPoint,
        menuProps,
        toggleMenu,
        menuRef,
    }
}