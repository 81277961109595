import styled from 'styled-components';
import { Button } from 'components/atomic/Button';

/**
 * Button for changing selected date range
 */
export const SpecialDateButton = styled(Button)`
  font-family: 'Poppins';
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  /* line-height: 20px; */
  /* letter-spacing: 0.5px; */
  color: #5a6acf;
  mix-blend-mode: normal;
  background: #fbfcfe;
  border: 0.5px solid #dde4f0;
  box-shadow: 0px 2px 1px rgba(64, 72, 82, 0.05);
  border-radius: 5px;
  padding: 0.3rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`;