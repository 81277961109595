import {
  compareAsc,
  isSameDay as isSameDayNative,
  isSameHour,
  isToday as isTodayNative,
  isYesterday as isYesterdayNative,
} from "date-fns";
import { createDateByUnixTime } from "./foo";

/**
 * @param date  number is in miliseconds
 */
export function isToday(date: number | Date): boolean {
  return isTodayNative(date);
}

export function isYesterday(date: number): boolean {
  return isYesterdayNative(date);
}

export function isSameDayByUnixTime(date1: number, date2: number): boolean {
  return isSameDayNative(
    createDateByUnixTime(date1),
    createDateByUnixTime(date2)
  );
}

export function checkIfDiffInHour(dateNum1, dateNum2): boolean {
  const date1 = new Date(dateNum1);
  const date2 = new Date(dateNum2);
  return !isSameHour(date1, date2);
}

/**
 *
 * @param a Date or miliseconds
 * @param b Date or miliseconds
 * @returns {boolean} is a before or equals b. If any of the two is null, return null.
 */
export function compareIsEarlierThanOrEqual(
  a: Date | number,
  b: Date | number
): boolean {
  // console.log('a: ', a);
  // console.log('b: ', b);
  return compareAsc(a, b) <= 0;
}

/**
 *
 * @param a Date or miliseconds
 * @param b Date or miliseconds
 * @returns {boolean} is a before b. If any of the two is null, return null.
 */
export function compareIsEarlier(a: Date | number, b: Date | number): boolean {
  // console.log('a: ', a);
  // console.log('b: ', b);
  return compareAsc(a, b) < 0;
}

/**
 *
 * @param a
 * @param b
 * @returns {boolean} is a after or equals b. If any of the two is null, return null.
 */
export function compareIsLaterThanOrEqual(
  a: Date | number,
  b: Date | number
): boolean {
  return compareAsc(a, b) >= 0;
}
