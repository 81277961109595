import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { GOOGLE_CLIENT_ID } from "features/kaidu-config-server";
import theme from "../styles/themes/theme";
import { ReactQueryProvider } from "./react-query";
import { ReduxProvider } from "./redux";
import { GlobalProvider } from "./state/GlobalContext";

export function ProviderGroup({ children }) {
  return (
    <>
      <ReactQueryProvider>
        <GlobalProvider>
          <BrowserRouter>
            <ReduxProvider>
              <ThemeProvider theme={theme}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  {children}
                </GoogleOAuthProvider>
              </ThemeProvider>
            </ReduxProvider>
          </BrowserRouter>
        </GlobalProvider>
      </ReactQueryProvider>
    </>
  );
}
