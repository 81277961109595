import { useImageListByTime, useVideoListByTime } from "../hooks";
import { parseImageList, parseVideoList } from "../processors";
import { Spinner } from "components/atomic/Spinner";
import { Container, FullSizeContainer } from "components/atomic/Container";
import { parseString, getDateRFC } from "features/date";
import { addMinutes } from "date-fns/fp";
import { pipe, concat } from "ramda";
import styled from "styled-components";
import { ThumbnailList } from "./ThumbnailList";
import { Title } from "./Title";
import { useState } from "react";
import { MainMedia } from "./MainMedia";
import { CloseButton } from "components/atomic/CloseButton";
import { ErrorMsg } from "components/molecule/ErrorMsg";
import { MediaItem } from "../types";
import { isFilledArray } from "../../../../../utils";
// import sample2 from './mock-data/videoSample2Flv.json';
// Alternative: https://www.npmjs.com/package/react-image-gallery

// const add5Minutes = pipe(parseString, addMinutes(5), getDateRFC);
const addInterval = pipe(parseString, addMinutes(3), getDateRFC);

const MainContainer = styled.div`
  display: flex;
  min-width: 72vw;
  flex: 1;
  justify-content: center;
  margin-bottom: 1rem;
`;

const RootContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TopRightCloseButton = styled(CloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const initMediaID = "img_0";

export function CameraModalContent({
  cameraID,
  timestamp,
  deviceName,
  ...optionals
}) {
  const { onHide } = optionals;
  // const { onHide, ...rest } = optionals;
  // Data Hooks
  const {
    isLoading: isLoadingImageList,
    data: imagListdata,
    error: imageListError,
  } = useImageListByTime(cameraID, timestamp);
  const {
    isLoading: isLoadingVideoList,
    data: videoListdata,
    error: videoListError,
  } = useVideoListByTime(cameraID, timestamp, addInterval(timestamp));

  // Local state
  const [mainMediaItem, setMainMediaItem] = useState(initMediaID); // selected media to be shown in the main container

  if (isLoadingImageList && isLoadingVideoList) {
    return (
      <FullSizeContainer
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Spinner variant="light" size="xl" />
        <TopRightCloseButton onClick={onHide} variant="white" />
      </FullSizeContainer>
    );
  }

  if (imageListError || videoListError) {
    return (
      <FullSizeContainer
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <ErrorMsg>{imageListError}</ErrorMsg>
        <TopRightCloseButton onClick={onHide} variant="white" />
      </FullSizeContainer>
    );
  }

  // Process data
  const imageList = parseImageList(imagListdata) || [];
  const defaultThumbnail = isFilledArray(imageList) ? imageList[0]?.src : "";
  const videoList = parseVideoList(videoListdata, defaultThumbnail) || [];
  const mediaList: MediaItem[] = concat(imageList, videoList);
  // console.debug('image list: ', imageList);
  // console.debug('video list: ', videoList);

  return (
    <RootContainer data-cy={"camera-modal-content-container"}>
      <Container
        data-cy="camera-modal-content-top"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title text={`Zone: ${deviceName ?? "Not available"}`} />
        <CloseButton onClick={onHide} variant="white" />
      </Container>
      <MainContainer data-cy="camera-modal-content-main">
        {mainMediaItem ? (
          <MainMedia
            data={mediaList?.find((item) => item?.mediaID === mainMediaItem)}
          />
        ) : null}
      </MainContainer>
      <div data-cy="camera-modal-content-bottom">
        <ThumbnailList
          mediaList={mediaList}
          timestamp={timestamp}
          mainMediaItem={mainMediaItem}
          onChangeMainMediaItem={(mediaID) => setMainMediaItem(mediaID)}
        />
      </div>
    </RootContainer>
  );
}
