import { useState } from "react";
import { useMeasure } from "react-use";

import { LargeContainer } from "components/molecule/Containers/LargeContainer";
import { TimeZoneText } from "components/molecule/TimeZoneText";
import { VisitorTrafficGraphForecast } from "domain/visitor-traffic/visitor-traffic-data";
import { DateBtnGroup } from "features/date/components/molecule/DateBtnGroup";
import { ErrorBoundary } from "features/error-handling";
import { getDataKeysFromStatsServer } from "features/kaidu-stats-server";
import { useMediaQueries } from "styles/themes";
import { isFilledArray } from "utils";
import { TrafficGraphUIData } from "../../../features/charts";
import { useUsersList } from "../../../features/kaidu-config-server";
import { VisitorTrafficGraphData } from "../../../types";
import { SelectedDateText } from "../../time-range/date-range/components/TimeRange/SelectedDateText";
import { VisitorTrafficBarChart } from "../VisitorTrafficGraph/VisitorTrafficBarChart";
import { LegendsGroupWithoutGlobalStates } from "../VisitorTrafficGraph/components/LegendsGroup";

export type Props = {
  data:
    | {
        data: VisitorTrafficGraphData[];
        forecast: VisitorTrafficGraphForecast[];
      }
    | undefined;
  isLoading: boolean;
  hideLeftAxis?: boolean;
  [x: string]: any;
};

/**
 *
 */
export function VisitorTrafficContainer({
  data,
  isLoading,
  hideLeftAxis = true,
  ...optionals
}: {
  data: TrafficGraphUIData;
  isLoading: boolean;
  hideLeftAxis: boolean;
  [x: string]: any;
}) {
  const {
    onBarClick,
    enableBarClick = false,
    headerChildren,
    bodyTop = (
      <div className="flex justify-between mb-1">
        <DateBtnGroup />
      </div>
    ),
    showBodyTopElements = true,
    showForecast = true,
    forecastProps,
    title,
    isModal = false,
    showCategoryMenu = true,
    timezone,
    graphLabel,
    ...rest
  } = optionals;

  // Process Props
  const { subData } = data || {
    mainData: [],
    forecast: [],
    subData: [],
    isData: false,
  };
  const availableSubBarProps = getDataKeysFromStatsServer(subData);

  //Hooks
  const [ref, { width }] = useMeasure();
  const isMedium = useMediaQueries("sm");

  // only show selector for super user
  const { userData } = useUsersList();
  const isSuperUser = userData?.isSuperUser;

  // Local state
  const [selectedBarCategory, setSelectedBarCategory] = useState([]);

  const isError = !data && !isLoading;

  return (
    <LargeContainer
      title={title ?? "Visitor Traffic"}
      icon={title ? "stats-bars" : ""}
      isLoading={isLoading}
      error={isError}
      headerChildren={
        headerChildren || (
          <div className="flex flex-col mt-2 lg:mt-0 lg:items-end">
            <SelectedDateText />
            <TimeZoneText timezone={timezone} />
          </div>
        )
      }
      className="min-w-min flex-1 relative"
      data-cy={"visitor-traffic-graph-container"}
      style={{
        boxShadow: isMedium ? "5px -5px 5px 0px rgba(0,0,0,0.2)" : "unset",
      }}
      {...rest}
    >
      <ErrorBoundary>
        {showBodyTopElements && bodyTop ? bodyTop : null}
        <div ref={ref} className={isModal ? "max-w-full" : "mt-4 max-w-full"}>
          <VisitorTrafficBarChart
            width={width * 0.99}
            height={isModal ? 300 : 420}
            data={data}
            hideLeftAxis={hideLeftAxis}
            onBarClick={enableBarClick ? onBarClick : null}
            selectedBarCategory={selectedBarCategory}
            showForecast={showForecast}
            forecastProps={forecastProps}
            timezone={timezone}
          />
        </div>
        {showCategoryMenu && (
          <LegendsGroupWithoutGlobalStates
            forecastProps={forecastProps}
            graphLabel={graphLabel}
            subCategoryProps={{
              shouldShow: isFilledArray(availableSubBarProps) && isSuperUser,
              selectableProps: availableSubBarProps,
              selected: selectedBarCategory,
              setSelected: setSelectedBarCategory,
            }}
          />
        )}
      </ErrorBoundary>
    </LargeContainer>
  );
}
