import { getLocalTimeZone } from "features/date";
import {
  useBuildingPreconfigs,
  useBuildingPreconfigsOfCustomer,
} from "../customers-building";
import {
  useFloorPreconfigs,
  // FloorDTO,
  useFloorPreconfigsOfCustomer,
} from "../customers-floor";
import { useCustomer } from "../customers-list";
import {
  LocationDTO,
  useLocationPreconfigs,
  useLocationPreconfigsOfCustomer,
} from "../customers-location";
import { User, useUsersList } from "../users-list";
import { HoursOfOperation } from "./../customers-list/types/hours-of-operation";
import { findBuildingForLocation } from "./processors";

export function usePreconfigs() {
  const { buildings, isLoadingBuildings, isBuildingsError } =
    useBuildingPreconfigs();
  const { floors, isLoadingFloors, isFloorsError } = useFloorPreconfigs();
  const { locations, isLoadingLocations, isLocationsError } =
    useLocationPreconfigs();

  return {
    buildings,
    floors,
    locations,
    isLoading: isLoadingBuildings || isLoadingFloors || isLoadingLocations,
    isError: isBuildingsError || isFloorsError || isLocationsError,
  };
}

export function usePreconfigsOfCustomer(customerID: string) {
  // console.log("file: hooks.ts:39 ~ usePreconfigsOfCustomer ~ customerID", customerID)
  const { buildings, isLoadingBuildings, isBuildingsError } =
    useBuildingPreconfigsOfCustomer(customerID);
  const { floors, isLoadingFloors, isFloorsError } =
    useFloorPreconfigsOfCustomer(customerID);
  const { locations, isLoadingLocations, isLocationsError } =
    useLocationPreconfigsOfCustomer(customerID);

  return {
    buildings,
    floors,
    locations,
    isLoading: isLoadingBuildings || isLoadingFloors || isLoadingLocations,
    isError: isBuildingsError || isFloorsError || isLocationsError,
  };
}

/**
 * If timezone is missing, use local time zone
 * @return the default customer item for current user
 */
export function useCustomerDataOfDefaultUser(
  enabled: boolean = true,
  optArgs?: any
) {
  const {
    userData,
    isLoading: isLoadingUsersList,
  }: { userData: User; isLoading: boolean } = useUsersList(enabled);
  const customerID: string = userData?.customers_list_id;
  const {
    data: customerData,
    isLoading: isLoadingCustomer,
    ...rest
  } = useCustomer(customerID, optArgs);
  const hoursOfOperation: HoursOfOperation = customerData?.customer_config
    ?.hoursOfOperation ?? {
    start: null,
    end: null,
    timezone: null,
  };
  const customerTimezone: string =
    customerData?.customer_config?.hoursOfOperation?.timezone ??
    getLocalTimeZone();
  return {
    ...rest,
    data: customerData,
    isLoading: isLoadingUsersList || isLoadingCustomer,
    userData,
    customerTimezone,
    customerID,
    hoursOfOperation,
  };
}

type BuildingAndLocation = { building: string; location: string };

/**
 * @description location param must be accompanied with building param
 * @returns the parameters for both building and location
 */
export function useLocationParams(
  customerID: string,
  location: LocationDTO,
  selectedBuilding
): { params: BuildingAndLocation; isLoading: boolean; [x: string]: any } {
  const { buildings, floors, locations, isLoading } =
    usePreconfigsOfCustomer(customerID);

  const matchedBuildingForlocation = findBuildingForLocation(
    location,
    buildings,
    floors
  );

  const buildingForRequest =
    selectedBuilding?.value ?? matchedBuildingForlocation?.building_name;

  const params = {
    building: buildingForRequest,
    location: buildingForRequest ? location?.value : null,
  };

  return { params, buildings, floors, locations, isLoading };
}
