import { useTable } from "hooks/useTable";
import { isFilledArray } from "utils";
import { FullSizeTableView } from "components/features/Table/FullSizeTableView";
import { NoData } from "components/features/Table/NoData";
import { useMediaQueries } from "styles/themes";
import { UserCardList } from "./UserCardList";

/**
 *
 */
export function UserTable({ columns, data, ...optionals }) {
  const {
    emptyState = <NoData />,
    searchBarPlaceholder = "Search",
    // ...rest
  } = optionals;

  // @ts-ignore
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
    prepareRow,
    setGlobalFilter,
  } = useTable(columns, data);
  const isLg = useMediaQueries("lg");

  // return null;

  const shouldShowPagination = true && pageCount > 1;
  const shouldShowFilter = true && isFilledArray(data);
  const filter = {
    shouldShowFilter,
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
  };
  const pagination = {
    shouldShowPagination,
    previousPage,
    gotoPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageIndex,
  };
  const hasData = isFilledArray(data);

  return (
    <div>
      {isLg ? (
        <FullSizeTableView
          filter={filter}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          emptyState={emptyState}
          pagination={pagination}
        />
      ) : (
        <UserCardList
          filter={filter}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          hasData={hasData}
          emptyState={emptyState}
          pagination={pagination}
        />
      )}
    </div>
  );
}
