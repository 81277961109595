import {
  BASE64_FILE_TYPES,
  FILE_TYPE_TO_IMG_SRC_PREFIX,
  FILE_TYPE_TO_VIDEO_SRC_PREFIX,
} from '../mime-file-type';

export function getFileTypeOfBase64(base64: string): string {
  if (!base64) return null;
  const c = base64.charAt(0);
  return BASE64_FILE_TYPES[c];
}

/**
 *
 * @param base64
 * @returns img src, default file type: svg
 */
export function generateImageSrcForBase64(base64: string): string {
  const imageType = getFileTypeOfBase64(base64);
  const prefix =
    FILE_TYPE_TO_IMG_SRC_PREFIX[imageType] ||
    FILE_TYPE_TO_IMG_SRC_PREFIX['svg'];

  return `${prefix}${base64}`;
}

/**
 *
 * @param base64
 * @returns img src, default file type: svg
 */
export function addBase64PrefixForImageSrc(
  imageType: string,
  base64: string
): string {
  const prefix = FILE_TYPE_TO_IMG_SRC_PREFIX[imageType];

  return `${prefix}${base64}`;
}

/**
 *
 * @param base64
 * @returns img src, default file type: svg
 */
export function addBase64PrefixForMIMEType(
  type: string,
  base64: string
): string {
  const prefix =
    FILE_TYPE_TO_IMG_SRC_PREFIX[type] || FILE_TYPE_TO_VIDEO_SRC_PREFIX[type];

  return `${prefix}${base64}`;
}
