import { localPoint } from "@visx/event";
import { BarRounded } from "@visx/shape";
import { useTheme } from "styled-components";
import { getBarWidth, tooltipTimeout } from "./CategorizedBarGroup";
import { getRadius } from "./MainBar";
import { Bar } from "components/features/charts/Bar";
// import { pipe, filter, equals } from 'ramda';

/**
 * hide the bar if height is 0
 */
export function SubBar({ handleMouseLeave, showTooltip, data, ...optionals }) {
  const { isBarClickEnabled, onClick } = optionals;
  // Hooks
  const theme = useTheme();
  // console.debug('barGroups', barGroups);
  const { x, y, width, height, key, date, color: barColor, value } = data || {};
  const color = theme.colors.graph[key] || barColor;
  // console.debug('bar: ', bar);
  const handleMouseMove = (event) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const eventSvgCoords = localPoint(event);
    showTooltip({
      tooltipData: {
        bar: data,
        time: date,
      },
      tooltipTop: eventSvgCoords?.y,
      tooltipLeft: eventSvgCoords?.x,
    });
  };

  if (!height || !value) {
    return null;
  }

  return (
    <Bar
      x={x}
      y={y}
      width={getBarWidth(width)}
      height={height}
      fill={color}
      // all={true}
      top={true}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      radius={getRadius(width)}
      $clickable={isBarClickEnabled}
      onClick={onClick}
    />
  );
}
