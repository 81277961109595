import { getData } from 'features/axios';
import { CAMERA_LIST_URL, IMAGE_URL, VIDEO_URL, IMAGE_LIST_SPECIFIC_TIME_URL, VIDEO_LIST_SPECIFIC_TIME_URL, WORKING_CAMERA_LIST_URL } from './api';

export function fetchLiveCameraList(): Promise<any> {
  return getData(CAMERA_LIST_URL, { headers: null });
}

export function fetchImageList(cameraID: string): Promise<any> {
  return getData<any>(IMAGE_URL, { params: {cameraID}, headers: null });
}

export function fetchVideoList(cameraID: string) {
  return getData<any>(VIDEO_URL, { params: {cameraID}, headers: null });
}

export function fetchImageListByTime(cameraID: string, timestamp: string): Promise<any> {
  const processedCameraID = cameraID?.trim();
  return getData<any>(IMAGE_LIST_SPECIFIC_TIME_URL, { params: {cameraID: processedCameraID, timestamp}, headers: null });
}

export function fetchVideoListByTime(cameraID: string, startTime: string, endTime: string) {
  const processedCameraID = cameraID?.trim();
  return getData<any>(VIDEO_LIST_SPECIFIC_TIME_URL, { params: {cameraID: processedCameraID, timestamp_start: startTime, timestamp_end: endTime}, headers: null });
}

export function fetchWrokingCameraList(): Promise<any> {
  return getData(WORKING_CAMERA_LIST_URL, { headers: null, timeout: 1000 * 60 * 5 });
}