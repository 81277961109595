import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Spinner } from "components/atomic/Spinner";
import { getAWeekDateRangeForYesterday, getLocalTimeZone } from "features/date";
import { ErrorBoundary } from "features/error-handling";
import { HoursOfOperation } from "features/kaidu-config-server";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  selectPartnerCustomer,
  selectSelectedCustomer,
} from "providers/redux/globalSelectSlice";
import { AppPageLayout } from "../../AppPageLayout";
import { HomePageContent } from "./HomePageContent";

/**
 * should display an overview across the customer
 */
export function Home() {
  const customer = useSelector(selectSelectedCustomer);
  const partnerCustomer = useSelector(selectPartnerCustomer);

  useDocumentTitle(partnerCustomer?.customer_name || "Kaidu");

  const customerTimezone: string = useMemo(
    () =>
      customer?.customer_config?.hoursOfOperation?.timezone ??
      getLocalTimeZone(),
    [customer]
  );
  const hoursOfOperation: HoursOfOperation = useMemo(
    () =>
      customer?.customer_config?.hoursOfOperation ?? {
        start: null,
        end: null,
        timezone: null,
      },
    [customer]
  );
  const defaultSelectedDateRange = useMemo(() => {
    const dateRange = getAWeekDateRangeForYesterday();
    return { ...dateRange, timezone: customerTimezone };
  }, [customerTimezone]);

  const customerData = useMemo(() => {
    return {
      customerTimezone,
      customerID: customer?.customer_id,
      hoursOfOperation,
    };
  }, [customerTimezone, customer, hoursOfOperation]);
  const addOnFeatures = {
    camera: {
      enabled: false,
    },
    dailyGraph: {
      enabled: true,
    },
  };

  return (
    <AppPageLayout className="max-w-full">
      <ErrorBoundary>
        {!customer ? (
          <Spinner className="flex-1 flex-grow h-screen" size={"md"} />
        ) : (
          <HomePageContent
            customerData={customerData}
            defaultSelectedDateRange={defaultSelectedDateRange}
            addOnFeatures={addOnFeatures}
          />
        )}
      </ErrorBoundary>
    </AppPageLayout>
  );
}
