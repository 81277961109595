import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";

import { Spinner } from "components/atomic/Spinner";
import {
  checkIsAuthInLocalStorageValid,
  checkIsTokenValid,
  clearAuthTokenInLocalStorage,
  getAuthTokenFromLocalStorage,
  getDecodeAuthTokenFromLocalStorage,
  getPartnerCustomerIdFromLocalStorage,
  setTokenToLocalStorage,
} from "features/auth";
import { getAxiosDefaultAuthHeader, setAxiosDefault } from "features/axios";
import {
  BASE_API,
  fetchCustomerByName,
  useCustomerDataOfDefaultUser,
} from "features/kaidu-config-server";
import { useAppState } from "hooks";
import useDocumentIcon from "hooks/useDocumentIcon";
import useDocumentTitle from "hooks/useDocumentTitle";
import { TEST_TOKEN } from "lib";
import {
  getLocalStorage,
  setToLocalStorage,
} from "lib/persistence/local-storage";
import {
  getCurrentUser,
  selectPartnerCustomer,
  selectSelectedCustomer,
  setCurrentUser,
  setPartnerCustomer,
  updateSelectedCustomer,
} from "providers/redux/globalSelectSlice";
import { UserState } from "providers/state/reducers";
import { ROUTES } from "../constants";
import PrivateRoute from "./PrivateRoute";

/**
 *  if auth is not valid, render public routes
 *  if auth is valid, update request headers and render private routes
 */
export function RouteGroup() {
  const token = getAxiosDefaultAuthHeader();
  const { user } = getDecodeAuthTokenFromLocalStorage() || {};

  // Hooks
  const dispatch = useDispatch();
  const customer = useSelector(selectSelectedCustomer);
  const partnerCustomer = useSelector(selectPartnerCustomer);
  const currentUser = useSelector(getCurrentUser);
  const history = useHistory();
  const { pathname, search } =
    useLocation<{ error?: string; email?: string }>();
  const { userState, userDispatch } = useAppState();
  const isPathForOTPVerify = pathname === "/verify_otp";
  const isLoginSuccessPath =
    pathname.toLowerCase().indexOf("/login/success") > -1;
  useDocumentTitle(partnerCustomer?.customer_name || "Kaidu");
  useDocumentIcon(partnerCustomer?.customer_config?.image);

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const customerName = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get("customer");
  }, [search]);
  const localPartnerCustomerName = getLocalStorage("partnerCustomer");

  // const loadPartnerCustomerById = useCallback(
  //   (customer_id: string) =>
  //     fetchCustomer(customer_id).then((partner) => {
  //       if (partner) {
  //         dispatch(setPartnerCustomer(partner));
  //       }
  //     }),
  //   [dispatch]
  // );

  const loadPartnerCustomerByName = useCallback(
    (customer_name: string) =>
      fetchCustomerByName(customer_name).then((partner) => {
        if (partner) {
          dispatch(setPartnerCustomer(partner));
        }
        setToLocalStorage("partner_customer_id", partner?.customer_id || null);
      }),
    [dispatch]
  );

  const {
    data: customerData,
    userData,
    isLoading: loadingCustomer,
  } = useCustomerDataOfDefaultUser(
    !isLoginSuccessPath &&
      !isPathForOTPVerify &&
      Boolean(user) &&
      Boolean(token)
  );

  useEffect(() => {
    // console.debug("Kaidu config server url: ", BASE_API);
    // console.debug('ENV_FILE_NAME', process.env?.REACT_APP_ENV_FILE_NAME);
    // console.debug('TEST_TOKEN', TEST_TOKEN);

    // When it's mounted, check local auth token
    // if it's valid, update used token in memory
    // else, clear the token and go to login page

    const shouldUpdateTokenForAPI =
      !isLoginSuccessPath &&
      !isPathForOTPVerify &&
      pathname.toLowerCase().indexOf("/login/failure") === -1;

    if (shouldUpdateTokenForAPI) {
      // update axios token if it's authenticated when the app is mounted
      // console.debug('usedToken', usedToken);
      if (
        checkIsAuthInLocalStorageValid() ||
        (TEST_TOKEN && checkIsTokenValid(TEST_TOKEN))
      ) {
        // token is not expired
        const usedToken = checkIsAuthInLocalStorageValid()
          ? getAuthTokenFromLocalStorage()
          : TEST_TOKEN;
        const partner_customer_id = getPartnerCustomerIdFromLocalStorage();
        setAxiosDefault(usedToken, partner_customer_id);
        usedToken && setTokenToLocalStorage(usedToken || "");

        setIsLoading(false);
        history.push("/");
        return;
      } else {
        console.warn("No valid token");
        // should log out
        clearAuthTokenInLocalStorage();
        setIsLoading(false);
        history.push(
          customerName ? `/login?customer=${customerName}` : "/login"
        );
      }
    } else {
      setIsLoading(false);
    }
  }, [partnerCustomer, isPathForOTPVerify, isLoginSuccessPath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerData && !customer) {
      dispatch(updateSelectedCustomer(customerData));
    }
  }, [customer, customerData, dispatch]);

  useEffect(() => {
    if (userData && !currentUser) {
      dispatch(setCurrentUser(userData));
    }
  }, [currentUser, userData, dispatch]);

  useEffect(() => {
    if (userData && !userState?.isAuth) {
      const newUserData: UserState = {
        isAuth: userData.isActive,
        userId: userData.user_id,
        username: userData.user_name,
        isSuper: userData.isSuperUser,
        isPartner: userData.is_partner || false,
        customerId: userData.customers_list_id,
        userPicture: user?.picture,
        allowed_customer_ids: userData?.allowed_customer_ids,
        token: "",
      };
      userDispatch({ type: "UPDATE_USER", payload: newUserData });
    }
  }, [userData, userState, user, userDispatch]);

  useEffect(() => {
    if (!partnerCustomer && localPartnerCustomerName) {
      loadPartnerCustomerByName(localPartnerCustomerName);
    }
  }, [partnerCustomer, localPartnerCustomerName, loadPartnerCustomerByName]);

  if (
    (isLoading || loadingCustomer) &&
    !isLoginSuccessPath &&
    !isPathForOTPVerify
  ) {
    // console.debug('isLoading', isLoading);
    // console.debug('isLoginSuccess', isLoginSuccess);
    return (
      <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} />
    );
  }

  const isAuthValid = checkIsAuthInLocalStorageValid();

  return (
    <>
      <Switch>
        {ROUTES.map((elem) => {
          const { route, exact, component, isPrivate, ...optionals } =
            elem || {};
          if (isPrivate)
            return (
              <PrivateRoute
                key={route}
                isAuthenticated={isAuthValid}
                exact={exact}
                path={route}
                component={component}
                {...optionals}
              />
            );
          else {
            return (
              <Route
                key={route}
                path={route}
                exact={exact}
                component={component}
                {...optionals}
              />
            );
          }
        })}
      </Switch>
    </>
  );
}
