
// * Items to show from Zone information
export const showItems = {
  device_name: 'Name',
  mac_address: 'MAC Address',
  building: 'Building',
  location: 'Location',
  floor: 'Floor',
  wifi_ssid: 'WiFi',
  wifi_password: 'WiFi Password',
};

export const ZoneUI = {
  device_name: { label: 'Name', type: 'key' },
  mac_address: { label: 'MAC Address', type: 'basic' },
  building: { label: 'Building', type: 'config' },
  location: { label: 'Location', type: 'config' },
  floor: { label: 'Floor', type: 'config' },
  wifi_ssid: { label: 'Wi-Fi', type: 'config' },
  wifi_password: { label: 'Wi-Fi Password', type: 'config' },
};
