export const HEATMAP_COLORS = [
  // '#f6f1fe',
  '#e0dfdc',
  '#d3b9fc',
  '#b080f9',
  '#8e48f6',
  '#620be5',
];

export const VISUAL_MAP_FIXED_PROPS = {
  min: 0,
  // maxOpen: true,
  type: 'piecewise',
  orient: 'horizontal',
  // left: 'right',
  // top: 20,
  bottom: 10,
  // inRange: {
  //   color: HEATMAP_COLORS
  // },
  // showLabel: false,
  // outOfRange: {
  //   color: '#fff'
  // },
  // selectedMode: false,
  itemGap: 4,
  itemHeight: 20,
  hoverLink: false,
  backgroundColor: 'transparent'
};

export const calendarLabelFontSize = 14;
export const calendarDefaultProps = {
  // top: 80,//45,
  bottom: 60,
  // cellSize: [32, 32],
  cellSize: ['auto', 'auto'],
  itemStyle: {
    borderWidth: 0,
    color: '#AAAAAA' // background color of the whole calendar
  },
  yearLabel: { show: false },
  monthLabel: {
    fontSize: calendarLabelFontSize
  },
  dayLabel: {
    nameMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    fontSize: calendarLabelFontSize
  },
  splitLine: {
    lineStyle: {
      type: 'dotted'
    }
  },
}