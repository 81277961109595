import { getDate, getMonth, getYear, subDays } from "date-fns";
import { TrafficResponseData } from "features/kaidu-stats-server";
import { padZeroTo2Digits } from "utils";

export function setDateByDiffToToday(date: string, days: number) {}

export function getDayMonthYear(date: Date) {
  const year = getYear(date);
  const month = getMonth(date);
  const day = getDate(date);
  return { year, month: month + 1, day };
}

export function modifyDateStringByDate(prev: string, next: Date) {
  const { year, month, day } = getDayMonthYear(next);
  const nextDatePart = `${year}-${padZeroTo2Digits(month)}-${padZeroTo2Digits(
    day
  )}`;
  // const [datePart, timePart] = prev.split('T');
  const [, timePart] = prev.split("T");
  return `${nextDatePart}T${timePart}`;
}

/**
 * set all dates. First element's date would be today, second would be yesterday, and so on
 */
export function setAllDatesToBeBackFromToday(data: any[]) {
  const today = new Date();
  // const {year, month, day} = getDayMonthYear(today);

  const processed = data.map((item, index) => {
    const targetDate = subDays(today, index);
    const { date } = item || {};
    const nextDate = modifyDateStringByDate(date, targetDate);
    return { ...item, date: nextDate };
  });
  return processed;
}

/**
 * set all dates. Last element's date would be today, second-to-the-last would be yesterday, and so on
 */
export function setAllDatesToBeAscendUntilToday(data: any[]) {
  const today = new Date();
  // const {year, month, day} = getDayMonthYear(today);

  const processed = data.map((item, index) => {
    const targetDate = subDays(today, index);
    const { date } = item || {};
    const nextDate = modifyDateStringByDate(date, targetDate);
    return { ...item, date: nextDate };
  });
  return processed.reverse();
}

export function modifyResponseDates(
  data: TrafficResponseData
): TrafficResponseData {
  const processedDates = setAllDatesToBeAscendUntilToday(data.graph.data);
  data.graph.data = processedDates;
  const resultData = data;
  return resultData;
}
