import { Input2 } from "components/atomic/Input";
import { MuiLabel } from "components/atomic/Label";
import { useController } from "react-hook-form";

/**
 * Edit Customer Name Input
 */
export function EditCustomerName({ control, ...optionals }) {
  const {
    field: { onChange, value },
  } = useController({
    name: "customer_name",
    control,
  });

  return (
    <>
      <MuiLabel required>Name</MuiLabel>
      <Input2
        required
        id="standard-required"
        // label='Name'
        variant="standard"
        onChange={onChange}
        value={value}
        inputProps={{ size: 30, maxLength: 30 }}
      />
    </>
  );
}
