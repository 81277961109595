import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { Button } from "components/atomic/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import {
  CUSTOMERS_LIST_API,
  CustomersListItem,
  DEVICE_CONFIG_API,
  putCustomer,
} from "features/kaidu-config-server";
import { updateSelectedCustomer } from "providers/redux/globalSelectSlice";
import { getDefaultCustomerConfiguration } from "../../../../application/components/SelectCustomerModal";
import { notifySuccess } from "../../../../lib";
import { CustomerConfigForm } from "../CustomerConfigForm";
import { CustomerSettingsContainer } from "../CustomerSettings";

/**
 * Edit Customer Configurations: Name, Hours of operation, Timezone
 * get default customer data from prop
 */
export function EditCustomer({
  show,
  onHide,
  data,
  ...optionals
}: {
  show: boolean;
  onHide: () => void;
  data: CustomersListItem;
  [x: string]: any;
}) {
  // Props
  const dispatch = useDispatch();
  const defaultValues = getDefaultCustomerConfiguration(data);
  const currentCustomerName = data?.customer_name;
  const customerID = data?.customer_id;

  // Hooks
  const { control, setValue, formState, reset, handleSubmit } = useForm({
    defaultValues,
  });
  const queryClient = useQueryClient();
  const updateCustomer = async ({ nextCustomer, resetValues }) =>
    putCustomer(nextCustomer);
  const customerPutMutation = useMutation(updateCustomer, {
    onSuccess: (response, variables) => {
      if (response.data) dispatch(updateSelectedCustomer(response.data));
      queryClient.invalidateQueries(["customer"]);
      queryClient.invalidateQueries(["customer", customerID]);
      queryClient.invalidateQueries([DEVICE_CONFIG_API]);
      queryClient.invalidateQueries([CUSTOMERS_LIST_API]);
      reset(variables?.resetValues);
      onHide && onHide();
      notifySuccess("Customer settings updated successfully");
    },
  });

  const handleSubmission = (values) => {
    const { customer_name, ...rest } = values || {};
    const nextCustomer = {
      ...data,
      customer_name: customer_name,
      customer_config: { ...data.customer_config, hoursOfOperation: rest },
    };
    customerPutMutation.mutate({ nextCustomer, resetValues: values });
    return;
  };

  // const handleHide = () => {
  //   reset();
  //   onHide && onHide();
  // };

  return (
    <Modal className="m-auto max-w-4xl" show={show} onHide={onHide}>
      <ModalHeader>
        <ModalTitle>
          <p className="break-all"> Edit Site: {currentCustomerName}</p>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <CustomerSettingsContainer>
          <CustomerConfigForm formProps={{ control, setValue, formState }} />
        </CustomerSettingsContainer>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleSubmit(handleSubmission)}
          className={"capitalize"}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
