import { useSelector } from "react-redux";

import { Button } from "components/atomic/Button";
import { CUSTOMER_ENTITY_LABEL, EditCustomer } from "domain/customer";
// import { CustomerSettingsContainer } from 'domain/customer/components/CustomerSettings';
import { UsersContainer } from "domain/user/components";
import useModal from "hooks/useModal";
import { ZonesContainer } from "../../../domain/zone/components/ZonesContainer";
import { INFO_SECTION } from "./constants";
// import styled from 'styled-components';
import { Icon } from "components/atomic/Icon";
import { InfoSection } from "components/organism/InfoSection";
import { checkShouldEnableCamera } from "domain/camera";
import { selectSelectedCustomer } from "providers/redux/globalSelectSlice";
import { useMediaQueries } from "styles/themes";
import { capitalizeFirstLetter } from "utils";
import { Spinner } from "../../../components/atomic/Spinner";

/**
 * All useful and meaningful content of settings page
 */
export function SettingsContent(props) {
  // Hooks
  const customerData = useSelector(selectSelectedCustomer);

  const isLg = useMediaQueries("lg");
  const { isShowing: isShowAdmin, toggle: adminModalToggle } = useModal();

  const isLoading = !customerData;
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <EditCustomer
        show={isShowAdmin}
        onHide={adminModalToggle}
        key={`edit-customer-modal-${customerData.customer_id}`}
        data={customerData}
      />
      <div className="flex justify-between mt-4" data-cy="settings-page-header">
        {isLg ? <InfoSection {...INFO_SECTION} /> : null}
        <div className="flex lg:justify-end items-center flex-1 gap-x-4">
          <Button
            onClick={adminModalToggle}
            className={"w-4/5 lg:w-auto "}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50px",
              width: "160px",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
              color: "white",
              border: "2px solid #0d6efd",
              backgroundColor: "#0d6efd",
            }}
          >
            <div className={"flex justify-center items-center gap-x-2"}>
              <Icon name="FaSlidersH" />
              {capitalizeFirstLetter(CUSTOMER_ENTITY_LABEL)} Settings
            </div>
          </Button>
        </div>
      </div>

      <div data-cy="settings-page-content-container">
        {/* <CustomerSettingsContainer /> */}
        <ZonesContainer
          enableBarClick={checkShouldEnableCamera(customerData.customer_id)}
          key={`zones-table-${customerData.customer_id}`}
          customerID={customerData.customer_id}
        />
        <UsersContainer />
      </div>
    </>
  );
}
