import {
  selectSelectedDate,
  selectIsSelectedDateSameDay,
  subDaysToEndDate,
  updateSelectedDate,
} from "providers/redux/globalSelectSlice";
import { Button } from "components/atomic/Button";
import { useDispatch, useSelector } from "react-redux";
import { shiftDateRangeBackward, diffInCalendarDays } from "features/date";

function getDateText(globalDate): string {
  const { startDate, endDate } = globalDate || {};
  if (!Boolean(startDate)) {
    return "Previous Day";
  } else {
    const diff = diffInCalendarDays(endDate * 1000, startDate * 1000);
    if (diff === 7) {
      return "Previous Week";
    } else {
      return "Previous Period";
    }
  }
}

export function PrevDateBtn({ ...optionals }) {
  const { onClick } = optionals;

  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(selectSelectedDate) || {};

  const isSingleDay = useSelector(selectIsSelectedDateSameDay);
  const text = getDateText({ startDate, endDate });

  const handleClick = () => {
    if (isSingleDay) {
      dispatch(subDaysToEndDate(1));
    } else {
      const nextDateRange = shiftDateRangeBackward({ startDate, endDate });
      dispatch(updateSelectedDate(nextDateRange));
    }

    onClick && onClick();
  };

  return (
    <Button
      onClick={handleClick}
      variant="outline-secondary"
      size="sm"
      data-cy={"next-day-button"}
    >
      {text}
    </Button>
  );
}
