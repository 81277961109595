import { useState } from "react";

import { LogoutDialog } from "domain/auth/components/LogoutDialog";
import { VERSION } from "lib";
import { useMediaQueries } from "styles/themes";
import { LinkedNavItem, SideNav } from "../components/organism/Nav";
import { MobileNav2 } from "../components/organism/Nav/MobileNav2";

/**
 * Navigation bar for Application
 */
export function Nav({ navItems, ...optionals }) {
  // Debugging version number
  console.log(`version - ${VERSION}`);
  const { centerIDs } = navItems || {};
  // Hooks
  const isWide = useMediaQueries("lg");
  // Local states
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  return (
    <>
      {isLogoutDialogOpen && (
        <LogoutDialog
          isOpen={isLogoutDialogOpen}
          onHide={() => setIsLogoutDialogOpen(false)}
        />
      )}
      {isWide ? (
        <SideNav
          onLogoutClick={() => setIsLogoutDialogOpen(true)}
          centerNavItems={
            <>
              {centerIDs.map((id) => {
                const item = navItems.byID[id];
                const { path, icon, label } = item || {};
                return (
                  <LinkedNavItem
                    key={id}
                    path={path}
                    iconName={icon}
                    label={label}
                  />
                );
              })}
            </>
          }
          siteName={""}
        />
      ) : (
        <MobileNav2
          onLogoutClick={() => setIsLogoutDialogOpen(true)}
          subTitle={""}
        />
      )}
    </>
  );
}
