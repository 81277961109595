import { Icon } from "components/atomic/Icon";
import { IconButton } from "components/atomic/IconButton";
import { DeleteModalView } from "domain/zone/components/DeleteModal";
import {
  deleteKaiduDevice,
  DEVICE_CONFIG_API,
  KAIDU_DEVICES_LIST_API,
} from "features/kaidu-config-server";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useTheme } from "styled-components";
import { notifyError, notifySuccess } from "../../../lib/services/notification";

/**
 *
 * @returns A button and a modal for deleting scanners
 */
export function DeleteZoneGroup({ item, ...optionals }) {
  const { mac_address, device_name } = item || {};

  // Hooks
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [isShow, setIsShow] = useState(false);

  const handleClick = () => {
    setIsShow(true);
  };

  const handleConfirm = () => {
    // execute delete request
    deleteKaiduDevice(mac_address)
      .then(() => {
        notifySuccess(`${device_name} has been deleted`);
        // mutate kaidu device list response
        queryClient.invalidateQueries(KAIDU_DEVICES_LIST_API);
        queryClient.invalidateQueries(DEVICE_CONFIG_API);
      })
      .catch((err) => {
        console.error(err);
        notifyError(err?.message);
      })
      .finally(() => {
        setIsShow(false);
      });
  };
  const handleHide = () => {
    setIsShow(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Icon name="trash" color={theme.colors.danger} />
      </IconButton>
      <DeleteModalView
        onConfirm={handleConfirm}
        onHide={handleHide}
        itemName={device_name}
        show={isShow}
      />
    </>
  );
}
