import { BASE_API } from '../constants';
import { sendDelete, sendPost, sendPut, sendPatch } from '../handler';
import { NewUser, NewCustomerCodeRequestData, UpdateUser } from './types';

// constants
const USERS_LIST_SUBPATH = '/users_list';
export const USERS_LIST_API = `${BASE_API}${USERS_LIST_SUBPATH}`;
export const USERS_LIST_ALL_API = `${USERS_LIST_API}/all`;
export const USERS_LIST_FOR_CUSTOMER_API = `${USERS_LIST_API}/customer`;
export const USERS_LIST_CODE = `${USERS_LIST_API}/code`; // create new customer with a given code
// export const ADD_CUSTOMER_TO_USER = `${USERS_LIST_API}/`

export async function postUser(data: NewUser) {
  const {userEmail} = data || {};
  if (!userEmail) {
    throw new Error('User email is missing');
  }
  const trimmedEmail = userEmail.trim();
  data.userEmail = trimmedEmail;
  const res = await sendPost(USERS_LIST_API, data);
  return res;
}

export async function putUser(data: UpdateUser) {
  const res = await sendPut(USERS_LIST_API, data);
  return res;
}

/**
 * soft delete a user
 */
export async function deleteUser(id: string) {
  const url = `${USERS_LIST_API}/${id}`;
  const res = await sendDelete(url);
  return res;
}

export async function postCode(data: NewCustomerCodeRequestData) {
  const res = await sendPost(USERS_LIST_CODE, data);
  return res;
}

/**
 * 
 */
export async function removeUserFromCustomer(userID: string, customerID:string) {
  const url = `${USERS_LIST_API}/${userID}/del_customer/${customerID}`;
  const res = await sendPatch(url);
  return res;
}


/**
 * 
 */
export async function addUserFromCustomer(userID: string, customerID:string) {
  const url = `${USERS_LIST_API}/${userID}/add_customer/${customerID}`;
  const res = await sendPatch(url);
  return res;
}