import { Dialog } from "components/atomic/Modal";
// import Backdrop from '@mui/material/Backdrop';
import { Container } from "components/atomic/Container";
import { ErrorBoundary } from "features/error-handling";
import { Profiler, useRef } from "react";
import { useClickAway } from "react-use";
import { CameraModalContent } from "./CameraModalContent";

function DialogContainer(props) {
  const { shouldHideOnClickAway, onHide, ...rest } = props;

  const containerRef = useRef(null);
  useClickAway(containerRef, () => {
    // console.log('OUTSIDE CLICKED');
    shouldHideOnClickAway && onHide();
  });

  return <Container ref={containerRef} maxWidth={"xl"} {...rest} />;
}

export function CameraModalGroup({ deviceName, timestamp, ...optionals }) {
  const { isOpen, onHide, cameraID = "1001d15a" } = optionals;
  // const { isOpen, onHide, cameraID = '1001d15a', ...rest } = optionals;

  return (
    <ErrorBoundary>
      <Profiler
        id="camera-modal-group-profiler"
        onRender={() => console.log("camera-modal-group-profiler render")}
      >
        <Dialog
          show={isOpen}
          onHide={onHide}
          maxWidth={false}
          fullScreen
          PaperComponent={(props) => (
            <DialogContainer
              shouldHideOnClickAway={true}
              onHide={onHide}
              {...props}
            />
          )}
        >
          {isOpen ? (
            <CameraModalContent
              cameraID={cameraID}
              timestamp={timestamp}
              onHide={onHide}
              deviceName={deviceName}
            />
          ) : null}
        </Dialog>
      </Profiler>
    </ErrorBoundary>
  );
}
