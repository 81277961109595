import MuiDialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MuiModal from "@mui/material/Modal";
import { useMemo } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import styled from "styled-components";

import { useMediaQueries } from "styles/themes";

const ContentContainer = styled.div`
  max-height: calc(100vh - 3rem);
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export function Dialog({ show, onHide, ...optionals }) {
  const { children, ...rest } = optionals;
  return (
    <MuiDialog open={show} onClose={onHide} {...rest}>
      {children}
    </MuiDialog>
  );
}

/**
 *
 */
export function Modal({ children, ...optionals }) {
  const {
    show,
    onHide,
    className = "",
    title,
    showCloseButton,
    ...rest
  } = optionals;
  return (
    <MuiModal open={show} onClose={onHide} {...rest}>
      <ContentContainer
        className={`m-auto bg-white w-4/5 max-h-full rounded-md ${className}`}
      >
        {title || showCloseButton ? (
          <ModalHeader closeButton={showCloseButton}>
            {title && <ModalTitle className="break-all">{title}</ModalTitle>}
          </ModalHeader>
        ) : null}
        {children}
      </ContentContainer>
    </MuiModal>
  );
}

export function DialogContent({ children, ...rest }) {
  return <MuiDialogContent {...rest}>{children}</MuiDialogContent>;
}

export function ModalHeader({ children, className = "", ...rest }) {
  const isSmall = useMediaQueries("sm");
  const classname = useMemo(
    () => `${isSmall ? "p-4" : "p-2"} ${className}`,
    [isSmall, className]
  );

  return (
    <BootstrapModal.Header className={classname} {...rest}>
      {children}
    </BootstrapModal.Header>
  );
}

export function ModalBody({ children, className = "", ...rest }) {
  const isSmall = useMediaQueries("sm");
  const classname = useMemo(
    () => `${isSmall ? "p-4" : "p-2"} ${className}`,
    [isSmall, className]
  );

  return (
    <div className={classname} {...rest}>
      {children}
    </div>
  );
}

export function BootstrapModalBody({ children, ...rest }) {
  return <BootstrapModal.Body {...rest}>{children}</BootstrapModal.Body>;
}

export function ModalFooter({ children, ...optionals }) {
  const { className = "", ...rest } = optionals;
  const isSmall = useMediaQueries("sm");
  const classname = useMemo(
    () => `${isSmall ? "p-4" : "p-2"} space-x-2 flex justify-end ${className}`,
    [isSmall, className]
  );
  return (
    <div className={classname} {...rest}>
      {children}
    </div>
  );
}

export function BootstrapModalFooter({ children, ...rest }) {
  return <BootstrapModal.Footer {...rest}>{children}</BootstrapModal.Footer>;
}

export function ModalTitle({ children, ...rest }) {
  return <BootstrapModal.Title {...rest}>{children}</BootstrapModal.Title>;
}

export function ModalTitle2({ children, ...rest }) {
  return <DialogTitle {...rest}>{children}</DialogTitle>;
}
