import { Button } from 'components/atomic/Button';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const PaginationBtn = ({ onClick, ...rest }) => {
  return (
    <Button variant="dark" size="sm" className="mx-1" onClick={onClick} {...rest} />
  )
};


export function PaginationBtnGroup({ paginationFns, paginationStates, ...optionals }) {
  const { gotoPage, previousPage, nextPage } = paginationFns || {};
  const { pageCount, canPreviousPage, canNextPage, pageIndex } = paginationStates || {};

  return (
    <Container className="pagination">
      {pageCount > 2 ? <PaginationBtn onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {"<<"}
      </PaginationBtn> : null}
      <PaginationBtn onClick={previousPage} disabled={!canPreviousPage} >{"<"}</PaginationBtn>
      <span>
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageCount}
        </strong>{" "}
      </span>
      <PaginationBtn onClick={nextPage} disabled={!canNextPage} >{">"}</PaginationBtn>
      {pageCount > 2 ? <PaginationBtn
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        {">>"}
      </PaginationBtn> : null}
    </Container>
  )
}
