import * as kaiduDeviceList from './kaiduDeviceList.json';
import * as buildings from './buildings.json';
import * as kaiduDeviceConfigurations from './kaidu-device-configurations/kaiduDeviceConfigurations.json';
import * as customerNoTimezone from './customer/testCustomerNoTimezone.json';
import * as multipleAvailableCustomers from './user/multipleAvailableCustomers.json';


const customer = {
  customerNoTimezone: customerNoTimezone
}

const user = {
  multipleAvailableCustomers: multipleAvailableCustomers
}


export {
  kaiduDeviceList,
  buildings,
  kaiduDeviceConfigurations,
  customer,
  user
}