export interface UserState {
  isAuth: boolean;
  username: string;
  userPicture: string | null;
  customerId: string;
  isSuper: boolean;
  isPartner: boolean;
  token: string | null;
  userId: string | null;
  allowed_customer_ids: string[] | null;
}

export const initialUserState: UserState = {
  isAuth: false,
  username: "",
  userPicture: "",
  customerId: "",
  isSuper: false,
  isPartner: false,
  token: null,
  userId: null,
  allowed_customer_ids: null,
};

export type UserAction =
  | {
      type: "UPDATE_USER";
      payload: UserState;
    }
  | {
      type: "LOGIN";
      isAuth: boolean;
      username: string;
      userPicture: string | null;
      customerId: string;
      isSuperUser: boolean;
      token: string;
    }
  | {
      type: "LOGOUT";
    };

export function userReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case "UPDATE_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "LOGIN":
      localStorage.setItem("auth", JSON.stringify({ token: action?.token }));
      return {
        ...state,
        isAuth: action.isAuth,
        username: action.username,
        userPicture: action.userPicture,
        customerId: action.customerId,
        isSuper: action.isSuperUser,
        token: action?.token,
      };
    case "LOGOUT":
      localStorage.clear();
      sessionStorage.clear();
      return {
        ...state,
        isAuth: false,
        username: "",
        userPicture: "",
        customerId: "",
        isSuper: false,
        token: null,
      };
    default:
      return state;
  }
}
