import { isFilledArray } from 'utils';
import { HHMMOption, HHMMValue } from "features/date";

export const WHOLE_DAY_OPTION: HHMMOption = {
  label: 'Whole Day',
  value: { start: '00:00', end: '24:00' },
};

export function formatHoursOfOperation(hoursOfOperation: HHMMValue): string {
  const { start, end } = hoursOfOperation || {};
  const result = `${start} - ${end}`;
  return result;
}


export function createSingleHourRangeOption(hoursOfOperation: HHMMValue) {
  const formatted = formatHoursOfOperation(hoursOfOperation);
  const option = { label: formatted, value: hoursOfOperation };
  return option;
}

/**
 * 
 */
export function createHoursOfOperationHourRangeOption(hoursOfOperation: HHMMValue) {
  const option = { label: 'Business Hours', value: hoursOfOperation };
  return option;
}

/**
 * take an hours of operation, create a list of options which contains that hours of operation and Whole day
 */
export function createHourRangeOptions(hoursOfOperation: HHMMValue) {
  // console.log("file: foo.ts:30 ~ createHourRangeOptions ~ hoursOfOperation", hoursOfOperation)
  const nextOption = createHoursOfOperationHourRangeOption(hoursOfOperation);
  const options = [
    WHOLE_DAY_OPTION,
    nextOption,
  ];
  return options;
}

export function createEmptyCustomOption() {
  const customOption = {label: 'Custom', value: {start: '', end: ''}}
  return customOption;
}

export function createCustomOption(start?: any, end?: any) {
  const customValue = {
    start,
    end
  }
  let customOption: HHMMOption;
  if (start && end) {
    customOption = {label: formatHoursOfOperation(customValue), value: customValue}
  } else {
    customOption = createEmptyCustomOption();
  }
  return customOption;
}

export function createCustomOptions(start?: any, end?: any) {
  const customValue = {
    start,
    end
  }
  let customOption;
  if (start && end) {
    customOption = [{label: formatHoursOfOperation(customValue), value: customValue}, createEmptyCustomOption()]
  } else {
    customOption = [createEmptyCustomOption()]
  }
  return customOption;
}

export function createStaticOptions(hoursOfOperation: HHMMValue): HHMMOption[] {
  const nextOption = createHoursOfOperationHourRangeOption(hoursOfOperation);
  const customOption = createEmptyCustomOption();

  const options = [
    WHOLE_DAY_OPTION,
    nextOption,
    customOption,
  ];
  return options;
}

export function getHourRangeOptionInList(target: HHMMValue, options: HHMMOption[]): HHMMOption | null {
  if (!isFilledArray(options)) {
    return null
  }
  const found = options.find(item => item?.value?.start === target?.start && item?.value?.end === target?.end);
  return found;
}

export function getOrCreateHourRangeOptionFromList(target: HHMMValue, options: HHMMOption[]): HHMMOption {
  const found = getHourRangeOptionInList(target, options);
  if (found) {
    return found;
  } else {
    return createSingleHourRangeOption(target);
  }
}

export function createHourRangeOptionsWithCustom(hoursOfOperation, start?: any, end?: any) {
  const staticOptions = createStaticOptions(hoursOfOperation);
  const customOption = getHourRangeOptionInList({start, end}, staticOptions);

  if (customOption) {
    return staticOptions;
  } else {
    const created = createSingleHourRangeOption({start, end});
    const options = [
      ...staticOptions,
      created,
    ];
    return options;
  }
}