import { getData } from "features/axios";
import {
  AVERAGE_HOURLY_API,
  GATHERED_HOURLY_COUNT_BY_CUSTOMER,
  GATHERED_TRAFFIC_BY_BEACON,
  GLOBAL_DATA,
  GLOBAL_DATA_SPLIT,
  GLOBAL_HISTORY_DATA,
  TRAFFIC_BY_BEACON_API,
  TRAFFIC_BY_BEACON_GROUP_BY_CUSTOMER,
  TRAFFIC_BY_BUILDING_API,
  TRIANGULATION_GRID_API,
  TRIANGULATION_GRID_TYPES_API,
} from "./api";
import { STATS_END_POINT } from "./constants";
import { withAuthInHeaders } from "./processors";
import {
  GlobalDataDTO,
  OptionalParamsForGlobalData,
  OptionalParamsForGlobalDataSplitted,
  OptionalParamsForTrafficByBuilding,
  TrafficByBeacon,
  TrafficByBuilding,
  TriangulationGridData,
  TriangulationGridTypesData,
} from "./types";

export async function fetchTrafficByBuilding(
  customer: string,
  endDate: string,
  args?: OptionalParamsForTrafficByBuilding
): Promise<TrafficByBuilding> {
  if (!customer) return null;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  return await getData(TRAFFIC_BY_BUILDING_API, withAuthInHeaders(params));
}

export async function fetchGlobalData(
  customer: string,
  endDate: string,
  args?: OptionalParamsForGlobalData
): Promise<GlobalDataDTO> {
  if (!customer || !endDate) return null;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  // console.debug('fetchGlobalStats');
  const result = await getData<GlobalDataDTO>(
    GLOBAL_DATA,
    withAuthInHeaders(params)
  );

  return result;
}

export async function fetchGlobalDateSplitted(
  customer: string,
  endDate: string,
  args?: Partial<OptionalParamsForGlobalDataSplitted>
) {
  if (!customer || !endDate) return null;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  // console.debug('fetchGlobalDateSplitted');
  const result = await getData<GlobalDataDTO>(
    GLOBAL_DATA_SPLIT,
    withAuthInHeaders(params)
  );

  return result;
}

export async function fetchGatheredGlobalData(
  customer: string,
  endDate: string,
  args?: Partial<any>
) {
  if (!customer || !endDate) return null;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  // console.debug('fetchGlobalDateSplitted');
  const result = await getData<GlobalDataDTO>(
    GLOBAL_HISTORY_DATA,
    withAuthInHeaders(params)
  );
  // throw new Error('test error');
  return result;
}

export async function fetchTrafficByBeacon(
  deviceId: string,
  endDate: string,
  args?: any
): Promise<TrafficByBeacon> {
  if (!deviceId) return null;
  const params = args ? { deviceId, endDate, ...args } : { deviceId, endDate };

  return await getData(TRAFFIC_BY_BEACON_API, withAuthInHeaders(params));
}

export async function fetchGatheredTrafficByBeacon(
  deviceid: string,
  endDate: string,
  args?: any
): Promise<TrafficByBeacon> {
  if (!deviceid) return null;
  const params = args ? { deviceid, endDate, ...args } : { deviceid, endDate };

  return await getData(GATHERED_TRAFFIC_BY_BEACON, withAuthInHeaders(params));
}

export async function fetchTriangulationGrid(
  customer: string,
  endDate: string,
  args?: any
): Promise<TriangulationGridData> {
  if (!customer) return null;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  return await getData(TRIANGULATION_GRID_API, withAuthInHeaders(params));
}

export async function fetchTriangulationGridTypes(
  customer: string,
  endDate: string,
  args?: any
): Promise<TriangulationGridTypesData> {
  // final url exmaple: http://kaidu-dev1.deeppixel.ai:8088/triangulation_grid_types?endDate=2022-05-10T00%3A00%3A00-04%3A00&customer=fb1514aa-b182-412a-8640-4793c30d5724

  if (!customer || !endDate) return null;

  const baseUrl = TRIANGULATION_GRID_TYPES_API;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  return await getData(baseUrl, withAuthInHeaders(params));
}

export async function fetchTrafficForCustomerBeacons(
  customer: string,
  endDate: string,
  args?: any
): Promise<any> {
  if (!customer || !endDate) return null;
  const params = args ? { customer, endDate, ...args } : { customer, endDate };

  // console.debug('fetchGlobalStats');
  const result = await getData<GlobalDataDTO>(
    TRAFFIC_BY_BEACON_GROUP_BY_CUSTOMER,
    withAuthInHeaders(params)
  );

  return result;
}

export async function fetchAverageHourlyData(
  customer: string,
  endDate: string,
  args?: any
) {
  const params = args ? { customer, endDate, ...args } : { customer, endDate };
  return await getData(AVERAGE_HOURLY_API, withAuthInHeaders(params));
}

export async function fetchTest() {
  // const params = args ? { customer, endDate, ...args } : { customer, endDate };
  return await getData(
    `${STATS_END_POINT}token_tester`,
    withAuthInHeaders({ test: "" })
  );
}

export async function fetchGatheredHourlyDataByCustomer(
  customer: string,
  endDate: string,
  deviceId?: string
) {
  const params = { customer, endDate, deviceId };

  return await getData(
    GATHERED_HOURLY_COUNT_BY_CUSTOMER,
    withAuthInHeaders(params)
  );
}
