import { useMemo } from "react";

import { getComparedDateRangeWithoutToday } from "domain/time-range";
import {
  createDateByUnixTime,
  getUnixTimeByDate,
  subMonths,
} from "features/date";
import {
  useGatherTrafficByBeacon,
  useGatheredGlobalData,
} from "features/kaidu-stats-server";
import { TrafficGraphTypes } from "types";

const MAX_ENDDATE = 2656084454; // unixtimestamp, Mon Mar 02 2054 12:14:14 GMT-0500 (Eastern Standard Time)

/**
 *
 */
export function useAllHomePageData(
  customerID,
  selectedLocation,
  selectedBuilding,
  customerTimezone,
  selectedDate,
  shouldDisplayForecast,
  selectedDevice
) {
  const { startDate, endDate } = selectedDate || {};

  const optArgs = {
    // ...params,
    startDate,
    timezone: customerTimezone,
  };
  // console.log("file: hooks.ts:33 ~ optArgs:", optArgs)
  const {
    isLoading: isLoadingCustomer,
    data: customerTrafficData,
    error: customerTrafficDataError,
  } = useHomePageData(customerID, endDate, {
    ...optArgs,
    //@ts-ignore
    prediction: shouldDisplayForecast ? shouldDisplayForecast : undefined,
    snr: shouldDisplayForecast ? shouldDisplayForecast : undefined, //|| userData?.isSuperUser,
  });

  const {
    isLoading: isLoadingZone,
    data: deviceTrafficData,
    error: deviceError,
    sortedDataByDate,
  } = useGatherTrafficByBeacon(selectedDevice?.mqtt_device_id, endDate, {
    startDate,
    timezone: customerTimezone,
  });

  const comparisonDateRange = getComparedDateRangeWithoutToday(selectedDate);
  // console.log("file: hooks.ts:48 ~ comparisonDateRange", comparisonDateRange);
  const { startDate: comparedStartDate, endDate: comparedEndDate } =
    comparisonDateRange || {};
  const comparedOptArgs = {
    // ...params,
    startDate: comparedStartDate,
    timezone: customerTimezone,
  };
  const comparisonDataQuery = useGatheredGlobalData(
    customerID,
    comparedEndDate,
    comparedOptArgs
  );

  const comparisonDeviceDataQuery = useGatherTrafficByBeacon(
    selectedDevice?.mqtt_device_id,
    comparedEndDate,
    comparedOptArgs
  );

  const trafficData = useMemo(() => {
    if (!selectedDevice) return customerTrafficData;
    // let passerbys = 0;
    // let uniqueVisitors = 0;
    if (
      deviceTrafficData &&
      deviceTrafficData.graph &&
      customerTrafficData &&
      customerTrafficData.graph &&
      customerTrafficData.graph.data
    ) {
      // deviceTrafficData.graph.data = sortedDataByDate;
      sortedDataByDate.forEach((data, idx) => {
        if (!data) return;
        // passerbys += data.passerbys || 0;
        // uniqueVisitors += data.visitors || 0;
        customerTrafficData.graph.data[idx] = {
          ...customerTrafficData.graph.data[idx],
          [`${selectedDevice.mqtt_device_id}_${TrafficGraphTypes.passerbys}`]:
            data.passerbys,
          [`${selectedDevice.mqtt_device_id}_${TrafficGraphTypes.visitors}`]:
            data.visitors,
        };
      });
    }
    // return { ...deviceTrafficData, passerbys, uniqueVisitors };
    return customerTrafficData;
  }, [
    selectedDevice,
    deviceTrafficData,
    customerTrafficData,
    sortedDataByDate,
  ]);

  const comparisonData = useMemo(() => {
    if (!selectedDevice) return comparisonDataQuery;
    let passerbys = 0;
    let uniqueVisitors = 0;
    if (comparisonDeviceDataQuery && comparisonDeviceDataQuery.data) {
      comparisonDeviceDataQuery.data.graph.data =
        comparisonDeviceDataQuery.sortedDataByDate;

      passerbys = comparisonDeviceDataQuery.sortedDataByDate.reduce(
        (sum, data) => sum + data?.passerbys || 0,
        0
      );
      uniqueVisitors = comparisonDeviceDataQuery.sortedDataByDate.reduce(
        (sum, data) => sum + data?.visitors || 0,
        0
      );
    }
    comparisonDeviceDataQuery.data = {
      ...comparisonDeviceDataQuery.data,
      passerbys,
      uniqueVisitors,
    };
    return comparisonDeviceDataQuery;
  }, [comparisonDeviceDataQuery, comparisonDataQuery, selectedDevice]);

  return {
    data: trafficData,
    isLoading: isLoadingCustomer || isLoadingZone,
    error: customerTrafficDataError || deviceError,
    compared: comparisonData,
    errors: {
      weeklyDataError: customerTrafficDataError || deviceError,
    },
  };
}

/**
 *
 */
export function useHomePageData(
  customerId: string,
  endDate: number,
  args?: Partial<{
    startDate: number;
    building: string;
    timezone: string;
    prediction;
    snr;
    detail;
  }>
) {
  const { startDate, building, timezone } = args || {};
  // console.debug('timezone', timezone);
  if (endDate > MAX_ENDDATE || endDate < 0) {
    console.warn("useHomePageData: ", endDate);
  }

  const result = useGatheredGlobalData(customerId, endDate, {
    building,
    timezone,
    startDate,
  });

  return result;
}

/**
 *
 */
export function useMonthlyData(
  customerId: string,
  endDate: number,
  args?: Partial<{
    building: string;
    timezone: string;
    months: number;
  }>,
  deviceId?: string
) {
  const { building, timezone, months = 6 } = args || {};
  // console.debug('timezone', timezone);
  if (endDate > MAX_ENDDATE || endDate < 0) {
    console.warn("useMonthlyData: ", endDate);
  }

  const startDate = getUnixTimeByDate(
    subMonths(createDateByUnixTime(endDate), months)
  );

  const customerTrafficData = useGatheredGlobalData(
    deviceId ? null : customerId,
    endDate,
    {
      building,
      timezone,
      startDate,
    }
  );

  const deviceTrafficData = useGatherTrafficByBeacon(deviceId, endDate, {
    startDate,
  });

  return deviceId ? deviceTrafficData : customerTrafficData;
}
