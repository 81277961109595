import { USERS_LIST_API, USERS_LIST_ALL_API, USERS_LIST_FOR_CUSTOMER_API } from './api';
import { User } from './types';
import { handle } from 'utils';
import axios from 'axios';
import { getErrorMessageInResponse, handleAxiosResultData } from '../processor';

export async function fetchUsersList(): Promise<User> {
  // console.debug('fetchUsersList is fired');
  const [result, resultErr] = await handle(axios.get(USERS_LIST_API));
  if (resultErr) {
    console.error(`fetchUsersList failed: ${resultErr?.message}`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}


export async function fetchAllUsersList(): Promise<User[]> {
  const [result, resultErr] = await handle(axios.get(USERS_LIST_ALL_API));
  if (resultErr) {
    console.error(`fetchAllUsersList failed: ${resultErr?.message}`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

/**
 * 
 */
export async function fetchUsersListOfCustomer(customerID: string): Promise<User[]> {
  const url = `${USERS_LIST_FOR_CUSTOMER_API}/${customerID}`;
  const [result, resultErr] = await handle(axios.get(url));
  if (resultErr) {
    console.error(`fetchUsersListOfCustomer failed: ${resultErr?.message}`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}
