import { Circle } from 'components/atomic/Circle';
import styled from 'styled-components';

export function StatusMolecule({
  status,
  color,
  ...optionals
}: {
  status: string;
  [x: string]: any;
}) {
  return (
    <>
      <Circle size={14} color={color} />
      <span>{status}</span>
    </>
  );
}

export const BaseStatusBadge = styled.span<any>`
  padding: 3px 6px;
  border-radius: 4px;
  color: ${(p) => (p?.color ? p.color : '#000')};
  background: ${(p) => (p?.bgColor ? p.bgColor : '#f3f3f3')};
  font-size: 16px;
  display: inline-block;
  text-align: center;
  width: 6rem;
`;

export const UserStatusBadge = styled(BaseStatusBadge)<{ isPending: boolean }>`
  padding: 3px 6px;
  border-radius: 8px;
  color: ${(p) => (p.isPending ? '#F1AE20' : '#4DB48F')};
  background: ${(p) => (p.isPending ? '#FFF7E4' : '#EAF9F5')};
  font-size: 16px;
`;
