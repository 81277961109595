import styled from 'styled-components';

export const Span = styled.span`
  color: ${p => p.theme.colors.tertiary};
`;

// export function Span(props) {
//   return (
//     <span {...props} />
//   )
// }