
const ACTIVE_RANKS = [
  0,
  500,
  1000,
  3000,
  5000,
];

const OVERALL_RANKS = [
  0,
  10,
  100,
  500,
  1000,
];

export const MAX_OVERALL_RANK = OVERALL_RANKS[OVERALL_RANKS.length - 1];

/**
 * 
 * @returns 0 to 4
 */
export function getActiveLevelRank(num: number): number {
  const index = ACTIVE_RANKS.findIndex(el => el >= num);
  return index === -1 ? ACTIVE_RANKS.length - 1 : index;
}

/**
 * 
 * @returns 0 to 4
 */
export function getOverallRank(num: number): number {
  const index = OVERALL_RANKS.findIndex(el => el >= num);
  return index === -1 ? OVERALL_RANKS.length - 1 : index;
}