// import styled from 'styled-components';
import { Table as BootstrapTable } from "react-bootstrap";
// import { NoData } from './NoData';
import { Td, Th, Thead, Tr } from "components/atomic/Tables";
import { ErrorBoundary } from "features/error-handling/components/ErrorBoundary";
import { isFilledArray } from "utils";
import { GlobalFilter } from "./GlobalFilter";
import NoData from "./NoData";
import { PaginationBtnGroup } from "./PaginationBtnGroup";
import { SortIndicator } from "./StyledSort";

export function Table({ children, getTableProps, ...rest }) {
  return (
    <BootstrapTable hover responsive {...rest} {...getTableProps()}>
      {children}
    </BootstrapTable>
  );
}

export function TableHeader({ headerGroups, ...optionals }) {
  return (
    <ErrorBoundary>
      <Thead {...optionals}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers?.map((column) => {
              const thProps = column.getHeaderProps(
                column.getSortByToggleProps()
              );
              // console.debug('thProps', thProps);
              return (
                <Th {...thProps}>
                  {column.render("Header") !== "skipme" && (
                    <span className="flex items-center">
                      {column.render("Header")}
                      <SortIndicator
                        isSortedDesc={column.isSortedDesc}
                        isSorted={column.isSorted}
                      />
                    </span>
                  )}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
    </ErrorBoundary>
  );
}

/**
 *
 */
export function TableBody(props) {
  const { page, prepareRow, ...rest } = props;

  if (!isFilledArray(page)) {
    return null;
  }

  return (
    <ErrorBoundary>
      <tbody {...rest}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Td {...cell.getCellProps()}>
                    <span>{cell.render("Cell")}</span>
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </tbody>
    </ErrorBoundary>
  );
}

/**
 *
 */
export function FullSizeTableView({
  filter,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  pagination,
  ...optionals
}) {
  // Props
  const { emptyState = <NoData />, ...rest } = optionals;
  const {
    shouldShowFilter,
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
  } = filter || {};
  const {
    shouldShowPagination,
    previousPage,
    gotoPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageIndex,
  } = pagination || {};

  return (
    <>
      <div className="flex justify-end">
        {shouldShowFilter && (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={searchBarPlaceholder}
          />
        )}
      </div>
      <Table getTableProps={getTableProps} {...rest}>
        <TableHeader headerGroups={headerGroups} />
        <TableBody
          {...getTableBodyProps()}
          page={page}
          prepareRow={prepareRow}
        />
      </Table>
      {!isFilledArray(page) && (
        <div className="flex items-center my-3">{emptyState}</div>
      )}
      {shouldShowPagination && (
        <PaginationBtnGroup
          paginationFns={{ previousPage, gotoPage, nextPage }}
          paginationStates={{
            pageCount,
            canPreviousPage,
            canNextPage,
            pageIndex,
          }}
        />
      )}
    </>
  );
}
