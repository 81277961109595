import { LegendItem as VisxLegendItem, LegendLabel } from "@visx/legend";
import styled from "styled-components";

const legendGlyphSize = 15;

const LabelText = styled.span`
  color: ${(p) => p.theme.colors.tertiary};
  opacity: 0.8;
  font-family: "Poppins";
  font-weight: 400;
`;

/**
 *
 */
export function LegendItem({ fillColor, label, ...optionals }) {
  const { className = "text-capitalize" } = optionals;
  const circleSize = legendGlyphSize / 2;

  return (
    <VisxLegendItem margin="0 5px 0 15px" className={className}>
      <svg
        width={legendGlyphSize}
        height={legendGlyphSize}
        style={{ margin: "2px 0" }}
      >
        <circle
          fill={fillColor}
          r={circleSize}
          cx={circleSize}
          cy={circleSize}
        />
      </svg>
      <LegendLabel margin="0 0 0 4px">
        <LabelText>{label}</LabelText>
      </LegendLabel>
    </VisxLegendItem>
  );
}
