import axios from 'axios';
import { getErrorMessageInResponse } from './processor';
import { handle } from 'utils';

/**
 * send post requests and get error message in the config server response
 */
export async function sendPost(url, data) {
  const [result, resultErr] = await handle(axios.post(url, data));

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function sendPut(url, data, config?: any) {
  const [result, resultErr] = await handle(axios.put(url, data, config));

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function sendDelete(url: string, data?: any) {
  const [result, resultErr] = await handle(axios.delete(url, data));

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function sendPatch(url: string, data?: any) {
  const [result, resultErr] = await handle(axios.patch(url, data));

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}