import { useEffect } from "react";
import SpinnerFavicon from "spinner-favicon";

function useDocumentIcon(
  image_url,
  loading: boolean = false,
  prevailOnUnmount = false
) {
  useEffect(() => {
    if (image_url) {
      const link = document.getElementById("icon");
      const appleLink = document.getElementById("apple-touch-icon");
      if (link) {
        link.setAttribute("href", image_url);
      } else {
        const link = document.createElement("link");
        link.id = "icon";
        link.rel = "icon";
        link.href = image_url;
        document.head.appendChild(link);
      }

      if (appleLink) {
        appleLink.setAttribute("href", image_url);
      } else {
        const appleLink = document.createElement("link");
        appleLink.id = "apple-touch-icon";
        appleLink.rel = "apple-touch-icon";
        appleLink.href = image_url;
        document.head.appendChild(appleLink);
      }
    }
  }, [image_url]);

  useEffect(() => {
    loading ? SpinnerFavicon.start() : SpinnerFavicon.stop();
  }, [loading]);
}

export default useDocumentIcon;
