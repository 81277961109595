import { BASE_API } from '../constants';
import { KaiduConfigurationsListItem } from './types';
import axios from 'axios';
import { handle, inspect } from 'utils';
import { getErrorMessageInResponse } from '../processor';
import _ from 'lodash';
import { handleAxiosResultData } from '../processor';
import { checkIsValidID } from '../processor';

const FOO = '/kaidu_configurations_list';
export const KAIDU_CONFIG_LIST_API = `${BASE_API}${FOO}`;
export const KAIDU_CONFIG_LIST_ITEM_API = `${KAIDU_CONFIG_LIST_API}/id`;

export async function postKaiduConfigListItem(data) {
  console.log(`postKaiduConfig with input data: ${inspect(data)}`);
  const submitted = _.omit(data, ['id']);
  const [result, resultErr] = await handle(
    axios.post(KAIDU_CONFIG_LIST_API, submitted)
  );

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

/**
 * 
 * @param data id is optional
 * @returns 
 */
export async function putKaiduConfigListItem(data) {
  console.log('putKaiduConfigListItem with data:', data);
  // const submitted = _.omit(data, ['id']);
  const [result, resultErr] = await handle(
    axios.put(KAIDU_CONFIG_LIST_API, data)
  );

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function deleteKaiduConfigListItem(id: string) {
  console.log('deleteKaiduConfigListItem :', id);
  const url = `${KAIDU_CONFIG_LIST_ITEM_API}/${id}`;
  const [result, resultErr] = await handle(axios.delete(url));
  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function fetchKaiduConfigListItem(
  id: string
): Promise<KaiduConfigurationsListItem> {
  if (checkIsValidID(id)) {
    console.log(`fetchKaiduConfigListItem with id: ${id}`);
    const [result, resultErr] = await handle(
      axios.get(`${KAIDU_CONFIG_LIST_ITEM_API}/${id}`)
    );

    if (resultErr) {
      console.error(resultErr);
      throw new Error(getErrorMessageInResponse(resultErr));
    }
    return handleAxiosResultData(result);
  } else {
    return null;
  }
}

export async function updateSensitivityLevel(data) {
  console.log('updateSensitivityLevel', data);

  if (!data?.kaidu_configuration_id || _.isNil(data?.sensitivity_level)) {
    throw new Error('kaidu_configuration_id and sensitivityLevel are required');
  }

  const [result, resultErr] = await handle(
    axios.put(`${KAIDU_CONFIG_LIST_API}/set_sensitivity_level`, data)
  );
  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return handleAxiosResultData(result);
}
