import Tooltip from "@atlaskit/tooltip";
import { intersectionWith } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Option } from "components/molecule/Select";
import {
  StyledMultiSelect,
  checkIsCloseToBottom,
} from "domain/visitor-traffic/domain/mini-chain";
import { useFetchDevicesByCustomer } from "features/kaidu-config-server";
import { convertDateUnixTimeToAPIParam } from "features/kaidu-stats-server";
import {
  selectSelectedCustomer,
  selectSelectedDevice,
  updateSelectedDevice,
} from "providers/redux/globalSelectSlice";
import { useWindowSize } from "react-use";
import { isFilledArray } from "utils";

/**
 * Selector for Kaidu scanner
 */

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick;
}

export function DeviceMultiSelector({ dateValue, ...optionals }) {
  const { onChange, ...rest } = optionals;
  const { startDate, endDate, timezone } = dateValue || {};
  const dispatch = useDispatch();

  const selectedDevice = useSelector(selectSelectedDevice);
  const customer = useSelector(selectSelectedCustomer);
  const { customer_id } = customer || {};
  const endDateString = endDate
    ? convertDateUnixTimeToAPIParam(endDate, timezone)
    : undefined;

  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone)
    : undefined;

  const { height } = useWindowSize();
  const intersectionRef = useRef(null);
  const isCloseToBottom = useMemo(
    () => checkIsCloseToBottom(intersectionRef.current, height),
    [intersectionRef, height]
  );
  const [selected, setSelected] = useState<{ label: string; value: string }[]>([
    { label: "All", value: "all" },
  ]);
  const devices = useFetchDevicesByCustomer(
    customer_id,
    startDateString,
    endDateString,
    {
      refetchOnMount: "always",
      enabled: Boolean(customer_id),
    }
  ); // Fetch all the device configurations
  const deviceOptions = useMemo(() => {
    const options = [{ label: "All", value: "all" }];
    if (isFilledArray(devices?.data)) {
      devices.data.forEach((device) => {
        options.push({
          label: device.device_name,
          value: device.kaidu_configuration_id,
        });
      });
    }
    return options;
  }, [devices?.data]);

  const handleChange = (nextValue: [{ label: string; value: string }]) => {
    const [lastOption] = nextValue.slice(-1);
    let nextDevices = [];
    let options = [{ label: "All", value: "all" }];
    if (lastOption && lastOption.value !== "all") {
      options = nextValue.filter((opt) => opt.value !== "all");
      nextDevices = intersectionWith(
        devices.data,
        options,
        (config, option) => config.kaidu_configuration_id === option.value
      );
    }
    const [nextDevice] = nextDevices.slice(-1);
    dispatch(updateSelectedDevice(nextDevice));
    setSelected(options);
    onChange && onChange(nextDevices);
  };

  return (
    <div ref={intersectionRef} {...rest}>
      <Tooltip
        content={
          selectedDevice
            ? ""
            : "All is a sum of all scanners or you can pick individual scanners to pick the respective zone they are located at."
        }
        delay={0}
      >
        <StyledMultiSelect
          className="multi w-full"
          options={deviceOptions}
          value={selected}
          onChange={handleChange}
          labelledBy="Select"
          hasSelectAll={false}
          overrideStrings={{ selectSomeItems: "More Options" }}
          disableSearch={true}
          isCloseToBottom={isCloseToBottom}
          ItemRenderer={(item: IDefaultItemRendererProps) => {
            const { checked, option, onClick, disabled } = item;
            const tooltip = option.value === "all" ? "" : option.label || "";
            return (
              <Tooltip content={tooltip} delay={0}>
                <div
                  className={`max-w-[200px] p-2.5 item-renderer ${
                    disabled ? "disabled" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex={-1}
                    disabled={disabled}
                  />
                  <span>{option.label}</span>
                </div>
              </Tooltip>
            );
          }}
          itemPadding={0}
          // {...rest}
        />
      </Tooltip>
    </div>
  );
}
