import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { UserProfileContainer } from "domain/user/components";
import { LinkedNavItem, NavItemContainer } from "./molecule/NavItem";
// import { NavItemContainer } from "./atomic/NavItemContainer";
import { Icon } from "components/atomic/Icon";
import { Image } from "components/atomic/Image";
import { selectPartnerCustomer } from "providers/redux/globalSelectSlice";
import KaiduLogoImg from "../../../assets/kaidu_logo.jpg";
// import KaiduLogoImg from "../../../assets/logo.png";
import { StyledLink } from "./atomic/StyledLink";
import { NavText } from "./molecule/NavText";

export const SideNavWrapper = styled.nav`
  color: ${(p) => p.theme.colors.darkGrey};
  font-size: 20px;
`;

/**
 * A side bar with hooks
 */
export function SideNav({
  onLogoutClick,
  siteName,
  ...optionals
}: {
  onLogoutClick: Function;
  siteName: string;
  [x: string]: any;
}) {
  const {
    // title = "Kaidu",
    centerNavItems,
    // bottomNavItems,
    subTitleComponent = (
      <h2 className={"text-lg"}>{siteName || "Not available"}</h2>
    ),
    // ...rest
  } = optionals;
  // const theme = useTheme();
  const partnerCustomer = useSelector(selectPartnerCustomer);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    onLogoutClick();
  };

  return (
    <SideNavWrapper
      className={
        "flex flex-col justify-between items-center min-w-max h-screen xl:w-64 2xl:w-72 sticky top-0 py-6"
      }
    >
      <div className="w-full flex flex-col justify-start">
        <StyledLink to="/" className="text-center px-4">
          <Image
            src={partnerCustomer?.customer_config?.image || KaiduLogoImg}
            width={300}
          />
          {/* <PageTitle>{title}</PageTitle> */}
        </StyledLink>
        {siteName && subTitleComponent}
      </div>
      <div className="w-full">{centerNavItems}</div>
      <div className="w-full">
        <LinkedNavItem path={"/"} iconName={"home"} label={"Home"} />
        <LinkedNavItem path={"/settings"} iconName={"cog"} label={"Settings"} />
        <NavItemContainer
          isSelected={false}
          className={"flex items-center w-full"}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <UserProfileContainer className="w-full p-0 m-0" />
        </NavItemContainer>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            sx: {
              overflow: "visible",
              width: 300,
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <MenuItem onClick={handleLogOut}>
            <Icon className="nav-icon w-7" name="sign-out-alt" />
            <NavText text="Logout" className="nav-text" />
          </MenuItem>
        </Menu>
      </div>
    </SideNavWrapper>
  );
}
