import { useMemo } from "react";
import { useMeasure } from "react-use";

import { getOverallRank } from "features/active-level";
import { getDay } from "features/date";
import styled, { useTheme } from "styled-components";
import { Echart } from "../../../components/features/charts/Echarts";
import { Chart } from "../../../components/features/charts/chartjs";
import { useMediaQueries } from "../../../styles/themes";
import { isFilledArray } from "../../../utils";
import { extractDateRangeFromChartData } from "../processors";
import { HEATMAP_COLORS, calendarDefaultProps } from "./constants";
import {
  formatTooltip,
  getHeight,
  getVisualMapProps,
  processData,
} from "./processors";
// import { VisitorGraphToolTipView } from './VisitorTraffic/VisitorTrafficGraph/components/VisitorGraphToolTipView';

const Container = styled.div<{ height: number; isLg?: boolean }>`
  height: ${(props) => props.height}px;
  max-width: ${(props) => (props.isLg ? "100%" : "480px")};
`;

/**
 * Calendar heatmap chart
 * view monthly or more days of data
 */
export function MonthlyHeatmapRect({
  data,
  timezone,
  ...optionals
}: {
  data: any[];
  timezone: string;
  valueProp?: string;
  onClick?: (date: string) => void;
  isLoading?: boolean;
  [x: string]: any;
}) {
  //Props
  const { valueProp = "overall", onClick } = optionals;
  // Hooks
  const [ref, { width }] = useMeasure();
  const theme = useTheme();
  const isLg = useMediaQueries("lg");

  const {
    data: heatmapData,
    today: todayData,
    hasToday,
  } = processData(data, valueProp, timezone, theme.colors.graph.today) || {};

  // Local props
  const visualMapProps = useMemo(
    () => getVisualMapProps(data, valueProp, width, hasToday) || {},
    [data, valueProp, width, hasToday]
  );

  if (!isFilledArray(data)) {
    return null;
  }

  // Process props
  const dateRange = extractDateRangeFromChartData(heatmapData);
  const height = getHeight(width, isLg);
  const legend = todayData?.value
    ? {
        data: [
          {
            name: "Today",
            icon: "roundRect",
            itemStyle: {
              color: theme.colors.graph.today,
            },
          },
        ],
        bottom: 8,
        itemHeight: 23,
        itemWidth: 23,
        left: "right",
        align: "right",
        selectedMode: false,
      }
    : undefined;
  // const valueRange = extent(data.map(item => item[valueProp]));
  // console.debug('MonthlyHeatmapRect range', range);
  const option = {
    tooltip: {
      padding: 8,
      formatter: (params, ticket) =>
        formatTooltip(params, ticket, timezone, valueProp),
    },
    visualMap: [visualMapProps],
    calendar: {
      ...calendarDefaultProps,
      range: dateRange,
      orient: isLg ? "horizontal" : "vertical",
      left: isLg ? 60 : 30,
      top: isLg ? 40 : 90,
    },
    series: [
      {
        name: "regular-days",
        type: "heatmap",
        coordinateSystem: "calendar",
        data: heatmapData,
        emphasis: {
          // hovered
          // focus: 'self'
          itemStyle: {
            borderWidth: 1,
            borderColor: "#555",
            color: "#3c7de5",
            opacity: 0.4,
          },
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: theme.colors.primary,
        },
      },
      {
        name: "Today",
        type: "heatmap",
        coordinateSystem: "calendar",
        data: todayData?.value,
        emphasis: {
          // hovered
          itemStyle: {
            borderWidth: 1,
            borderColor: "#555",
            color: "#3c7de5",
            opacity: 0.4,
          },
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: theme.colors.primary,
          color: theme.colors.graph.today,
        },
      },
    ],
    legend: legend,
  };

  /**
   * click a day from Historical Calendar, pass target date range to modal
   */
  const handleClick = (event) => {
    // console.debug('MonthlyHeatmapRect click event', event);
    const { data } = event || {};
    const { originalDate } = data || {};
    onClick && onClick(originalDate);
  };

  return (
    <Container
      className={`flex-1 ${isLg ? "mt-1" : "mt-4"}`}
      ref={ref}
      height={height}
      isLg={isLg}
    >
      <Echart
        option={option}
        onEvents={{ click: handleClick }}
        style={{
          height: "100%",
          width: "100%",
        }}
      />
    </Container>
  );
}

/**
 * Heatmap implemented via Visx
 */
export function MonthlyHeatmapRect2({ data, ...optionals }) {
  // Hooks
  const [ref] = useMeasure();
  // const [ref, { width, height }] = useMeasure();

  if (!isFilledArray(data)) {
    return null;
  }

  // const xScale = scaleBand({
  //   range: [0, width],
  //   domain: data?.map((d) => d?.date),
  //   padding: 0.1,
  // });

  // const yScale = scaleBand({
  //   range: [0, height],
  //   domain: data?.map((d) => d?.date),
  //   padding: 0.1,
  // });

  // const colorScale = scaleOrdinal({
  //   domain: data?.map((d) => d?.overall),
  //   range: HEATMAP_COLORS,
  // });

  return (
    <div ref={ref} className="h-72">
      {/* <HeatmapRect xScale={xScale} yScale={yScale} colorScale={colorScale} {...optionals} /> */}
    </div>
  );
}

/**
 * Calendar heatmap chart implemented via ChartJS
 */
export function MonthlyHeatmapRect3({ data, ...optionals }) {
  // Hooks
  const [ref] = useMeasure();
  // const [ref, { width, height }] = useMeasure();

  const chartData = useMemo(() => {
    const processedData = data.map((item) => {
      const iso = item.date.substr(0, 10);
      return {
        x: iso,
        y: "" + getDay(new Date(item.date)),
        v: item.overall,
        d: iso,
      };
    });
    // console.log("file: MonthlyHeatmapRect.tsx:25 ~ chartData ~ processedData", processedData);

    return {
      datasets: [
        {
          // label: 'My Matrix',
          data: processedData,
          backgroundColor(c) {
            const value = c.dataset.data[c.dataIndex].v;
            const rank = getOverallRank(value);
            // const alpha = (10 + value) / 60;
            return HEATMAP_COLORS[rank];
            // return helpers.color('green').alpha(alpha).rgbString();
            // return '#0a6fe2a0';
          },
          // hoverBackgroundColor: 'aliceblue',
          // hoverBorderColor: 'yellowgreen',
          borderWidth: 0,
          width({ chart }) {
            // const a = c.chart.chartArea || {};
            // return (a.right - a.left) / 5 - 1;
            const a = chart.chartArea || {};
            return (a.bottom - a.top) / 7 - 1;
          },
          height({ chart }) {
            const a = chart.chartArea || {};
            return (a.bottom - a.top) / 7 - 1;
          },
        },
      ],
    };

    // return data.map(item => ({x: item.date, y: getDay(new Date(item.date)), v: item.overall, d: item.date}));
  }, [data]);

  if (!isFilledArray(data)) {
    return null;
  }

  const scales = {
    y: {
      type: "time",
      offset: true,
      time: {
        unit: "day",
        round: "day",
        isoWeekday: 1,
        parser: "i",
        displayFormats: {
          day: "iiiiii",
        },
      },
      reverse: true,
      position: "left",
      ticks: {
        maxRotation: 0,
        autoSkip: true,
        padding: 1,
        font: {
          size: 9,
        },
      },
      grid: {
        display: false,
        drawBorder: false,
        tickLength: 0,
      },
    },
    x: {
      type: "time",
      position: "bottom",
      offset: true,
      time: {
        unit: "week",
        round: "week",
        isoWeekday: 0,
        displayFormats: {
          week: "MMM dd",
        },
      },
      ticks: {
        maxRotation: 0,
        autoSkip: true,
        font: {
          size: 9,
        },
      },
      grid: {
        display: false,
        drawBorder: false,
        tickLength: 0,
      },
    },
  };

  const options = {
    aspectRatio: 5,
    plugins: {
      legend: false,
      tooltip: {
        displayColors: false,
        callbacks: {
          title() {
            return "";
          },
          label(context) {
            const item = context.dataset.data[context.dataIndex];
            const labelText = ["Day: " + item.d, "Number: " + item.v];
            return labelText;
          },
        },
      },
    },
    scales: scales,
    layout: {
      padding: {
        top: 10,
      },
    },
  };

  const config = {
    type: "matrix" as any,
    data: chartData,
    options: options,
  };

  return (
    <div ref={ref} className="h-72">
      <Chart {...config} {...optionals} />
    </div>
  );
}
