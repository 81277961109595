import * as globalData1 from './kaidu-stats-server/global/today1.json';
import * as globalData2 from './kaidu-stats-server/global/today2.json';
import * as globalDataMonth from './kaidu-stats-server/global/aMonth.json';
import * as globalData2Months from './kaidu-stats-server/global/twoMonths.json';
import * as globalData6Months from './kaidu-stats-server/global/sixMonths.json';
// import * as triRes1 from './data/TraiangulationInfo_coor1.json';
import * as cardsSummaryData from './kaidu-stats-server/cards_summary.json';
import * as trafficDataSingleDay from './kaidu-stats-server/byBuilding/trafficByBuildingSingleDay.json';
import * as trafficByBuildingMultiDays from './kaidu-stats-server/byBuilding/trafficByBuildingMultiDays.json';
import * as trafficByBuilding1Month from './kaidu-stats-server/byBuilding/trafficByBuilding1Month.json';
import * as trafficByBeacon1 from './kaidu-stats-server/byBeacon/trafficByBeacon.json';
import * as trafficByBeacon2 from './kaidu-stats-server/byBeacon/trafficByBeaconADay2.json';
import * as trafficByBeacon1Week from './kaidu-stats-server/byBeacon/trafficByBeacon1Week.json';
// import * as kaiduDeviceList from './data/kaiduDeviceList.json';
// import * as globalData from './globalDataToday.json';
import * as noExist from './kaidu-stats-server/noExist.json';
import * as averageData2 from './kaidu-stats-server/hourlyAverage/hourlyAverage2.json';
import * as averageData3 from './kaidu-stats-server/hourlyAverage/hourlyAverage3.json';
import * as averageData4 from './kaidu-stats-server/hourlyAverage/hourlyAverage4.json';
import * as averageData1 from './kaidu-stats-server/hourlyAverage/hourlyAverage1.json';
import * as aWeekGlobalData from './kaidu-stats-server/global/aWeek.json';
import * as configServerData from './kaidu-config-server';

const globalData ={
  month: globalDataMonth,
  twoMonths: globalData2Months,
  sixMonths: globalData6Months,
  week: aWeekGlobalData,
  today: globalData1,
}

const hourlyAverage = {
  default: averageData2,
  shortFormat: averageData1,
  timezones: {
    plus8: averageData3,
    zero: averageData4
  }
}

const cardsData = {
  summary: cardsSummaryData
}

const trafficByBuilding = {
  multiDays: trafficByBuildingMultiDays,
  singleDay: trafficDataSingleDay,
  aMonth: trafficByBuilding1Month,
}

const trafficByBeacon = {
  aDay1: trafficByBeacon1,
  aDay2: trafficByBeacon2,
  aWeek: trafficByBeacon1Week,
};

export {
  globalData1,
  globalData2,
  noExist,
  aWeekGlobalData,
  globalData,
  hourlyAverage,
  cardsData,
  trafficByBuilding,
  trafficByBeacon,
  configServerData
}