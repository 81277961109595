import { Card2 } from 'components/atomic/Card';

/**
 * display a scanner data in a card
 */
export function UserCard({ row, ...rest }) {
  const { user_email } = row?.original || {};
  const regularCells = row.cells.filter(
    (item) => item.column.id !== 'user_email'
  );
  // console.debug('UserCard row:', row);

  return (
    <Card2 className='space-y-3 p-3 max-w-full'>
      <div className='flex justify-between items-center flex-col sm:flex-row gap-y-2'>
        <div className={'break-all'}>{user_email}</div>
        <div className={'flex items-center ml-2 gap-x-1'}>
          {regularCells?.map((cell, index) => {
            return cell.render('Cell');
          })}
        </div>
      </div>
    </Card2>
  );
}
