import { Button } from "components/atomic/Button";
import { Icon } from "components/atomic/Icon";
import { IconButton } from "components/atomic/IconButton";
import { useTheme } from "styled-components";
import { User } from "features/kaidu-config-server";

/**
 * Operations Cell for user table
 */
export function OperationsCell({
  user,
  setSelectedRemoveUser,
  setIsShowingModal,
  ...optionals
}) {
  const {
    setModalData,
    isSending = false,
    isDeleteDisabled = false,
    setScheduledUser,
    // ...rest
  } = optionals;

  // Hooks
  const theme = useTheme();

  const handlePrepareSendingReports = () => {
    // console.debug("sending reports");
    setModalData({ ...user });
    setIsShowingModal(true);
    // return sendReportMutation.mutate({ customerID, email, endDate: getNow(), timezone: customerTimezone });
  };

  const handleOpenRemoveUserModal = (user: User) => setSelectedRemoveUser(user);

  return (
    <div className="flex gap-1 flex-col sm:flex-row sm:justify-center sm:items-center">
      {/* {sendReportMutation.isLoading ? <span>Sending...</span> : <Button onClick={() => handleSendingReports(user_email)}>Send report</Button>} */}
      {isSending ? (
        <span>Sending...</span>
      ) : (
        <>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
              textOverflow: "eclipsis",
              height: "30px",
            }}
            onClick={() => handlePrepareSendingReports()}
          >
            Send report
          </Button>
          <Button
            className="btn btn-success"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
              textOverflow: "eclipsis",
              height: "30px",
            }}
            onClick={() => setScheduledUser(user)}
          >
            Schedule report
          </Button>
        </>
      )}
      <IconButton
        style={{ fontSize: "20px" }}
        onClick={() => handleOpenRemoveUserModal(user)}
        disabled={isDeleteDisabled}
      >
        <Icon
          name="trash"
          color={
            isDeleteDisabled ? theme.colors.grayscale[4] : theme.colors.danger
          }
        />
      </IconButton>
    </div>
  );
}
