// import { Navbar } from 'react-bootstrap';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";

import { Icon } from "components/atomic/Icon";
import { PageTitle, Typography } from "components/atomic/Typography";
import { UserImage } from "domain/user/components";
import { useAppState } from "hooks";
import {
  selectPartnerCustomer,
  selectSelectedCustomer,
} from "providers/redux/globalSelectSlice";

/**
 * Mobile Navigation bar (at top)
 */
export function MobileNav2({ onLogoutClick, subTitle, ...optionals }) {
  // Hooks
  // const theme = useTheme();
  const { userState } = useAppState();
  const customer = useSelector(selectSelectedCustomer);
  const partnerCustomer = useSelector(selectPartnerCustomer);

  return (
    <AppBar position="static">
      <Toolbar className="flex py-2">
        <UserImage src={userState?.userPicture} />
        <div className={"flex flex-col items-center flex-1"}>
          <PageTitle className="mb-0 text-xl" color={"primary"}>
            {partnerCustomer?.customer_name || "Kaidu"}
          </PageTitle>
          <Typography
            className="mb-0 text-xs max-w-[200px] break-all"
            color={"lightGrey"}
          >
            {customer?.customer_name ?? subTitle}
          </Typography>
        </div>
        <Icon name={"sign-out-alt"} onClick={onLogoutClick} />
      </Toolbar>
    </AppBar>
  );
}

// export function MobileNav({ onLogoutClick, ...optionals }) {
//   return (
//     <nav>
//       <Navbar collapseOnSelect expand='lg' className='ps-0'>
//         <StyledLink to='/'>
//           <Navbar.Brand className='ps-2 fw-bold'>Kaidu</Navbar.Brand>
//         </StyledLink>
//         <Navbar.Toggle aria-controls='responsive-navbar-nav' />
//         <Navbar.Collapse id='responsive-navbar-nav' className='mt-5'>
//           <LinkedNavItem path={'/'} iconName={'home'} label={'Home'} />
//           <LinkedNavItem path={'/heatmap'} iconName={'map'} label={'Heatmap'} />
//           <LinkedNavItem
//             path={'/security'}
//             iconName={'lock'}
//             label={'Security'} />
//           <LinkedNavItem
//             path={'/cards'}
//             iconName={'FaCreditCard'}
//             label={'Cards'} />
//           <LinkedNavItem
//             path={'/settings'}
//             iconName={'cog'}
//             label={'Settings'} />
//           <UserProfileContainer />
//           <NavItemContainer isSelected={false} onClick={onLogoutClick}>
//             <LogoutListItem />
//           </NavItemContainer>
//         </Navbar.Collapse>
//       </Navbar>
//     </nav>
//   );
// }
