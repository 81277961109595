import { BaseSelect, MultiSelect, Option } from "components/molecule/Select";
import { useMemo, useRef } from "react";
import { useWindowSize } from "react-use";
import styled from "styled-components";
/**
 * Multi select component for traffic data sub-categories
 */
export function SubCategoriesMultiSelect({
  options,
  value,
  onChange,
  ...optionals
}: {
  options: Option[];
  value: Option[];
  onChange: Function;
  [x: string]: any;
}) {
  const { ...rest } = optionals;

  const handleChange = (next) => {
    // console.debug("SubCategoriesMultiSelect handleChange", next);
    onChange(next);
  };

  return (
    <BaseSelect
      className="w-72 max-h-full"
      options={options}
      value={value}
      onChange={handleChange}
      labelledBy="Select"
      isMulti={true}
      placeholder={"More Options"}
      closeMenuOnSelect={false}
      cropWithEllipsis={true}
      {...rest}
    />
  );
}

export const StyledMultiSelect = styled(MultiSelect)<{
  isCloseToBottom?: boolean;
}>`
  .dropdown-content {
    transform: ${(props) =>
      props.isCloseToBottom ? "translateY(-125%)" : "none"};
  }
  .select-item {
    padding: ${(props) => {
      return props.itemPadding ?? "10px";
    }};
  }
`;

export function checkIsCloseToBottom(intersectionRef, height, range = 60) {
  // console.log("file: SubCategoriesMultiSelect.tsx:86 ~ rect", intersectionRef);
  // console.log("file: SubCategoriesMultiSelect.tsx:77 ~ height", height);
  const rect = intersectionRef?.getBoundingClientRect();
  // console.log("file: SubCategoriesMultiSelect.tsx:86 ~ rect", rect);
  const { bottom } = rect || {};

  return bottom < height + 20 && height - bottom < range;
}

/**
 * Multi select component for traffic data sub-categories
 */
export function SubCategoriesMultiSelect2({
  options,
  value,
  onChange,
  ...optionals
}: {
  options: Option[];
  value: Option[];
  onChange: Function;
  [x: string]: any;
}) {
  const { ...rest } = optionals;

  // Hooks
  // const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const intersectionRef = useRef(null);
  const isCloseToBottom = useMemo(
    () => checkIsCloseToBottom(intersectionRef.current, height),
    [intersectionRef, height]
  );

  return (
    <div ref={intersectionRef}>
      <StyledMultiSelect
        className="multi w-full"
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select"
        hasSelectAll={false}
        overrideStrings={{ selectSomeItems: "More Options" }}
        disableSearch={true}
        isCloseToBottom={isCloseToBottom}
        {...rest}
      />
    </div>
  );
}
