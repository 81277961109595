import { DefaultTheme } from "styled-components";

const colors = {
  primary: "#fdfffd",
  secondary: "#0160f3",
  tertiary: "#414953",
  fourth: "#fe2f58",
  black: "#000",
  white: "#fff",
  grey: "#8d9094",
  darkGrey: "#6c757d",
  lightGrey: "#efefef",
  danger: "#d32f2f",
  alert: "#ffa000",
  success: "#388e3c",
  graph: {
    bluetooth: "#0070FF",
    wifi: "#eb6f6f",
    nonapple: "#12a0a5",
    overall: "#B9C9EA",
    forecast: "#efefef",
    snr: "#8755d9",
    cards: "#d32f2f",
    currentTime: "#26BB0E",
    today: "#CFAE5A",
    average: "#607D8B",
    grid: "#252525c3",
    passerbys: "#0160F3",
  },
  cardStatus: {
    active: "#26BB0E",
    away: "#EA9F2F",
    new: "#542FEA",
    lost: "#EA3D2F",
  },
  status: [
    "#542FEA",
    "#EA9F2F",
    "#2fc5ea",
    "#26BB0E",
    "#414141",
    "#bdbdbd",
    "#EA3D2F",
  ],
  grayscale: [
    "#212121",
    "#414141",
    "#616161",
    "#9e9e9e",
    "#bdbdbd",
    "#e0e0e0",
    "#eeeeee",
    "#ffffff",
  ],
};

const zIndexMap = {
  highest: 9999,
  higher: 999,
  medium: 99,
  low: 9,
  lower: 1,
  default: 0,
  lowest: -1,
};

const theme: DefaultTheme = {
  colors: colors,
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    sm: "12px",
    md: "18px",
    lg: "24px",
    xl: "36px",
  },
  zIndexes: zIndexMap,
};

export default theme;
