import { addDays } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUnmount } from "react-use";
import { useTheme } from "styled-components";

import { Button } from "components/atomic/Button";
import { Icon } from "components/atomic/Icon";
import { IconButton } from "components/atomic/IconButton";
import { Dialog, ModalBody } from "components/atomic/Modal";
import { processDailySummaryData } from "domain/visitor-traffic";
import { Summary } from "domain/visitor-traffic/Summary";
import { VisitorTrafficContainer } from "domain/visitor-traffic/VisitorTraffic";
import {
  UnixtimeSelectedDate,
  createDateByUnixTime,
  formatDateRangeWithoutTimezone,
  getUnixTimeByDate,
  isToday,
} from "features/date";
import { ErrorBoundary } from "features/error-handling";
import { useAppState } from "hooks";
import { selectSelectedDevice } from "providers/redux/globalSelectSlice";
import { useMediaQueries } from "styles/themes";
import { TrafficGraphTypes } from "types";
import { formatDataToGraphData } from "../../components/Modals/processors";
import { TimeZoneText } from "../../components/molecule/TimeZoneText";
import { useGlobalHourlyDataModal } from "./hooks";
import { processGraphData } from "./processors";

const MODAL_TOAST_ID = "missing-data-warning";

/**
 * Modal view for visitor traffic data;
 * cannot change selected date
 * for single day only
 */
export function GlobalDataModal({
  show,
  onHide,
  selectedDateRange,
  ...optionals
}: {
  show: boolean;
  onHide: () => void;
  selectedDateRange: UnixtimeSelectedDate;
  enableBarClick?: boolean;
  timezone?: string;
  [x: string]: any;
}) {
  // Props
  const { timezone, device_id, ...rest } = optionals;
  const { endDate } = selectedDateRange || {};
  const [selectedDate, setSelectedDate] = useState<number | undefined>(
    endDate || 0
  );
  const { isLoading, data, refetch } = useGlobalHourlyDataModal({
    endDate: selectedDate,
    timezone,
    device_id,
  });
  const isMedium = useMediaQueries("sm");
  const theme = useTheme();
  const { appState } = useAppState();
  const device = useSelector(selectSelectedDevice);
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );
  const processedGraphData = useMemo(() => processGraphData(data), [data]);
  const summaryData = useMemo(() => {
    const data = processDailySummaryData(
      processedGraphData,
      timezone,
      currentGraphType
    );
    return data;
  }, [currentGraphType, processedGraphData, timezone]);
  const shouldShowNextBtn = useMemo(() => {
    const isSelectedDateToday = isToday(selectedDate * 1000);
    return !isSelectedDateToday;
  }, [selectedDate]);
  // Side-effect
  useUnmount(() => toast.dismiss(MODAL_TOAST_ID));

  useEffect(() => {
    refetch();
  }, [device, refetch]);

  const onChangeDate = useCallback(
    (days: number) => {
      const newDate = getUnixTimeByDate(
        addDays(createDateByUnixTime(selectedDate), days)
      );
      setSelectedDate(newDate);
    },
    [setSelectedDate, selectedDate]
  );

  // Conditions
  if (!selectedDateRange || !endDate) {
    return null;
  }

  // Process props
  const formattedDate = formatDateRangeWithoutTimezone(
    { endDate: selectedDate },
    timezone,
    "LLL dd yyyy"
  );

  const handleHide = () => {
    onHide();
    toast.dismiss(MODAL_TOAST_ID);
  };

  return (
    <Dialog
      show={show}
      onHide={handleHide}
      maxWidth="lg"
      fullWidth={true}
      fullScreen={!isMedium}
      sx={{
        zIndex: 0, // z-index must be <= 0 to show the tooltips in this modal
      }}
      PaperProps={{
        sx: isMedium ? { borderRadius: "1rem" } : {},
      }}
      {...rest}
    >
      <>
        <ErrorBoundary>
          {!isMedium && (
            <div className="flex justify-end p-2">
              <IconButton variant="light" onClick={handleHide}>
                <Icon name="close" color={theme?.colors?.tertiary} />
              </IconButton>
            </div>
          )}
          <ModalBody className="flex justify-center w-full pt-0 flex-col">
            {summaryData && (
              <Summary
                data={summaryData}
                isLoading={isLoading}
                showPasserbys={false}
                hiddenHeader
                isModal
                valueClassName="!mt-2 !mb-0"
              />
            )}

            <VisitorTrafficContainer
              title={`Hourly Scores: ${formattedDate}`}
              isModal
              isLoading={isLoading}
              data={formatDataToGraphData(data, false, false)}
              hideLeftAxis={false}
              showForecast={false}
              headerChildren={
                <div className="flex flex-col mt-2 lg:mt-0 lg:items-end">
                  <TimeZoneText timezone={timezone} />
                </div>
              }
              key={`global-visitor-traffic-container`}
              showCategoryMenu={false}
              timezone={timezone}
              showBodyTopElements
              bodyTop={
                <div className="flex justify-between mb-1">
                  <Button
                    onClick={() => onChangeDate(-1)}
                    variant="outline-secondary"
                    size="sm"
                    data-cy={"pre-day-button"}
                  >
                    Previous Day
                  </Button>
                  {shouldShowNextBtn && (
                    <Button
                      onClick={() => onChangeDate(1)}
                      variant="outline-secondary"
                      size="sm"
                      data-cy={"next-day-button"}
                    >
                      Next Day
                    </Button>
                  )}
                </div>
              }
            />
          </ModalBody>
        </ErrorBoundary>
      </>
    </Dialog>
  );
}
