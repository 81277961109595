export const BASE_API =
  process.env?.REACT_APP_SERVER || "REPLACE_BY_BUILD_REACT_APP_SERVER";

export const NULL_VALUE = "00000000-0000-0000-0000-000000000000";
export const DEFAULT_FLOORPLAN_URL = `${BASE_API}/assets/rectangle.svg`;
export const DASHBOARD_URL =
  process.env.REACT_APP_DASHBOARD_URL ||
  "REPLACE_BY_BUILD_REACT_APP_DASHBOARD_URL";
export const APPLE_CLIENT_ID =
  process.env.REACT_APP_APPLE_CLIENT_ID ||
  "REPLACE_BY_BUILD_REACT_APP_APPLE_CLIENT_ID";
export const GOOGLE_CLIENT_ID =
  process.env?.REACT_APP_GOOGLE_CLIENT_ID ||
  "REPLACE_BY_BUILD_REACT_APP_GOOGLE_CLIENT_ID";
