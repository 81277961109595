import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "components/atomic/DatePicker";
import {
  changeTimeZone,
  createDateByUnixTime,
  getUnixTimeByDate,
  subDays,
} from "features/date";
import { DateSelector } from "domain/time-range/date-range/components/TimeRange/Date/DateSelector";
import { UnixtimeSelectedDate } from "features/date";
import {
  selectEndDate,
  selectSelectedDate,
  updateEndDate,
  updateSelectedDate,
} from "providers/redux/globalSelectSlice";
import { resetHeatmapFilter } from "providers/redux/heatMapSilce";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const DatePickerContainer = styled.div`
  /* width: 40px; */
  display: flex;
  /* height: 100%; */
  align-items: flex-end;
  margin-left: 0.5rem;
`;

/**
 * A composite Date selector component that is not coupled with Appstate and only for single days
 */
export function SimpleDateSelectors(props) {
  const { onlySingleDayQueries, showAll, timezone } = props;
  // Hooks
  const dispatch = useDispatch();

  // Global state
  const globalDate: UnixtimeSelectedDate = useSelector(selectSelectedDate);
  const selectedEndDate = useSelector(selectEndDate);

  const maxSelectableDate = subDays(new Date(), 0);

  const handleDateChangeFromCalendar = (date: Date) => {
    // const dateUnix = getUnixTimeByDate(date);
    // get a date which is the day with same hour, minute, second at that day
    const nextEndDate = getUnixTimeByDate(changeTimeZone(date, timezone));

    // console.debug('typeof dateUnix', typeof dateUnix);
    dispatch(updateEndDate(nextEndDate));
    dispatch(resetHeatmapFilter());
  };

  const handleDateChangeFromSelector = (d: {
    startDate?: number;
    endDate: number;
  }) => {
    try {
      // console.debug("Date selector change date value: ", d);
      // const {startDate, endDate} = d || {};
      // dispatch(updateEndDate(endDate));
      dispatch(updateSelectedDate(d));
      dispatch(resetHeatmapFilter());
    } catch (error) {
      console.error("handleDateChangeFromSelector got error");
      console.error(error);
    }
  };

  return (
    <div className="flex items-stretch w-full lg:w-auto">
      <DateSelector
        onChange={handleDateChangeFromSelector}
        onlySingleDayQueries={onlySingleDayQueries}
        value={globalDate}
        showAll={showAll}
      />
      <DatePickerContainer>
        <DatePicker
          selected={
            selectedEndDate ? createDateByUnixTime(selectedEndDate) : undefined
          }
          onChange={handleDateChangeFromCalendar}
          customInput={<FontAwesomeIcon icon="calendar-alt" />}
          maxDate={maxSelectableDate}
        />
      </DatePickerContainer>
    </div>
  );
}
