/**
 * Displayed options
 * init: only mainData
 * super user: subData and subForecast
 */
export const displayedOptions = {
  mainData: true,
  forecast: false,
  subData: false,
  subForecast: false,
};

export const GRAPH_PADDING = { x: 30, y: 40 }; // padding for the graph to make the graph fully visible
export const GRAPH_PADDING_FOR_BARS = { x: 50, y: 40 }; // padding for the graph to make the graph fully visible