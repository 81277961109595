import { LabeledSelect } from "components/molecule/Select";
import {
  UnixtimeSelectedDate,
  getTargetDateRangeAvailableInOptions,
} from "features/date";
import { createMultiDaysSelectOptions } from "features/date";
import { isFilledArray } from "utils";

/**
 * Select a multi-day range
 */
export function MultiDaysDateSelector({
  onChange,
  value,
  ...optionals
}: {
  onChange: Function;
  value: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  const { label = "Date", timezone, labelClassName, ...rest } = optionals;

  // Process props
  const displayedOptions = createMultiDaysSelectOptions(value, timezone);

  const handleChange = (nextValue) => {
    onChange && onChange(nextValue.value);
  };

  const defaultValue = isFilledArray(displayedOptions)
    ? displayedOptions[0]
    : null;

  return (
    <LabeledSelect
      options={displayedOptions}
      label={label}
      value={getTargetDateRangeAvailableInOptions(
        value,
        displayedOptions,
        timezone
      )}
      defaultValue={defaultValue}
      onChange={handleChange}
      data-cy={"multi-date-selector"}
      innerClassName="flex-1 lg:flex-none w-52" //w-52
      labelClassName={labelClassName}
      {...rest}
    />
  );
}
