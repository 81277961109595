import {putUser} from './api';

/**
 * Update the selected customer id for a user
 */
export async function setUserSelectedCustomerId({customerID, userID}: {customerID: string, userID: string}) {
  const payload = {
    userId: userID,
    payload: {
      customers_list_id: customerID,
    },
  };

  return await putUser(payload);
}