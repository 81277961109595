import { DateSelected, getNow, startOfDay } from "features/date";
import {
  AppState,
  Customer,
  Floorplan,
  TrafficGraphTypes,
  Zone,
} from "../../../types";
import { UserAction } from "./index";

export const initalAppState: AppState = {
  isLoading: false,
  selected: {
    user: null,
    customer: null,
    building: null,
    location: null,
    floor: null,
    floorplan: null,
    device: null,
    dateSelection: DateSelected.today,
    queryDate: startOfDay(getNow()),
    selectedTrafficGraphType: TrafficGraphTypes.visitors,
  },
};

export type AppAction =
  | {
      type: "APP_REFRESH";
      userDispatch: React.Dispatch<UserAction>;
    }
  | { type: "UPDATE_SELECTED_DATE"; selection: DateSelected }
  | { type: "UPDATE_BUILDING"; newValue: string | null }
  | { type: "UPDATE_CUSTOMER"; newValue: Customer | null }
  | { type: "UPDATE_LOCATION"; newValue: string | null }
  | { type: "UPDATE_FLOORPLAN"; newValue: Floorplan | null }
  | { type: "UPDATE_FLOOR"; newValue: string }
  | { type: "UPDATE_QUERY_DATE"; newValue: Date }
  | { type: "UPDATE_DEVICE"; newValue: null | Zone }
  | { type: "UPDATE_DATE"; newValue: DateSelected }
  | { type: "SET_TRAFFIC_GRAPH_TYPE"; newValue: TrafficGraphTypes }
  | { type: "RESET_APP_STATE" };

export const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case "UPDATE_BUILDING":
      sessionStorage.setItem("building", JSON.stringify(action.newValue));
      return {
        ...state,
        selected: { ...state.selected, building: action.newValue },
      };
    case "UPDATE_LOCATION":
      sessionStorage.setItem("location", JSON.stringify(action.newValue));
      return {
        ...state,
        selected: { ...state.selected, location: action.newValue },
      };
    case "UPDATE_DEVICE":
      sessionStorage.setItem("device", JSON.stringify(action.newValue));
      return {
        ...state,
        selected: { ...state.selected, device: action.newValue },
      };
    case "UPDATE_CUSTOMER":
      sessionStorage.setItem("customer", JSON.stringify(action.newValue));
      return {
        ...state,
        selected: { ...state.selected, customer: action.newValue },
      };
    case "UPDATE_FLOORPLAN":
      sessionStorage.setItem("floorplan", JSON.stringify(action.newValue));
      return {
        ...state,
        selected: { ...state.selected, floorplan: action.newValue },
      };
    case "UPDATE_FLOOR":
      sessionStorage.setItem("floor", JSON.stringify(action.newValue));
      return {
        ...state,
        selected: { ...state.selected, floor: action.newValue },
      };
    case "UPDATE_DATE":
      sessionStorage.setItem("date", JSON.stringify(action.newValue));

      return {
        ...state,
        selected: { ...state.selected, dateSelection: action.newValue },
      };
    case "UPDATE_QUERY_DATE":
      const date = startOfDay(action.newValue);
      sessionStorage.setItem("custom_date", JSON.stringify(date));
      return {
        ...state,
        selected: { ...state.selected, queryDate: date },
      };

    case "SET_TRAFFIC_GRAPH_TYPE":
      return {
        ...state,
        selected: {
          ...state.selected,
          selectedTrafficGraphType: action.newValue,
        },
      };
    case "RESET_APP_STATE":
      return initalAppState;
    default:
      return state;
  }
};
