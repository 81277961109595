import { desaturate } from "polished";
import styled, { useTheme } from "styled-components";

import { Icon } from "components/atomic/Icon";
// import { Chip, Divider } from '@mui/material';
// import {Span} from 'components/atomic/Span';
import { UnixtimeSelectedDate, formatDateRange } from "features/date";
import { NextDateArrowBtn } from "features/date/components/molecule/NextDateArrowBtn";
import { PrevDateArrowBtn } from "features/date/components/molecule/PrevDateArrowBtn";

const Container = styled.div`
  background-color: ${(props) => desaturate(0.4, props.theme.colors.secondary)};
`;

const DateText = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;
const SecondaryDateText = styled.span`
  color: ${(props) => props.theme.colors.tertiary};
`;

/**
 * UI component for indicating related date range
 */
export function DateRangeIndicator({
  dateRange,
  ...optionals
}: {
  dateRange: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  // const { ...rest } = optionals;
  const dateText = formatDateRange(dateRange, "MMM dd");
  const theme = useTheme();

  return (
    <Container className="p-2 px-3 rounded-md flex items-center">
      <Icon
        name="calendar-week"
        color={theme.colors.primary}
        className="mr-2"
      />
      <DateText>{dateText}</DateText>
      {/* <Chip label={dateText} variant="outlined" /> */}
    </Container>
  );
}

/**
 * UI component for indicating related date range
 */
export function SecondaryDateRangeIndicator({
  dateRange,
  ...optionals
}: {
  dateRange: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  const { showNavigation, onChangeDate } = optionals;
  // Hooks
  const theme = useTheme();
  const dateText = formatDateRange(dateRange, "MMM dd");

  return (
    <div className="p-2 flex items-center">
      {showNavigation && (
        <PrevDateArrowBtn dateRange={dateRange} onChangeDate={onChangeDate} />
      )}
      <Icon
        name="calendar-week"
        color={theme.colors.tertiary}
        className="mr-2"
      />
      <SecondaryDateText>{dateText}</SecondaryDateText>
      {showNavigation && (
        <NextDateArrowBtn dateRange={dateRange} onChangeDate={onChangeDate} />
      )}
    </div>
  );
}
