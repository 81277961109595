import {
  MenuItem,
  ControlledMenu,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { isFilledArray } from 'utils';

export function GeneralMenu({ options, ...optionals }) {
  const { onOptionClick, anchorRef, anchorPoint, children, menuProps, containerRef, ...rest } = optionals;

  const handleOptionClick = (option) => {
    onOptionClick && onOptionClick(option);
  };

  return (
    <>
      <ControlledMenu
        anchorRef={anchorRef ? anchorRef : undefined}
        anchorPoint={anchorPoint ? anchorPoint : undefined}
        {...menuProps}
        {...rest}
      >
        <div ref={containerRef}>
          {isFilledArray(options) ? options.map(option => {
            return (<MenuItem key={option.label} onClick={(e) => handleOptionClick(option)} {...option}>{option.label}</MenuItem>);
          }) : null}
          {children ? children : null}
        </div>
      </ControlledMenu>
    </>
  )
}