import { Group } from "@visx/group";
import { scaleBand, scaleOrdinal } from "@visx/scale";
import { BarGroup } from "@visx/shape"; //https://airbnb.io/visx/bargroup
import { Option } from "components/molecule/Select";
import { parseString } from "features/date";
import { useTheme } from "styled-components";
import { TrafficGraphTypes, VisitorTrafficGraphData } from "types";
import { isFilledArray } from "utils";
import { SubBar } from "./SubBar";
import { MIN_BAR_WIDTH } from "./constants";
import { getKeysOfData } from "../../processors";
// import { getKeysOfData } from "./processors";

export let tooltipTimeout: number;

export function getBarWidth(width) {
  return Math.max(width, MIN_BAR_WIDTH);
}

const deviceColors: string[] = [
  "#9fa8da",
  "#536dfe",
  "#304ffe",
  "#5c6bc0",
  "#1a237e",
];

/**
 * All bars for displaying the series of sub-bars
 * contains many barGroups
 * A barGroup contains multi sub-bars.
 * each sub-bar represents a category, e.g. apple devices.
 */
export function CategorizedBarGroup({
  data,
  yMax,
  selected,
  tooltipProps,
  scales,
  barGroupWidth,
  isBarClickEnabled,
  onBarClick,
}: {
  data: VisitorTrafficGraphData[];
  selected: Option[];
  [x: string]: any;
}) {
  // Props
  const { hideTooltip, showTooltip } = tooltipProps || {};
  const { timeScale, yValueScale } = scales || {};

  // Hooks
  const theme = useTheme();
  const graphColors = theme.colors?.graph || {};
  const subBarColors = selected?.map(
    (elem, idx) => graphColors[elem.value] || deviceColors[idx]
  );

  // Condition rendering
  if (!isFilledArray(data) || !isFilledArray(selected)) {
    return null;
  }

  const keys = isBarClickEnabled
    ? selected.map((item) => item.value as string)
    : getKeysOfData(data);

  const labels = isBarClickEnabled
    ? selected.reduce((obj, item) => {
        if (item) {
          obj[item.value] = item.label;
        }
        return obj;
      }, {})
    : keys.reduce((obj, key) => {
        obj[key] = key;
        return obj;
      }, {});

  const handleMouseLeave = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  // scale for each sub-bar
  const keyScale = scaleBand<string>({
    domain: keys,
    padding: 0.1,
    range: [0, barGroupWidth],
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: subBarColors,
  });

  return (
    <>
      <BarGroup
        data={data}
        keys={keys}
        height={yMax}
        x0={(d: VisitorTrafficGraphData) => parseString(d.date)}
        x0Scale={timeScale}
        x1Scale={keyScale}
        yScale={yValueScale}
        color={colorScale}
      >
        {(barGroups) =>
          barGroups.map((barGroup) => {
            // console.debug("CategorizedBarGroup barGroup", barGroup);
            return (
              <Group
                key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                left={barGroup.x0}
              >
                {barGroup.bars.map((bar) => {
                  // if the bar category is not selected
                  const isSubcategorySelected = selected
                    .map((elem) => elem.value)
                    .includes(bar.key);
                  if (!isSubcategorySelected) {
                    return null;
                  }

                  const handleBarClick = (event) => {
                    const barData = data.find(
                      (item) => item[bar.key] === bar.value
                    );
                    let [device_id] = bar.key.split(
                      `_${TrafficGraphTypes.visitors}`
                    );
                    [device_id] = (device_id || "").split(
                      `_${TrafficGraphTypes.passerbys}`
                    );
                    device_id = (device_id || "").trim();

                    if (barData) {
                      isBarClickEnabled &&
                        onBarClick &&
                        onBarClick({ ...barData, device_id }, event);
                    }
                  };

                  return (
                    <SubBar
                      key={`bar-group-bar-${barGroup.index}-${bar.key}`}
                      data={{
                        ...bar,
                        date: data[barGroup.index]?.date,
                        label: labels[bar.key],
                      }}
                      handleMouseLeave={handleMouseLeave}
                      showTooltip={showTooltip}
                      isBarClickEnabled={isBarClickEnabled}
                      onClick={handleBarClick}
                    />
                  );
                })}
              </Group>
            );
          })
        }
      </BarGroup>
    </>
  );
}
