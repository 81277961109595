import { extent } from "d3-array";
import {
  UnixtimeSelectedDate,
  addHoursToDateString,
  addMinutesToDateString,
  checkIsTodayInTimezone,
  convertDateToTimezonedRFC3339AtEndOfDay,
  convertDateToTimezonedRFC3339AtStartOfDay,
  diffInHours,
  getMaxDate,
  getMinDate,
  getNow,
  getUnixTimeByDate,
  isAfter,
  isBefore,
  parseString,
} from "features/date";
import _ from "lodash";
import { GraphDataRow } from "./types";
// import { SummaryDataItem, SummaryDataProps } from './Summary';
import { Option } from "components/molecule/Select";
import { DateTime } from "luxon";
import { curry, pipe } from "ramda";
import { getAverageByProp, isFilledArray } from "utils";
import { TrafficGraphTypes } from "types";

/**
 * today is from the beginning of the day at given timezone to the end of the day at given timezone
 */
export function hasTodayData(data: GraphDataRow[], timezone?: string): boolean {
  // console.debug('hasTodayData input', data);
  if (!isFilledArray(data)) {
    return false;
  }
  const today = getNow();
  const start = parseString(
    convertDateToTimezonedRFC3339AtStartOfDay(today, timezone)
  );
  const end = parseString(
    convertDateToTimezonedRFC3339AtEndOfDay(today, timezone)
  );
  const allDates: Date[] = data.map((item) => parseString(item.date));
  const result = allDates.some(
    (date) => isBefore(date, end) && isAfter(date, start)
  );
  // console.debug('hasTodayData output', result);
  return result;
}

/**
 *
 */
export function getLatestDate(data: GraphDataRow[]): Date {
  if (!isFilledArray(data)) {
    return null;
  }
  const allDates: Date[] = data.map((item) => parseString(item.date));
  return getMaxDate(allDates);
}

/**
 *
 */
export function hasMultiDaysData(data: GraphDataRow[]): boolean {
  if (!isFilledArray(data)) {
    return false;
  }
  const allDates: Date[] = data.map((item) => parseString(item.date));
  const maxDate: Date = getMaxDate(allDates);
  const minDate = getMinDate(allDates);
  return diffInHours(maxDate, minDate) >= 26;
}

/**
 * min date and max date in data
 */
export function getDateRangeFromData(
  data: any[],
  timezone?: string
): UnixtimeSelectedDate {
  // console.debug("getDateRangeFromData input: ", data);
  // const formatString = "yyyy-MM-dd'T'HH:mm:ssXXX"; //e.g. "2022-11-02T00:00:00-04:00"
  if (!isFilledArray(data)) {
    return { startDate: undefined, endDate: undefined };
  }
  const allDates: Date[] = data.map((item) => parseString(item.date));
  const maxDate: Date = getMaxDate(allDates);
  const minDate = getMinDate(allDates);
  // const result = {
  //   startDate: formatInTimeZone(minDate, timezone, formatString),
  //   endDate: formatInTimeZone(maxDate, timezone, formatString),
  // };
  const result = {
    startDate: getUnixTimeByDate(minDate),
    endDate: getUnixTimeByDate(maxDate),
    timezone,
  };
  // console.debug('getDateRangeFromData output', result);
  return result;
}

/**
 * return if the data array has and only has data for today
 */
export function checkIsTodayData(mainData, timezone) {
  // const dateRange = getDateRangeFromData(mainData);
  const hasToday = hasTodayData(mainData, timezone);
  const hasMultiDays = hasMultiDaysData(mainData);
  const shouldShowCurrentTimeLine = hasToday && !hasMultiDays;
  return shouldShowCurrentTimeLine;
}

/**
 * prop date is Date
 */
export function checkAllDatesAreToday(mainData, timezone) {
  if (!isFilledArray(mainData)) {
    return false;
  }
  const result = mainData.every((item) => {
    const { date } = item;
    return checkIsTodayInTimezone(timezone, date);
  });
  return result;
}

/**
 *
 */
export function getGlobalDataModalKey(customerID: string, dateRange) {
  const { startDate, endDate } = dateRange || {};
  // const startUnix = getUnixTimeByDate(startDate);
  // const endUnix = getUnixTimeByDate(endDate);
  if (!startDate && !endDate) {
    return customerID;
  } else if (!startDate) {
    return `global-data-modal-${customerID}-${endDate}`;
  }
  return `global-data-modal-${customerID}-${endDate}-${startDate}`;
}

/**
 * extract date range from traffice data
 */
export function extractDateRange(
  data: Array<{ date: string } & any>
): [string, string] {
  if (!isFilledArray(data)) {
    return ["", ""];
  }
  // console.log("extractDateRange ~ data", data);

  const range = extent(data?.map((item) => item?.date));
  return range;
}

export function extractDateRangeFromChartData(data) {
  if (!data) {
    return null;
  }

  const dates = data.map((item) => item?.value[0]);
  const range = extent(dates);
  return range;
}

/**
 * return the average integer with all zeros filtered out
 */
export function getAverageOfTrafficData(
  dataInput: any[],
  key: TrafficGraphTypes = TrafficGraphTypes.visitors
): number {
  if (!isFilledArray(dataInput)) {
    return null;
  }

  const filtered = dataInput?.filter((item) => item[key] !== 0);
  const rawAverage = getAverageByProp(filtered, key);
  // const filtered = dataInput?.filter((item) => item.overall !== 0);
  // const rawAverage = getAverageByProp(filtered, "overall");
  const ave = Math.floor(rawAverage);
  return ave;
}
export function getAverageOfTrafficData_V2(dataInput: any[]): {
  visitors: number;
  passerbys: number;
} {
  if (!isFilledArray(dataInput)) {
    return null;
  }

  const filteredVistors = [];
  const filteredPassbys = [];
  dataInput?.forEach((item) => {
    if (item.overall !== 0) filteredVistors.push(item);
    if (item.passerbys !== 0) filteredPassbys.push(item);
  });

  const avgVisitors = getAverageByProp(filteredVistors, "overall");
  const avgPassbys = getAverageByProp(filteredPassbys, "passerbys");
  return {
    visitors: Math.floor(avgVisitors),
    passerbys: Math.floor(avgPassbys),
  };
}

export function getTotalOfTrafficData(dataInput) {
  if (!isFilledArray(dataInput)) {
    return 0;
  }
  const total = _.sumBy(dataInput, "overall");
  return total;
}

const curriedIsTodayInTimezone = curry(checkIsTodayInTimezone);

/**
 * return a traffic data array without any data in today (timezoned)
 */
export function filterOutTodayData(dataInput, timezone: string) {
  const checkIsDateStringToday = pipe(
    parseString,
    curriedIsTodayInTimezone(timezone)
  );
  const todayIndex = dataInput?.findIndex((item) =>
    checkIsDateStringToday(item.date)
  );
  const filteredOutToday = _.clone(dataInput);
  filteredOutToday.splice(todayIndex, 1);
  // console.log("file: processors.ts:166 ~ filterOutTodayData ~ filteredOutToday", filteredOutToday);
  return filteredOutToday;
}

/**
 * return a traffic data array without any data in today (timezoned) nor any zero data
 */
export function getAverageOfTrafficDataWithoutToday(
  dataInput,
  timezone,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors
) {
  const filteredOutToday = filterOutTodayData(dataInput, timezone);
  return getAverageOfTrafficData(filteredOutToday, key);
}

/**
 * get max from 'overall by default'
 */
export function getMaxOfTrafficData(
  data,
  selectedOptions: Option[] = [],
  forecastProp?: any
): number {
  // console.log("file: processors.ts:179 ~ getMaxOfTrafficData ~ data, selectedProps", data, selectedOptions);
  if (!isFilledArray(data)) {
    return 0;
  }

  const selectedProps = selectedOptions.map((option) => option.value);
  const processedSelectedProps = ["overall", ...selectedProps];

  const maxMainData = _.max<number>(
    data.map((dataItem) => {
      const maxOfItem = _.max(
        processedSelectedProps.map((prop) => dataItem[prop])
      );
      return maxOfItem;
    })
  );

  const { show, data: forecastData } = forecastProp || {};
  if (show) {
    const maxForecast = _.maxBy<any>(forecastData, "overall")?.overall;
    const max = _.max<number>([maxForecast, maxMainData]);
    // console.log("file: processors.ts:212 ~ getMaxOfTrafficData ~ max", max);
    return max;
  }
  // const max = _.maxBy(data, (item) => {
  //   // always include 'overall'
  //   const processedSelectedProps = ['overall', ...selectedProps]
  //   const maxOfItem = _.max(processedSelectedProps.map(prop => item[prop]));
  //   return maxOfItem;
  // });
  // console.log("getMaxOfTrafficData ~ maxValue", max);
  return maxMainData;
}

/**
 *
 */
export function processWeeklyData(input) {
  const { mainData, forecast, subData, subForecast, ...rest } = input || {};
  return {
    mainData: addHoursToAllDates(mainData),
    forecast: addHoursToAllDates(forecast),
    subData: addHoursToAllDates(subData),
    subForecast: addHoursToAllDates(subForecast),
    ...rest,
  };
}

export function addHoursToAllDates(input, num?: number) {
  if (!isFilledArray(input)) {
    return input;
  }
  const result = input.map((item) => ({
    ...item,
    visitors: item.overall,
    date: addHoursToDateString(item?.date, num),
  }));
  return result;
}

export function addMinutesToAllDates(input, num?: number) {
  if (!isFilledArray(input)) {
    return input;
  }
  const result = input.map((item) => ({
    ...item,
    date: addMinutesToDateString(item?.date, num),
  }));
  return result;
}

/**
 * timezone offset difference with local timezone in hours
 */
export function getOffsetDiffInHours(timezone: string) {
  const now = DateTime.now();
  const offset = now.setZone(timezone).offset;
  // const localTimezone = getLocalTimeZone();
  const localOffset = DateTime.now().offset;
  const localOffsetInHours = Math.floor(localOffset / 60);
  const offsetInHours = Math.floor(offset / 60);
  return offsetInHours - localOffsetInHours;
}

export function setTimezoneForDateStrings(input, timezone: string) {
  if (!isFilledArray(input) || !timezone) {
    return input;
  }
  const result = input.map((item) => ({
    ...item,
    visitors: item.overall,
    date: DateTime.fromISO(item?.date).setZone(timezone).toISO(),
  }));
  return result;
}
