import { AxiosResponse } from "axios";
import { NULL_VALUE } from "./constants";

/**
 * interpret error from server
 */
export function getErrorMessageInResponse(resError): string {
  if (!resError) {
    return "Unkown error: No error object detected";
  }

  const { response } = resError || {};
  const { data, headers, status } = response || {};
  const { message, statusCode } = data || {};
  if (!headers) {
    return "Unkown error: No headers in response";
  }

  if (!message || typeof message !== "string") {
    return "Unkown error: No error message from server";
  }

  if (message.includes("Invalid credentials")) {
    return "Invalid credentials. Please logout and sign-in again.";
  }

  if (statusCode) {
    return `Status: ${statusCode}; Error: ${message}`;
  }

  if (status) {
    return `Status: ${status}; Error: ${message}`;
  }

  return message;
}

export function handleAxiosResultData<T>(axiosResponse: AxiosResponse<T>): T {
  if (!axiosResponse?.data) {
    if (axiosResponse.status === 200) {
      console.warn("No data in the response", axiosResponse);
    } else {
      console.error("Failed to get the data from the response", axiosResponse);
      // throw new Error(`Got response from server but no data in the response`);
    }
  }
  return axiosResponse?.data;
}

export function formateHardwareVersion(str: string): string {
  if (str.startsWith("v")) {
    return str;
  } else {
    return "v" + str;
  }
}

// check if the id is valid, include cases id is all '0's
export function checkIsValidID(id: string): boolean {
  return id && id !== "null" && id !== "undefined" && id !== NULL_VALUE;
}
