import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import styled from 'styled-components';
import { ErrorBoundary } from 'features/error-handling';

/**
 *
 */
export function NotificationGroup() {
  return (
    <ErrorBoundary>
      <div>
        <ToastContainer
          enableMultiContainer
          position={'bottom-right'}
          autoClose={5000}
          className={'toast-container-test'}
          containerId={'general-toast-container'}
        />
        <ToastContainer
          enableMultiContainer
          position={'top-center'}
          autoClose={false}
          containerId={'scanner-offline-toast-container'}
          className={'w-4/5'}
        />
      </div>
    </ErrorBoundary>
  );
}
