import { Icon } from "components/atomic/Icon";
import { isNil } from "lodash";
import { cond, always, T, lt, gt } from "ramda";

/**
 * UI for percentage numbers
 */
export function RateNum({ num, ...optionals }) {
  // const { numOfDecimalPlaces = 1, ...rest } = optionals;
  const { numOfDecimalPlaces = 1 } = optionals;
  // no need to display rate number
  if (isNil(num) || num === Infinity) return null;

  // console.log('file: RateNum.tsx:9 ~ RateNum ~ num', num);
  // get a invalid rate number
  if (Number.isNaN(num)) {
    return <span>N/A%</span>;
  }

  const formatted = num && Math.abs(num?.toFixed(numOfDecimalPlaces));
  const colorClassName = cond([
    [lt(0), always("text-green-700")],
    [gt(0), always("text-red-600")],
    [T, always("")],
  ])(num);

  return (
    <div className={`flex space-x-1 items-center ${colorClassName}`}>
      {num > 0 ? <Icon name={"arrow-up"} /> : null}
      {num < 0 ? <Icon name={"arrow-down"} /> : null}
      <span>{formatted}%</span>
    </div>
  );
}
