import { localPoint } from "@visx/event";
import { parseString } from "features/date";
// import { cond } from 'ramda';
import { scaleOrdinal } from "@visx/scale";
import { Bar, BarStack } from "components/features/charts/Bar";
import { useTheme } from "styled-components";
import { useMemo } from "react";

let tooltipTimeout: number;

/**
 * if date is invalid, return 0
 */
function getBarHeight(yValue, yMax, valueScale) {
  return yValue ? yMax - valueScale(yValue) : 0;
}

export function getRadius(width) {
  return Math.floor(width * 0.3);
  // let result = 4;
  // if (width > 500) {
  //   result = 8;
  // } else if (width > 300) {
  //   result = 6;
  // }
  // return result;
}

/**
 * A vertical bar for main data
 */
export function MainBar({
  d,
  yPropName,
  yMax,
  valueScale,
  timeScale,
  tooltipProps,
  onBarClick,
  isBarClickEnabled,
  fill,
  width: barWidth,
  ...rest
}) {
  const maxBarWidth = 80;
  const { date } = d || {};
  const { x, yPropLabel } = rest;
  const yValue = d[yPropName];
  const barHeight = getBarHeight(yValue, yMax, valueScale);
  const barY = yMax - barHeight;
  // const barX = date && timeScale(date);
  const { hideTooltip, showTooltip } = tooltipProps;
  const width = useMemo(
    () => (barWidth > maxBarWidth ? maxBarWidth : barWidth),
    [barWidth]
  );
  const barX = useMemo(() => {
    const xVal = x ? x : date && timeScale(parseString(date));
    if (width < maxBarWidth) return xVal;
    return width < maxBarWidth
      ? xVal
      : xVal + Math.floor((barWidth - maxBarWidth) / 2);
  }, [width, barWidth, date, timeScale, x]);
  // const barX = useMemo(() => x ? x : date && timeScale(parseString(date), [width]);

  // Hooks
  // const isLg = useMediaQueries('lg');
  if (!barHeight) {
    return null;
  }

  const handleBarClick = (event) => {
    isBarClickEnabled && onBarClick && onBarClick(d, event);
  };

  const handleMouseLeave = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  const handleMouseMove = (event) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const { x, y } = localPoint(event) || {};
    const tooltipData = {
      bar: {
        key: yPropName,
        color: fill,
        value: Math.round(yValue),
        label: yPropLabel,
      },
      time: date,
    };
    // console.log("file: MainBar.tsx:67 ~ handleMouseMove ~ tooltipData", tooltipData);
    showTooltip({
      tooltipData: tooltipData,
      tooltipTop: y,
      tooltipLeft: x,
    });
  };

  return (
    <>
      <Bar
        x={barX}
        y={barY}
        radius={getRadius(width)}
        top={true}
        width={width}
        height={barHeight}
        fill={fill}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onClick={handleBarClick}
        $clickable={isBarClickEnabled}
      />
    </>
  );
}

/**
 * MainBar with special color
 */
export function SpecialColorMainBar(props) {
  const { fill, ...rest } = props;
  const theme = useTheme();

  return (
    <>
      <MainBar fill={fill || theme.colors.graph.today} {...rest} />
    </>
  );
}

/**
 * MainBar with stack
 */
export function StackedColoredMainBarForToday({
  data,
  keys,
  timeScale,
  valueScale,
  tooltipProps,
  onBarClick,
  isBarClickEnabled,
  ...optionals
}: any) {
  const { width, hasForecast } = optionals;
  const { hideTooltip, showTooltip } = tooltipProps;

  // Hooks
  const theme = useTheme();
  // const isLg = useMediaQueries("lg");

  const xAccessor = (d) => d.date;
  const colorScale = scaleOrdinal<any, string>({
    domain: keys,
    range: [theme.colors?.graph?.today, theme?.colors?.graph?.forecast],
  });
  const handleMouseLeave = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  return (
    <BarStack
      data={data}
      keys={keys}
      x={xAccessor}
      xScale={timeScale}
      yScale={valueScale}
      color={colorScale}
    >
      {(barStacks) =>
        barStacks.map((barStack) =>
          barStack.bars.map((barProps) => {
            // console.log("file: MainBar.tsx:180 ~ barStack.bars.map ~ bar", barProps)
            const { bar, key } = barProps || {};
            const { data: barData } = bar || {};
            const yValue = barData[key];

            if (!yValue) {
              return null;
            }

            const handleBarClick = (event) => {
              isBarClickEnabled && onBarClick && onBarClick(barData, event);
            };

            const handleMouseMove = (event) => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const { x, y } = localPoint(event) || {};
              showTooltip({
                tooltipData: {
                  bar: {
                    key: key,
                    color: colorScale(key),
                    value: barData[key], //Math.round(yValue),
                  },
                  time: barData.date,
                },
                tooltipTop: y,
                tooltipLeft: x,
              });
            };
            const barX = timeScale(parseString(barData.date));

            return (
              <Bar
                key={`bar-stack-${barStack.index}-${barProps.index}`}
                x={barX}
                y={barProps.y}
                height={barProps.height}
                width={width}
                fill={barProps.color}
                onClick={handleBarClick}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                radius={getRadius(width)}
                top={!hasForecast}
                $clickable={isBarClickEnabled}
              />
            );
          })
        )
      }
    </BarStack>
  );
}
