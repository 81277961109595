import BootstrapCard from 'react-bootstrap/Card';
import styled, { useTheme } from 'styled-components';
import MuiCard from '@mui/material/Card';
import { lighten } from 'polished';

export function Card({ children, ...optionals }) {
  const { title, titleColor, ...rest } = optionals;
  const theme = useTheme();

  return (
    <BootstrapCard border="light" {...rest}>
      <BootstrapCard.Body>
        {title && <BootstrapCard.Title style={{ color: titleColor ?? theme?.colors?.grey }}>{title}</BootstrapCard.Title>}
        {children}
      </BootstrapCard.Body>
    </BootstrapCard>
  )
}

export const CardWithGrayBg = styled(Card)`
  && {
    flex: 1;
    background-color: ${props => props.theme?.colors.grayscale[6]};
    border-radius: 16px;
  }
`;

export const SubCard = styled(CardWithGrayBg)`
  flex: 1;
  max-width: 12rem;
`;

/**
 * Material UI Card
 */
export function Card2(props) {
  return <MuiCard {...props} />
}

export const StyledCardLine = styled.div<{ colored: boolean }>`
  /* background-color: ${(p) => lighten(0.93, p.theme.colors.tertiary)}; */
  background-color: ${(p) =>
    p?.colored ? lighten(0.64, p.theme.colors.tertiary) : 'transparent'};
`;

export function CardLine(props) {
  const { className, ...rest } = props;
  return <StyledCardLine className={className ? className : 'rounded p-2 flex justify-between'} {...rest} />
}