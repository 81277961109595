import { Box, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { Button as BootstrapBtn, Spinner } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

// import { GoogleLogin } from 'components/features/Auth/GoogleLogin';
import { BASE_API } from "features/kaidu-config-server";
import { getLocalStorage } from "lib/persistence/local-storage";
import { mediaQueries } from "styles/themes";
import KaiduBgImg from "../../assets/kaidu-dashboard-background.jpg";

const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.danger};
  text-align: center;
`;

const Wrapper = styled.div`
  /* display: flex; */
  height: 100vh;
  width: 100vw;

  .image {
    max-width: 715px;
    width: 100%;
    border-right: 5px ${(p) => p.theme.colors.secondary} solid;
    background-image: url(${KaiduBgImg});
    background-repeat: no-repeat;
    background-position: center;
    display: none;
    ${mediaQueries("md")`
      display: block;
    `};
  }
`;

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 5px ${(p) => p.theme.colors.secondary} solid;

  .content {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
`;

const OTPInput = styled.input`
  border: 1px solid;
`;

/**
 *
 */
export function VerifyOTP() {
  // Hooks
  const history = useHistory();
  const { state } = useLocation<{ email?: string }>();

  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>(500);

  const email = state?.email;

  const handleSubmit = (code: string) => {
    const verification_code = code.trim();
    if (!verification_code) {
      setError("OTP Code Required");
    } else if (!/^[A-Z0-9]{8}$/.test(verification_code)) {
      setError("Invalid OTP");
    } else {
      setLoading(true);
      axios
        .post(`${BASE_API}/auth/verify_otp`, {
          verification_code,
          isManagementDashboard: true,
        })
        .then((response) => {
          setLoading(false);
          if (response?.data?.jwt) {
            setError("");
            history.push(`/login/success?token=${response.data.jwt}`);
          }
        })
        .catch((err: AxiosError<any>) => {
          const errMsg = err?.response?.data?.message;
          console.log({ err });
          setError(errMsg || err?.message || "Failed to verify OTP");
          setErrorCode(
            err?.response?.data?.statusCode || err?.response?.status || 500
          );
          setLoading(false);
        });
    }
  };

  const handlePaste: React.ClipboardEventHandler = (event) => {
    const verification_code = event.clipboardData.getData("text");
    handleSubmit(verification_code);
  };

  const resendOTP = () => {
    if (!email) history.goBack();
    else {
      setLoading(true);
      axios
        .post(`${BASE_API}/auth/request_otp`, {
          email,
          isManagementDashboard: true,
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err: AxiosError<any>) => {
          const errMsg = err?.response?.data?.message;
          setError(errMsg || err?.message || "Failed to request OTP");
          setErrorCode(500);
          setLoading(false);
        });
    }
  };

  const goToLogin = () => {
    const customerName = getLocalStorage("partnerCustomer");
    return customerName
      ? history.push(`/login?customer=${encodeURIComponent(customerName)}`)
      : history.push("/login");
  };

  return (
    <Wrapper className="flex">
      <div className="image"></div>
      <Main>
        <div className="content">
          <h2 className="text-center text-sec">Check your email for a code</h2>
          <Typography
            variant="body1"
            component="h6"
            align="center"
            className="text-neutral-400"
          >
            We've sent a 8-character code to{" "}
            <span className="text-black font-medium">{email}</span>. This code
            expires shortly, so please enter it soon.
          </Typography>
          <Box className="mt-4">
            <OtpInput
              containerStyle="justify-center gap-1"
              numInputs={8}
              renderInput={(props) => {
                return (
                  <OTPInput
                    {...props}
                    type="text"
                    className="!w-12 !h-12 rounded"
                  />
                );
              }}
              onPaste={handlePaste}
              shouldAutoFocus
              value={otp}
              onChange={(e) => setOtp(e.toUpperCase())}
            />
            {error && <ErrorText className={"mt-4"}>{error}</ErrorText>}
          </Box>
          {errorCode < 500 ? (
            <BootstrapBtn
              className="mt-4"
              onClick={() => (errorCode === 401 ? resendOTP() : goToLogin())}
              disabled={isLoading || otp.length !== 8}
            >
              {isLoading && (
                <Spinner animation="border" size="sm" className="mr-2" />
              )}
              {errorCode === 401 ? "Resend" : "Go To Login"}
            </BootstrapBtn>
          ) : (
            <BootstrapBtn
              className="mt-4"
              onClick={() => handleSubmit(otp)}
              disabled={isLoading || otp.length !== 8}
            >
              {isLoading && (
                <Spinner animation="border" size="sm" className="mr-2" />
              )}
              Verify
            </BootstrapBtn>
          )}
        </div>
      </Main>
    </Wrapper>
  );
}
