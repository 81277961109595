import { getNow } from "../date";
import { AUTH_KEY } from "./constants";
import jwt_decode from "jwt-decode";
import { JWT } from "./types";
import {
  setToLocalStorage,
  getLocalStorage,
} from "lib/persistence/local-storage";
import { removeAxiosAuthHeader } from "../axios";
import { checkIfValidUUID } from "utils";

export function isTokenExpired(exp: number): boolean {
  const now = Math.floor(getNow().getTime() / 1000);
  const result = exp < now;
  result && console.warn("Token expired!", exp, now);
  return result;
}

export function setTokenToLocalStorage(token: string) {
  return setToLocalStorage(AUTH_KEY, {
    token,
  });
}

export function getAuthTokenFromLocalStorage(): string {
  const { token } = getLocalStorage(AUTH_KEY) || {};
  return token;
}

export function getPartnerCustomerIdFromLocalStorage(): string {
  let partner_customer_id = getLocalStorage("partner_customer_id");
  partner_customer_id = String(partner_customer_id).trim();
  return checkIfValidUUID(partner_customer_id) ? partner_customer_id : null;
}

export function getDecodeAuthTokenFromLocalStorage() {
  try {
    const token = getAuthTokenFromLocalStorage();
    // console.debug("getDecodeAuthTokenFromLocalStorage", token);
    const tokenObj: JWT = token && jwt_decode(token);
    return tokenObj;
  } catch (error) {
    return null;
  }
}

export function clearAuthTokenInLocalStorage(): void {
  setTokenToLocalStorage(null);
}

export function checkIsTokenValid(token: string): boolean {
  try {
    if (!token) return false;
    const tokenObj: any = jwt_decode(token);
    const { exp } = tokenObj || { exp: true };
    return exp && !isTokenExpired(exp);
  } catch (error) {
    console.warn("checkIsTokenValid gets error", error);
    return false;
  }
}

export function checkIsAuthInLocalStorageValid(): boolean {
  const token = getAuthTokenFromLocalStorage();
  return checkIsTokenValid(token);
}

export function logout() {
  clearAuthTokenInLocalStorage();
  sessionStorage.clear();
  removeAxiosAuthHeader();
}
