import {
  clearLocalStorage,
  getLocalStorage,
  setToLocalStorage,
} from "lib/persistence/local-storage";
import { VERSION } from "../constants";

export function handleNewVersion() {
  const oldVersion = getLocalStorage("version");

  if (oldVersion === VERSION) return;
  clearLocalStorage();
  setToLocalStorage("version", VERSION);
}
