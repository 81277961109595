import _ from "lodash";
import { useQuery } from "react-query";
import { USERS_LIST_API } from "./api";
import {
  fetchAllUsersList,
  fetchUsersList,
  fetchUsersListOfCustomer,
} from "./fetch";
import { User } from "./types";

/**
 * get current user data
 */
export function useUsersList(enabled: boolean = true) {
  const {
    data: userData,
    error,
    ...rest
  } = useQuery<User, Error>(USERS_LIST_API, fetchUsersList, { enabled });
  return {
    ...rest,
    userData,
    isError: error,
    error,
  };
}

/**
 * get all users data that current user can access
 */
export function useAllUsersList() {
  const { data, ...rest } = useQuery<User[], Error>(
    [USERS_LIST_API, "all"],
    fetchAllUsersList
  );
  return {
    ...rest,
    data: _.sortBy(data, "user_email"),
  };
}

/**
 * get users that can access this customer
 */
export function useUserListForCustomer(customerID: string) {
  const { data, ...rest } = useQuery<User[], Error>(
    [USERS_LIST_API, "customer", customerID],
    () => fetchUsersListOfCustomer(customerID),
    { enabled: !!customerID }
  );
  return {
    ...rest,
    // data: _.sortBy(data, 'user_email'),
    data,
  };
}
