import { useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { FullSizeTableView } from "components/features/Table/FullSizeTableView";
import { NoData } from "components/features/Table/NoData";
import { ErrorBoundary } from "features/error-handling/components/ErrorBoundary";
import { useMediaQueries } from "styles/themes";
import { isFilledArray } from "utils";
import { ZonesCardList } from "./ZonesCardList";

/**
 * Zones table on the settings page
 */
export function ZonesTable({ columns, data, ...optionals }) {
  const { emptyState = <NoData />, searchBarPlaceholder = "Search" } =
    optionals;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, globalFilter },
    prepareRow,
    setGlobalFilter,
    setHiddenColumns,
    //@ts-ignore
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageSize: 7,
        sortBy: [
          {
            id: "Scanner Name",
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const isLg = useMediaQueries("lg");
  const is2Xl = useMediaQueries("2xl");

  const shouldShowPagination = true && pageCount > 1;
  const shouldShowFilter = isFilledArray(data);
  const filter = {
    shouldShowFilter,
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
  };
  const pagination = {
    shouldShowPagination,
    previousPage,
    gotoPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageIndex,
  };

  useEffect(() => {
    is2Xl ? setHiddenColumns([]) : setHiddenColumns(["mac_address"]);
    // return () => {
    // };
  }, [is2Xl, setHiddenColumns]);

  return (
    <div className="max-w-full" data-cy="zones-table">
      <ErrorBoundary>
        {isLg ? (
          <FullSizeTableView
            filter={filter}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            emptyState={emptyState}
            pagination={pagination}
          />
        ) : (
          <ZonesCardList
            filter={filter}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            emptyState={emptyState}
            pagination={pagination}
          />
        )}
      </ErrorBoundary>
    </div>
  );
}
