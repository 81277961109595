import { addBase64PrefixForMIMEType } from 'features/base64';
import { keys, omit, pipe } from 'ramda';
import { isFilledArray } from 'utils';
import { LiveImage, LiveVideo, MediaItem, WorkingCameraItem } from './types';

export function parseImageList(
  data: LiveImage,
  defaultThumbnail?: string
): MediaItem[] {
  // console.debug('input: ', data);
  if (!data) {
    return null;
  }

  const imageKeys = pipe(omit(['msg']), keys)(data);
  const images = imageKeys.map((key) => {
    try {
      const image = data[key];
      const src = addBase64PrefixForMIMEType('jpg', image)
        .replace("b'", '')
        .slice(0, -1);
      return {
        mediaID: key,
        src,
        type: 'image',
        thumbnail: src ?? defaultThumbnail,
      };
    } catch (error) {
      console.error('Invalid image base64 string', error);
      return null;
    }
  });
  return images;
}

// function dataURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(','),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);

//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new File([u8arr], filename, { type: mime });
// }

export function parseVideoList(
  data: LiveVideo,
  defaultThumbnail?: string
): MediaItem[] {
  // console.debug('input: ', data);
  if (!data) {
    return null;
  }
  
  const videosKeys = pipe(omit(['msg']), keys)(data);
  const videos = videosKeys.map((key) => {
    try {
      const item = data[key];
      const src = addBase64PrefixForMIMEType('flv', item)
        .replace("b'", '')
        .slice(0, -1);
      // const foo = dataURLtoFile(src, 'main.flv');
      // console.debug('result: ', foo);

      return {
        mediaID: key,
        src: src,
        type: 'video',
        thumbnail: defaultThumbnail,
      };
    } catch (error) {
      console.error('Invalid base64 string', error);
      return null;
    }
  });
  return videos;
}

// References: https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
// returns an URL representing the image
export function parseImage(bytesString: string): any {
  const foo = new Blob([bytesString], { type: 'image/jpeg' });
  // console.debug('result: ', foo);
  const result = URL.createObjectURL(foo);
  return result;
}

export function decodeEscapeSequence(input) {
  return input.replace(/\\x([0-9A-Fa-f]{2})/g, function () {
    return String.fromCharCode(parseInt(arguments[1], 16));
  });
}

export function parseCameraIDs(input): string[] {
  if (!isFilledArray(input)) {
    return null;
  }

  return input.filter((item: string) => item.includes('Camera location:') && item.includes('ID:')).map(item => {
    return item.split('ID:')[1].trim();
  });
}

export function parseCameraList(input): any[] {
  if (!isFilledArray(input)) {
    return null;
  }

  return input.filter((item: string) => item.includes('Camera location:') && item.includes('ID:')).map(item => {
    const cameraID = item.split('ID:')[1].trim();
    const cameraLocation = item.split('Camera location:')[1].split('>>')[0].trim();
    return {
      cameraID,
      cameraLocation,
    }
  });
}


export function convertWorkingCamListToOptions(cameras: WorkingCameraItem[]) {
  if (!isFilledArray(cameras)) {
    return null;
  }

  return cameras?.map(camera => {
    const {id, name} = camera || {};
    return {
      value: id,
      label: name,
    };
  });
}