import { subDays } from "date-fns";
import {
  compareIsEarlierThanOrEqual,
  compareIsLaterThanOrEqual,
} from "../checker";
import {
  convertDateToTimezonedRFC3339AtEndOfDay,
  convertDateToTimezonedRFC3339AtStartOfDay,
  convertUnixTimestampToTimezonedRFC3339AtEndOfDay,
  convertUnixTimestampToTimezonedRFC3339AtStartOfDay,
} from "../convertor";
import { createDateByUnixTime, parseString } from "../foo";
import { getNow } from "../getters";

/**
 * check if two dates are in the same day in a specific time zone
 * return false for invalid dates
 */
export function isSameDayInTimezone(
  date1: number,
  date2: number,
  timezone: string
): boolean {
  if (!date1 || !date2) return false;

  // console.log("file: checker.ts ~ date1:", date1);
  // console.log("file: checker.ts ~ date2:", date2);
  // console.log("file: checker.ts ~ timezone:", timezone);

  const startTimeString = convertUnixTimestampToTimezonedRFC3339AtStartOfDay(
    date2,
    timezone
  );
  const endTimeString = convertUnixTimestampToTimezonedRFC3339AtEndOfDay(
    date2,
    timezone
  );
  const startTime = parseString(startTimeString);
  const endTime = parseString(endTimeString);
  // console.log("file: checker.ts ~ startTime:", startTimeString);
  // console.log("file: checker.ts ~ endTime:", endTimeString);
  const date1Obj = createDateByUnixTime(date1);
  const result =
    compareIsEarlierThanOrEqual(date1Obj, endTime) &&
    compareIsLaterThanOrEqual(date1Obj, startTime);
  return result;
}

export function checkIsSameDayInTimezoneForDates(
  date1: Date,
  date2: Date,
  timezone: string
): boolean {
  if (!date1 || !date2) return false;

  const startTimeString = convertDateToTimezonedRFC3339AtStartOfDay(
    date2,
    timezone
  );
  const endTimeString = convertDateToTimezonedRFC3339AtEndOfDay(
    date2,
    timezone
  );
  const startTime = parseString(startTimeString);
  const endTime = parseString(endTimeString);
  return (
    compareIsEarlierThanOrEqual(date1, endTime) &&
    compareIsLaterThanOrEqual(date1, startTime)
  );
}

/**
 * check if a date is today in the desired timezone
 */
export function checkIsTodayInTimezone(timezone: string, date: Date): boolean {
  const now = getNow();
  const result = checkIsSameDayInTimezoneForDates(date, now, timezone);
  return result;
}

export function checkIsYesterdayInTimezone(
  timezone: string,
  date: Date
): boolean {
  const yesterday = subDays(getNow(), 1);
  const result = checkIsSameDayInTimezoneForDates(date, yesterday, timezone);
  return result;
}
