import { convertCameraDeviceListToOptions, convertCustomerCustomDataToCameraDeviceList } from './foo';
import {
  useFetchDevices
} from 'features/kaidu-config-server';

export function useCameraData(camerasForScanners) {
  // console.log("file: hooks.ts:7 ~ useCameraData ~ camerasForScanners", camerasForScanners)
  const devices = useFetchDevices(Boolean(camerasForScanners));

  // Process data
  // const shouldUseGlobalData = isValidLocations || !selectedDevice;
  const cameraDeviceList = convertCustomerCustomDataToCameraDeviceList(
    camerasForScanners,
    devices?.data
  );
  const cameraOptions = convertCameraDeviceListToOptions(cameraDeviceList);

  return {
    cameraDeviceList,
    cameraOptions,
    devices
  };
}