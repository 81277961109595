import {
  differenceInDays,
  getTime,
  getUnixTime,
  max,
  min,
  parse,
  parseISO,
  subDays,
} from "date-fns";
import _ from "lodash";
import { isFilledArray } from "utils";

export function getNow(): Date {
  return new Date();
}

/**
 * @returns the date object with (current time - 1 day)
 */
export function getYesterday(): Date {
  return subDays(getNow(), 1);
}

export function getTimeInMilliseconds(date: Date = new Date()): number {
  return getTime(date);
}

/**
 * Get the seconds timestamp of the given date
 */
export function getUnixTimeByDate(date: Date = new Date()): number {
  return getUnixTime(date);
}

export function getMinDate(datesArray: Date[]) {
  return min(datesArray);
}

export function getMaxDate(datesArray: Date[]) {
  return max(datesArray);
}

/**
 * return start date as a week ago and end date as today, both in unix timestamp
 */
export function getAWeekDateRange() {
  const today = getNow();
  const aWeekAgo = subDays(today, 7);
  return {
    startDate: getUnixTimeByDate(aWeekAgo),
    endDate: getUnixTimeByDate(today),
  };
}

/**
 * return start date as a week ago and end date as today, both in unix timestamp
 */
export function getAWeekDateRangeForYesterday() {
  const endDay = getYesterday();
  const aWeekAgo = subDays(endDay, 6);
  return {
    startDate: getUnixTimeByDate(aWeekAgo),
    endDate: getUnixTimeByDate(endDay),
  };
}

/**
 * day1 - day2
 * day string format: 'yyyy-MM-dd'
 */
export function getDayDiffByShortDayString(day1: string, day2: string) {
  const date1 = parse(day1, "yyyy-MM-dd", new Date());
  const date2 = parse(day2, "yyyy-MM-dd", new Date());
  return differenceInDays(date1, date2);
}

/**
 * get the item in array which with the latest date
 */
export function getLatestByDateInArray<T>(list: Array<T>): T {
  // get latest date item in array
  if (!isFilledArray(list)) {
    return null;
  }
  const dateList = list.map((item: any, index) => ({
    date: parseISO(item?.date),
    i: index,
  }));
  const max = _.maxBy<any>(dateList, "date");
  return list[max.i];
}

/**
 * get the second latest date in array
 */
export function getSecondLatestByDateInArray<T>(list: Array<T>): T {
  // get latest date item in array
  if (!isFilledArray(list)) {
    return null;
  }
  const dateList = list.map((item: any, index) => ({
    date: parseISO(item?.date),
    i: index,
  }));
  const sorted = _.sortBy<any>(dateList, "date");
  const sortedItem = sorted[sorted.length - 2];
  const secondMax = list[sortedItem.i];
  return secondMax;
}

/**
 * return a string, e.g. "-04:00", "+10:00"
 * @param date e.g. "2023-03-12T00:00:00-04:00"
 */
export function getTimezoneDiffInDateString(date: string): string {
  if (!date) {
    return "";
  }
  // get last 6 char from string
  const last6Char = date?.slice(-6);
  return last6Char;
}
