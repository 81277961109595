import styled from 'styled-components';

const DefaultP = styled.p`
  /* font-size: 1.2rem; */
  color: ${(p) => p.theme.colors[p.color] || p.theme.colors.tertiary};
`;

export function Typography({children, ...optionals}) {
  const {className = '', ...rest} = optionals;

  return (
    <DefaultP className={className} {...rest} >{children}</DefaultP>
  )
}

export function Heading4({children, ...optionals}) {
  const {className = '', ...rest} = optionals;
  return (
    <h4 className={className} {...rest} >{children}</h4>
  );
}

export function Heading3({children, ...optionals}) {
  const {className = 'text-xl', ...rest} = optionals;
  return (
    <h3 className={className} {...rest} >{children}</h3>
  );
}

export function Heading2({children, ...optionals}) {
  const {className = '', ...rest} = optionals;
  return (
    <h2 className={className} {...rest} >{children}</h2>
  );
}

export function Heading1({children, ...optionals}) {
  const {className = 'text-lg md:text-2xl font-bold mb-0', ...rest} = optionals;
  return (
    <h1 className={className} {...rest} >{children}</h1>
  );
}

export const PageTitle = styled.h1<{color?: string}>`
  color: ${(p) => p.theme.colors[p.color] || p.theme.colors.secondary};
  font-weight: bold;
`;


export const GrayTypography = styled.p`
  color: ${(p) => p.theme.colors.grayscale[3]};
`;

// export const MobileTitle = styled