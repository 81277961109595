// import {} from ''

export const TABS_LIST = {
  byID: {
    home: { path: "/", icon: "home", label: "Home" },
    settings: { path: "/settings", icon: "cog", label: "Settings" },
  },
  allIDs: ["home", "settings"],
  // allIDs: ['home', 'zones', 'cards', 'settings'],
};

export const SIDEBAR_NAV = {
  byID: {
    home: { path: "/", icon: "home", label: "Home" },
    settings: { path: "/settings", icon: "cog", label: "Settings" },
  },
  allIDs: ["home", "settings"],
  // allIDs: ['home', 'zones', 'cards', 'settings'],
  centerIDs: [],
  // centerIDs: ['home', 'zones', 'cards'],
  bottomIDs: ["home", "settings"],
};
