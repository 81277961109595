/**
 * 
 */
export function VisitorGraphToolTipView({
  label, timeText, value, color, ...optionals
}) {
  // time: 2022-11-18T00:00:00-05:00
  return (
    <>
      <p style={{ color }} className='text-capitalize text-base mb-0'>
        {label}
      </p>
      <p className='text-xl mb-0'>{value}</p>
      <span>{timeText}</span>
    </>
  );
}
