import { GridRows } from '@visx/grid';
import { useTheme } from 'styled-components';


export function Grids({ width, left, scale, ...rest }) {
  // Hooks
  const theme = useTheme();

  return (
    <GridRows
      numTicks={5}
      left={left}
      scale={scale}
      offset={0}
      width={width}
      strokeDasharray='1,3'
      stroke={theme.colors.graph.grid}
      strokeOpacity={0.4}
      pointerEvents='none'
    />
  )
}