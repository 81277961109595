import { useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/atomic/Button";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import { Typography } from "components/atomic/Typography";
import { Select } from "components/molecule/Select";
import {
  createDateByUnixTime,
  getLocalTimeZone,
  getUnixTimeByDate,
  getYesterday,
} from "features/date";
import { DateSelectorGroup } from "features/date/components/DateSelectors";
import { sendReport } from "features/kaidu-stats-server";
import {
  NOTIFICATION_TYPE,
  notifyInfo,
  updateNotification,
} from "lib/services/notification";
import {
  selectSelectedCustomer,
  updateEndDate,
} from "providers/redux/globalSelectSlice";
import { DOCUEMENT_TYPES, REPORT_TYPES } from "../report";

const sendReportWithMutation = (input) => sendReport(input);

/**
 *
 */
export function UserModalContent({ data, onHide, ...optionals }) {
  const { user_email } = data || {};

  // Hooks
  const dispatch = useDispatch();
  const customer = useSelector(selectSelectedCustomer);
  const { customerID, customerTimezone } = useMemo(() => {
    if (!customer) return {};
    return {
      customerID: customer.customer_id,
      customerTimezone:
        customer.customer_config?.hoursOfOperation?.timezone ??
        getLocalTimeZone(),
    };
  }, [customer]);
  const toastId = useRef(null);
  const sendReportMutation = useMutation(sendReportWithMutation, {
    onSuccess: () => {
      // queryClient.invalidateQueries(['customer', customerID]);
      updateNotification(toastId.current, {
        render: "Success! Report will be delivered soon",
        type: NOTIFICATION_TYPE.SUCCESS,
        autoClose: 5000,
      });
      // notifySuccess('Success! Report will be delivered soon');
    },
    onError: (error: Error) => {
      updateNotification(toastId.current, {
        render: error?.message,
        type: NOTIFICATION_TYPE.ERROR,
        autoClose: 5000,
      });
      // notifyError(error?.message);
    },
  });
  const { control, handleSubmit } = useForm({
    defaultValues: {
      type: "visitors",
      docType: "pdf",
      endDate: getYesterday(),
    },
  });

  const handleSendingReports = (formData) => {
    // console.debug('sending reports', formData);
    // return;
    const email = user_email;
    if (!formData || typeof formData !== "object") {
      return;
    }

    sendReportMutation.mutate({
      customerID,
      email,
      timezone: customerTimezone,
      ...formData,
    });
    dispatch(updateEndDate(getUnixTimeByDate(getYesterday())));
    onHide();
    toastId.current = notifyInfo(
      "Sending the report now. Please wait for a few mintues",
      { autoClose: false }
    );
    return;
  };

  return (
    <>
      <ModalHeader>
        <ModalTitle>Send Report</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Typography>To: {user_email}</Typography>
        <div className="flex gap-2 flex-wrap">
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value, name } }) => (
              <Select
                label={"Report Type"}
                onChange={(option) => onChange(option?.value)} // send value to hook form
                options={REPORT_TYPES}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="docType"
            render={({ field: { onChange, value, name } }) => (
              <Select
                label={"Document Type"}
                onChange={(option) => onChange(option?.value)} // send value to hook form
                options={DOCUEMENT_TYPES}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange, value } }) => (
              <DateSelectorGroup
                label="Report Date"
                onlySingleDayQueries
                timezone={customerTimezone}
                onChange={(nextValue) => {
                  if (typeof nextValue === "number")
                    onChange(createDateByUnixTime(nextValue));
                  else if (
                    typeof nextValue === "object" &&
                    typeof nextValue.endDate === "number"
                  )
                    onChange(createDateByUnixTime(nextValue.endDate));
                }}
              />
            )}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmit(handleSendingReports)}>Send</Button>
      </ModalFooter>
    </>
  );
}
