import axios, { AxiosResponse } from "axios";
import { inspect } from "../../utils";

const DEFAULT_TIMEOUT = 50004;

/**
 * @description set default axios header for all axios requests; add authorization and timeout
 * @param  {string} token
 */
export function setAxiosDefault(token: string, partner_customer_id?: string) {
  // console.debug(`setting axios default header with token: ${token}`);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // Axios default timeout time
  axios.defaults.timeout = DEFAULT_TIMEOUT;
  if (partner_customer_id) {
    axios.defaults.params = { partner_customer_id };
  }
}

export function getAxiosDefaultAuthHeader() {
  const result = axios?.defaults?.headers?.common["Authorization"];
  // console.log(`Default axios auth header: ${result}`);
  return result;
}

export function addAxiosInterceptor(errorCallback: Function) {
  axios.interceptors.response.use(
    function (response) {
      // console.log(response.request._url);
      // console.log(response?.headers);
      // console.log(response?.request?._headers?.authorization);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      errorCallback(error);
      return Promise.reject(error);
    }
  );
}

export function removeAxiosAuthHeader() {
  delete axios.defaults.headers.common["Authorization"];
}

export function getErrorMessageInResponse(resError): string {
  if (!resError) {
    return "Failed to get the error object";
  }

  const resData = resError?.response?.data;
  if (resData) {
    const errorKeys = Object.keys(resData);
    // console.log(errorKeys);
    const errorMsg = errorKeys
      .map((key) => `${key}: ${resData[key]}`)
      .join(" ");
    return errorMsg;
  }

  const result = resError?.message || inspect(resError);
  return result;
}

export function handleAxiosResultData<T>(axiosResponse: AxiosResponse<T>): T {
  if (!axiosResponse.data) {
    throw new Error(`Got response from server but no data in the response`);
  }
  return axiosResponse.data;
}
