import { useState } from "react";
import styled from "styled-components";

// import { lighten } from 'polished';
import Collapse from "@mui/material/Collapse";
import { Card2, CardLine } from "components/atomic/Card";
import { Icon } from "components/atomic/Icon";
import { IconButton } from "components/atomic/IconButton";
import KaiduPlug from "../../../assets/kaidu_plug.png";

const ExpandableIcon = styled(Icon)`
  font-size: 1.5rem;
  color: ${(p) => p.theme.colors.secondary};
`;

const NOT_COVERTED_TO_ROW_COLUMNS = ["device_name", "Status", "skipme"];

function filterOutColumnsInHeader(cells, noInBodyColumns) {
  return cells.filter((item) => !noInBodyColumns.includes(item.column?.id));
}

/**
 * display a scanner data in a card
 * areaes: Header, Body, Footer
 */
export function ZoneCard({ row, title, ...optionals }) {
  // Props
  const {
    headerRightColumn = "Status",
    noInBodyColumns = NOT_COVERTED_TO_ROW_COLUMNS,
  } = optionals;

  const regularCells = filterOutColumnsInHeader(row.cells, noInBodyColumns);
  const footerCell = row.cells.find((item) => item.column?.id === "skipme");
  // console.debug('regularCells', regularCells);

  // Local state
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card2
      className="space-y-3 p-3"
      sx={{
        height: isExpanded ? "260px" : "74px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", marginBottom: "8px" }}>
          <div className="flex justify-content-between">
            <img
              src={KaiduPlug}
              style={{ width: "30px", height: "30px" }}
              alt="kaidu_plug"
            />
            <span
              className={"break-all"}
              style={{
                padding: "8px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "219px",
              }}
            >
              {title}
            </span>
          </div>
        </div>
        <div className={"flex items-center"}>
          {row.values[headerRightColumn]}
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-2"
          >
            <ExpandableIcon
              name={isExpanded ? "FaChevronUp" : "FaChevronDown"}
              style={{
                color: "black",
                width: "14px",
                height: "14px",
              }}
            />
          </IconButton>
        </div>
      </div>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <div>
          {regularCells.map((cell, index) => {
            const key = cell.column.id;
            const Header = cell.column.Header;
            const isColoredBg = index % 2 === 0;

            return (
              <CardLine
                data-cy="zone-card-line"
                colored={isColoredBg}
                key={key}
              >
                <span>{Header}:</span>
                <span>{cell.render("Cell")}</span>
              </CardLine>
            );
          })}
        </div>
        {footerCell ? (
          <div className="flex justify-end mt-2">
            {footerCell.render("Cell")}
          </div>
        ) : null}
      </Collapse>
    </Card2>
  );
}
