import { Notification } from "lib/services/notification";
import { v4 as uuidv4 } from "uuid";
export type NotificationState = Notification[];
export const initalNotifiactionState: NotificationState = [];

export type NotificationAction =
  | {
      type: "ADD_NOTIFICATION";
      notification: {
        message: string;
        variant: "primary" | "danger" | "warning" | "success";
      };
    }
  | { type: "REMOVE_NOTIFICATION"; id: string };

export const notificationReducer = (state: Notification[], action: NotificationAction) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return [...state, { id: uuidv4(), ...action.notification }];
    case "REMOVE_NOTIFICATION":
      return state.filter((el) => el.id !== action.id);
    default:
      return state;
  }
};
