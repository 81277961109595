import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaApple,
  FaArrowDown,
  FaArrowUp,
  FaBluetooth,
  FaBuilding,
  FaCalendarAlt,
  FaCalendarDay,
  FaCalendarWeek,
  FaChartLine,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaChevronDown,
  FaChevronUp,
  FaColumns,
  FaCreditCard,
  FaEllipsisH,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaEye,
  FaEyeSlash,
  FaLayerGroup,
  FaLocationArrow,
  FaMapMarkedAlt,
  FaPlayCircle,
  FaRegSquare,
  FaSlidersH,
  FaTags,
  FaTools,
  FaUserPlus,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa"; //https://react-icons.github.io/react-icons/search
import { ImStatsBars } from "react-icons/im";
import { TbSettingsPin } from "react-icons/tb";

export function Icon({ name, ...rest }: { name: any; [x: string]: any }) {
  if (name === "tools") {
    return <FaTools {...rest} />;
  } else if (name === "FaRegSquare") {
    return <FaRegSquare {...rest} />;
  } else if (name === "bluetooth") {
    return <FaBluetooth {...rest} />;
  } else if (name === "floor") {
    return <FaLayerGroup {...rest} />;
  } else if (name === "FaCreditCard") {
    return <FaCreditCard {...rest} />;
  } else if (name === "FaEllipsisH") {
    return <FaEllipsisH {...rest} />;
  } else if (name === "play-circle") {
    return <FaPlayCircle {...rest} />;
  } else if (name === "columns") {
    return <FaColumns {...rest} />;
  } else if (name === "arrow-up") {
    return <FaArrowUp {...rest} />;
  } else if (name === "arrow-down") {
    return <FaArrowDown {...rest} />;
  } else if (name === "calendar-week") {
    return <FaCalendarWeek {...rest} />;
  } else if (name === "warning") {
    return <FaExclamationTriangle {...rest} />;
  } else if (name === "FaChevronCircleDown") {
    return <FaChevronCircleDown {...rest} />;
  } else if (name === "FaChevronCircleUp") {
    return <FaChevronCircleUp {...rest} />;
  } else if (name === "FaChevronDown") {
    return <FaChevronDown {...rest} />;
  } else if (name === "FaChevronUp") {
    return <FaChevronUp {...rest} />;
  } else if (name === "circle-exclamation") {
    return <FaExclamationCircle {...rest} />;
  } else if (name === "location") {
    return <FaLocationArrow {...rest} />;
  } else if (name === "building") {
    return <FaBuilding {...rest} />;
  } else if (name === "FaCalendarDay") {
    return <FaCalendarDay {...rest} />;
  } else if (name === "calendar-alt") {
    return <FaCalendarAlt {...rest} />;
  } else if (name === "stats-bars") {
    return <ImStatsBars {...rest} />;
  } else if (name === "FaMapMarkedAlt") {
    return <FaMapMarkedAlt {...rest} />;
  } else if (name === "FaTags") {
    return <FaTags {...rest} />;
  } else if (name === "FaChartLine") {
    return <FaChartLine {...rest} />;
  } else if (name === "FaSlidersH") {
    return <FaSlidersH {...rest} />;
  } else if (name === "FaUserPlus") {
    return <FaUserPlus {...rest} />;
  } else if (name === "TbSettingsPin") {
    return <TbSettingsPin {...rest} />;
  } else if (name === "FaEyeSlash") {
    return <FaEyeSlash {...rest} />;
  } else if (name === "FaEye") {
    return <FaEye {...rest} />;
  } else if (name === "FaAngleLeft") {
    return <FaAngleLeft {...rest} />;
  } else if (name === "FaAngleRight") {
    return <FaAngleRight {...rest} />;
  } else if (name === "FaApple") {
    return <FaApple {...rest} />;
  } else if (name) {
    return <FontAwesomeIcon icon={name} {...rest} />;
  } else {
    return null;
  }
}
