import MuiDialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

const Styled = styled(MuiDialogTitle)`
  color: #fff;
`;

export function Title({text, ...optionals}) {
  return (
    <Styled sx={{fontSize: '2rem'}}>{text}</Styled>
  )
}