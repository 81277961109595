import {BASE_API} from '../constants';

const FOO = '/customers_building';
export const BUILDING_LIST_API = `${BASE_API}${FOO}`;
export const BUILDING_LIST_OF_CUSTOMER_API = `${BUILDING_LIST_API}/customer`; 
export const BUILDING_ITEM_ID_API = `${BUILDING_LIST_API}/id`;

// export const DEFAULT_CREAT_BUILDING_DTO = {
//   "customers_building_id": "string",
//   "customers_list_id": "string",
//   "building_name": "string",
// };


