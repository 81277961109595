import { useMemo } from "react";

import { LargeContainer } from "components/molecule/Containers/LargeContainer";
import { ErrorMsg } from "components/molecule/ErrorMsg";
import { getComparedDateRange } from "domain/time-range/date-range";
import { ErrorBoundary } from "features/error-handling";
import { useHomePageData } from "../../../application/pages/Home/hooks";
import { SummaryContent } from "./SummaryContent";
import { SummaryContainer } from "./SummaryView";
import { formatSelectedDateTextForSummary } from "./processors";
import { SummaryDataProps } from "./types";

/**
 * Summary section;
 */
export function Summary({
  data,
  ...optionals
}: {
  data: SummaryDataProps;
  [x: string]: any;
  timezone?: string;
  isLoading?: boolean;
  error?: Error;
}) {
  // Process props
  const {
    timezone,
    optArgs,
    isLoading,
    error,
    isModal,
    className,
    valueClassName,
    ...rest
  } = optionals;

  // Hooks

  return (
    <ErrorBoundary>
      <LargeContainer
        hiddenHeader
        icon=""
        // title={'Traffic Summary'}
        //@ts-ignore
        // icon={'columns' as const}
        isLoading={isLoading}
        className="text-capitalize flex-1 !border-none md:!p-0"
        {...rest}
      >
        {error ? (
          <ErrorMsg text={error?.message} />
        ) : (
          <SummaryContent
            data={data}
            timezone={timezone}
            isModal={isModal}
            className={className}
            valueClassName={valueClassName}
          />
        )}
      </LargeContainer>
    </ErrorBoundary>
  );
}

/**
 * Summary section: for today and a week before
 */
export function Summary2({ customerID, selectedDate, ...optionals }) {
  // Process props
  const { timezone, optArgs, totalNum, onChangeDate } = optionals;
  const { startDate: comparedStartDate, endDate: comparedEndDate } =
    getComparedDateRange(selectedDate);

  // Hooks
  // const dispatch = useDispatch();
  // console.debug('summary compared endDate', comparedEndDate);
  const {
    data: comparedData,
    isLoading: isLoadingPrevData,
    error: comparedError,
  } = useHomePageData(customerID, comparedEndDate, {
    ...optArgs,
    startDate: comparedStartDate,
  });

  // total number of selected range
  // total number of previous selected range
  const comparedTotalNum: number = comparedData?.uniqueVisitors;
  const rateNum: number = useMemo(() => {
    if (!Number.isInteger(totalNum) || !Number.isInteger(comparedTotalNum)) {
      return null;
    }
    return ((totalNum - comparedTotalNum) / comparedTotalNum) * 100;
  }, [totalNum, comparedTotalNum]);

  const lastPeriodLabel = formatSelectedDateTextForSummary({
    startDate: comparedStartDate,
    endDate: comparedEndDate,
    timezone,
  });

  const error = comparedError;
  const isLoading = isLoadingPrevData;

  const handleUpdateDateRange = () => {
    // set the selected date to previous period
    const nextSelectedDate = {
      startDate: comparedStartDate,
      endDate: comparedEndDate,
    };
    onChangeDate && onChangeDate(nextSelectedDate);
  };

  return (
    <ErrorBoundary>
      <LargeContainer
        title={"Summary"}
        //@ts-ignore
        icon={"columns"}
        isLoading={isLoading}
        className="text-capitalize flex-1"
      >
        {error ? (
          <ErrorMsg text={error?.message} />
        ) : (
          <SummaryContainer
            totalNum={totalNum}
            comparedTotalNum={comparedTotalNum}
            rateNum={rateNum}
            lastPeriodLabel={lastPeriodLabel}
            onClick={handleUpdateDateRange}
            isLoading={isLoading}
            dateRange={selectedDate}
            timezone={timezone}
          />
        )}
      </LargeContainer>
    </ErrorBoundary>
  );
}
