import { IconButton } from "@mui/material";
import { useTheme } from "styled-components";

import { Icon } from "components/atomic/Icon";
import {
  UnixtimeSelectedDate,
  compareIsEarlier,
  getUnixTimeByDate,
  shiftDateRangeForward,
} from "features/date";

export function NextDateArrowBtn({
  dateRange,
  onChangeDate,
  ...optionals
}: {
  dateRange: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  const theme = useTheme();
  const { startDate, endDate } = dateRange || {};
  const today = new Date().setHours(0, 0, 0, 0);
  const todayUnixTime = getUnixTimeByDate(new Date(today));
  const shouldShowNextBtn = compareIsEarlier(endDate, todayUnixTime);

  const handleClick = () => {
    const nextDateRange = shiftDateRangeForward({ startDate, endDate });
    onChangeDate && onChangeDate(nextDateRange);
  };

  return (
    <>
      {shouldShowNextBtn ? (
        <IconButton onClick={handleClick}>
          <Icon name="FaAngleRight" color={theme.colors.tertiary} />
        </IconButton>
      ) : null}
    </>
  );
}
