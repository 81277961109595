import React from 'react';
import { Nav } from "./Nav";
import { Tabs } from 'application/components/Tabs';
import { PageLayout } from 'components/template/PageLayout';
import { TABS_LIST, SIDEBAR_NAV } from './constants';

/**
 * Page layout for this application
 */
export const AppPageLayout = ({ children, ...optionals }) => {
  const { ...rest } = optionals;


  return (
    <PageLayout sideComponents={<Nav navItems={SIDEBAR_NAV} />} bottomComponents={<Tabs tabList={TABS_LIST}/>} {...rest} >
      {children}
    </PageLayout>
  );
};