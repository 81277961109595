import _ from "lodash";
import { curry, pipe } from "ramda";

import {
  SummaryDataItem,
  SummaryDataProps,
  extractDateRange,
  filterOutTodayData,
} from "domain/visitor-traffic";
import { getDaysDiffNumFromDateRange } from "domain/visitor-traffic/VisitorTraffic/processors";
import {
  checkIsTodayInTimezone,
  checkIsYesterdayInTimezone,
  formatDateStringToText,
  getLatestByDateInArray,
  parseString,
} from "features/date";
import { TrafficDTO } from "features/kaidu-stats-server";
import { TrafficGraphTypes } from "types";
import { getAverageByProp, getIncreaseRate, isFilledArray } from "utils";
import {
  addHoursToAllDates,
  getAverageOfTrafficData_V2,
} from "../../processors";

const curriedIsTodayInTimezone = curry(checkIsTodayInTimezone);
const curriedIsYesterdayInTimezone = curry(checkIsYesterdayInTimezone);

const FORMAT_STRING = "EEE, MMM dd";

function getMaxItemOfTrafficData(
  dataInput,
  comparedDataInput,
  timezone,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors,
  showIncrease: Boolean = true
) {
  const maxItem = _.maxBy<any>(dataInput, key);
  const maxDate = formatDateStringToText(
    maxItem?.date,
    FORMAT_STRING,
    timezone
  );
  const maxItemInCompared = comparedDataInput
    ? _.maxBy<any>(comparedDataInput, key)
    : null;
  const comparedMax = maxItemInCompared
    ? Math.floor(maxItemInCompared[key])
    : null;
  const maxValue = maxItem ? maxItem[key] : null;
  const highest = {
    date: "",
    value: maxItem?.overall || 0,
    visitors: maxItem?.overall || 0,
    passerbys: maxItem?.passerbys || 0,
    title: `Highest Day Score`,
    subTitle: `( ${maxDate} )`,
    tooltip: `Highest Day Score reflects the score for the day with the highest traffic.`,
  };
  if (showIncrease)
    highest["increaseRate"] = getIncreaseRate(maxValue, comparedMax);
  return highest;
}

export function getAverageItemOfTrafficData(
  dataInput,
  comparedDataInput?: any[],
  key: TrafficGraphTypes = TrafficGraphTypes.visitors,
  showIncrease: Boolean = true
): SummaryDataItem {
  // console.log("file: processors.ts:44 ~ getAverageItemOfTrafficData ~ dataInput", dataInput)
  const avg = getAverageOfTrafficData_V2(dataInput);
  // console.log("file: processors.ts:46 ~ getAverageItemOfTrafficData ~ ave", ave)
  const average: SummaryDataItem = {
    value: avg?.visitors || 0,
    visitors: avg?.visitors || 0,
    passerbys: avg?.passerbys || 0,
    // title: 'Daily Average',
    // increaseRate: getIncreaseRate(ave, comparedAverage),
    tooltip:
      "Average Score reflects the average of foot traffic captured by the scanner on any given date period. A higher score indicates a greater number of average people detected. Use this metric to gauge and compare vs Daily score.",
  };
  if (comparedDataInput && showIncrease) {
    const comparedAverage = Math.floor(
      getAverageByProp(comparedDataInput, key)
    );
    const averageValue = avg ? avg[key] : 0;
    const increaseRate = getIncreaseRate(averageValue, comparedAverage);
    average.increaseRate = increaseRate;
  }

  return average;
}

export function getTotalItemOfTrafficData(
  data,
  comparedData,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors,
  showIncrease: Boolean = true
): SummaryDataItem {
  if (!comparedData) {
    return {
      value: data?.visitors || 0,
      visitors: data?.visitors || 0,
      passerbys: data?.passerbys || 0,
      title: "",
    };
  }

  const result = {
    value: data?.visitors || 0,
    visitors: data?.visitors || 0,
    passerbys: data?.passerbys || 0,
    title: "Total Score",
  };

  if (showIncrease) {
    let increaseRate = 0;
    const value = data ? data[key] : 0;
    const comparedValue =
      key === TrafficGraphTypes.visitors
        ? comparedData.uniqueVisitors
        : comparedData.passerbys;
    increaseRate = getIncreaseRate(value, comparedValue);
    result["increaseRate"] = increaseRate;
  }
  return result;
}

/**
 *
 */
export function processWeeklySummaryData(
  data: { mainData: any; forecast?: any; [x: string]: any },
  timezone,
  comparedData,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors,
  showIncrease: Boolean = true
): SummaryDataProps {
  const originalDataInput: TrafficDTO[] = data?.mainData;
  if (!originalDataInput || !isFilledArray(originalDataInput)) {
    return null;
  }

  const originalComparedDataInput = comparedData?.graph?.data;

  // add 12 hours for all dates in data
  const comparedDataInput = addHoursToAllDates(originalComparedDataInput);
  const dataInput = addHoursToAllDates(originalDataInput);

  const comparedDateRange = extractDateRange(comparedDataInput);
  const originalDateRange = extractDateRange(comparedDataInput);
  const [start, end] = originalDateRange || [];
  const endDate = new Date(end || start || 0);
  const startDate = new Date(start || 0);
  const daysNum = getDaysDiffNumFromDateRange({ startDate, endDate });

  const checkIsDateStringToday = pipe(
    parseString,
    curriedIsTodayInTimezone(timezone)
  );
  // pipe date string to yesterday
  const isDateStringYesterdayFn = pipe(
    parseString,
    curriedIsYesterdayInTimezone(timezone)
  );

  const todayItem = originalDataInput?.find((item) =>
    checkIsDateStringToday(item.date)
  );
  const todayIndex = originalDataInput?.findIndex((item) =>
    checkIsDateStringToday(item.date)
  );

  const total = getTotalItemOfTrafficData(
    data,
    comparedData,
    key,
    showIncrease
  );
  if (showIncrease) {
    total.title =
      daysNum === 30
        ? "Monthly Total Score"
        : daysNum === 6
        ? "Weekly Total Score"
        : total.title;

    total.date =
      daysNum === 30 ? "vs. last month" : daysNum === 6 ? "vs. last week" : "";
    total.tooltip = `${
      daysNum === 30 ? "Weekly" : daysNum === 6 ? "Weekly" : ""
    } Total Score reflects the volume of foot traffic captured by the scanner for the given ${
      daysNum === 30 ? "month" : daysNum === 6 ? "week" : "date range"
    }. A higher score indicates a greater number of people detected. Use this metric to gauge and compare the traffic flow across different ${
      daysNum === 30 ? "months" : daysNum === 6 ? "weeks" : "dates"
    } or locations.`;
  } else {
    total.tooltip = `Total Score reflects the volume of foot traffic captured by the scanner for the given date range. A higher score indicates a greater number of people detected. Use this metric to gauge and compare the traffic flow across different dates or locations.`;
  }

  if (todayItem && todayIndex > -1) {
    const filteredOutToday = filterOutTodayData(dataInput, timezone);
    // console.log("file: processors.ts:114 ~ filteredOutToday", filteredOutToday);
    const average: SummaryDataItem = getAverageItemOfTrafficData(
      filteredOutToday,
      comparedDataInput,
      key,
      showIncrease
    );
    if (showIncrease) {
      average.title =
        daysNum === 30
          ? "Monthly Average Score"
          : daysNum === 6
          ? "Weekly Average Score"
          : "Average Score";
      average.date =
        daysNum === 30
          ? "vs. last month"
          : daysNum === 6
          ? "vs. last week"
          : "";
    }
    const highest = getMaxItemOfTrafficData(
      filteredOutToday,
      comparedDataInput,
      timezone,
      key,
      showIncrease
    );
    if (showIncrease) {
      highest.date =
        daysNum === 30
          ? "vs. last month"
          : daysNum === 6
          ? "vs. last week"
          : "";
    }
    const yesterdayItem = dataInput?.find((item) =>
      isDateStringYesterdayFn(item.date)
    );

    const yesterdayDate = formatDateStringToText(
      yesterdayItem?.date,
      FORMAT_STRING,
      timezone
    );
    const yesterday = {
      value: yesterdayItem?.overall,
      visitors: yesterdayItem?.overall,
      passerbys: yesterdayItem?.passerbys,
      title: `Yesterday`,
      subTitle: `( ${yesterdayDate} )`,
      tooltip:
        "Yesterday Score reflects the volume of foot traffic captured by the scanner on yesterday.",
    };
    const result = {
      byType: {
        yesterday,
        total,
        average,
        highest,
      },
      allTypes: ["yesterday", "total", "average", "highest"],
    };
    if (showIncrease) result["others"] = { comparedDateRange };

    return result;
  } else {
    // No today
    const average: SummaryDataItem = getAverageItemOfTrafficData(
      dataInput,
      comparedDataInput,
      key,
      showIncrease
    );
    if (showIncrease) {
      average.title =
        daysNum === 30
          ? "Monthly Average Score"
          : daysNum === 6
          ? "Weekly Average Score"
          : "Average Score";

      average.date =
        daysNum === 30
          ? "vs. last month"
          : daysNum === 6
          ? "vs. last week"
          : "";
    }
    const highest = getMaxItemOfTrafficData(
      dataInput,
      comparedDataInput,
      timezone,
      key,
      showIncrease
    );
    if (showIncrease) {
      highest.date =
        daysNum === 30
          ? "vs. last month"
          : daysNum === 6
          ? "vs. last week"
          : "";
    }
    const latestDayItem = getLatestByDateInArray<any>(dataInput);
    const formattedLatestDateString = formatDateStringToText(
      latestDayItem?.date,
      FORMAT_STRING,
      timezone
    );
    const latestDay = {
      value: latestDayItem?.overall,
      visitors: latestDayItem?.overall,
      passerbys: latestDayItem?.passerbys,
      title: `Daily Score`,
      date: "",
      subTitle: `( ${formattedLatestDateString} )`,
      tooltip:
        "Daily Score reflects the volume of foot traffic captured by the scanner on any given day. A higher score indicates a greater number of people detected. Use this metric to gauge and compare the traffic flow across different days or locations.",
    };

    const result = {
      byType: {
        latestDay,
        total,
        average,
        highest,
      },
      allTypes: ["latestDay", "total", "average", "highest"],
    };
    if (showIncrease) result["others"] = { comparedDateRange };
    return result;
  }
}

export function processDailySummaryData(
  data: { mainData: any; forecast?: any; [x: string]: any },
  timezone,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors
) {
  const dataInput = data?.mainData;
  // console.log('file: processors.ts:106 ~ data', data);
  if (!isFilledArray(dataInput)) {
    return null;
  }

  // get the sum of all overall prop in dataInput
  // const sum = getTotalOfTrafficData(dataInput);
  const total = {
    value: data?.total || 0,
    visitors: data?.total || 0,
    passerbys: data?.passerbys || 0,
    title: "Daily Score",
    tooltip:
      "Daily Score reflects the volume of foot traffic captured by the scanner on any given day. A higher score indicates a greater number of people detected. Use this metric to gauge and compare the traffic flow across different days or locations.",
  };
  const dataInputfilterOutZeros = dataInput?.filter((item) =>
    item ? item[key] !== 0 : false
  );

  const average = getAverageItemOfTrafficData(
    dataInputfilterOutZeros,
    undefined,
    key
  );
  average.title = "Hourly Average Score";

  const maxItem = _.maxBy<any>(dataInputfilterOutZeros, key);
  const maxDate = formatDateStringToText(maxItem?.date, "hh:mm aa", timezone);
  const highest = {
    date: "",
    value: maxItem?.overall,
    visitors: maxItem?.overall || 0,
    passerbys: maxItem?.passerbys || 0,
    title: `Highest Hour Score`,
    subTitle: `(${maxDate})`,
    tooltip:
      "Highest hour Score reflects the score for the hour with the highest traffic.",
  };

  const minItem = _.minBy<any>(dataInputfilterOutZeros, key);
  const minDate = formatDateStringToText(minItem?.date, "hh:mm aa", timezone);
  const lowest = {
    date: "",
    value: minItem?.overall,
    visitors: minItem?.overall || 0,
    passerbys: minItem?.passerbys || 0,
    title: `Lowest Hour Score`,
    subTitle: `(${minDate})`,
    tooltip: `Lowest hour Score reflects the score for the hour with the lowest traffic.`,
  };

  const result = {
    byType: {
      total,
      average,
      highest,
      lowest,
    },
    allTypes: ["total", "average", "highest", "lowest"],
  };

  return result;
}
