import { LabelForSelect } from "components/atomic/Label";
import { GrayTypography, Typography } from "components/atomic/Typography";
import { formatTimezoneInShort } from "features/date";
import { ErrorBoundary } from "features/error-handling";

/**
 * Text for given timezone, coupled with default customer's timezone
 */
export function TimeZoneText(props) {
  const { timezone } = props;
  // Hooks
  return (
    <ErrorBoundary>
      <GrayTypography className="text-base mb-0">{`Time zone: ${formatTimezoneInShort(
        timezone
      )}`}</GrayTypography>
    </ErrorBoundary>
  );
}

/**
 * Text for given timezone
 */
export function FlexTimeZoneText(props) {
  // Hooks
  const { timezone } = props;
  return (
    <ErrorBoundary>
      <div className="flex md:flex-col justify-between md:justify-normal flex-1 md:flex-none w-full md:w-auto">
        <LabelForSelect className="text-base mb-0">{`Time zone`}</LabelForSelect>
        <div className="flex flex-col justify-center md:h-full">
          <Typography className="text-base mb-0 grow-0">
            {formatTimezoneInShort(timezone)}
          </Typography>
        </div>
      </div>
    </ErrorBoundary>
  );
}
