import { NoDataView } from "components/molecule/NoDataView";
import { isFilledArray } from "utils";
import { SummaryCard } from "./SummaryCard";
import { SummaryDataProps } from "./types";
import { Tooltip2 } from "components/atomic/Tooltip";

/**
 * Display summary data
 */
export function SummaryContent({
  data,
  ...optionals
}: {
  data: SummaryDataProps;
  [x: string]: any;
}) {
  const { isModal, className, valueClassName } = optionals;

  // Conditions
  const hasNoData = !data || !isFilledArray(data?.allTypes);
  if (hasNoData) {
    return <NoDataView />;
  }

  return (
    <>
      <div
        className={"flex justify-around gap-x-8 flex-wrap gap-y-6 max-w-screen"}
      >
        {data.allTypes.map((typeKey) => {
          const item = data.byType[typeKey];
          const label = item?.title || typeKey;
          const subTitle = item?.subTitle;
          return (
            <Tooltip2 title={item?.tooltip} arrow>
              <div className="flex-1 flex justify-center">
                <SummaryCard
                  key={`summary-card-${typeKey}`}
                  num={item?.value}
                  visitors={item?.visitors}
                  passerbys={item?.passerbys}
                  label={label}
                  rateNum={item?.increaseRate}
                  date={item?.date}
                  subTitle={subTitle}
                  isModal={isModal}
                  className={className}
                  valueClassName={valueClassName}
                />
              </div>
            </Tooltip2>
          );
        })}
      </div>
    </>
  );
}
