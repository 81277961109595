import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngry,
  faBorderAll,
  faCalendar,
  faCalendarAlt,
  faChartPie,
  faCheck,
  faClock,
  faCog,
  faCogs,
  faDoorOpen,
  faEdit,
  faExclamation,
  faExpand,
  faEye,
  faFingerprint,
  faHeartbeat,
  faHome,
  faInfoCircle,
  faLaptop,
  faLock,
  faMap,
  faMobileAlt,
  faNewspaper,
  faPlus,
  faSatelliteDish,
  faSave,
  faSearch,
  faShoePrints,
  faSignLanguage,
  faSignOutAlt,
  faSmile,
  faSortDown,
  faSortUp,
  faStreetView,
  faTablet,
  faTabletAlt,
  faTimes,
  faTrash,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
  faUsers,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngry,
  faBorderAll,
  faCalendar,
  faCalendarAlt,
  faChartPie,
  faCheck,
  faClock,
  faCog,
  faCogs,
  faDoorOpen,
  faEdit,
  faExclamation,
  faExpand,
  faEye,
  faFingerprint,
  faHeartbeat,
  faHome,
  faInfoCircle,
  faLaptop,
  faLock,
  faMap,
  faMobileAlt,
  faNewspaper,
  faPlus,
  faSatelliteDish,
  faSave,
  faSearch,
  faShoePrints,
  faSignLanguage,
  faSignOutAlt,
  faSmile,
  faSortDown,
  faSortUp,
  faStreetView,
  faTablet,
  faTabletAlt,
  faTimes,
  faTrash,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserPlus,
  faUsers,
  faWalking
);
