import { mockSingleCustomer, mockUserList } from './kaidu-config-server';
import {
  mockAverageHourlyAPI,
  mockFetchCardsData,
  mockGlobalData,
  mockHeatmapData,
  mockTrafficByBeacon,
  mockTrafficByBuilding
} from './kaidu-stats-server';

const allMocks = [
  mockHeatmapData,
  mockFetchCardsData,
  mockTrafficByBeacon,
  mockGlobalData,
  mockAverageHourlyAPI,
  mockTrafficByBuilding,
  mockSingleCustomer,
  mockUserList,
];

/**
 * All handlers for mocked API calls
 */
export const handlers = allMocks;
