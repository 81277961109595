import styled from "styled-components";

import { Icon } from "components/atomic/Icon";
import { Link, useLocation } from "react-router-dom";
import { NavText } from "./NavText";

export const NavItemContainer = styled.div<{ isSelected: boolean }>`
  background: inherit;
  /* width: 100%; */
  height: 65px;
  /* display: flex; */
  /* align-items: center; */
  cursor: pointer;
  border: 3px solid transparent;
  padding-left: 50px;
  color: ${(p) => p.theme.colors.darkGrey};
  /* transition: all 0.2s ease-in; */
  opacity: 1;
  ${(p) =>
    p.isSelected ? `border-left: 3px solid ${p.theme.colors.white}` : null};
  ${(p) => (p.isSelected ? `color: ${p.theme.colors.darkGrey}` : null)};
  ${(p) => (p.isSelected ? `background: ${p.theme.colors.lightGrey}` : null)};

  .nav-icon,
  .nav-text {
    ${(p) => (p.isSelected ? `color: ${p.theme.colors.secondary}` : null)};
  }

  .name {
    margin: 0px;
    margin-left: 15px;
  }

  &:hover {
    background: ${(p) => p.theme.colors.lightGrey};
    .nav-icon,
    .nav-text {
      color: ${(p) => p.theme.colors.secondary};
    }
  }
`;

export const NoneStyleLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

/**
 * Navigation Item
 */
export function NavItem({
  iconName,
  label,
  ...optionals
}: {
  path?: string;
  iconName: string;
  label: string;
  [x: string]: any;
}) {
  //Hooks
  const { pathname } = useLocation();
  // const theme = useTheme();

  const { onClick, path } = optionals;

  return (
    <NavItemContainer
      isSelected={path && pathname === path}
      onClick={onClick}
      data-cy={"nav-item"}
      className={"flex items-center w-full"}
    >
      <Icon className="nav-icon w-7" name={iconName} />
      <NavText text={label} className="nav-text" />
    </NavItemContainer>
  );
}

/**
 *
 */
export function LinkedNavItem({
  path,
  iconName,
  label,
  ...optionals
}: {
  path: string;
  iconName: string;
  label: string;
  [x: string]: any;
}) {
  const { onClick } = optionals;

  return (
    <NoneStyleLink to={path}>
      <NavItem
        path={path}
        iconName={iconName}
        label={label}
        onClick={onClick}
      />
    </NoneStyleLink>
  );
}
