import React from 'react';
import styled from 'styled-components';
import { useMediaQueries, mediaQueriesByMaxWidth } from 'styles/themes';

// workaround for bootstrap css !important
const MainContainer = styled.div`
  ${mediaQueriesByMaxWidth('lg')`
    padding: 0.75rem; 
  `};
`;

/**
 * Wrapper For every Page
 */
export const PageLayout = ({ children, ...optionals }) => {
  const {
    className = '',
    mainClassName = 'max-w-full',
    sideComponents,
    bottomComponents,
    ...rest
  } = optionals;
  // Hooks
  const isLg = useMediaQueries('lg');

  return (
    <div className={`flex ${isLg ? '' : 'flex-column'} ${className}`} {...rest}>
      {sideComponents}
      <MainContainer
        className={`flex-1 md:pl-10 md:pr-8 lg:px-8 xl:px-12 max-w-full`}
      >
        <main className={mainClassName}>{children}</main>
      </MainContainer>
      {bottomComponents}
    </div>
  );
};
