import axios from "axios";
import { CUSTOMER_ITEM_ID_API, CUSTOMERS_LIST_API } from "./api";
import { CustomersListItem } from "./types";

export const fetchCustomer = async (
  customerId: string
): Promise<CustomersListItem> => {
  if (customerId) {
    return await axios
      .get(`${CUSTOMER_ITEM_ID_API}/${customerId}`)
      .then((res) => res.data);
  } else return null;
};

export async function fetchCustomerList(): Promise<CustomersListItem[]> {
  return await axios.get(CUSTOMERS_LIST_API).then((res) => res.data);
}

export const fetchCustomerByName = async (
  customer_name: string
): Promise<CustomersListItem> => {
  if (!customer_name) return null;
  return await axios
    .get(`${CUSTOMERS_LIST_API}/name/${customer_name}`)
    .then((res) => res.data);
};
