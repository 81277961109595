import { Modal, ModalBody, ModalFooter } from "components/atomic/Modal";
import { Button } from "components/atomic/Button";
import { ListItem } from "components/molecule/ListItem";
import { ConfirmProps } from "./index";

/**
 *
 */
export function ConfirmModal(props: ConfirmProps) {
  // const { show, onHide, refetch, differences, mac, zone, onSubmit, ...rest } = props;
  const { show, onHide, differences, onSubmit } = props;

  const handleConfirm = () => {
    onSubmit && onSubmit();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h5>Are you sure you want to make this change?</h5>
        <div className="mt-5">
          {Object.keys(differences).map((difference, index) => (
            <ListItem
              title={"Device Name"}
              description={differences[difference]}
              key={differences[difference] ?? index}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="success" onClick={handleConfirm}>
          Make Changes
        </Button>
        <Button onClick={onHide}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
