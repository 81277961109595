// use test token for local dev
import { v4 as uuidv4 } from "uuid";

export const TEST_TOKEN = process?.env?.REACT_APP_TEST_TOKEN;
// export const VERSION = `0.1.5-${uuidv4()}`;
export const SHOULD_USE_OLD_HEATMAP_CIRCLE = false;

export const NIL_VALUE_TEXT = "Not available";

export const DEFAULT_TIME_ZONE = "America/Toronto";

export enum AuthProvider {
  GOOGLE = "google",
  APPLE = "apple",
}

export const VERSION = "24022801";
