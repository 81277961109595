import styled from "styled-components";

export const Thead = styled.thead`
  border-radius: 8px;
`;

export const Th = styled.th`
  text-transform: capitalize;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.md};
`;

// export function Th(props) {
//   const {className = 'lg: text-base xl:text-lg', ...rest} = props;
//   return <tr className={className} {...rest}/>
// }

// export const Tr = styled.tr`
//   font-size: ${(p) => p.theme.fontSizes.md};
// `;

export function Tr(props) {
  const {className = 'lg: text-base xl:text-lg', ...rest} = props;
  return <tr className={className} {...rest}/>
}

export const Td = styled.td`
  span {
    vertical-align: middle;
  }
`;
