
import {
  CamerasForScanners,
  KaiduDeviceConfiguration,
} from 'features/kaidu-config-server';
import { isFilledArray } from 'utils';


export function convertCustomerCustomDataToOptions(
  cameras: CamerasForScanners,
  devices: KaiduDeviceConfiguration[]
) {
  const macs = cameras && Object.keys(cameras);
  if (!isFilledArray(macs) || !isFilledArray(devices)) {
    return null;
  }
  // console.debug('convertCustomerCustomDataToOptions', cameras, devices);
  const result = macs
    .map((mac) => {
      const { camera_id } = cameras[mac];
      // console.debug('convertCustomerCustomDataToOptions camera_id', cameras);
      const deviceName = devices.find(
        (item) => item?.mac_address === mac
      )?.device_name;
      if (!deviceName) {
        return null;
      }
      return { label: deviceName, value: camera_id };
    })
    .filter((item) => Boolean(item));
  // console.debug('convertCustomerCustomDataToOptions result', result);
  return result;
}

/**
 * 
 * @deprecated only enable camera feature for this customer 
 */
export function checkShouldEnableCamera(customerID: string) {
  return customerID === 'a0f9c1c7-c6e2-4aef-8aa2-00607df68d3f';
}