import { KAIDU_DEVICES_LIST_API } from './api';
import { KaiduDevicesListItem } from './types';
import { useQuery } from 'react-query';
import { fetchKaiduDevicesList } from './fetcher';

export function useKaiduDevicesList(key: string) {
  const { data, error, ...rest } = useQuery<KaiduDevicesListItem[], Error>(
    [KAIDU_DEVICES_LIST_API, key],
    fetchKaiduDevicesList,
  );

  // if (data) {
  //   console.debug('useKaiduDevicesList', data);
  // }
  return {
    kaiduDevicesList: data,
    error,
    ...rest,
  };
}

export function useSingleKaiduDevice(
  mac: string,
  options?: any
): {
  kaiduDevice: KaiduDevicesListItem;
  isLoading: boolean;
  isError: Error | undefined;
} {
  console.debug(`useSingleKaiduDevice called with mac: ${mac}`);
  const { data, error } = useQuery<KaiduDevicesListItem, Error>(
    mac ? `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}` : null,
    options
  );

  return {
    kaiduDevice: data,
    isLoading: !error && !data,
    isError: error,
  };
}
