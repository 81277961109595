import styled from "styled-components";
import { Button } from "components/atomic/Button";
import { SummaryCard } from "./SummaryCard";
// import {
//   SelectedDateText,
//   DateText,
// } from 'domain/time-range/TimeRange/SelectedDateText';
// import { TimeZoneText } from 'components/molecule/TimeZoneText';

const SpecialDateButton = styled(Button)`
  font-family: "Poppins";
  /* font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  /* line-height: 20px; */
  /* letter-spacing: 0.5px; */
  color: #5a6acf;
  mix-blend-mode: normal;
  background: #fbfcfe;
  border: 0.5px solid #dde4f0;
  box-shadow: 0px 2px 1px rgba(64, 72, 82, 0.05);
  border-radius: 5px;
  padding: 0.3rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`;

/**
 * A comparison of 2 days summary
 */
export function SummaryComparison({
  totalNum,
  rateNum,
  comparedTotalNum,
  ...optionals
}) {
  const { lastPeriodLabel = "Date not found" } = optionals;
  // const {startDate, endDate} = dateRange || {};

  return (
    <div className="flex gap-x-4 lg:gap-x-8 flex-1 justify-between flex-col sm:flex-row">
      <SummaryCard
        num={totalNum}
        visitors={0}
        passerbys={0}
        label={"Total Overall"}
        rateNum={rateNum}
      />
      <div className="flex items-center my-4 sm:my-0">
        <span className="normal-case">vs.</span>
      </div>
      <SummaryCard
        num={comparedTotalNum}
        visitors={0}
        passerbys={0}
        label={lastPeriodLabel}
      />
    </div>
  );
}

/**
 *
 */
export function SummaryContainer({
  totalNum,
  rateNum,
  comparedTotalNum,
  onClick,
  ...optionals
}) {
  const {
    // isLoading,
    lastPeriodLabel = "Date not found",
    // dateRange,
    // timezone,
    children,
  } = optionals;
  // const {startDate, endDate} = dateRange || {};

  return (
    <div className={"flex flex-col justify-between"}>
      <div className="flex justify-between mb-2 flex-col lg:flex-col xl:flex-row gap-y-6 lg:gap-y-0">
        <SummaryComparison
          totalNum={totalNum}
          rateNum={rateNum}
          comparedTotalNum={comparedTotalNum}
          lastPeriodLabel={lastPeriodLabel}
        />
        <div className="flex items-start flex-1 lg:justify-end">
          <SpecialDateButton
            onClick={onClick}
            className={"flex-1 lg:flex-none"}
          >
            View {lastPeriodLabel}
          </SpecialDateButton>
        </div>
      </div>
      {children}
      {/* <div className='mt-4'>
        <DateText startDate={startDate} endDate={endDate} timezone={timezone} />
        <TimeZoneText />
      </div> */}
    </div>
  );
}
