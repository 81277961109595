import { LineSubject, Label, Annotation } from "@visx/annotation";
import { useTheme } from "styled-components";

/**
 * Average Line Annotation for daily traffic graph
 */
export function AverageLineAnnotation({ y, width, ...optionals }) {
  const theme = useTheme();

  return (
    <Annotation y={y} dx={20}>
      <LineSubject
        max={width}
        min={40}
        stroke={theme.colors.graph.average}
        strokeWidth={1}
        orientation={"horizontal"}
      />
      <Label
        title="Avg"
        verticalAnchor="middle"
        showBackground={false}
        showAnchorLine={false}
        fontColor={theme.colors.graph.average}
        titleFontSize={14}
        x={width - 40}
        y={y - 12}
        data-cy={"average-line-annotation-label"}
      />
    </Annotation>
  );
}
