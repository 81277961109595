import { CustomerState } from "domain/customer";

export const initialCustomerState: CustomerState = {
  name: null,
  id: null,
  hoursOfOperation: {
    start: "09:00",
    end: "17:00",
  },
  floorplans: [],
};

export type CustomerAction = { type: "UPDATE_CUSTOMER"; payload: CustomerState } | { type: "CLEAR_CUSTOMER" };

export function customerReducer(state: CustomerState, action: CustomerAction): CustomerState {
  switch (action.type) {
    case "UPDATE_CUSTOMER":
      return { ...state, ...action.payload };
    case "CLEAR_CUSTOMER":
      return initialCustomerState;
    default:
      return state;
  }
}
