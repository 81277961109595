import { isFilledArray } from 'utils';
import { extent } from 'd3-array';
import { parseString, diffInHours, addDays } from 'features/date';

/**
 *  Append one day, if 1 < days <= 13
 */
export function getTimeScaleDomain(
  mainData: any[],
  forecast: any[],
  showForecast: boolean = true
): [Date, Date] | [] {
  const shouldDisplayForecast: boolean = showForecast && isFilledArray(forecast);
  const dateStringList: string[] = shouldDisplayForecast
    ? [...mainData, ...forecast]?.map((d) => d?.date)
    : mainData?.map((d) => d?.date);

  const originalDomain: [Date, Date] | [] = isFilledArray(dateStringList)
    ? extent(dateStringList, (d) => parseString(d))
    : [];
  const diffInDays = diffInHours(originalDomain[1], originalDomain[0]) / 24;
  if (diffInDays <= 13 && diffInDays >= 1) {
    const modified: [Date, Date] = [
      originalDomain[0],
      addDays(originalDomain[1], 1)
    ];
    // console.debug('timeScaleDomain', modified);
    return modified;
  }
  // console.debug('timeScaleDomain DisplayedDateDomain', originalDomain);
  return originalDomain;
}
