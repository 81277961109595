import {
  UnixtimeSelectedDate,
  createDateByUnixTime,
  diffInCalendarDays,
  formatDate,
  getNow,
  getUnixTimeByDate,
  getYesterday,
  isSameDay,
  isSameDayByUnixTime,
  subDays,
  subMonths,
  subWeeks,
} from "features/date";
import { isFilledArray } from "utils";
import { isSameDayInTimezone } from "../../../features/date/lib/time-zone";

/**
 * @description create [Today, Yesterday, Week, Month] as date options. If the selected single date is not in these 4 options, a new option will be created, displayed based on the timezone
 * @returns returns an option list for date selector
 */
export function createDisplayedOptions(
  onlySingleDayQueries: boolean,
  value,
  timezone?: string
): any[] {
  try {
    const today = getNow();
    // console.debug('today', today);
    const yesterday = getYesterday();

    let result = [
      {
        label: "Today",
        value: { startDate: null, endDate: getUnixTimeByDate(today) },
      },
      {
        label: "Yesterday",
        value: { startDate: null, endDate: getUnixTimeByDate(yesterday) },
      },
    ];

    if (!onlySingleDayQueries) {
      result.push({
        label: "Week",
        value: {
          startDate: getUnixTimeByDate(subWeeks(today, 1)),
          endDate: getUnixTimeByDate(today),
        },
      });
      result.push({
        label: "Month",
        value: {
          startDate: getUnixTimeByDate(subMonths(today, 1)),
          endDate: getUnixTimeByDate(today),
        },
      });
    }

    // insert customized date if it doesn't exist
    if (value) {
      // console.debug('selectedDate: ', value);
      const { endDate: endDateUnixstamp, startDate: startDateUnixstamp } =
        value || {};
      const endDate: Date = createDateByUnixTime(endDateUnixstamp);
      const startDate: Date =
        startDateUnixstamp && createDateByUnixTime(startDateUnixstamp);
      const isAWeek = diffInCalendarDays(endDate, startDate) === 7;
      const isAMonth = isSameDay(subMonths(endDate, 1), startDate);
      const isASpecialDateRange =
        startDateUnixstamp &&
        !isSameDay(endDate, startDate) &&
        !isAWeek &&
        !isAMonth;
      if (isASpecialDateRange) {
        result.push({ label: "Custom", value: undefined });
        return result;
      }

      const isValueOutOfRange = diffInCalendarDays(today, endDate) > 1;
      const label = formatDate(endDate, timezone, "MMM dd");

      if (isValueOutOfRange) {
        result.push({ label, value });
      }
    }

    // console.debug('date select options:', result);
    return result;
  } catch (error) {
    console.error("Failed to create date options", error);
    return [];
  }
}

/**
 * check if the startDate and endDate in targetDate can be matched in the options. If the startDate and endDate are the same day in the given timezone, then it should return the matched option in options,
 * if no options, it should return a new option with label "Custom"
 */
export function getTargetDateRangeAvailableInOptions(
  targetDate: UnixtimeSelectedDate,
  options: { label: string; value: UnixtimeSelectedDate }[],
  timezone?: string
) {
  if (!targetDate) return null;
  if (!isFilledArray(options)) return { label: "Custom", value: targetDate };
  // console.debug(
  //   'getTargetDateRangeAvailableInOptions input',
  //   targetDate,
  //   options,
  //   timezone
  // );

  const { endDate, startDate } = targetDate || {};
  // const isSameStartDate = isSameDayInTimezone()

  if (!startDate) {
    const result = options.find(
      (opt) =>
        !Boolean(opt?.value?.startDate) &&
        isSameDayInTimezone(opt?.value?.endDate, endDate, timezone)
    );
    // console.debug(
    //   "no startDate getTargetDateRangeAvailableInOptions output",
    //   result
    // );
    return result;
  }

  const result = options.find((opt) => {
    const { value } = opt;
    if (!value) return false;
    const { startDate: optStartDate, endDate: optEndDate } = value;
    return (
      isSameDayInTimezone(optStartDate, startDate, timezone) &&
      isSameDayInTimezone(optEndDate, endDate, timezone)
    );
  });
  // console.debug('getTargetDateRangeAvailableInOptions output', result);
  return result;
}

/**
 *
 * @returns the value in options which have the same date; discard differences in hour, minute, etc.
 */
export function assignValue(targetDate: UnixtimeSelectedDate, options) {
  if (!targetDate) return undefined;
  const { endDate, startDate } = targetDate || {};
  const selectedEndDate = createDateByUnixTime(endDate);
  const selectedStartDate = createDateByUnixTime(startDate);

  if (!selectedStartDate) {
    const result = options?.find((opt) =>
      isSameDayByUnixTime(opt?.value?.endDate, endDate)
    );
    return result?.value;
  } else {
    // check startDate
    const isStartDayEndDaySame = isSameDay(selectedStartDate, selectedEndDate);
    if (isStartDayEndDaySame) {
      // display the end date
      const result = options?.find((opt) =>
        isSameDayByUnixTime(opt?.value?.endDate, endDate)
      );
      return result?.value;
    }
    // console.debug('assignValue', selectedEndDate, selectedStartDate);
    const isAWeek =
      diffInCalendarDays(selectedEndDate, selectedStartDate) === 7;
    if (isAWeek) {
      return options?.find((opt) => opt.label === "Week")?.value;
    }
    const isAMonth = isSameDay(
      subMonths(selectedEndDate, 1),
      selectedStartDate
    );
    if (isAMonth) {
      return options?.find((opt) => opt.label === "Month")?.value;
    }

    return options?.find((opt) => opt.label === "Custom")?.value;
  }
}

/**
 *
 * @returns all static day select options without customized
 */
export function generateDefaultMultiDaysSelectOptions() {
  const now = getNow();
  const yesterday = subDays(now, 1);
  const aWeekAgo = subWeeks(now, 1);
  const twoWeeksAgo = subDays(now, 13);
  // const threeWeeksAgo = subDays(now, 19);
  // console.debug('today', today);
  // const yesterday = getYesterday();

  // const weekOption = {
  //   label: "Last Week w/ Today",
  //   value: {
  //     startDate: getUnixTimeByDate(aWeekAgo),
  //     endDate: getUnixTimeByDate(now),
  //   },
  // };

  const latestWeekOnlyOption = {
    label: "Week",
    value: {
      startDate: getUnixTimeByDate(aWeekAgo),
      endDate: getUnixTimeByDate(yesterday),
    },
  };

  const prevWeekOption = {
    label: "Previous Week",
    value: {
      startDate: getUnixTimeByDate(twoWeeksAgo),
      endDate: getUnixTimeByDate(aWeekAgo),
    },
  };

  // const twoWeeksEarlierOption = {
  //   label: "Two Weeks Ago",
  //   value: {
  //     startDate: getUnixTimeByDate(threeWeeksAgo),
  //     endDate: getUnixTimeByDate(twoWeeksAgo),
  //   },
  // };

  const monthOption = {
    label: "Month",
    value: {
      startDate: getUnixTimeByDate(subMonths(now, 1)),
      endDate: getUnixTimeByDate(now),
    },
  };

  const result = [latestWeekOnlyOption, prevWeekOption, monthOption];
  return result;
}

/**
 * @description create [Latest Week, Previous Week, (Specific week dates)] as date options. If the selected range is not in these options, a new option will be created, displayed based on the timezone
 * @returns returns an option list for date selector
 */
export function createMultiDaysSelectOptions(value, timezone?: string): any[] {
  try {
    let result = generateDefaultMultiDaysSelectOptions();

    // insert customized date if it doesn't exist
    if (value) {
      // console.debug('selectedDate: ', value);
      const { endDate: endDateUnixstamp, startDate: startDateUnixstamp } =
        value || {};
      const endDate: Date = createDateByUnixTime(endDateUnixstamp);
      const startDate: Date =
        startDateUnixstamp && createDateByUnixTime(startDateUnixstamp);
      // const isAWeek = diffInCalendarDays(endDate, startDate) === 7;
      // const isAMonth = isSameDay(subMonths(endDate, 1), startDate);
      // const isASpecialDateRange =
      //   startDateUnixstamp &&
      //   !isSameDay(endDate, startDate) &&
      //   !isAWeek &&
      //   !isAMonth;
      // if (isASpecialDateRange) {
      //   result.push({ label: 'Custom', value: undefined });
      //   return result;
      // }
      const isValueOutOfRange =
        result.find((opt) => {
          const { value } = opt;
          if (!value) return false;
          const { startDate: optStartDate, endDate: optEndDate } = value;
          return (
            isSameDayInTimezone(optStartDate, startDateUnixstamp, timezone) &&
            isSameDayInTimezone(optEndDate, endDateUnixstamp, timezone)
          );
        }) === undefined;
      // console.debug('isValueOutOfRange', isValueOutOfRange);

      if (!startDate) {
        const endDatelabel = formatDate(endDate, timezone, "MMM d");
        result.push({ label: endDatelabel, value });
        return result;
      }

      const startDatelabel = formatDate(startDate, timezone, "MMM d");
      const endDatelabel = formatDate(endDate, timezone, "MMM d");

      if (isValueOutOfRange) {
        result.push({ label: `${startDatelabel}-${endDatelabel}`, value });
      }
    }

    // console.debug('date select options:', result);
    return result;
  } catch (error) {
    console.error("Failed to create date options", error);
    return [];
  }
}
