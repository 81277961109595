import { Button } from "components/atomic/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import { useRemoveUser } from "../hooks";
import { User } from "../types";

type AppSettingsProps = {
  show: boolean;
  user: User;
  onHide: () => void;
  customerName?: string;
  [x: string]: any;
};

function getDisplayedUserName(user_name, user_email) {
  if (user_name === "null") {
    return user_email;
  }
  return user_name || user_email;
}

/**
 * Remove a User
 */
export function RemoveUserModal({
  show,
  user,
  onHide,
  customerID,
  ...optionals
}: AppSettingsProps) {
  const { customerName } = optionals;
  const { user_id, user_email, user_name } = user || {};
  const { mutate } = useRemoveUser(user_id, customerID, onHide) || {};
  // const { isSuccess, isError, mutate } = deleteUser || {};

  return (
    <Modal className="min-w-fit max-w-xl" show={show} onHide={onHide}>
      <ModalHeader>
        <ModalTitle>Remove User</ModalTitle>
      </ModalHeader>
      <ModalBody>
        Are you sure to remove {getDisplayedUserName(user_name, user_email)}{" "}
        from <p className="break-all">{customerName}</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={mutate} variant="danger">
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default RemoveUserModal;
