import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";

import {
  Dialog,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import { VisitorTrafficContainer } from "domain/visitor-traffic/VisitorTraffic";
import { DateSelectorGroup } from "features/date/components/DateSelectors";
import { CameraModalGroup } from "features/kaidu-stats-server/features/camera";
import useSelectors from "hooks/useSelectors";
import { Zone } from "types/interfaces";
// import styled from 'styled-components';
import { Icon } from "components/atomic/Icon";
import { IconButton } from "components/atomic/IconButton";
import { getLocalTimeZone } from "features/date";
import { ErrorBoundary } from "features/error-handling";
import { useGatherTrafficByBeacon } from "features/kaidu-stats-server";
import {
  selectSelectedCustomer,
  selectSelectedDate,
  selectShouldDisplayForecast,
} from "providers/redux/globalSelectSlice";
import { useMediaQueries } from "styles/themes";
import { isFilledArray } from "utils";
import { formatDataToGraphData } from "../processors";

type ZoneModalProps = {
  show: boolean;
  onHide: () => void;
  onlySingleDayQueries?: boolean;
  zone: Zone;
  enableBarClick?: boolean;
  rest?: any;
};

/**
 * @description Modal that displays the traffic data for a given zone, fetching data from trafficByBeacon
 */
export function ZoneModal(props: ZoneModalProps) {
  const {
    show,
    onHide,
    onlySingleDayQueries,
    zone,
    enableBarClick = false,
    ...rest
  } = props;
  const { device_name, mqtt_device_id, mac_address } = zone || {};

  // Hooks
  const theme = useTheme();
  const selectors = useSelectors();
  const isMedium = useMediaQueries("sm");
  const customerData = useSelector(selectSelectedCustomer);

  // Global state
  const { startDate, endDate } = useSelector(selectSelectedDate);
  const shouldDisplayForecast = useSelector(selectShouldDisplayForecast);

  // Local state
  const customerTimezone: string = useMemo(
    () =>
      customerData?.customer_config?.hoursOfOperation?.timezone ??
      getLocalTimeZone(),
    [customerData]
  );
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [timestamp, setTimestamp] = useState(null);

  // Dependent hooks
  const zoneData = useGatherTrafficByBeacon(mqtt_device_id, endDate, {
    startDate,
    timezone: customerTimezone,
  });
  const { isLoading: isLoadingZone, data, sortedDataByDate } = zoneData || {};
  const { graph } = data || {};
  const isLoading = !customerData || isLoadingZone;
  if (graph) {
    graph.data = sortedDataByDate;
  }
  const forecastData = graph?.forecast;

  // get camera id by mac_address from customer data
  const cameraID: string =
    customerData?.customer_custom_data?.[mac_address]?.camera_id;

  // Handlers
  const handleBarClick = (data: any) => {
    // console.debug("handleBarClick", data);
    const originalDate = data?.date;
    // const timestampWithoutTimezone = removeRFCTimezone(originalDate);
    setTimestamp(originalDate);
    setIsCameraModalOpen(true);
  };

  const handleHideCameraModal = () => {
    setIsCameraModalOpen(false);
  };

  return (
    <Dialog
      show={show}
      onHide={onHide}
      maxWidth="lg"
      fullWidth={true}
      fullScreen={!isMedium}
      PaperProps={{
        sx: isMedium ? { borderRadius: "1rem" } : {},
      }}
      {...rest}
    >
      <>
        <ErrorBoundary>
          <ModalHeader
            className={`pb-0 flex ${isMedium ? "" : "flex-col items-start"}`}
          >
            {!isMedium && (
              <div className="flex justify-end w-full">
                <IconButton variant="light" onClick={onHide}>
                  <Icon name="close" color={theme?.colors?.tertiary} />
                </IconButton>
              </div>
            )}
            <ModalTitle className={isMedium ? "" : "mb-2"}>
              <p className="break-all">Location: {device_name}</p>
            </ModalTitle>
            <div
              className={`flex gap-4 justify-end ${isMedium ? "" : "w-full"}`}
            >
              <DateSelectorGroup
                onlySingleDayQueries={onlySingleDayQueries}
                setCustomDate={selectors.setCustomDate}
              />
            </div>
          </ModalHeader>
          <ModalBody className="flex justify-center w-full">
            <VisitorTrafficContainer
              title={startDate ? "Daily Traffic Score" : "Realtime Occupancy *"}
              isModal
              isLoading={isLoading}
              data={formatDataToGraphData(data, false, shouldDisplayForecast)}
              hideLeftAxis={false}
              onBarClick={enableBarClick ? handleBarClick : undefined}
              enableBarClick={enableBarClick}
              showForecast={false}
              timezone={customerTimezone}
              graphLabel={startDate ? undefined : "* Unprocessed data"}
              forecastProps={{
                isFetchable: false, // for a zone, it's not fetchable for now
                isFetched: Boolean(forecastData),
                isAvailable: isFilledArray(forecastData),
                shouldDisplay: shouldDisplayForecast,
              }}
            />
          </ModalBody>
          <CameraModalGroup
            deviceName={device_name}
            isOpen={isCameraModalOpen}
            onHide={handleHideCameraModal}
            timestamp={timestamp}
            cameraID={cameraID}
          />
        </ErrorBoundary>
      </>
    </Dialog>
  );
}
