import {
  DateRange,
  calculateDiffInDaysForDateRange,
  differenceInDays,
  formatDate,
  parseString,
} from "features/date";

export function getFormatTemplateString(width: number, daysNum: number) {
  // difference 1 day: display hours
  if (daysNum < 1) {
    return width > 800 ? "haaa" : width > 450 ? "h:mmaaa" : "kk";
  }
  // difference 2 to 7 days: display the weekday
  if (daysNum >= 1 && daysNum <= 8) {
    return width > 800 ? "EEEE" : width > 450 ? "E" : "dd";
  }
  // difference more than 7 days: display the date and weekday
  return width > 800 ? "E, MMM dd" : width > 450 ? "MMM dd" : "E, MMM dd";
}

/**
 *
 */
export function formatTickByDateRange(
  date: string | Date,
  width: number,
  dateRange: { startDate: number; endDate: number },
  timezone?: string
) {
  // console.debug('formatTickByDateRange input', date, dateRange);
  // console.debug('formatTickByDateRange date type', typeof date);
  const { startDate, endDate } = dateRange || {};
  const daysNum = calculateDiffInDaysForDateRange(endDate, startDate);
  const formatString = getFormatTemplateString(width, daysNum);
  // console.debug('formatTickByDateRange', daysNum);
  let d: Date;
  if (typeof date === "string") {
    d = parseString(date);
  } else {
    d = date;
  }
  if (formatString !== "E" && formatString !== "EEEE")
    return formatDate(d, timezone, formatString);
  return formatDate(d, timezone, `${formatString}-MMM dd`);
}

/**
 * if not start date, return 0
 */
export function getDaysDiffNumFromDateRange(dateRange: DateRange) {
  const { startDate, endDate } = dateRange || {};
  const daysNum = !startDate ? 0 : differenceInDays(endDate, startDate);
  return daysNum;
}

export function formatTickByDateRangeAsDate(
  date: string | Date,
  width: number,
  dateRange: { startDate: Date; endDate: Date },
  timezone?: string
) {
  // console.debug("formatTickByDateRangeAsDate input", date, dateRange);
  // console.debug('formatTickByDateRange date type', typeof date);
  // const { startDate, endDate } = dateRange || {};
  const daysNum = getDaysDiffNumFromDateRange(dateRange);
  const formatString = getFormatTemplateString(width, daysNum);
  // console.debug('formatTickByDateRange', daysNum);
  let d: Date;
  if (typeof date === "string") {
    d = parseString(date);
  } else {
    d = date;
  }
  return formatDate(d, timezone, formatString);
}

export function formatTickToBeIntegers(d: any) {
  if (Number.isInteger(d)) return d;
}

export function getXYRangeInVisxXYChart(height: number, width: number) {
  const yRange = [50, height - 50];
  const xRange = [50, width - 50];
  return { xRange, yRange };
}
