import _ from 'lodash';
import { trimObjectProperties, replaceEmptyStringWithNull } from 'utils';

const PROPS = [
  'device_name',
  'wifi_ssid',
  'wifi_password',
  'mqtt_device_id',
  'mqtt_device_certificate',
  'mac_address',
  'customer_name',
  'building',
  'location',
  'floor',
  'kaidu_configuration_id',
  'rssi_threshold',
];

export function processSubmittedKaiduDeviceConfig(data) {
  const trimmed = trimObjectProperties(data);
  const replaced = replaceEmptyStringWithNull(trimmed);
  const result = _.pick(replaced, PROPS) as any;
  return result;
}
