import { Typography } from "../../atomic/Typography";
import noData from "./assets/noData.svg";

/**
 *  View to be displayed when there is no data in table
 */
export function NoData({text='No Data', ...rest}) {
  return (
    <div className="flex flex-col w-full justify-center my-2">
      <img src={noData} alt="no data" height={150} />
      <Typography className="text-center text-secondary mt-3">{text}</Typography>
    </div>
  );
}

export default NoData
