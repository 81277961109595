import { useMemo } from "react";
import { useLocation } from "react-router";
import "../../styles/forbidden.css";

export function Forbidden() {
  const { search } = useLocation();
  const customerName = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get("customer");
  }, [search]);

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>403 - Forbidden to {customerName}</h2>
        </div>
      </div>
    </div>
  );
}
