import { Form } from "react-bootstrap";
import ReactSelect, { Options } from "react-select"; //https://react-select.com/home
import Creatable from "react-select/creatable";
import { LabelForSelect } from "../atomic/Label";
import { MultiSelect as ReactMultiSelect } from "react-multi-select-component";

export type Option = {
  value: string | number;
  label: string;
  [x: string]: any;
};

/**
 *
 */
export function BaseSelect(props) {
  return <ReactSelect {...props} />;
}

/**
 * Select with Label
 */
export function LabeledSelect(props) {
  const { label, className, innerClassName, labelClassName, ...rest } = props;
  return (
    <div
      className={`flex items-center md:flex-col md:items-start w-full md:w-auto justify-center md:justify-start ${className}`}
      data-cy={rest["data-cy"]}
    >
      {label ? (
        <LabelForSelect className={`mb-0 mr-2 ${labelClassName ?? ""}`}>
          {label}
        </LabelForSelect>
      ) : null}
      <BaseSelect {...rest} className={innerClassName} />
    </div>
  );
}

/**
 * Select from a dropdown list
 */
export function Select({
  ...optionals
}: Partial<{
  disabled: boolean;
  value: string;
  showall: boolean;
  label: string;
  options: Options<Option>;
  selectFirst: boolean;
  placeholder: string;
  [x: string]: any;
}>) {
  const {
    disabled = false,
    showall = false, // should show a "all" option
    selectFirst = true,
    value,
    onChange,
    label,
    options = [],
    className = "",
    selectClassName = "",
    placeholder = "Select",
    defaultValue,
    ...rest
  } = optionals;

  // Props
  const displayedOptions = showall
    ? [{ label: "All", value: null }, ...options]
    : options;
  // const isMoreThanOneOption = options && options.length > 1;
  const processedDefaultValue = selectFirst
    ? displayedOptions[0]
    : displayedOptions.find((opt) => opt.value === defaultValue);

  return (
    <div
      className={`flex items-center lg:flex-col lg:items-start w-full lg:w-auto justify-center lg:justify-start ${className}`}
      data-cy={rest["data-cy"]}
    >
      {label ? (
        <LabelForSelect className="mb-0 mr-2">{label}</LabelForSelect>
      ) : null}
      <ReactSelect
        options={displayedOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        defaultValue={processedDefaultValue}
        onChange={onChange}
        value={displayedOptions.find((opt) => opt.value === value)}
        className={
          selectClassName
            ? selectClassName
            : "flex-1 lg:flex-none lg:w-36 xl:w-40"
        }
        {...rest}
      />
    </div>
  );
}

/**
 * Select from a dropdown list
 */
export function Select2({
  ...optionals
}: Partial<{
  disabled: boolean;
  value: { label: string; value: any };
  label: string;
  options: Options<Option>;
  selectFirst: boolean;
  placeholder: string;
  [x: string]: any;
}>) {
  const {
    disabled = false,
    selectFirst = true,
    value,
    onChange,
    label,
    options = [],
    className = "",
    selectClassName = "",
    placeholder = "Select",
    defaultValue,
    ...rest
  } = optionals;

  // Props
  const processedDefaultValue = selectFirst
    ? options[0]
    : options.find((opt) => opt.value === defaultValue);

  return (
    <div
      className={`flex items-center lg:flex-col lg:items-start w-full lg:w-auto justify-center lg:justify-start ${className}`}
      data-cy={rest["data-cy"]}
    >
      {label ? (
        <LabelForSelect className="mb-0 mr-2">{label}</LabelForSelect>
      ) : null}
      <ReactSelect
        options={options}
        isDisabled={disabled}
        placeholder={placeholder}
        defaultValue={processedDefaultValue}
        onChange={onChange}
        value={value}
        className={
          selectClassName
            ? selectClassName
            : "flex-1 lg:flex-none lg:w-36 xl:w-40"
        }
        {...rest}
      />
    </div>
  );
}

export function BootstrapSelect({ ...optionals }) {
  const {
    // disabled = false,
    value,
    showall,
    label,
    options,
    ...rest
  } = optionals;

  const isValidOptions = options && options.length > 1;

  return (
    <>
      <Form.Group className="mx-2 w-full">
        <Form.Label
          className="text-secondary my-0"
          style={{ fontSize: "16px" }}
        >
          <small>{label}</small>
        </Form.Label>
        <Form.Control
          disabled={!isValidOptions}
          value={value ?? undefined}
          {...rest}
          as="select"
        >
          {showall && <option value={"all"}>All</option>}
          {options.map((item, index) => (
            <option key={`${item}${index}-key`} value={item}>
              {item ? item : "Null"}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </>
  );
}

export function CreateableSelect(props) {
  const { options, ...rest } = props;
  return <Creatable isClearable options={options} {...rest} />;
}

/**
 *
 */
export function MultiSelect(props) {
  return <ReactMultiSelect {...props} />;
}
