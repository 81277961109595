import { IconName } from "@fortawesome/fontawesome-common-types";
import { User } from "domain/user/types";
import { HoursOfOperation } from "features/kaidu-config-server";

export type DotType = {
  id: string;
  name: string;
  coords: {
    x: number;
    y: number;
  };
};

export type FloorplanZone = {
  id: string;
  name: string;
  coords: { x: number; y: number };
};
export type Floorplan = {
  name: string;
  building: string;
  svg: string;
  zones: FloorplanZone[];
};

export type Customer = {
  id: string;
  customer_id: string;
  customer_name: string;
  customer_config: {
    floorplans: Floorplan[];
    hoursOfOperation: {
      start: number;
      end: number;
    };
  };
};

export type Zone = {
  building: string;
  customer_name: string;
  device_name: string;
  floor: string;
  location: string;
  mac_address: string;
  mqtt_device_certificate: string;
  mqtt_device_id: string;
  rssi_threshold: number;
  wifi_password: string;
  wifi_ssid: string;
  kaidu_configuration_id: string;
};

export type InfoSectionType = { title: string; text: string; icon: IconName };

export type AppState = {
  isLoading: boolean;
  selected: {
    user: null | User;
    customer: null | Customer;
    building: null | string;
    location: null | string;
    floor: null | string;
    floorplan: null | any;
    device: null | Zone;
    dateSelection: any;
    queryDate: Date;
    selectedTrafficGraphType: TrafficGraphTypes;
  };
};

export type CustomerState = {
  name: null | string;
  id: null | string; // customer_id
  hoursOfOperation: HoursOfOperation;
  floorplans: Floorplan[];
};

export type VisitorTrafficGraphData = {
  date: string;
  bluetooth: number;
  wifi: number;
  nonapple: number;
  overall: number;
  visitors: number;
  accum: number;
  snr?: number;
};

export type GridTypeFilter = {
  scanners: boolean; // false - do not filter out, true - filter out
  valid: boolean; // false - do not filter out, true - filter out
  not_valid: boolean; // false - do not filter out, true - filter out
};

export type GatheredDataOnlyHourlyCountItem = {
  date: string;
  Count: number;
  overall: number;
  visitors: number;
  passerbys: number;
};

export enum TrafficGraphTypes {
  visitors = "visitors",
  passerbys = "passerbys",
}
