
export function Circle(props) {
  const { size, color } = props;

  return (
    <svg width={size} height={size} style={{ margin: "2px 0" }}>
      <circle fill={color} r={size / 2} cx={size / 2} cy={size / 2} />
    </svg>
  )
}
