import {
  // fromUnixTime,
  formatDistance,
  formatDistanceStrict,
  formatRFC3339,
  format as nativeFormat,
} from "date-fns";
import { DateSelected } from "../types";
import { createDateByUnixTime } from "./foo";

export function formatFromUnixTime(unix: number, form: string) {
  return nativeFormat(createDateByUnixTime(unix), form);
}

/**
 * https://date-fns.org/v2.29.3/docs/format
 */
export function format(date: number | Date, form: string) {
  if (!date) return null;
  try {
    const result = nativeFormat(date, form);
    return result;
  } catch (error) {
    console.error("format error", error);
  }
}

// * Converts seconds into a string like "1 min, 2 hours, 1 day"
export const secondsToTime = (s: number) => {
  return formatDistanceStrict(0, s * 1000);
};

export const secondsToMinutes = (s: number) => {
  // * Check if number is infinty
  if (!isFinite(s)) return "No Contacts Found";
  return formatDistance(0, s * 1000, { includeSeconds: true });
};

// * Format JS DATE to either "8:00 PM" or "May 31st, 2021"
export function formatDateBySelection(
  date: Date,
  timeSelected: DateSelected,
  shortForm = true
) {
  if (
    timeSelected === DateSelected.today ||
    timeSelected === DateSelected.yesterday ||
    timeSelected === DateSelected.custom
  )
    return format(new Date(date), shortForm ? "haaa" : "h:mmaaa");
  else return format(new Date(date), shortForm ? "MMM dd" : "MMMM dd");
}

/**
 * formatRFC3339 for date. if no date is given, formatRFC3339 for now
 * @returns e.g. '2019-09-18T19:00:52Z'
 */
export function getDateRFC(date: Date = new Date()): string {
  return formatRFC3339(date);
}
