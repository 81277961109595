import { Coordinates } from './types';
import { DEFAULT_MAP_HEIGHT, DEFAULT_MAP_WIDTH } from './constants';
import { MapSize } from 'types';
import * as turf from '@turf/turf';
/**
 *
 * @param x relative x position, range 0 - 100
 * @param y relative y position, range 0 - 100
 * @param maxWidth
 * @param maxHeight
 * @returns [number, number] [0,0] is at the center
 */
export function convertRelativePercentToCartestianPixels(
  x: number,
  y: number,
  maxWidth: number,
  maxHeight: number
): Coordinates {
  const xPixels = ((x - 50) * maxWidth) / 100;
  const yPixels = (-(y - 50) * maxHeight) / 100;

  return [Math.round(xPixels), Math.round(yPixels)];
}

/**
 *
 * @param x relative x position, range 0 - 100
 * @param y relative y position, range 0 - 100
 * @param maxWidth
 * @param maxHeight
 * @returns [number, number] [0,0] is at the top left
 */
export function convertRelativePercentToCartestianTopLeft(
  x: number,
  y: number,
  maxWidth: number,
  maxHeight: number
): Coordinates {
  const xPixels = (x * maxWidth) / 100;
  const yPixels = (y * maxHeight) / 100;

  return [Math.round(xPixels), Math.round(yPixels)];
}

/**
 * @description converts coordinates from default map size to match the current map size
 * @param x
 * @param y
 * @param maxWidth
 * @param maxHeight
 * @returns
 */
export function rescaleCartesianFromDefault(
  x: number,
  y: number,
  maxWidth: number,
  maxHeight: number
): Coordinates {
  const xPixels = (x / DEFAULT_MAP_WIDTH) * maxWidth * 0.98;
  const yPixels = (y / DEFAULT_MAP_HEIGHT) * maxHeight;

  return [Math.round(xPixels), Math.round(yPixels)];
}

/**
 *
 * @param input [x,y]
 * @returns [x,y] [0,0] is at the center. y
 */
export function convertTopLeftToCentered(
  input: Coordinates,
  mapSize: MapSize
): Coordinates {
  const [x, y] = input;
  const nextX = x - mapSize.width / 2;
  const nextY = mapSize.height / 2 - y;
  return [nextX, nextY];
}

/**
 *
 * @param input [x,y] in pixels
 * @param mapSize
 * @returns
 */
export function convertTriangulationInfoCoor(
  input: Coordinates,
  mapSize: MapSize
): Coordinates {
  // console.log('convertTriangulationInfoCoor input', input);
  const [x, y] = input;
  const rescaled = rescaleCartesianFromDefault(
    x,
    y,
    mapSize.width,
    mapSize.height
  );
  const centered = convertTopLeftToCentered(rescaled, mapSize);
  // console.log('convertTriangulationInfoCoor result', centered);

  return centered;
}

export function getNearest(collection: Coordinates[], target: Coordinates) {
  try {
    const targetP = turf.point(target);
    const collectionP = turf.featureCollection(
      collection.map((c) => turf.point(c))
    );
    const nearest = turf.nearestPoint(targetP, collectionP);
    // console.debug('nearest', nearest);
    return nearest.geometry.coordinates;
    
  } catch (error) {
    console.error('getNearest target', target);
    console.error('getNearest collection', collection);
    console.error(error);
    throw error;
  }
}


export function getNearestPointOnEdge(source: Coordinates): Coordinates{
  // in percentage
  const left = source[0];
  const top = source[1];
  const right = 100 - left;
  const bottom = 100 - top;
  const min = Math.min(left, right, top, bottom);
  if (min === left) {
    return [0, top];
  } else if (min === right) {
    return [100, top];
  } else if (min === bottom) {
    return [left, 100];
  } else {
    return [left, 0];
  }
}

export type PositionDirection = 'left' | 'right' | 'top' | 'bottom';


const DEFAULT_DIRECTION = 'left';
/**
 *
 * @param input1
 * @param input2
 * @returns the cloest direction that input2 is located to input1
 */
export function getDirection(
  input1: Coordinates,
  input2: Coordinates
): PositionDirection {
  let result: PositionDirection = DEFAULT_DIRECTION;
  const xDiff = input1[0] - input2[0];
  const yDiff = input1[1] - input2[1];
  if (Math.abs(xDiff) < Math.abs(yDiff)) {
    // cloeser in x
    result = yDiff > 0 ? 'top' : 'bottom';
  } else {
    result = xDiff > 0 ? 'left' : 'right'; 
  }
  return result;
}


export function getOppositeDirection(
  direction: PositionDirection
): PositionDirection {
  switch (direction) {
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
  }
}

export function parseXYToCoordinate(input: any): Coordinates {
  const {x, y} = input || {};
  return [x, y];
}