import {
  CARDS_LIST_BY_CUSTOMER,
  CUSTOMER_ITEM_ID_API,
  DEVICE_CONFIG_API,
  KAIDU_DEVICES_LIST_API,
  USERS_LIST_API
} from 'features/kaidu-config-server';
import { rest } from 'msw'; //
import { CONFIG } from '../configs';
import { configServerData } from '../data';

// const today = startOfDay(getNow());

export const mockCardsConfigList = rest.get(
  `${CARDS_LIST_BY_CUSTOMER}*`,
  (req, res, ctx) => {
    // Check if the user is authenticated in this session
    // const isAuthenticated = sessionStorage.getItem('is-authenticated')
    // if (!isAuthenticated) {
    //   // If not authenticated, respond with a 403 error
    //   return res(
    //     ctx.status(403),
    //     ctx.json({
    //       errorMessage: 'Not authorized',
    //     }),
    //   )
    // }

    // If authenticated, return a mocked user details
    // return res(ctx.status(200), ctx.json(cardsSummaryData));
  }
);

const mockKaiduDeviceConfigurations = rest.get(
  `${DEVICE_CONFIG_API}*`,
  (req, res, ctx) => {
    // If authenticated, return a mocked user details
    let responseData;
    if (CONFIG.configControl.scanners.useNoScanner) {
      responseData = {};
    } else {
      responseData = configServerData.kaiduDeviceConfigurations;
    }
    return res(ctx.status(200), ctx.json(responseData));
  }
);

const mockKaiduDeviceList = rest.get(
  `${KAIDU_DEVICES_LIST_API}*`,
  (req, res, ctx) => {
    // If authenticated, return a mocked user details
    return res(ctx.status(200), ctx.json(configServerData.kaiduDeviceList));
  }
);

export const mockSingleCustomer = rest.get(
  `${CUSTOMER_ITEM_ID_API}*`,
  (req, res, ctx) => {
    // If authenticated, return a mocked user details
    return res(ctx.status(200), ctx.json(configServerData.customer.customerNoTimezone));
  }
);

export const configServerMocks = [
  mockCardsConfigList,
  mockKaiduDeviceConfigurations,
  mockKaiduDeviceList,
  mockSingleCustomer,
];

export const mockUserList = rest.get(
  `${USERS_LIST_API}`,
  (req, res, ctx) => {
    // If authenticated, return a mocked user details
    let responseData;
    responseData = configServerData.user.multipleAvailableCustomers;
    return res(ctx.status(200), ctx.json(responseData));
  }
);

// export const mockCustomer = rest.get(
//   `${CUSTOMERS_LIST_API}*`,
//   (req, res, ctx) => {
//     // If authenticated, return a mocked user details
//     let responseData;
//     responseData = configServerData.kaiduDeviceConfigurations;
//     return res(ctx.status(200), ctx.json(responseData));
//   }
// );