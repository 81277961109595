import { Icon } from 'components/atomic/Icon';

/**
 * show nothing if not sorted
 * show different icons for descending and ascending
 */
export function SortIndicator({
  isSortedDesc, isSorted,
}: {
  isSortedDesc: boolean;
  isSorted: boolean;
}) {
  if (!isSorted) {
    return null;
  }

  const sortUpClassName = `${isSortedDesc ? 'text-secondary' : ''}`;
  const sortDownClassName = `${isSortedDesc ? '' : 'text-secondary'}`;

  return (
    <>
      <div className='flex flex-col items-center mx-3'>
        <Icon
          name='sort-up'
          className={sortUpClassName}
          style={{ marginBottom: '-10px' }} />
        <Icon
          name='sort-down'
          className={sortDownClassName} />
      </div>
    </>
  );
}
