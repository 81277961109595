import {
  convertUnixTimestampToTimezonedRFC3339AtStartOfDay,
  createDateByUnixTime,
  format,
  getDateRFC,
  getTimeInMilliseconds,
  parseString,
  startOfDay,
} from "features/date";
import { pipe } from "ramda";
import { getKeysExcept, isFilledArray } from "utils";
import { AUTH_WITH_MASTER_KEY } from "./constants";
import { GlobalDataDTO, TrafficDTO, UnverifiedGlobalDataDTO } from "./types";

/**
 *
 * @param trafficByBuilding
 * @returns the interval of date in trafficByBuilding, in miliseconds
 */
export function getBasicUnitOfTime(trafficByBuilding: TrafficDTO[]): number {
  if (!isFilledArray(trafficByBuilding) && trafficByBuilding.length > 1) {
    return 0;
  }
  const date1 = parseString(trafficByBuilding[0]?.date);
  const date2 = parseString(trafficByBuilding[1]?.date);
  const result = getTimeInMilliseconds(date2) - getTimeInMilliseconds(date1);
  // console.debug('getBasicUnitOfTime', result);
  return result;
}

export function getDateProp(traffic: TrafficDTO): Date {
  if (!traffic) return;
  return new Date(traffic.date);
}

export function isGlobalData(resOfGlobal: UnverifiedGlobalDataDTO) {
  return (resOfGlobal as GlobalDataDTO).graph !== undefined;
}
/**
 * @return e.g. []
 */
export function getDataKeysFromStatsServer(data): string[] {
  if (!isFilledArray(data)) {
    return [];
  }
  const firstRow = data[0];
  if (!firstRow) {
    return [];
  }
  const result = Object.keys(firstRow).filter(
    (key) => !["date", "overall"].includes(key)
  );
  // console.log("file: processors.ts:43 ~ getDataKeysFromStatsServer ~ result", result)
  return result;
}

/**
 * each mqtt device id should has multiple dates and counts
 */
export function convertTrafficByBuildingToGroupedByDevice(
  trafficByBuildingData: TrafficDTO[]
): Record<string, { count: number; date: string }[]> {
  if (!isFilledArray(trafficByBuildingData)) {
    return null;
  }
  // console.debug('convertTrafficByBuildingToGroupedByDevice input', trafficByBuildingData);

  //@ts-ignore
  const mqttDeviceIDs = getKeysExcept(trafficByBuildingData[0], [
    "date",
    "overall",
  ]);
  let result = {};
  for (const mqttDeviceID of mqttDeviceIDs) {
    // result.set(mqttDeviceID, []);
    result[mqttDeviceID] = [];
  }

  for (const row of trafficByBuildingData) {
    // e.g. dev41: [{date: string, count: number}]
    const date = row.date;
    for (const mqttDeviceID of mqttDeviceIDs) {
      result[mqttDeviceID].push({ date, count: row[mqttDeviceID] });
    }
  }

  return result;
}

/**
 * Add the master auth key to request headers
 */
export function withAuthInHeaders(params = {}, headerObj = {}) {
  Object.assign(headerObj, AUTH_WITH_MASTER_KEY);

  return {
    params,
    headers: AUTH_WITH_MASTER_KEY,
  };
}

export function checkIsSameTimeDiff(date: string, timeDiff: string) {
  // const dateWithoutTimeDiff = date?.slice(0, -6);
  // const dateWithTimeDiff = dateWithoutTimeDiff.concat(timeDiff);
  return date.includes(timeDiff);
}

export function setTimeDiffForDateString(date: string, timeDiff: string) {
  if (!date || !timeDiff) {
    return date;
  }
  const dateWithoutTimeDiff = date?.slice(0, -6);
  const dateWithTimeDiff = dateWithoutTimeDiff.concat(timeDiff);
  return dateWithTimeDiff;
}

/**
 *
 * @param date unix timestamp seconds
 */
export function convertDateUnixTimeToAPIParam(
  date: any,
  timezone?: string,
  timeDiff?: string
): string {
  if (timezone) {
    const result = convertUnixTimestampToTimezonedRFC3339AtStartOfDay(
      date,
      timezone
    );
    if (timeDiff && !checkIsSameTimeDiff(result, timeDiff)) {
      return setTimeDiffForDateString(result, timeDiff);
    }
    return convertUnixTimestampToTimezonedRFC3339AtStartOfDay(date, timezone);
  } else {
    return pipe(createDateByUnixTime, startOfDay, getDateRFC)(date);
  }
}

/**
 *
 * @deprecated when target timezone and local timezone is different, may return different day SS-1463
 */
export function convertDateUnixTimeToAPIParamByTimeDiff(
  unix: number,
  timeDiff: string
) {
  // console.log("file: processors.ts:132 ~ timeDiff:", timeDiff);
  if (!timeDiff) {
    return pipe(createDateByUnixTime, startOfDay, getDateRFC)(unix);
  }

  const date = createDateByUnixTime(unix);
  const formatted = format(date, "yyyy-MM-dd 00:00:00XXX")
    .replaceAll(" ", "T")
    .replaceAll("Z", "");
  console.log("file: processors.ts:132 ~ formatted:", formatted);
  const result = formatted?.slice(0, -6).concat(timeDiff);
  // console.log("file: hooks.ts:71 ~ convertDateUnixTimeToAPIParamByTimeDiff ~ result:", result);
  return result;
}
