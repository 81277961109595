import { addDays, addHours, addMinutes, parseISO, subDays } from "date-fns";
// import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { UnixtimeSelectedDate } from "../types";
// import { timeStringToFloat } from './hourAndMinute';
import { DateTime } from "luxon";
import { isSameDayByUnixTime } from "./checker";
import { createDateByUnixTime, diffInCalendarDays } from "./foo";
import { getUnixTimeByDate } from "./getters";

/**
 * @description shift the date range backward. if startDate is absent or the same as endDate, shift 1 day
 * @returns unix timestamp
 */
export function shiftDateRangeBackward({
  endDate,
  startDate,
}): UnixtimeSelectedDate {
  let daysNum;
  const prevEndDate = createDateByUnixTime(endDate);
  if (!startDate || isSameDayByUnixTime(endDate, startDate)) {
    daysNum = 1;
    const nextEndDate = subDays(prevEndDate, daysNum);
    return { startDate: null, endDate: getUnixTimeByDate(nextEndDate) };
  } else {
    const prevStartDate = createDateByUnixTime(startDate);
    daysNum = diffInCalendarDays(prevEndDate, prevStartDate);
    const nextStartDate = subDays(prevStartDate, daysNum);
    const nextEndDate = subDays(prevEndDate, daysNum);
    return {
      startDate: getUnixTimeByDate(nextStartDate),
      endDate: getUnixTimeByDate(nextEndDate),
    };
  }
  // const daysNum: number = (startDate && !isSameDayNative(prevEndDate, prevStartDate)) ? diffInCalendarDays(prevEndDate, prevStartDate) : 1;
}

export function shiftDateRangeForward({
  endDate,
  startDate,
}): UnixtimeSelectedDate {
  const prevEndDate = createDateByUnixTime(endDate);
  const prevStartDate = createDateByUnixTime(startDate);
  const daysNum: number = diffInCalendarDays(prevEndDate, prevStartDate);
  const nextStartDate = addDays(prevStartDate, daysNum);
  const nextEndDate = addDays(prevEndDate, daysNum);
  return {
    startDate: getUnixTimeByDate(nextStartDate),
    endDate: getUnixTimeByDate(nextEndDate),
  };
}

/**
 * change a local date to another timezone, timestampe value will be changed
 */
export function changeTimeZone(date: Date, timeZone: string): Date {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const result = DateTime.fromObject(
    {
      year,
      month,
      day,
      hour,
      minute,
      second,
    },
    { zone: timeZone }
  );

  return result.toJSDate();
  // if (typeof date === 'string') {
  //   return new Date(
  //     new Date(date).toLocaleString('en-US', {
  //       timeZone,
  //     })
  //   );
  // }

  // return new Date(
  //   date.toLocaleString('en-US', {
  //     timeZone,
  //   })
  // );
}

/**
 * set the hour, minute, second of a Date to the current local hour, minute, second
 */
export function setLocalTime(date: Date): Date {
  if (!date) {
    return null;
  }
  const now = new Date();
  date.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
  return date;
}

export function modifyAverageHourlyDatesForDateString(
  prevDate: string,
  year,
  month,
  day
): string {
  const sliced = prevDate.slice(10);
  const nextDate = `${year}-${month}-${day}${sliced}`;
  // console.log("file: setters.ts:85 ~ modifyDayForDateString ~ nextDate:", nextDate);
  return nextDate;
}

/**
 *
 */
export function resetTimeToTheStartOfDayForTimeZone(
  date: Date,
  timezone: string
): number {
  // console.log("file: setters.ts ~ date:", date, timezone);
  // const iso = date.toISOString();
  const result = DateTime.fromJSDate(date, { zone: timezone })
    .setZone(timezone)
    .startOf("day")
    .toUnixInteger();
  // console.log("file: setters.ts ~ result:", result);
  return result;
}

/**
 * default 1 hour
 */
export function addHoursToDateString(dateString: string, hours = 1): string {
  const date = parseISO(dateString);
  const nextDate = addHours(date, hours);
  return nextDate.toISOString();
}

/**
 * default 1 hour
 */
export function addMinutesToDateString(dateString: string, num = 1): string {
  const date = parseISO(dateString);
  const nextDate = addMinutes(date, num);
  return nextDate.toISOString();
}
