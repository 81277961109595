import _ from "lodash";
import { GatheredDataOnlyHourlyCountItem } from "types";

export function processGraphData(data: any) {
  // console.log("file: GlobalDataModal.tsx:39 ~ processGraphData ~ data", data);
  const { graph, graph_snr, isData, uniqueVisitors, passerbys } = data || {};
  if (!graph) {
    return;
  }

  // sort graph data by date
  const visitorTrafficData = _.sortBy(graph?.data ?? [], "date");
  graph.data = visitorTrafficData;

  const { data: mainData, forecast: forecastData } = graph || {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: snrData, forecast: snrForecast } = graph_snr || {};
  const result = {
    mainData: mainData,
    forecast: forecastData,
    subData: mainData,
    subForecast: snrForecast,
    isData: isData || mainData?.length > 0,
    total: uniqueVisitors,
    passerbys: passerbys,
  };

  // console.log("file: GlobalDataModal.tsx:39 ~ processGraphData ~ result", result);

  return result;

  // return { main: graph, forecast: forecastData };
}

export function processHourlGraphData(data: GatheredDataOnlyHourlyCountItem[]) {
  const result = {
    data: [],
    mainData: [],
    subData: [],
    isData: false,
    total: 0,
    passerbys: 0,
  };
  if (!data) {
    return result;
  }
  const visitorTrafficData = _.sortBy(data ?? [], "date");
  let uniqueVisitors = 0;
  let passerbys = 0;
  data.forEach((item) => {
    uniqueVisitors += item.overall;
    passerbys += item.passerbys;
  });

  return {
    data: visitorTrafficData,
    mainData: visitorTrafficData,
    subData: visitorTrafficData,
    isData: data.length > 0,
    total: uniqueVisitors,
    passerbys: passerbys,
  };
}
