import Form from "react-bootstrap/Form";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";

export function Switch2(props) {
  // const { label, ...rest } = props;

  return <Form.Check type="switch" {...props}></Form.Check>;
}

export function Switch(props) {
  const { label, disabled, ...rest } = props;

  return (
    <FormControlLabel
      {...rest}
      control={<MuiSwitch disabled={disabled} />}
      label={label}
    />
  );
}
