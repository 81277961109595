import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";

import { Span } from "components/atomic/Span";
import { Switch } from "components/atomic/Switch";
import { Tooltip2 } from "components/atomic/Tooltip";
import { createDateByUnixTime, isToday } from "features/date";
import { useUsersList } from "features/kaidu-config-server";
import {
  selectEndDate,
  selectShouldFetchForecast,
  updateShouldFetchForecast,
  updateShouldShowForecast,
} from "providers/redux/globalSelectSlice";
import { mediaQueries } from "styles/themes/breakpoints";
import { ForecastProps, MiniChainSelectorProps } from "types";
import { isFilledArray } from "utils";
import { SubCategoriesMultiSelect2 } from "../../domain/mini-chain/SubCategoriesMultiSelect";
import { DATA_SOURCE_DEVICE_CATEGORIES } from "../../domain/mini-chain/constants";
import { LegendItem } from "./molecule/LegendItem";

const LegendWrapper = styled.div`
  ${mediaQueries("md")`
    flex-direction: row;
    justify-content: space-between;
  `};

  .multi {
    ${mediaQueries("md")`
      margin-left: 8px;
      width: 170px;
    `};
  }
`;

function getSelectOptions(selectableProps: string[]) {
  if (isFilledArray(selectableProps)) {
    return DATA_SOURCE_DEVICE_CATEGORIES.filter((category) =>
      selectableProps?.includes(category.value)
    );
  } else {
    return DATA_SOURCE_DEVICE_CATEGORIES;
  }
}

/**
 * contains a show forecast switch, a sub-category selector
 * forecast control: show forecast switch, show forecast legend, is forecast fetchable?, is forecast available?
 * show the switch when forecast is fetchable
 */
export function LegendsGroup({
  selected,
  setSelected,
  selectableProps,
  dateRange,
  ...optionals
}) {
  // Props
  const { isData, forecastProps } = optionals;
  const { isFetchable, isFetched, isAvailable } = forecastProps || {};

  // Hooks
  const dispatch = useDispatch();
  // const theme = useTheme();
  // only show selector for super user
  const { userData } = useUsersList(isFilledArray(selectableProps));
  // const isLg = useMediaQueries("lg");

  // Global state
  const selectedEndDate = useSelector(selectEndDate);
  const isEndDateToday = isToday(createDateByUnixTime(selectedEndDate));
  const shouldFetchForecast = useSelector(selectShouldFetchForecast);
  // const shouldDisplayForecast = useSelector(selectShouldDisplayForecast);
  // const isSelectedDateSameDay = useSelector(selectIsSelectedDateSameDay);

  const handleShouldShowForecastChange = (e) => {
    const checked = e.target?.checked;
    // console.debug('handleShouldShowForecastChange', checked);
    dispatch(updateShouldFetchForecast(checked));
    dispatch(updateShouldShowForecast(checked));
  };

  if (!isData) {
    return null;
  }

  const shouldShowSNRSelector =
    isFilledArray(selectableProps) && userData?.isSuperUser;
  // const showForecastLegend = isFetched && isAvailable && shouldDisplayForecast;
  const showForecastSwitch = isFetchable && isEndDateToday;
  const shouldForecastDisabled = isFetchable && isFetched && !isAvailable; //!isEndDateToday || !isForecastAvailable;
  // const showToday = isToday(createDateByUnixTime(dateRange?.endDate));

  return (
    <LegendWrapper className="flex flex-col justify-center items-center mb-2 gap-y-6">
      <div className="flex w-full ">
        {/* <LegendItem
          fillColor={theme?.colors?.graph?.overall}
          label={'Overall'}
        />
        {showForecastLegend ? (
          <LegendItem
            fillColor={theme.colors?.graph?.forecast}
            label={'Forecast'}
          />
        ) : null}
        {showToday ? (
          <LegendItem
            fillColor={theme.colors?.graph?.today}
            label={'Today (till now)'}
            className='normal-case'
          />
        ) : null} */}
      </div>
      <div className="flex flex-col lg:flex-row lg:flex-row lg:justify-start items-center">
        {showForecastSwitch ? (
          <Tooltip2
            title={shouldForecastDisabled ? "No available forecast data" : null}
          >
            <div>
              <Switch
                className={"mx-2 min-w-max"}
                label={<Span>Show forecast</Span>}
                disabled={shouldForecastDisabled}
                onChange={handleShouldShowForecastChange}
                checked={shouldFetchForecast}
              />
            </div>
          </Tooltip2>
        ) : null}
        {shouldShowSNRSelector ? (
          <SubCategoriesMultiSelect2
            options={getSelectOptions(selectableProps)}
            value={selected}
            onChange={setSelected}
          />
        ) : null}
      </div>
      {/* <Tooltip anchorId="my-element" /> */}
    </LegendWrapper>
  );
}

/**
 * contains a show forecast switch, a sub-category selector
 * forecast control: show forecast switch, show forecast legend, is forecast fetchable?, is forecast available?
 * show the switch when forecast is fetchable
 * sub-category selector: hidden when is not super user
 */
export function LegendsGroupWithoutGlobalStates({
  forecastProps,
  subCategoryProps,
  ...optionals
}: {
  forecastProps: ForecastProps;
  subCategoryProps: MiniChainSelectorProps;
  shouldHide?: boolean;
  [x: string]: any;
}) {
  // Props
  const { shouldHide = false, graphLabel } = optionals;
  const {
    isFetchable,
    isFetched,
    isAvailable,
    shouldDisplay,
    onDisplayForecast,
  } = forecastProps || {};
  const { shouldShow, selectableProps, selected, setSelected } =
    subCategoryProps || {};

  // Hooks
  // const theme = useTheme();

  const handleShouldShowForecastChange = (e) => {
    const checked = e.target?.checked;
    onDisplayForecast && onDisplayForecast(checked);
  };

  if (shouldHide) {
    return null;
  }

  // const shouldShowSubCategorySelector =
  //   (isFilledArray(selectableProps)) && userData?.isSuperUser;
  const shouldShowSubCategorySelector = shouldShow;
  // const showForecastLegend =
  //   isFetched && isAvailable && isFetchable && shouldDisplay;
  const showForecastSwitch = isFetchable;
  const shouldForecastDisabled = isFetchable && isFetched && !isAvailable;

  return (
    <LegendWrapper className="flex flex-col justify-center items-center mb-2 gap-y-6">
      <div className="flex w-full ">
        {graphLabel && <p className="m-auto">{graphLabel}</p>}

        {/* <LegendItem
          fillColor={theme?.colors?.graph?.overall}
          label={'Overall'}
        />
        {showForecastLegend ? (
          <LegendItem
            fillColor={theme.colors?.graph?.forecast}
            label={'Forecast'}
          />
        ) : null} */}
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-start items-center">
        {showForecastSwitch ? (
          <Tooltip2 title={!isAvailable ? "No available forecast data" : null}>
            <div>
              <Switch
                className={"mx-2 min-w-max"}
                label={<Span>Show forecast</Span>}
                disabled={shouldForecastDisabled}
                onChange={handleShouldShowForecastChange}
                checked={shouldDisplay}
              />
            </div>
          </Tooltip2>
        ) : null}
        {shouldShowSubCategorySelector ? (
          <SubCategoriesMultiSelect2
            options={getSelectOptions(selectableProps)}
            value={selected}
            onChange={setSelected}
          />
        ) : null}
      </div>
    </LegendWrapper>
  );
}

export function LegendsGroupView({
  forecastProps,
  subCategoryProps,
  ...optionals
}: {
  forecastProps: ForecastProps;
  subCategoryProps: MiniChainSelectorProps;
  shouldHide?: boolean;
  [x: string]: any;
}) {
  // Props
  const { shouldHide = false } = optionals;
  const {
    isFetchable,
    isFetched,
    isAvailable,
    shouldDisplay,
    onDisplayForecast,
  } = forecastProps || {};
  const { shouldShow, selectableProps, selected, setSelected } =
    subCategoryProps || {};

  // Hooks
  const theme = useTheme();

  const handleShouldShowForecastChange = (e) => {
    const checked = e.target?.checked;
    onDisplayForecast && onDisplayForecast(checked);
  };

  if (shouldHide) {
    return null;
  }

  // const shouldShowSubCategorySelector =
  //   (isFilledArray(selectableProps)) && userData?.isSuperUser;
  const shouldShowSubCategorySelector = shouldShow;
  const showForecastLegend =
    isFetched && isAvailable && isFetchable && shouldDisplay;
  const showForecastSwitch = isFetchable;
  const shouldForecastDisabled = isFetchable && isFetched && !isAvailable;

  return (
    <LegendWrapper className="flex flex-col justify-center items-center mb-2">
      <div className="flex w-full ">
        <LegendItem
          fillColor={theme?.colors?.graph?.overall}
          label={"Overall"}
        />
        {showForecastLegend ? (
          <LegendItem
            fillColor={theme.colors?.graph?.forecast}
            label={"Forecast"}
          />
        ) : null}
      </div>
      <div className="flex flex-col mt-4 lg:flex-row lg:justify-start items-center">
        {showForecastSwitch ? (
          <Tooltip2 title={!isAvailable ? "No available forecast data" : null}>
            <div>
              <Switch
                className={"mx-2 min-w-max"}
                label={<Span>Show forecast</Span>}
                disabled={shouldForecastDisabled}
                onChange={handleShouldShowForecastChange}
                checked={shouldDisplay}
              />
            </div>
          </Tooltip2>
        ) : null}
        {shouldShowSubCategorySelector ? (
          <SubCategoriesMultiSelect2
            options={getSelectOptions(selectableProps)}
            value={selected}
            onChange={setSelected}
          />
        ) : null}
      </div>
    </LegendWrapper>
  );
}
