import { Button } from "components/atomic/Button";
import { Modal } from "components/atomic/Modal";
import { InfoCard } from "components/molecule/InfoCard";
import { capitalizeFirstLetter } from "utils";
// import { Typography } from '../atomic/Typography';

/**
 * Delete a zone
 * @param onConfirm - fire the execution of the confirm function
 * @param onHide - cancel
 */
export function DeleteModalView({ itemName, onConfirm, onHide, ...optionals }) {
  // Props
  const { show = false } = optionals;

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={`Delete ${capitalizeFirstLetter(itemName)}`}
    >
      <div className={"p-4"}>
        <div {...optionals}>
          {/* <Typography className='mt-2'>
            Are you sure you want to delete “{itemName}”?
          </Typography> */}
          <InfoCard
            text={`Deleting the scanner ${itemName} will result in the removal of all associated information and data. If you wish to proceed with the deletion, please click the "Delete" button`}
            subHeadingText={"Warn"}
            icon={"warning"}
          />
        </div>
        <div className="flex justify-end mt-4 space-x-2">
          <Button onClick={onConfirm} variant="danger">
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
