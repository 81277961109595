import { Icon } from 'components/atomic/Icon';
import styled, { useTheme } from 'styled-components';
// import { useMediaQueries } from 'styles/themes';


const IconContainer = styled.div`
  border: 1px solid ${props => props.theme?.colors?.secondary};
  background: ${props => props.theme?.colors?.secondary};
`;

/**
 * A page header with icon, title and subtitle
 */
export function InfoSection({ title, text, icon, ...optionals }: { title: string; text: string; icon?: string }) {
  const theme = useTheme();
  // const isLg = useMediaQueries('lg');

  return (
    <>
      <div className="mb-2 me-2 flex items-center">
        {icon && (
          <IconContainer
            className="me-4 rounded p-2"
          >
            <Icon name={icon} style={{ fontSize: "32px", color: theme.colors.white }} />
          </IconContainer>
        )}
        <div>
          <h1 className="m-0 text-3xl">{title}</h1>
          <p className="m-0 text-muted font-medium text-base xl:text-lg">
            {text}
          </p>
        </div>
      </div>
    </>
  );
}

export default InfoSection;
