import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, Reducer } from 'redux';

import heatmapReducer, { heatmapSlice } from './heatMapSilce';
import globalSelectedReducer, {
  globalSelectedSlice,
} from './globalSelectSlice';
import cardReducer, { cardSlice } from './cardSlice';

const reducers: Reducer = combineReducers({
  //all reducers should be put here
  [heatmapSlice.name]: heatmapReducer,
  [globalSelectedSlice.name]: globalSelectedReducer,
  [cardSlice.name]: cardReducer,
});

const makeStore = () =>
  configureStore({
    reducer: reducers,
    devTools: true,
  });

export const store = makeStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof makeStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<AppStore['getState']>;
