import {
  DEVICE_CONFIG_API,
  KAIDU_DEVICES_LIST_API,
  setUserSelectedCustomerId,
  useKaiduConfigList,
} from "features/kaidu-config-server";
import {
  NewUser,
  USERS_LIST_ALL_API,
  USERS_LIST_API,
  deleteUser,
  postUser,
  putUser,
  removeUserFromCustomer,
} from "features/kaidu-config-server/users-list";
import { getReportSchedule } from "features/kaidu-stats-server";
import { notifyError, notifySuccess } from "lib/services/notification";
import { useMutation, useQuery, useQueryClient } from "react-query";

/**
 *
 */
export const useAddPendingUser = (userData) => {
  const queryClient = useQueryClient();

  return useMutation((user: NewUser) => postUser(user), {
    onSuccess: (d) => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(USERS_LIST_ALL_API);
      notifySuccess(`${userData?.userEmail} has been added`);
    },
    onError: (err: Error) => {
      const msg = err?.message;
      const isRegistered = msg && msg.toLowerCase().includes("already exist");
      if (isRegistered) {
        notifyError(
          "E-mail has already been registered. Please use another one"
        );
      } else {
        notifyError(err?.message);
      }
    },
  });
};

export function useDeleteUser(userId: string, onSuccessFunction: Function) {
  const queryClient = useQueryClient();

  return useMutation(() => deleteUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(USERS_LIST_ALL_API);
      onSuccessFunction();

      notifySuccess("Deleted user successfully");
    },
  });
}

export const useUpdateUser = (user: any) => {
  const foo = () => {
    // console.debug("update user", user);
    return putUser(user);
  };

  return useMutation(foo, {
    onSuccess: () => {
      window?.sessionStorage.clear();
      window?.location?.reload();
    },
  });
};

/**
 * remove a user from customer and
 */
export function useRemoveUser(
  userId: string,
  customerID: string,
  onSuccess: Function
) {
  const queryClient = useQueryClient();

  return useMutation(() => removeUserFromCustomer(userId, customerID), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries([USERS_LIST_API]);
      // queryClient.invalidateQueries(USERS_LIST_ALL_API);
      notifySuccess("Removed user successfully");
      onSuccess && onSuccess();
    },
    onError: (err: Error) => {
      notifyError(err?.message);
    },
  });
}

export function useMutationOfUserSelectedCustomer({ onSuccess, onError }) {
  const { refetch } = useKaiduConfigList();

  const queryClient = useQueryClient();
  const mutation = useMutation(setUserSelectedCustomerId, {
    onSuccess: (rawData) => {
      // Invalidate every query in the cache
      queryClient.invalidateQueries(undefined, {
        refetchActive: true,
        refetchInactive: true,
        active: true,
        inactive: true,
        fetching: true,
      });
      queryClient.invalidateQueries([DEVICE_CONFIG_API]);
      queryClient.invalidateQueries([KAIDU_DEVICES_LIST_API]);
      refetch();
      notifySuccess("You have changed the site!");
      const { data } = rawData || {};
      onSuccess && onSuccess(data);
    },
    onError: (error) => {
      console.error(error);
      notifyError("Failed to change the site! Please retry later.");
      onError && onError();
    },
  });
  return mutation;
}

export function useReportScheduleRequest({ customer, email }) {
  const result = useQuery<any, Error>(
    ["reportScheduleRequest", customer, email],
    () => getReportSchedule(customer, email),
    {
      refetchOnMount: "always",
    }
  );
  return result;
}
