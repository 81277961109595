import { BASE_API } from "../constants";
import { CardListItem, CreateCardListItemDTO } from "./types";
import axios from "axios";

export const CARDS_LIST_API = `${BASE_API}/cards_list`;
export const CARDS_LIST_BY_CUSTOMER = `${CARDS_LIST_API}/customer`;
export const CARDS_LIST_BY_ID = `${CARDS_LIST_API}/id`;
export const CARDS_LIST_BY_CARD_ID = `${CARDS_LIST_API}/card_id`;
export const CARDS_LIST_BY_MAC = `${CARDS_LIST_API}/mac`;
export const CARDS_LIST_UPDATE_LASTSEEN = `${CARDS_LIST_API}/update_last_seen`;

export async function putCard(data: CardListItem) {
  // console.debug('putCard data', data);
  const { id } = data || {};
  if (!id) {
    throw new Error("id not found");
  }

  const url = `${CARDS_LIST_BY_ID}/${encodeURIComponent(id)}`;
  return await axios.put(url, data).then((res) => res?.data);
}

export async function postCard(data: CreateCardListItemDTO) {
  // console.debug("postCard data", data);
  return await axios.post(CARDS_LIST_API, data).then((res) => res?.data);
}

export async function putCardByCardID(data: CardListItem) {
  const { card_id } = data || {};
  // console.debug("putCardByCardID data", data);
  if (!card_id) {
    throw new Error("card_id not found");
  }

  const url = `${CARDS_LIST_BY_CARD_ID}/${encodeURIComponent(card_id)}`;
  return await axios.put(url, data).then((res) => res?.data);
}
