import { sortBy } from "lodash";
import { useQuery } from "react-query";
import {
  fetchCustomer,
  fetchCustomerByName,
  fetchCustomerList,
} from "./fetchers";
import { CustomersListItem } from "./types";

/**
 *
 */
export function useCustomer(customerId: string, optArgs?: any) {
  const { onError } = optArgs || {};
  return useQuery<CustomersListItem, Error>(
    ["customer", customerId],
    () => fetchCustomer(customerId!),
    {
      enabled: Boolean(customerId),
      onError,
    }
  );
}

/**
 * Fetch all customers
 */
export function useCustomersList(options?: any) {
  const { data, ...rest } = useQuery<CustomersListItem[], Error>(
    ["customer"],
    fetchCustomerList,
    options
  );

  return {
    data: data && sortBy(data, "customer_name"),
    ...rest,
  };
}

export function useCustomerByName(customerName: string, optArgs?: any) {
  const { onError } = optArgs || {};
  return useQuery<CustomersListItem, Error>(
    ["customerByName", customerName],
    () => fetchCustomerByName(customerName!),
    {
      enabled: Boolean(customerName),
      onError,
    }
  );
}
