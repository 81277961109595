import { GlobalFilter } from "components/features/Table/GlobalFilter";
import { NoData } from "components/features/Table/NoData";
import { PaginationBtnGroup } from "components/features/Table/PaginationBtnGroup";
import { isFilledArray } from "utils";
import { ZoneCard } from "./ZoneCard";

/**
 * A list of zone cards on the settings page
 */
export function ZonesCardList({
  filter,
  pagination,
  page,
  getTableBodyProps,
  prepareRow,
  ...optionals
}) {
  const {
    shouldShowFilter,
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
  } = filter || {};
  const {
    shouldShowPagination,
    previousPage,
    gotoPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageIndex,
  } = pagination || {};

  // if (!isFilledArray(page)) {
  //   return <NoData />;
  // }

  return (
    <>
      <div className="flex flex-row justify-content-end">
        <div className="flex mt-2 mb-1" style={{ width: "160px" }}>
          {shouldShowFilter && (
            <GlobalFilter
              style={{ height: "20px" }}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder={searchBarPlaceholder}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        {isFilledArray(page) ? (
          page.map((row, i) => {
            prepareRow(row);
            // console.debug(row);
            return (
              <ZoneCard
                row={row}
                key={row?.id + row?.value}
                data-cy="zone-card-list-item"
                title={row?.original?.device_name}
              />
            );
          })
        ) : (
          <NoData />
        )}
        {shouldShowPagination && (
          <PaginationBtnGroup
            paginationFns={{ previousPage, gotoPage, nextPage }}
            paginationStates={{
              pageCount,
              canPreviousPage,
              canNextPage,
              pageIndex,
            }}
          />
        )}
      </div>
    </>
  );
}
