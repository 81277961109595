import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker"; //https://www.npmjs.com/package/react-datepicker
import styled from "styled-components";
import { diffInCalendarDays, getNow } from 'features/date';

export const BaseStyledDatePicker = styled(ReactDatePicker)`
  font-size: 24px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.darkGrey};
  /* width: 100px; */
  &:hover {
    color: ${(p) => p.theme.colors.grey};
  }
`;

/**
 * props: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
 */
export function DatePicker(props) {
  return (
    <BaseStyledDatePicker data-cy={'date-calendar-picker'} {...props} />
  )
}

export function DateRangePicker({ ...optionals }) {
  const { onChange, selectedDates, ...rest } = optionals;

  const [startDate, setStartDate] = useState(getNow());
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // when a range is selected, and selected date is updated to a different day
    // update the date range to other components
    const isDiffStartDate = selectedDates?.startDate !== startDate && (!selectedDates?.startDate || Math.abs(diffInCalendarDays(selectedDates?.startDate, startDate)) > 0);
    const isDiffEndDate = selectedDates?.endDate !== endDate && (!selectedDates?.startDate || Math.abs(diffInCalendarDays(selectedDates?.endDate, endDate)) > 0);
    const isDiffDay = endDate && diffInCalendarDays(endDate, startDate) > 0;
    const shouldUpdate = endDate && onChange && (isDiffStartDate || isDiffEndDate);
    if (shouldUpdate) {
      if (!isDiffStartDate || !isDiffDay) { // update end date only, if start date is not changed or is the same day as end day
        onChange({ endDate });
      } else if (!isDiffEndDate) { // update start date only if end date is not changed
        onChange({ startDate });
      } else {
        console.debug('update both start date and end date', isDiffStartDate);
        onChange({ startDate, endDate });
      }
    }
  }, [endDate]);

  useEffect(() => {
    // reset local state when global state is changed
    if (selectedDates) {
      const { startDate: nextStartDate, endDate: nextEndDate } = selectedDates || {};
      nextStartDate ? setStartDate(nextStartDate) : setStartDate(nextEndDate);
      setEndDate(nextEndDate);
    }
  }, [selectedDates])

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <DatePicker
      onChange={handleChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange={true}
      showDisabledMonthNavigation
      // isClearable={true}
      {...rest}
    />
  );
}