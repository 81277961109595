import { createContext, useReducer } from "react";

import {
  customerReducer,
  initialCustomerState,
  CustomerAction,
  appReducer,
  initalAppState,
  initialUserState,
  UserAction,
  userReducer,
  AppAction,
  UserState,
  initalNotifiactionState,
  NotificationAction,
  notificationReducer,
  NotificationState,
} from "./reducers";
import { AppState } from "../../types";
import { CustomerState } from "domain/customer";

type ContextProps = {
  appState: AppState;
  appDispatch: React.Dispatch<AppAction>;
  userState: UserState;
  userDispatch: React.Dispatch<UserAction>;
  notificationState: NotificationState;
  notificationDispatch: React.Dispatch<NotificationAction>;
  customerDispatch: React.Dispatch<CustomerAction>;
  customerState: CustomerState;
};

export const GlobalContext = createContext({} as ContextProps);

/**
 *
 */
export const GlobalProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(appReducer, initalAppState);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);
  const [customerState, customerDispatch] = useReducer(
    customerReducer,
    initialCustomerState
  );
  const [notificationState, notificationDispatch] = useReducer(
    notificationReducer,
    initalNotifiactionState
  );

  const value: ContextProps = {
    appState,
    appDispatch,
    customerState,
    customerDispatch,
    userState,
    userDispatch,
    notificationState,
    notificationDispatch,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
