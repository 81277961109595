import { useMemo } from "react";

import { Card2 } from "components/atomic/Card";
import { Typography } from "components/atomic/Typography";
import { RateNum } from "components/molecule/RateNum";
import { useAppState } from "hooks";
import { useMediaQueries } from "styles/themes";
import { TrafficGraphTypes } from "types";

/**
 * Summary Card container
 */
export function SummaryCard({
  num,
  label,
  visitors,
  passerbys,
  showPasserbys = true,
  ...optionals
}: {
  num: number;
  visitors: number;
  passerbys: number;
  label: string;
  rateNum?: number;
  date?: string;
  showPasserbys?: boolean;
  [x: string]: any;
}) {
  const isMedium = useMediaQueries("sm");
  const { appState } = useAppState();
  const value: number = useMemo(() => {
    const currentGraphType =
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors;
    return currentGraphType === TrafficGraphTypes.visitors
      ? visitors
      : passerbys;
  }, [appState.selected.selectedTrafficGraphType, visitors, passerbys]);
  const { rateNum, date, subTitle, isModal, className, valueClassName } =
    optionals;
  const isValidNum = Number.isFinite(value);

  return (
    <Card2
      className={`${
        isMedium ? "p-3" : "p-2"
      } flex-1 flex flex-col items-stretch justify-between border ${
        className ?? ""
      }`}
      sx={{
        borderWidth: 1,
        borderRadius: 4,
        minWidth: 180,
        maxWidth: 480,
        boxShadow: isMedium ? "5px -5px 5px 0px rgba(0,0,0,0.2)" : "unset",
      }}
      data-cy={"summary-card"}
    >
      <div className={isModal ? "" : "min-h-[2.5rem]"}>
        <Typography className="font-light mb-0">{label}</Typography>
        {subTitle ? (
          <Typography className="font-light mb-0 text-sm normal-case">
            {subTitle}
          </Typography>
        ) : null}
      </div>
      <div
        className={`flex items-center justify-center gap-4 mb-2 ${
          valueClassName ?? ""
        } ${isModal ? "" : " h-16"}`}
      >
        {isValidNum ? (
          <Typography
            className={`${isMedium ? "text-4xl" : "text-3xl"} font-medium mb-0`}
          >
            {`${value}`}
          </Typography>
        ) : (
          <Typography className="text-2xl font-medium mb-0">
            Not available
          </Typography>
        )}

        <RateNum num={rateNum} />
      </div>
      {date && (
        <div className="flex justify-end h-4">
          <Typography className="mb-0 normal-case">{date}</Typography>
        </div>
      )}
    </Card2>
  );
}
