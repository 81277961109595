/**
 * Container of customer settings
 */
export function CustomerSettingsContainer({
  children,
  ...optionals
}) {
  // const { ...rest } = optionals;
  return (
    <>
      <div className='lg:flex lg:justify-center'>
        {children}
      </div>
    </>
  );
}
