import {
  useTable as useReactTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';

export function useTable(columns, data) {
  // @ts-ignore
  return useReactTable(
    {
      columns,
      data: data ?? [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
}
