import { BASE_API } from '../constants';
import { KaiduDeviceConfiguration } from './types';
import { processSubmittedKaiduDeviceConfig } from './pre-request';
import { sendPut } from 'features/axios';

// const FOO = '/kaidu_device_configuration';
export const DEVICE_CONFIG_API = `${BASE_API}/kaidu_device_configuration`; // return configurations of the default customer
export const DEVICE_CONFIG_CUSTOMER_API = `${DEVICE_CONFIG_API}/customer`;
export const DEVICE_CONFIG_ALL_API = `${DEVICE_CONFIG_API}/all`;

export async function putKaiduConfig(
  data: KaiduDeviceConfiguration
): Promise<any> {
  console.debug(`putKaiduConfig (old API) is called with input:`, data);
  const { mac_address } = data || {};
  const url = `${DEVICE_CONFIG_API}/${encodeURIComponent(mac_address)}`;
  const processed = processSubmittedKaiduDeviceConfig(data);

  console.debug(`putKaiduConfig with data:`, processed);
  return await sendPut(url, processed);
}
