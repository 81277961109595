import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";

import { Button } from "components/atomic/Button";
import { Icon } from "components/atomic/Icon";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import { Spinner } from "components/atomic/Spinner";
import {
  NewUser,
  USERS_LIST_ALL_API,
  USERS_LIST_API,
  User,
  postUser,
} from "features/kaidu-config-server";
import { isFilledArray } from "utils";
import { notifyError, notifySuccess } from "../../../lib";

type AppSettingsProps = {
  show: boolean;
  onHide: () => void;
  userData?: User;
  existingUsers?: User[];
  [x: string]: any;
};

const emailSchema = yup.string().email().required("Email is required");
const customerSchema = yup.string().required("Customer is required");

const schema1 = yup.object().shape({
  email: emailSchema,
  customer: customerSchema,
});

/**
 * Add user to site
 */
export function AddUserModal({ show, onHide, ...optionals }: AppSettingsProps) {
  // Processed Props
  const { userData, existingUsers, customerData } = optionals;
  const loginUserCustomerID = userData?.customers_list_id;
  const emailSchema2 = emailSchema.concat(
    yup
      .string()
      .notOneOf(existingUsers.map((user: User) => user?.user_email))
      .required()
      .typeError("User already exist!")
  );

  const finalSchema = isFilledArray(existingUsers)
    ? yup.object().shape({
        email: emailSchema2,
        customer: customerSchema,
      })
    : schema1;

  // Hooks
  // const addUserMutation = useAddPendingUser();
  const queryClient = useQueryClient();
  const is_partner = useMemo(
    () =>
      customerData?.isPartner &&
      customerData?.partner_customer_id === loginUserCustomerID,
    [customerData, loginUserCustomerID]
  );

  const addUserMutation = useMutation((user: NewUser) => postUser(user), {
    onSuccess: (d, variables) => {
      queryClient.invalidateQueries(["users"]);
      queryClient.invalidateQueries([USERS_LIST_API]);
      queryClient.invalidateQueries(USERS_LIST_ALL_API);
      notifySuccess(`${variables?.userEmail} has been added`);
    },
    onError: (err: Error) => {
      const msg = err?.message;
      const isRegistered = msg && msg.toLowerCase().includes("already exist");
      if (isRegistered) {
        notifyError(
          "E-mail has already been registered. Please use another one"
        );
      } else {
        notifyError(err?.message);
      }
    },
  });
  // const theme = useTheme();
  const {
    formState: { errors, isDirty, isValid },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      customer: loginUserCustomerID,
    },
    resolver: yupResolver(finalSchema),
    mode: "onChange",
  });

  useEffect(() => {
    addUserMutation.isSuccess && onHide();
  }, [addUserMutation.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = (values) => {
    // console.debug("submitted values", values);
    const nextUser = {
      userEmail: values.email,
      companyId: loginUserCustomerID,
      isSuperUser: false,
      is_partner,
    };
    addUserMutation.mutate(nextUser);
  };

  const isEmailExistError = errors.email && errors.email.type === "notOneOf";
  // if (isEmailExistError) {
  //   console.warn('email error', errors.email);
  // }

  return (
    <Modal
      className="m-auto max-w-lg"
      show={show}
      onHide={onHide}
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>Add a user</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Form.Group controlId="email" className="flex space-x-4 items-center">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              isInvalid={isDirty && !isValid}
              {...register("email")}
            />
            {isDirty && isValid && <Icon name="check" color="green" />}
            {isEmailExistError ? (
              <>
                <Form.Control.Feedback type="invalid">
                  User already exist!
                </Form.Control.Feedback>
              </>
            ) : null}
          </Form.Group>
          <div className="flex items-center justify-end mt-4 gap-x-3">
            {addUserMutation.isLoading ? (
              <Spinner />
            ) : (
              <Button
                type="submit"
                disabled={addUserMutation.isLoading || Boolean(errors.email)}
              >
                Add
              </Button>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
