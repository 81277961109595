import { get } from "lodash";
import { find, map, mergeLeft, pipe, propEq } from "ramda";
import { useMemo, useState } from "react";

import { ZoneModal } from "components";
import { EditModal } from "components/Modals/EditZoneModal";
import { Icon } from "components/atomic/Icon";
import { IconButton } from "components/atomic/IconButton";
import { Span } from "components/atomic/Span";
import { Spinner } from "components/atomic/Spinner";
import { LargeContainer } from "components/molecule/Containers/LargeContainer";
import { ErrorMsg } from "components/molecule/ErrorMsg";
import { ErrorBoundary } from "features/error-handling";
import {
  findDeviceById,
  useKaiduConfigList,
  useKaiduDevicesList,
} from "features/kaidu-config-server";
import styled, { useTheme } from "styled-components";
import { Zone } from "types";
import { DeleteZoneGroup } from "./DeleteZoneGroup";
// import { useMediaQueries } from 'styles/themes';
import { GrayTypography } from "components/atomic/Typography";
import { generalSortFn } from "features/table";
import { isFilledArray } from "utils";
import { ScannerStatus } from "./ScannerStatus";
import { ZonesTable } from "./ZonesTable";

const OperationsCellContainer = styled.div`
  min-width: 7.5rem;
`;

// const LargeWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   color: ${(props) => props.theme.colors.darkGrey};
//   max-width: 100%;

//   border: 1px solid ${(props) => props.theme.colors.lightGrey};
//   border-radius: 8px;
//   /* background: white; */
//   margin: 18px 0;
//   padding: 0 12px;
// }
// `;

function composeZoneData(
  kaiduDevicesList: any,
  kaiduDeviceConfigurationsData: any
) {
  // find and merge kaiduDevicesList with kaide device configurations
  const mappingFn = (device) =>
    pipe(
      find(propEq("mac_address", device?.mac_address)),
      mergeLeft(device)
    )(kaiduDevicesList);
  const result =
    isFilledArray(kaiduDeviceConfigurationsData) &&
    isFilledArray(kaiduDevicesList)
      ? map(mappingFn, kaiduDeviceConfigurationsData)
      : [];
  // console.debug('deviceTableData', result);
  return result;
}

/**
 * Zones container on the Settings page
 */
export function ZonesContainer(props) {
  const { enableBarClick = false, customerID } = props;

  // Hooks
  const {
    data: kaiduDeviceConfigurationsData,
    isLoading: isLoadingKaiduConfigList,
  } = useKaiduConfigList();
  const {
    kaiduDevicesList,
    isLoading: isLoadingKaiduDeviceList,
    error: kaiduDeviceListError,
  } = useKaiduDevicesList(customerID);
  const theme = useTheme();

  // * Open/Close Zone Modal
  const [selectedZone, setSelectedZone] = useState<Zone | null>(null);
  const openZoneModal = (zone: Zone) => setSelectedZone(zone);
  const closeZoneModal = () => setSelectedZone(null);

  // * Open/Close Edit Modal
  const [selectedZoneEdit, setSelectedZoneEdit] = useState<Zone | null>(null);
  const openEditZoneModal = (zone: Zone) => setSelectedZoneEdit(zone);
  const closeEditZoneModal = () => setSelectedZoneEdit(null);

  const renderOperationsCell = (cell: any) => {
    const { mqtt_device_id, mac_address, device_name } =
      cell?.row?.original || {};
    // console.debug('cell.row', cell?.row);
    const device = findDeviceById(
      mqtt_device_id,
      kaiduDeviceConfigurationsData
    );

    if (!device) {
      return null;
    }

    return (
      <OperationsCellContainer>
        <IconButton onClick={() => openZoneModal(device)}>
          <Icon name="stats-bars" color={theme.colors.secondary} />
        </IconButton>
        <IconButton onClick={() => openEditZoneModal(device)}>
          <Icon name="edit" color={theme.colors.secondary} />
        </IconButton>
        <DeleteZoneGroup item={{ mac_address, device_name }} />
      </OperationsCellContainer>
    );
  };

  const zoneColumns = useMemo(
    () => [
      {
        Header: "Scanner Name",
        accessor: "device_name", // accessor is the "key" in the data
      },
      {
        Header: "Status",
        accessor: "kaidu_device_status",
        Cell: ({ value }) => <ScannerStatus statusId={value} />,
        sortType: (rowA, rowB) => {
          const path = "original.kaidu_device_status";
          // console.debug('rowA', rowA);
          return get(rowA, path) > get(rowB, path) ? 1 : -1;
        },
      },
      {
        Header: "MAC Address",
        accessor: "mac_address",
        sortType: generalSortFn("original.mac_address"),
      },
      {
        Header: "Device ID",
        accessor: "mqtt_device_id",
        sortType: generalSortFn("original.mqtt_device_id"),
      },
      {
        Header: "Distance Threshold",
        accessor: "rssi_threshold",
        Cell: ({ value }) => {
          return <Span>{`${value} dbm` || "N/A"}</Span>;
        },
      },
      {
        Header: "skipme",
        Cell: (props: any) => renderOperationsCell(props),
      },
    ],
    [kaiduDevicesList, kaiduDeviceConfigurationsData, customerID] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const deviceTableData = useMemo(() => {
    return composeZoneData(kaiduDevicesList, kaiduDeviceConfigurationsData);
  }, [kaiduDeviceConfigurationsData, kaiduDevicesList]);

  // Conditions
  if (kaiduDeviceListError) {
    return <ErrorMsg text={kaiduDeviceListError?.message} />;
  }

  if (isLoadingKaiduDeviceList || isLoadingKaiduConfigList) {
    return <Spinner />;
  }

  // console.debug("deviceNum", deviceNum);

  return (
    <ErrorBoundary>
      <LargeContainer title="Locations" icon="FaMapMarkedAlt">
        {isLoadingKaiduConfigList ? (
          <Spinner />
        ) : (
          <ZonesTable
            columns={zoneColumns}
            data={deviceTableData}
            searchBarPlaceholder={`Search Scanner`}
          />
        )}

        <div className="flex justify-center mt-3">
          <GrayTypography>
            The status is updated every 15 minutes
          </GrayTypography>
        </div>
      </LargeContainer>
      {Boolean(selectedZoneEdit) && (
        <EditModal
          zone={selectedZoneEdit}
          show={!!selectedZoneEdit}
          onHide={closeEditZoneModal}
        />
      )}
      {Boolean(selectedZone) && (
        <ZoneModal
          show={!!selectedZone}
          onHide={closeZoneModal}
          onlySingleDayQueries={false}
          zone={selectedZone}
          enableBarClick={enableBarClick}
        />
      )}
    </ErrorBoundary>
  );
}
