import MuiContainer from '@mui/material/Container';
import styled from 'styled-components';

export function Container(props) {
  return (
    <MuiContainer maxWidth={false} {...props}/>
  )
}

export const FullSizeContainer = styled(Container)`
  width: 100%;
  height: 100%;
  flex: 1;
  flex-grow: 1;
  position: relative;
`;