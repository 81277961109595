import { BootstrapInput } from "components/atomic/Input";

/**
 * Two inputs for selecting a start to end time range
 */
export function TimeRangeInput({
  startValue,
  onStartChange,
  endValue,
  onEndChange,
  ...optionals
}) {
  // const { children, ...rest } = optionals;
  const { children } = optionals;
  return (
    <>
      <BootstrapInput
        type="time"
        name="start"
        value={startValue}
        onChange={onStartChange}
        className="w-36 lg:w-60"
      />
      {children ? children : null}
      <BootstrapInput
        type="time"
        name="end"
        value={endValue}
        className="w-36 lg:w-60"
        onChange={onEndChange}
      />
    </>
  );

  // return (
  //   <HourRangePicker2 {...props}/>
  // )

  // return (
  //   <HourRangePicker1 {...props}/>
  // )
}

// export function
