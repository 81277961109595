import { isFilledArray } from "utils";
// import { base64ImageSample1 } from './mock-data';
import { Button } from "components/atomic/Button";
import { useMemo } from "react";
import styled from "styled-components";
import { MediaItem } from "../types";
import { Thumbnail } from "./Thumbnail";

const Container = styled.div`
  max-height: 25vh;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export function ThumbnailList({
  mediaList,
  timestamp,
  ...optionals
}: {
  mediaList: MediaItem[];
  timestamp: string;
  mainMediaItem?: string;
  [x: string]: any;
}) {
  const { mainMediaItem, onChangeMainMediaItem } = optionals;
  // const { mainMediaItem, onChangeMainMediaItem, ...rest } = optionals;

  const mainMediaItemIndex = useMemo(() => {
    if (isFilledArray(mediaList)) {
      return mediaList.findIndex((item) => item.mediaID === mainMediaItem);
    }
  }, [mainMediaItem, mediaList]);

  const handlePrev = () => {
    if (mainMediaItemIndex > 0) {
      onChangeMainMediaItem(mediaList[mainMediaItemIndex - 1].mediaID);
    }
  };

  const handleNext = () => {
    if (mainMediaItemIndex < mediaList.length - 1) {
      onChangeMainMediaItem(mediaList[mainMediaItemIndex + 1].mediaID);
    }
  };

  return (
    <Container>
      {isFilledArray(mediaList) ? (
        <>
          <Button
            variant="light"
            className="me-3"
            onClick={handlePrev}
            disabled={mainMediaItemIndex <= 0}
          >
            {"<"}
          </Button>
          {mediaList?.map((item, index) => (
            <Thumbnail
              src={item?.src}
              key={item?.type + index}
              alt={`camera-${item?.type}-${timestamp}-${index}`}
              isSelected={item?.mediaID === mainMediaItem}
              onClick={onChangeMainMediaItem}
              itemData={item}
            />
          ))}
          <Button
            variant="light"
            onClick={handleNext}
            disabled={
              mainMediaItemIndex < 0 ||
              mainMediaItemIndex >= mediaList?.length - 1
            }
          >
            {">"}
          </Button>
        </>
      ) : (
        "No available image"
      )}
    </Container>
  );
}
