import {
  diffInCalendarDays,
  formatRFC3339,
  getDayDiffByShortDayString,
  getNow,
  isSameDay,
  parseString,
  startOfDay,
  subDays,
} from 'features/date';
import {
  AVERAGE_HOURLY_API,
  CARDS_DATA,
  GATHERED_TRAFFIC_BY_BEACON,
  GLOBAL_HISTORY_DATA,
  HEATMAP_TRIANGULATION_INFO_COOR_API,
  TRAFFIC_BY_BUILDING_API,
} from 'features/kaidu-stats-server';
import { rest } from 'msw'; //
import {
  aWeekGlobalData,
  cardsData,
  globalData,
  hourlyAverage,
  trafficByBeacon,
  trafficByBuilding,
} from '../data';
import { modifyResponseDates, setAllDatesToBeAscendUntilToday } from '../helpers';

export const mockAverageHourlyAPI = rest.get(
  `${AVERAGE_HOURLY_API}*`,
  (req, res, ctx) => {
    // If authenticated, return a mocked user details
    // return res(ctx.status(200), ctx.json(globalData?.default));
    const rawData = hourlyAverage.timezones.zero;
    return res(ctx.status(200), ctx.json(rawData));
  }
);

export const mockGlobalData = rest.get(
  `${GLOBAL_HISTORY_DATA}*`,
  (req, res, ctx) => {
    // If authenticated, return a mocked user details
    // return res(ctx.status(200), ctx.json(globalData?.default));

    // const customerID = req.url.searchParams.get('customer');
    const endDate = req.url.searchParams.get('endDate');
    const startDate = req.url.searchParams.get('startDate');

    const endDay = endDate && endDate.slice(0, 10);
    const startDay = startDate && startDate.slice(0, 10);

    const duration = getDayDiffByShortDayString(endDay, startDay);
    // console.log("file: kaidu-stats-server.ts:43 ~ mockGlobalData ~ duration", duration);
    let resultData;

    if (duration > 1 && duration <= 7) {
      const processedDates = setAllDatesToBeAscendUntilToday(aWeekGlobalData.graph.data);
      aWeekGlobalData.graph.data = processedDates
      resultData = aWeekGlobalData;
    } else if (duration > 7 && duration <= 31) {
      const processedDates = setAllDatesToBeAscendUntilToday(globalData?.month.graph.data);
      globalData.month.graph.data = processedDates
      resultData = globalData?.month;
    } else if (duration > 31 && duration <= 160) {
      resultData = modifyResponseDates(globalData?.twoMonths);
    } else if (duration > 160) {
      resultData = modifyResponseDates(globalData?.sixMonths);
    } else {
      resultData = modifyResponseDates(globalData.today);
      // resultData = globalData.today;
    }
    const json = ctx.json(resultData);
    // console.log("file: kaidu-stats-server.ts:54 ~ mockGlobalData ~ resultData", json)

    return res(ctx.status(200), json);
  }
);

// e.g. https://kaidu-dev1.deeppixel.ai/getData_cards?customer=e975dcfd-e348-499d-ab55-4d268e475db5&endDate=2022-10-24T00:00:00%2B03:00&timezone=Europe%2FVilnius
export const mockFetchCardsData = rest.get(
  `${CARDS_DATA}?*`,
  (req, res, ctx) => {
    // Check if the user is authenticated in this session
    // const isAuthenticated = sessionStorage.getItem('is-authenticated')
    // if (!isAuthenticated) {
    //   // If not authenticated, respond with a 403 error
    //   return res(
    //     ctx.status(403),
    //     ctx.json({
    //       errorMessage: 'Not authorized',
    //     }),
    //   )
    // }

    // If authenticated, return a mocked user details
    return res(ctx.status(200), ctx.json(cardsData?.summary));
  }
);

export const mockHeatmapData = rest.get(
  `${HEATMAP_TRIANGULATION_INFO_COOR_API}`,
  (req, res, ctx) => {
    // Check if the user is authenticated in this session
    // const isAuthenticated = sessionStorage.getItem('is-authenticated')
    // if (!isAuthenticated) {
    //   // If not authenticated, respond with a 403 error
    //   return res(
    //     ctx.status(403),
    //     ctx.json({
    //       errorMessage: 'Not authorized',
    //     }),
    //   )
    // }

    // If authenticated, return a mocked user details
    // return res(ctx.status(200), ctx.json());
  }
);

const today = startOfDay(getNow());

export const mockTrafficByBuilding = rest.get(
  `${TRAFFIC_BY_BUILDING_API}*`,
  (req, res, ctx) => {
    // const customer = req.url.searchParams.get('customer');
    const startDate = req.url.searchParams.get('startDate');
    const endDate = req.url.searchParams.get('endDate');

    console.debug('mockTrafficByBuilding API params', startDate);
    // Test data doesn't exist
    // return res(ctx.status(200), ctx.json(noExist.default));

    if (isSameDay(new Date(endDate), today)) {
      console.debug('Mock TrafficByBuilding API is today!');
    }

    let result = ctx.json(trafficByBuilding?.multiDays);
    try {
      const diffInDays = diffInCalendarDays(
        parseString(endDate),
        parseString(startDate)
      );
      if (diffInDays > 7) {
        const toBeModified = trafficByBuilding?.aMonth.graph;
        toBeModified[toBeModified.length - 1].date = formatRFC3339(today);
        for (let i = 1; i < toBeModified.length; i++) {
          toBeModified[toBeModified.length - i - 1].date = formatRFC3339(
            subDays(today, i)
          );
        }
        result = ctx.json(trafficByBuilding?.aMonth);
      } else if (diffInDays > 1) {
        const toBeModified = trafficByBuilding?.multiDays.graph;
        toBeModified[0].date = formatRFC3339(today);
        toBeModified[1].date = formatRFC3339(subDays(today, 1));
        toBeModified[2].date = formatRFC3339(subDays(today, 2));
        toBeModified[3].date = formatRFC3339(subDays(today, 3));
        toBeModified[4].date = formatRFC3339(subDays(today, 4));
        toBeModified[5].date = formatRFC3339(subDays(today, 5));
        toBeModified[6].date = formatRFC3339(subDays(today, 6));
        result = ctx.json(trafficByBuilding?.multiDays);
      }
      return res(ctx.status(200), result);
    } catch (error) {
      console.error('Mock error', error);
      return res(ctx.status(200), result);
    }
  }
);

export const mockTrafficByBeacon = rest.get(
  `${GATHERED_TRAFFIC_BY_BEACON}*`,
  (req, res, ctx) => {
    const singleDay = trafficByBeacon?.aDay2;
    const oneWeek = trafficByBeacon?.aWeek;

    // const customer = req.url.searchParams.get('customer');
    const startDate = req.url.searchParams.get('startDate');
    const endDate = req.url.searchParams.get('endDate');

    let result = ctx.json(singleDay);
    try {
      const diffInDays = diffInCalendarDays(
        parseString(endDate),
        parseString(startDate)
      );
      console.debug('mock trafficByBeacon params for', diffInDays);
      if (diffInDays > 1) {
        const toBeModified = oneWeek.graph.data;
        toBeModified[0].date = formatRFC3339(today);
        toBeModified[1].date = formatRFC3339(subDays(today, 1));
        toBeModified[2].date = formatRFC3339(subDays(today, 2));
        toBeModified[3].date = formatRFC3339(subDays(today, 3));
        toBeModified[4].date = formatRFC3339(subDays(today, 4));
        toBeModified[5].date = formatRFC3339(subDays(today, 5));
        toBeModified[6].date = formatRFC3339(subDays(today, 6));
        result = ctx.json(oneWeek) as any;
        return res(ctx.status(200), result);
      }
    } catch (error) {}

    return res(ctx.status(200), result);
  }
);
