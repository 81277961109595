/**
 * String Manipulations
 */

/**
 * conver the first letter to uppercase
 */
export function capitalizeFirstLetter(string: string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function padZeroTo2Digits(num: number) {
  return String(num).padStart(2, "0");
}

export function checkIfValidUUID(str): boolean {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}
