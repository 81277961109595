import { isFilledArray } from "../../utils";
import { CamerasForScanners, KaiduDeviceConfiguration } from "../kaidu-config-server";


export function convertCameraDeviceListToOptions(
  input
): { label: string; value: string }[] {
  if (!input) {
    return null;
  }
  return Object.keys(input)?.map((key) => {
    const { deviceName, cameraID } = input[key];
    return { label: deviceName, value: cameraID, key };
  });
}


export function convertCustomerCustomDataToCameraDeviceList(
  cameras: CamerasForScanners,
  devices: KaiduDeviceConfiguration[]
) {
  const macs = cameras && Object.keys(cameras);
  if (!isFilledArray(macs) || !isFilledArray(devices)) {
    return null;
  }

  let result = {};
  for (const mac of macs) {
    const { camera_id } = cameras[mac];
    const deviceName = devices.find(
      (item) => item?.mac_address === mac
    )?.device_name;
    if (deviceName && camera_id) {
      result[mac] = { deviceName, cameraID: camera_id };
    }
  }
  return result;
}