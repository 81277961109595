import { useQuery } from 'react-query';
import { fetchLiveCameraList, fetchImageList, fetchVideoList, fetchImageListByTime, fetchVideoListByTime, fetchWrokingCameraList } from './fetchers';
import {WorkingCameraItem} from './types';

export function useImageList(customerID: string) {
  return useQuery<any, Error>('imageList', () => fetchImageList(customerID), {
    enabled: Boolean(customerID),
  });
}

export function useImageListByTime(customerID: string, timestamp: string) {
  return useQuery<any, Error>(['imageListByTime', customerID, timestamp], () => fetchImageListByTime(customerID, timestamp), {
    enabled: Boolean(customerID && timestamp),
  });
}

export function useVideoListByTime(customerID: string, startTime: string, endTime: string) {
  return useQuery<any, Error>(['videoListByTime', customerID, startTime], () => fetchVideoListByTime(customerID, startTime, endTime), {
    enabled: Boolean(customerID && startTime && endTime),
  });
}

export function useVideoList(customerID: string) {
  return useQuery('videoList', () => fetchVideoList(customerID), {
    enabled: Boolean(customerID),
  });
}

export function useCameraList() {
  return useQuery<any, Error>('cameraList', fetchLiveCameraList);
}

export function useWorkingCameraList() {
  return useQuery<WorkingCameraItem[], Error>('workingCameraList', fetchWrokingCameraList);
}

