import { lighten } from "polished";
import styled from "styled-components";

import { Typography } from "components/atomic/Typography";
import { NIL_VALUE_TEXT } from "lib";

const Container = styled.div<{ colored?: boolean }>`
  /* background-color: ${(p) => lighten(0.93, p.theme.colors.tertiary)}; */
  background-color: ${(p) =>
    p?.colored ? lighten(0.64, p.theme.colors.tertiary) : "transparent"};
`;

const Label = styled(Typography)`
  color: ${(props) => props.theme.colors.grayscale[3]};
`;

/**
 *
 */
export function KeyValuePair({ label, value, ...optionals }) {
  const { className = "flex" } = optionals;
  // const { className = 'flex', ...rest } = optionals;
  // const theme = useTheme();

  return (
    <Container className={className}>
      <Label className="w-48">{label}</Label>
      <Typography className={`${value ? "font-medium" : "font-light"}`}>
        {value || NIL_VALUE_TEXT}
      </Typography>
    </Container>
  );
}
