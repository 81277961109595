// import { HeatMapPage } from 'application/pages/Heatmap';
import { Forbidden } from "application/pages/Forbidden";
import { Home } from "application/pages/Home";
import { Login } from "application/pages/Login";
import { LoginSuccess } from "application/pages/LoginSuccess";
// import { Security } from 'application/pages/Security';
import { Settings } from "application/pages/Settings";
import { VerifyOTP } from "application/pages/VerifyOTP";
// import { CardsPage } from 'application/pages/Cards';
// import { Zones } from 'application/pages/Zones';

const AUTH_ROUTES = [
  {
    route: "/login",
    isPrivate: false,
    component: Login,
    exact: true,
    id: "login",
  },
  {
    route: "/login/failure",
    isPrivate: false,
    component: Login,
    exact: true,
    id: "login-failure",
  },
  {
    route: "/login/success/:token",
    isPrivate: false,
    component: LoginSuccess,
    exact: false,
    id: "login-success",
  },
  {
    route: "/login/success",
    isPrivate: false,
    component: LoginSuccess,
    exact: false,
    id: "login-success-2",
  },
  {
    route: "/403",
    isPrivate: false,
    component: Forbidden,
    exact: false,
    id: "forbidden",
  },
  {
    route: "/verify_otp",
    isPrivate: false,
    component: VerifyOTP,
    exact: false,
    // exact: true,
    id: "verify",
  },
];

export const ROUTES = [
  ...AUTH_ROUTES,
  { route: "/", isPrivate: true, component: Home, exact: true, id: "home" },
  // {
  //   route: '/zones',
  //   isPrivate: true,
  //   component: Zones,
  //   exact: false,
  //   id: 'zones',
  // },
  {
    route: "/settings",
    isPrivate: true,
    component: Settings,
    exact: false,
    id: "settings",
  },
  // {
  //   route: '/heatmap',
  //   isPrivate: true,
  //   component: HeatMapPage,
  //   exact: false,
  //   id: 'heatmap',
  // },
  // {
  //   route: '/cards',
  //   isPrivate: true,
  //   component: CardsPage,
  //   exact: false,
  //   id: 'cards',
  // },
  // {
  //   route: '/security',
  //   isPrivate: false,
  //   component: Security,
  //   exact: false,
  //   id: 'security',
  // },
];
