import { useQuery } from "react-query";
import { DEVICE_CONFIG_API } from "../api";
import { fetchDevices, fetchDevicesByCustomer } from "../fetch";
// import { fetchConfigurationByMAC } from '../fetch';
import { getData } from "features/axios";
import { KaiduDeviceConfiguration } from "../types";

export function useAllKaiduConfig(mounted = true) {}

/**
 *
 */
export function useKaiduConfigList(enabled = true) {
  // enabled && console.log("file: index.ts:16 ~ useKaiduConfigList ~ enabled", enabled);
  return useQuery<KaiduDeviceConfiguration[], Error>(
    [DEVICE_CONFIG_API],
    () => getData(DEVICE_CONFIG_API),
    {
      enabled,
      refetchOnMount: "always",
      // refetchOnWindowFocus: "always",
    }
  );
}

export function useKaiduConfigByMac(mac: string, options?: any) {}

export function useFetchDevices(fireOnLoad = true) {
  fireOnLoad && console.debug(`useFetchDevices is fired`);
  return useQuery<KaiduDeviceConfiguration[], Error>(
    [DEVICE_CONFIG_API],
    fetchDevices,
    {
      staleTime: 60 * 1000 * 10, // 10 minutes
      enabled: fireOnLoad,
    }
  );
}

export function useFetchDevicesByCustomer(
  customer_id: string,
  startDate?: string,
  endDate?: string,
  options?: any
) {
  const api_url = `${DEVICE_CONFIG_API}/customer/${customer_id}`;
  return useQuery<KaiduDeviceConfiguration[], Error>(
    [api_url, startDate, endDate],
    () => fetchDevicesByCustomer(customer_id, startDate, endDate),
    options
  );
}
