import { HoursOfOperationInput } from "domain/customer/components/HoursOfOperation/HoursOfOperationInput";
// import { useForm } from 'react-hook-form';
// import { Button } from '../../../components/atomic/Button';
import { TimezoneInputGroup } from "../time-zone";
import { EditCustomerName } from "./EditCustomerName";

// const DEAFULT_FORM_OPTIONS = { mode: "onChange", reValidateMode: "onChange" };

/**
 *
 */
export function CustomerConfigForm({ formProps, ...optionals }) {
  // const { ...rest } = optionals;

  const { control, setValue } = formProps || {};

  return (
    <div
      className={
        "flex flex-col items-center lg:items-start justify-between flex-wrap gap-y-6 max-w-5xl w-full"
      }
    >
      <div className={"w-full"}>
        <EditCustomerName control={control} />
      </div>
      <div className={"w-full"}>
        <TimezoneInputGroup control={control} />
      </div>
      <div className={"w-full"}>
        <HoursOfOperationInput
          control={control}
          setValue={setValue}
          className="w-full"
        />
      </div>
    </div>
  );
}
