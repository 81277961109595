import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addDaysToUnixTime,
  createDateByUnixTime,
  getUnixTimeByDate,
  getYesterday,
  isSameDay,
  isToday,
  subDaysToUnixTime,
  UnixtimeSelectedDate,
} from "features/date";
import { createSelector } from "reselect";
import { FloorDTO, LocationDTO } from "../../features/kaidu-config-server";
import type { AppState } from "./store";
import { SelectedPreconfig } from "./types";

const defaultEndDate = getUnixTimeByDate(getYesterday());

const initialState = {
  currentUser: null,
  customer: null,
  partnerCustomer: null,
  building: null,
  location: null,
  floor: null,
  floorplan: null,
  device: null,
  endDate: defaultEndDate, // unix timestamp in seconds
  startDate: null, // unix timestamp in seconds
  shouldFetchForecast: false,
  shouldShowForecast: false,
};

export const globalSelectedSlice = createSlice({
  name: "globalSelected",
  initialState,
  reducers: {
    updateSelectedCustomer(state, action: PayloadAction<any>) {
      state.customer = action.payload;
      return state;
    },
    updateSelectedBuilding(state, action: PayloadAction<any>) {
      state.building = action.payload;
      return state;
    },
    updateSelectedFloor(state, action: PayloadAction<any>) {
      state.floor = action.payload;
      return state;
    },
    updateSelectedLocation(state, action: PayloadAction<any>) {
      state.location = action.payload;
      return state;
    },
    updateSelectedDevice(state, action: PayloadAction<any>) {
      state.device = action.payload;
      return state;
    },
    updateEndDate(state, action: PayloadAction<any>) {
      state.endDate = action.payload;
      return state;
    },
    addDaysToEndDate(state, action: PayloadAction<any>) {
      const num = action.payload;
      const added = addDaysToUnixTime(state.endDate, num);
      state.endDate = getUnixTimeByDate(added);
      return state;
    },
    subDaysToEndDate(state, action: PayloadAction<any>) {
      const num = action.payload;
      const subed = subDaysToUnixTime(state.endDate, num);
      state.endDate = getUnixTimeByDate(subed);
      return state;
    },
    updateStartDate(state, action: PayloadAction<any>) {
      state.startDate = action.payload;
      return state;
    },
    updateSelectedDate(
      state,
      action: PayloadAction<{ startDate?: number; endDate: number }>
    ) {
      const { startDate, endDate } = action.payload || {};
      state.startDate = startDate;
      state.endDate = endDate;
      return state;
    },
    updateSelectedFloorplan(state, action: PayloadAction<any>) {
      state.floorplan = action.payload;
      return state;
    },
    updateShouldFetchForecast(state, action: PayloadAction<any>) {
      state.shouldFetchForecast = action.payload;
      return state;
    },
    updateShouldShowForecast(state, action: PayloadAction<any>) {
      state.shouldShowForecast = action.payload;
      return state;
    },
    setCurrentUser(state, action: PayloadAction<any>) {
      state.currentUser = action.payload;
      return state;
    },
    resetGlobalStates(_state, _action: PayloadAction<any>) {
      return initialState;
    },
    setPartnerCustomer(state, action: PayloadAction<any>) {
      state.partnerCustomer = action.payload;
      return state;
    },
  },
});

export default globalSelectedSlice.reducer;

export const {
  setCurrentUser,
  updateSelectedCustomer,
  updateSelectedBuilding,
  updateSelectedFloor,
  updateSelectedLocation,
  updateSelectedDevice,
  updateEndDate,
  updateStartDate,
  addDaysToEndDate,
  subDaysToEndDate,
  updateSelectedFloorplan,
  updateSelectedDate,
  updateShouldFetchForecast,
  updateShouldShowForecast,
  resetGlobalStates,
  setPartnerCustomer,
} = globalSelectedSlice.actions;

export const selectSelectedCustomer = (state: AppState) => {
  return state[globalSelectedSlice.name].customer;
};
export const selectPartnerCustomer = (state: AppState) => {
  return state[globalSelectedSlice.name].partnerCustomer;
};
export const selectSelectedBuilding = (
  state: AppState
): SelectedPreconfig & { customers_building_id?: string } => {
  return state[globalSelectedSlice.name].building;
};
export const selectSelectedFloor = (state: AppState): FloorDTO => {
  return state[globalSelectedSlice.name].floor;
};
export const selectSelectedFloorplan = (state: AppState) => {
  return state[globalSelectedSlice.name].floorplan;
};
export const selectSelectedLocation = (state: AppState): LocationDTO => {
  return state[globalSelectedSlice.name].location;
};
export const selectSelectedDevice = (state: AppState) => {
  return state[globalSelectedSlice.name].device;
};
export const selectEndDate = (state: AppState) => {
  return state[globalSelectedSlice.name].endDate;
};
export const selectStartDate = (state: AppState) => {
  return state[globalSelectedSlice.name].startDate;
};
export const selectSelectedDate = (state: AppState): UnixtimeSelectedDate => {
  return {
    endDate: state[globalSelectedSlice.name].endDate,
    startDate: state[globalSelectedSlice.name].startDate,
  };
};
export const selectAllSelected = (state: AppState) => {
  return state[globalSelectedSlice.name];
};
export const selectIsSelectedDateToday = (state: AppState) => {
  const endDate = createDateByUnixTime(state[globalSelectedSlice.name].endDate);
  const startDate =
    state[globalSelectedSlice.name].startDate &&
    createDateByUnixTime(state[globalSelectedSlice.name].startDate);
  // console.debug('selectIsSelectedDateToday', endDate, startDate);
  const startDateCheck =
    startDate === null || startDate === undefined || isToday(startDate);
  return isToday(endDate) && startDateCheck;
};
export const selectIsSelectedDateSameDay = createSelector(
  [selectStartDate, selectEndDate],
  (start, end) => {
    const endDate = createDateByUnixTime(end);
    const startDate = start && createDateByUnixTime(start);

    return isSameDay(startDate, endDate) || !startDate;
  }
);
/**
 *
 * @param state
 * @returns if the frontend should fetch forecast, controlled by the frontend
 */
export const selectShouldFetchForecast = (state: AppState) => {
  return state[globalSelectedSlice.name].shouldFetchForecast;
};

// export const selectShouldDisplayForecast = createSelector(
//   [selectShouldFetchForecast, selectEndDate],
//   (shouldFetch, endDate) => {
//     return shouldFetch && isToday(createDateByUnixTime(endDate));
//   }
// );

export const selectShouldDisplayForecast = (state: AppState) => {
  return state[globalSelectedSlice.name].shouldShowForecast;
};

export const getCurrentUser = (state: AppState) => {
  return state[globalSelectedSlice.name].currentUser;
};
