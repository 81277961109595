import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { LabelForSelect } from "components/atomic/Label";
import { Spinner } from "components/atomic/Spinner";
import { ErrorMsg } from "components/molecule/ErrorMsg";
import { CustomerSelector, getAvailableCustomerList } from "domain/customer";
import { MultiDaysDatePickerGroup } from "domain/time-range/date-range/components/TimeRange/Date/MultiDaysDateSelectors";
import { DeviceSelector } from "domain/zone/components/DeviceSelector";
import { logout } from "features/auth";
import { TrafficDataSelector } from "features/charts";
import {
  DEVICE_CONFIG_API,
  KAIDU_DEVICES_LIST_API,
  fetchCustomer,
  setUserSelectedCustomerId,
  useCustomersList,
  useKaiduConfigList,
} from "features/kaidu-config-server";
import { useAppState } from "hooks";
import { notifyError, notifySuccess } from "lib";
import {
  selectSelectedCustomer,
  setCurrentUser,
  updateSelectedCustomer,
  updateSelectedDevice,
} from "providers/redux/globalSelectSlice";
import { FlexTimeZoneText } from "../../../components/molecule/TimeZoneText";
import { DeviceMultiSelector } from "domain/zone/components/DeviceMultiSelector";

/**
 * A group of date-related UI components on the home page
 */
export function HomePageDateGroup({
  dateValue,
  onChangeDate,
  hourRangeOptions,
  ...optionals
}) {
  const { timezone, className, onSelectCustomDateRange, onSelectDevice } =
    optionals;

  const dispatch = useDispatch();
  const { userState } = useAppState();
  const history = useHistory();
  const queryClient = useQueryClient();
  const customer = useSelector(selectSelectedCustomer);

  const isSuperUser = useMemo(
    () => userState?.isSuper || userState?.isPartner,
    [userState]
  );
  const {
    data: customerList,
    isLoading: isLoadingCustomerList,
    error: customerListError,
  } = useCustomersList({
    refetchOnMount: "always",
  });
  const { refetch, data: devicesList } = useKaiduConfigList();

  const mutation = useMutation(setUserSelectedCustomerId, {
    onSuccess: (response) => {
      const { data } = response;
      if (data) dispatch(setCurrentUser(data));
      if (data && data.customers_list_id) {
        fetchCustomer(data.customers_list_id).then((res) => {
          dispatch(updateSelectedCustomer(res));
          dispatch(updateSelectedDevice(null));
        });
      }
      // Invalidate every query in the cache
      // queryClient.invalidateQueries(undefined, {
      //   refetchActive: true,
      //   refetchInactive: true,
      //   active: true,
      //   inactive: true,
      //   fetching: true,
      // });
      // queryClient.invalidateQueries([USERS_LIST_API]);
      queryClient.invalidateQueries([DEVICE_CONFIG_API]);
      queryClient.invalidateQueries([KAIDU_DEVICES_LIST_API]);
      refetch();
      notifySuccess("You have changed the site!");
    },
    onError: (error) => {
      console.error(error);
      notifyError("Failed to change the site! Please retry later.");
    },
  });

  const customerName = useMemo(() => customer?.customer_name, [customer]);
  const availableCustomerList = useMemo(() => {
    const result = getAvailableCustomerList(
      isSuperUser,
      customerList,
      userState?.allowed_customer_ids
    );
    return result;
  }, [isSuperUser, customerList, userState?.allowed_customer_ids]);

  const error = customerListError;
  if (error) {
    return <ErrorMsg text={error?.message} />;
  }

  const isLoading = !customer || isLoadingCustomerList || mutation.isLoading;

  const handleChangeSelectedCustomer = (nextValue) => {
    const customerID = nextValue?.value;
    const userID = userState?.userId;
    if (nextValue?.value !== customer?.customer_id) {
      mutation.mutate({ customerID, userID });
      onSelectDevice(undefined);
    }
  };

  const handleGoToLogin = () => {
    logout();
    history.push("/login");
  };

  return (
    <div
      className={`flex flex-col md:flex-row flex-wrap justify-around gap-x-8  gap-y-6 max-w-screen md:mt-4 ${
        className ?? ""
      }`}
    >
      {!isLoading ? (
        <>
          <div className="flex md:flex-col justify-between md:justify-normal flex-1 md:flex-none w-full md:w-auto">
            <LabelForSelect className="text-base mb-0 flex items-center min-w-[80px]">
              Customer
            </LabelForSelect>
            <CustomerSelector
              availableCustomerList={availableCustomerList}
              selectedCustomerID={customer?.customer_id}
              selectedCustomerName={customerName}
              onInvalid={handleGoToLogin}
              onChange={handleChangeSelectedCustomer}
            />
          </div>
          {devicesList?.length > 1 && (
            <div className="flex md:flex-col justify-between md:justify-normal flex-1 md:flex-none w-full md:w-auto">
              <LabelForSelect className="text-base mb-0 flex items-center min-w-[80px]">
                Locations
              </LabelForSelect>
              <DeviceMultiSelector
                className="w-52"
                dateValue={dateValue}
                onChange={onSelectDevice}
              />
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
      <MultiDaysDatePickerGroup
        timezone={timezone}
        value={dateValue}
        onChange={onChangeDate}
        onSelectCustomDateRange={onSelectCustomDateRange}
        labelClassName="min-w-[80px] mr-0"
      />
      <FlexTimeZoneText timezone={timezone} />
      <div className="flex flex-col justify-end md:ml-auto">
        <TrafficDataSelector />
      </div>
    </div>
  );
}
