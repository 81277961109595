import _ from "lodash";
import { useState } from "react";

import { Button } from "components/atomic/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import { useMutationOfUserSelectedCustomer } from "domain/user";
import {
  findCustomerByID,
  useCustomersList,
  useUsersList,
} from "features/kaidu-config-server";
import { StyledSelect } from "../../components/Selectors/Select";
import { Spinner } from "../../components/atomic/Spinner";
import {
  CUSTOMER_ENTITY_LABEL,
  getCustomerOptions,
  getHoursOfOperations,
} from "../../domain/customer";

type AppSettingsProps = {
  show: boolean;
  onHide: () => void;
  rest?: any;
};

export function getDefaultCustomerConfiguration(customer) {
  // Process props
  const customerName = customer?.customer_name;
  const initialValues = {
    ...getHoursOfOperations(customer),
    customer_name: customerName,
  };
  return initialValues;
}

/**
 * Update the selected customer in a user
 */
export function SelectCustomerModal({ show, onHide }: AppSettingsProps) {
  // Hooks
  const { userData, isLoading: isLoadingUser } = useUsersList();
  const customers = useCustomersList();
  const currentCustomerName =
    findCustomerByID(customers.data, userData.customers_list_id)
      ?.customer_name || null;

  const handleSuccess = (data) => {
    // console.debug("updated to ", data);
    // const {customers_list_id} = data || {}
    onHide();
  };

  const handleHideByCancel = () => {
    currentCustomerName && setSelectedCustomer(currentCustomerName);
    onHide();
  };

  const updateUserSelectedCustomerMutation = useMutationOfUserSelectedCustomer({
    onSuccess: handleSuccess,
    onError: onHide,
  });

  // Local states
  const [customerSelected, setSelectedCustomer] =
    useState<null | string>(currentCustomerName);

  // useEffect(() => {
  //   currentCustomerName && setSelectedCustomer(currentCustomerName);
  // }, [customers.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const foundCustomer = _.find(customers.data, [
    "customer_name",
    customerSelected,
  ]);
  const sortedCustomers = getCustomerOptions(customers.data);

  if (customers.isLoading || isLoadingUser) {
    return <Spinner />;
  }

  return (
    <Modal className="m-auto max-w-lg" show={show} onHide={handleHideByCancel}>
      <ModalHeader>
        <ModalTitle>Select Site</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <StyledSelect
          options={sortedCustomers}
          label={CUSTOMER_ENTITY_LABEL}
          value={customerSelected}
          showall={false}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSelectedCustomer(e.target.value)
          }
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="light" onClick={handleHideByCancel}>
          Close
        </Button>
        <Button
          onClick={() => {
            updateUserSelectedCustomerMutation.mutate({
              customerID: foundCustomer?.customer_id,
              userID: userData?.user_id,
            });
          }}
          className={"capitalize"}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}
