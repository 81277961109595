// import { useLocationParams } from 'features/kaidu-config-server';
// import styled from 'styled-components';
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { useInterval, useMeasure } from "react-use";

import { GlobalDataModal } from "application/components/GlobalDataModal";
import { GeneralMenu } from "components/molecule/ContextMenu";
import { useCamera } from "domain/camera";
import { WHOLE_DAY_OPTION, createHourRangeOptions } from "domain/time-range";
import {
  getGlobalDataModalKey,
  processWeeklyData,
  processWeeklySummaryData,
} from "domain/visitor-traffic";
import { Summary } from "domain/visitor-traffic/Summary";
import { useCameraData } from "features/camera";
import { TrafficGraphUIData } from "features/charts";
import {
  UnixtimeSelectedDate,
  createDateByUnixTime,
  diffInMinutes,
  getNow,
  getUnixTimeByDate,
  isSameDayInTimezone,
  parseString,
} from "features/date";
import { KaiduDeviceConfiguration } from "features/kaidu-config-server";
import { CameraModalGroup } from "features/kaidu-stats-server/features/camera";
import { useAppState } from "hooks";
import {
  selectSelectedBuilding,
  selectSelectedDevice,
  selectSelectedLocation,
  selectShouldDisplayForecast,
  updateStartDate,
} from "providers/redux/globalSelectSlice";
import { TrafficGraphTypes } from "types";
import { HomePageDateGroup } from "./HomePageDateGroup";
import { HomePageVisitorTrafficGroup } from "./HomePageVisitorTrafficGroup";
import { useAllHomePageData } from "./hooks";

/**
 *  process fetched global data for UI
 */
function formatGlobalDataSplitted(
  data
  // shouldFetchForecast: boolean = false
): TrafficGraphUIData {
  const { graph, graph_snr, isData } = data || {};
  // console.debug('formatGlobalDataSplitted input data', data);
  const { data: mainData, forecast: mainForecast } = graph || {};
  const { data: snrData, forecast: snrForecast } = graph_snr || {};

  // const subDataResult = (!isSameDay && snrData) || mainData;
  const subDataResult = snrData || mainData;
  const totalNum = data?.uniqueVisitors;

  return {
    mainData: mainData,
    forecast: mainForecast || [],
    subData: subDataResult,
    subForecast: snrForecast,
    isData,
    totalNum,
    visitors: totalNum || 0,
    passerbys: data?.passerbys || 0,
  };
}

/**
 * should control the global building, date
 */
export function HomePageContent({
  customerData,
  defaultSelectedDateRange,
  ...optionals
}: {
  customerData: any;
  defaultSelectedDateRange: any;
  addOnFeatures?: any;
  [x: string]: any;
}) {
  // Props
  const {
    customerID,
    hoursOfOperation,
    customerTimezone,
    customer_custom_data,
  } = customerData || {};
  // const { addOnFeatures, ...rest } = optionals;
  const { addOnFeatures } = optionals;
  const isCameraEnabled = addOnFeatures?.camera?.enabled;
  const isDailyGraphEnabled = addOnFeatures?.dailyGraph?.enabled;

  const [ref, { width }] = useMeasure();
  const { appState } = useAppState();

  // Global states
  const selectedBuilding = useSelector(selectSelectedBuilding);
  const selectedLocation = useSelector(selectSelectedLocation);
  const shouldDisplayForecast = useSelector(selectShouldDisplayForecast);
  const device = useSelector(selectSelectedDevice);

  // State
  // const selectedDate = useSelector(selectSelectedDate);
  const [selectedDate, setSelectedDate] = useState<UnixtimeSelectedDate>(
    defaultSelectedDateRange
  );
  const [selectedHourRange, setSelectedHourRange] = useState(
    WHOLE_DAY_OPTION.value
  );
  const [showDailyGraph, setShowDailyGraph] = useState(false);
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState<
    { device_id: string; device_name: string }[]
  >(
    device?.mqtt_device_id
      ? [
          {
            device_id: device.mqtt_device_id,
            device_name: device.mqtt_device_id,
          },
        ]
      : []
  );
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );

  // Hooks
  const dispatch = useDispatch();

  const { startDate } = selectedDate || {};
  const {
    data: globalData,
    isLoading: isLoadingGlobalData,
    errors,
    error,
    compared,
  } = useAllHomePageData(
    customerID,
    selectedLocation,
    selectedBuilding,
    customerTimezone,
    selectedDate,
    shouldDisplayForecast,
    device
  );
  const { data: comparedData } = compared || {};

  const { cameraDeviceList, cameraOptions } = useCameraData(
    customer_custom_data?.camerasForScanners
  );

  // Process data
  const graphData: TrafficGraphUIData = formatGlobalDataSplitted(
    globalData
    // shouldDisplayForecast
  );

  // Local state
  const {
    isCameraModalOpen,
    setIsCameraModalOpen,
    timestamp,
    setTimestamp,
    selectedScanner,
    setSelectedScanner,
    anchorPoint,
    setAnchorPoint,
    menuProps,
    toggleMenu,
    menuRef,
  } = useCamera();

  // Side-effects
  useInterval(() => {
    // compare now with the endDate in selectedDate, if diff is less than 10 mins, then set endDate to now
    const { endDate } = selectedDate || {};
    if (endDate) {
      const prevEndDate = createDateByUnixTime(endDate);
      const now = getNow();
      const diff = diffInMinutes(now, prevEndDate);
      if (diff < 10) {
        // console.debug('should update endDate to now', now, endDate);
        setSelectedDate({ ...selectedDate, endDate: getUnixTimeByDate(now) });
      }
    }
  }, 1000 * 6 * 5);

  // Handlers
  const handleMainBarClick = (data: any, e?: MouseEvent) => {
    // camera handler
    if (isCameraEnabled) {
      const originalDate = data?.date;

      setTimestamp(originalDate);
      // should open the context menu
      setAnchorPoint({ x: e.clientX, y: e.clientY });
      toggleMenu(true);
    } else if (isDailyGraphEnabled) {
      // open modal for a single day
      const { device_id } = data || {};
      const endDateUnix = getUnixTimeByDate(parseString(data?.date));
      setModalData({
        type: "dailyGraph",
        dateRange: { endDate: endDateUnix },
        device_id,
      });
      setShowDailyGraph(true);
    }
  };

  const handleHideCameraModal = () => {
    setIsCameraModalOpen(false);
  };

  const handleCalendarHeatmapClick = (date) => {
    // console.debug("handleCalendarHeatmapClick", date);
    if (date) {
      // const date = data.value[0];
      const endDateUnix = getUnixTimeByDate(parseString(date));
      setModalData({
        type: "dailyGraph",
        dateRange: { endDate: endDateUnix },
      });
      setShowDailyGraph(true);
      return;
    }
  };

  const handleMenuItemClick = (option) => {
    // should open the modal for selected device
    toggleMenu(false);
    setSelectedScanner(option.key);
    setIsCameraModalOpen(true);
  };

  const handleChangeDateRange = (nextDateRange) => {
    if (!nextDateRange?.timezone && selectedDate?.timezone) {
      // keep the original timezone
      const processedNextDateRange = {
        ...nextDateRange,
        timezone: selectedDate.timezone,
      };
      setSelectedDate(processedNextDateRange);
    } else {
      setSelectedDate(nextDateRange);
    }
  };

  const options = createHourRangeOptions(hoursOfOperation);
  const weeklyProps = {
    data: processWeeklyData(graphData),
    onClick: handleMainBarClick,
    // disable bar click when is showing single day data
    enableBarClick:
      selectedDate?.startDate &&
      !isSameDayInTimezone(
        selectedDate?.startDate,
        selectedDate?.endDate,
        customerTimezone
      ),
  };
  const monthlyProps = {
    onClick: handleCalendarHeatmapClick,
  };
  const summaryDataProps = processWeeklySummaryData(
    weeklyProps.data,
    customerTimezone,
    comparedData,
    currentGraphType,
    !isCustomDateRange
  );

  const headerFlexDirection = useMemo(
    () => (width > 800 ? "flex-col md:flex-row" : "flex-col"),
    [width]
  );

  const selectDevice = useCallback(
    (deviceConfigList: KaiduDeviceConfiguration[]) => {
      if (deviceConfigList === undefined) setSelectedDevices(null);
      else {
        setSelectedDevices(
          deviceConfigList.map((deviceConfig) => ({
            device_id: deviceConfig.mqtt_device_id,
            device_name:
              deviceConfig.device_name || deviceConfig.mqtt_device_id,
          }))
        );
      }
    },
    [setSelectedDevices]
  );

  return (
    <>
      <div
        ref={ref}
        className={`flex justify-between gap-8 md:gap-0 ${headerFlexDirection}`}
      >
        <HomePageDateGroup
          dateValue={selectedDate}
          onChangeDate={handleChangeDateRange}
          timezone={customerTimezone}
          hourRangeValue={selectedHourRange}
          onChangeDisplayedHourlyGraph={(option) => {
            setSelectedHourRange(option?.value);
          }}
          hourRangeOptions={options}
          hoursOfOperation={hoursOfOperation}
          className="justify-start"
          onSelectCustomDateRange={setIsCustomDateRange}
          onSelectDevice={selectDevice}
        />
      </div>
      <div>
        <div className="m-0 w-full">
          <div className="flex w-full flex-col md:flex-row md:space-x-4 ">
            <Summary
              timezone={customerTimezone}
              data={graphData?.isData ? summaryDataProps : null}
              isLoading={isLoadingGlobalData}
              error={error}
              valueClassName="!m-0"
              className="!min-w-[200px]"
            />
          </div>
          <>
            <HomePageVisitorTrafficGroup
              isLoading={isLoadingGlobalData}
              data={graphData}
              hideLeftAxis={false}
              onBarClick={handleMainBarClick}
              selectedDate={selectedDate}
              hourRange={selectedHourRange}
              weeklyProps={weeklyProps}
              monthlyProps={monthlyProps}
              errors={errors}
              timezone={customerTimezone}
              customerID={customerID}
              onChangeDate={handleChangeDateRange}
              selectedDevices={selectedDevices || []}
            />
            <GeneralMenu
              menuProps={menuProps}
              options={cameraOptions}
              anchorPoint={anchorPoint}
              onOptionClick={handleMenuItemClick}
              containerRef={menuRef}
            />
          </>
        </div>
      </div>
      {cameraDeviceList && (
        <CameraModalGroup
          deviceName={cameraDeviceList[selectedScanner]?.deviceName}
          isOpen={isCameraModalOpen}
          onHide={handleHideCameraModal}
          timestamp={timestamp}
          cameraID={cameraDeviceList[selectedScanner]?.cameraID}
        />
      )}
      {showDailyGraph && (
        <GlobalDataModal
          show={showDailyGraph}
          onHide={() => setShowDailyGraph(false)}
          selectedDateRange={modalData?.dateRange}
          key={getGlobalDataModalKey(customerID, modalData?.dateRange)}
          timezone={customerTimezone}
          device_id={modalData?.device_id}
        />
      )}
      <Prompt
        when={Boolean(startDate)}
        message={() => {
          dispatch(updateStartDate(null));
          return true;
        }}
      />
    </>
  );
}
