import RCSlider, { Range as RCRange } from 'rc-slider'; //https://slider-react-component.vercel.app/
import 'rc-slider/assets/index.css';

export function Slider(props) {
  return (
    //@ts-ignore
      <RCSlider {...props} />
  );
}


export function Range(props) {
  return (
    //@ts-ignore
      <RCRange  {...props}/>
  );
}