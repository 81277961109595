import { Spinner as BootstrapSpinner } from "react-bootstrap";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
`;

export function Spinner(props) {
  const { as = "span", animation = "border", role = "status", ariaHidden = "true", size = "sm", className = '', ...rest } = props;

  return (
    <Container className={className} >
      <BootstrapSpinner as={as} animation={animation} role={role} aria-hidden={ariaHidden} size={size} {...rest} data-cy='loader-spinner'/>
    </Container>
  )
}

export function SpinnerWithoutContainer(props) {
  const { as = "span", animation = "border", role = "status", ariaHidden = "true", size = "sm", ...rest } = props;

  return (
    <BootstrapSpinner as={as} animation={animation} role={role} aria-hidden={ariaHidden} size={size} {...rest} data-cy='loader-spinner'/>
  )
}