import {
  APPLE_CLIENT_ID,
  BASE_API,
  DASHBOARD_URL,
} from "../kaidu-config-server/constants";

//const LOGIN_SUBPATH = process.env?.REACT_APP_LOGIN_API_SUBPATH || 'REPLACE_BY_BUILD_REACT_APP_LOGIN_API_SUBPATH';

// export const GOOGLE_LOGIN_API = process.env?.REACT_APP_LOGIN_API_SUBPATH
//   ? `${BASE_API}${LOGIN_SUBPATH}`
//   : `${BASE_API}/auth/google`;
export const GOOGLE_LOGIN_API = `${BASE_API}/auth/google`;

export const USER_PROFILE_PIC_PLACEHOLDER_URL =
  "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg";

export const APPLE_AUTH_OPTIONS = {
  clientId: APPLE_CLIENT_ID, // This is the service ID we created.
  scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
  redirectURI: `${DASHBOARD_URL}/auth`, // As registered along with our service ID
  // redirectURI: APPLE_REDIRECT_URI, // As registered along with our service ID
  usePopup: true, // Important if we want to capture the data apple sends on the client side.
  responseMode: "query",
};

export const AUTH_KEY = "auth";

export const LOGIN_ERROR_MESSAGE = "An error occurred. Please try again later.";
export const UNREGISTERED_USER_ERROR_MESSAGE =
  "Access Denied: We were unable to retrieve your credentials from the server. If you are using a registered account and need assistance, please contact our technical support team for help";
// "Failed to get the credential from the Kaidu server. Please contact us for technical support if you are using a registered account. "
