import { AxisLeft } from '@visx/axis';

/**
 * 
 */
export function VisitorAxisLeft({ hide, scale, left }) {
  return (
    <>
      {!hide && (
        <AxisLeft
          tickFormat={(d: any) => {
            if (Number.isInteger(d)) return d;
          }}
          hideZero={true}
          hideAxisLine={true}
          hideTicks={true}
          left={left}
          scale={scale}
        />
      )}
    </>
  )
}