import { formatInTimeZone } from "date-fns-tz";
import { padZeroTo2Digits } from "../../../utils";

/**
 *
 * @returns a number in hour, e.g. "12:30" -> 12.5
 */
export function timeStringToFloat(time: string) {
  // console.debug('timeStringToFloat input:', time);
  if (!time) {
    return 0;
  }

  const hoursMinutes = time?.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  const result = hours + minutes / 60;
  // console.debug('timeStringToFloat output:', result);
  return result;
}

/**
 * e.g. '01:30' -> 90
 */
export function parseTimeStringToMinuteNum(time: string) {
  if (!time) {
    return 0;
  }

  const hoursMinutes = time?.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours * 60 + minutes;
}

/**
 * e.g. 1 hour 30 minutes -> 1.5
 */
export function convertHourMinuteToDecimalFloat(
  hour: number,
  minute: number
): number {
  return hour + minute / 60;
}

export function compareHourMinute(input1, input2) {}

// return a time string in 24hr format, e.g. "20:17"
export function convertTimeNumToString(num: number): string {
  console.debug("convertTimeNumToString input: ", num);
  let hours1 = Math.floor(num / 60);
  let minutes1 = num - hours1 * 60;

  let hourStr;
  if (hours1 < 10) {
    hourStr = "0" + hours1;
  } else {
    hourStr = String(hours1);
  }

  let minutesStr;
  if (minutes1 === 0) {
    minutesStr = "00";
  } else if (minutes1 < 10) {
    minutesStr = "0" + minutes1;
  } else {
    minutesStr = String(minutes1);
  }

  return hourStr + ":" + minutesStr;
}

// return a time string in 24hr format, e.g. "20:17"
export function convertHourNumToString(num: number): string {
  let hours1 = num;

  let hourStr;
  if (hours1 < 10) {
    hourStr = "0" + hours1;
  } else {
    hourStr = String(hours1);
  }

  return `${hourStr}:00`;
}

export function getHourByTimezone(date: Date, timezone: string): number {
  const dateHour = Number(formatInTimeZone(date, timezone, "HH"));
  return dateHour;
}

export function getMinutesByTimezone(date: Date, timezone: string): number {
  const dateMinute = Number(formatInTimeZone(date, timezone, "mm"));
  return dateMinute;
}

export function convertHHMMSSStringToHHMMSSObject(time: string) {
  const [hourString, minuteString, secondString] = time?.split(/[.:]/);
  return {
    hour: parseInt(hourString, 10),
    minute: parseInt(minuteString, 10),
    second: parseInt(secondString, 10),
  };
}

export function diffHHMMSSObjectsInMinutes(obj1, obj2): number {
  const { hour: hour1, minute: minute1, second: second1 } = obj1;
  const { hour: hour2, minute: minute2, second: second2 } = obj2;
  const hour = hour1 - hour2;
  const minute = minute1 - minute2;
  const second = second1 - second2;

  const total = hour * 3600 + minute * 60 + second;
  return total / 60;
}

export function addHHMMSSObject(obj1, obj2) {
  const { hour: hour1, minute: minute1, second: second1 } = obj1;
  const { hour: hour2, minute: minute2, second: second2 } = obj2;
  const hour = hour1 + hour2;
  const minute = minute1 + minute2;
  const second = second1 + second2;

  const total = hour * 3600 + minute * 60 + second;
  const nextHour = Math.floor(total / 3600);
  const nextMinute = Math.floor((total - nextHour * 3600) / 60);
  const nextSecond = total - nextHour * 3600 - nextMinute * 60;
  return { hour: nextHour, minute: nextMinute, second: nextSecond };
}

export function addMinutesTOHHMSSObject(obj1, minutes: number) {
  const { hour: hour1, minute: minute1, second: second1 } = obj1;

  const total = hour1 * 3600 + (minute1 + minutes) * 60 + second1;
  const nextHour = Math.floor(total / 3600);
  const nextMinute = Math.floor((total - nextHour * 3600) / 60);
  const nextSecond = total - nextHour * 3600 - nextMinute * 60;
  return { hour: nextHour, minute: nextMinute, second: nextSecond };
}

export function formatHHMMSSObjectToHHMMString(time: {
  hour: number;
  minute: number;
  second: number;
}) {
  const { hour, minute } = time;
  const hourString = padZeroTo2Digits(hour);
  const minuteString = padZeroTo2Digits(minute);
  return `${hourString}:${minuteString}`;
}
