import { scaleTime } from "@visx/scale";
import { useMemo } from "react";
import { createXYBounds, getDisplayedDateList } from "./processors";
// import styled from 'styled-components';
import { NoDataView } from "components/molecule/NoDataView";
import { ErrorBoundary } from "features/error-handling";
import { BarChartWithStackedForecastAndPasserbys } from "./BarChart";
// import { checkIsSameDayInTimezoneForDates } from 'features/date';
import { Typography } from "components/atomic/Typography";
import { getTimeScaleDomain } from "components/features/charts/processors";
import { Option } from "components/molecule/Select";
import { useAppState } from "hooks";
import { TrafficGraphTypes } from "types";
import { checkIsTodayData, hasMultiDaysData } from "../processors";
import { GRAPH_PADDING_FOR_BARS } from "./constants";
import { getSingleBarWidth, getVisitorTrafficScale } from "./processors";

/**
 * Bar Chart only, for displaying the daily traffic
 */
export function VisitorTrafficBarChart({
  width,
  height,
  data,
  selectedBarCategory,
  ...optionals
}: {
  width: number;
  height: number;
  data: any;
  selectedBarCategory: Option[];
  [x: string]: any;
}) {
  // Process Props
  const {
    onBarClick,
    hideLeftAxis = true,
    forecastProps,
    timezone,
    hideTotalTrafficData = false,
  } = optionals;
  const {
    mainData,
    forecast,
    // subData,
    // subForecast,
    isData,
  } = data || { mainData: [], forecast: [], subData: [] };
  const { appState } = useAppState();
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );
  /**
   * inner chart size
   */
  const barsGroupSize = {
    width: width - GRAPH_PADDING_FOR_BARS.x,
    height: height - GRAPH_PADDING_FOR_BARS.y,
  };

  // Hooks
  // const isLg = useMediaQueries('lg');
  // const { customerTimezone } = useCustomerDataOfDefaultUser();
  // console.debug('VisitorTrafficGraph data', forecast);

  // Processed Props
  // bounds -> determined by the view size
  const { xRange, yRange } = createXYBounds(
    width,
    height,
    GRAPH_PADDING_FOR_BARS
  );
  const yMax = yRange[1];

  // scales
  // X scale
  const timeScaleDomain = getTimeScaleDomain(
    mainData,
    forecast,
    forecastProps?.shouldDisplay
  );
  const timeScale = scaleTime({
    domain: timeScaleDomain,
    range: xRange,
    clamp: true,
  });

  /**
   * scale by time
   */
  const singleBarGroupWidth = useMemo(() => {
    const displayedDateList = getDisplayedDateList(
      mainData,
      forecast,
      forecastProps?.shouldDisplay
    );

    return getSingleBarWidth(displayedDateList, timeScale);
  }, [mainData, forecast, forecastProps?.shouldDisplay, timeScale]);

  // Dependencies on state
  const yValueScale = useMemo(() => {
    return getVisitorTrafficScale(
      data,
      selectedBarCategory,
      forecastProps?.shouldDisplay,
      yRange,
      currentGraphType,
      hideTotalTrafficData
    );
  }, [
    data,
    selectedBarCategory,
    forecastProps?.shouldDisplay,
    yRange,
    currentGraphType,
    hideTotalTrafficData,
  ]);

  const currentTimeLeftOffset = singleBarGroupWidth + 1;

  if (width < 80) {
    return <Typography>Please view the graph on a larger screen</Typography>;
  }

  if (!isData) {
    return <NoDataView />;
  }

  const scales = { yValueScale, timeScale, valueScale: yValueScale };
  const layoutProps = {
    height,
    width,
    barsGroupSize,
    yMax,
    xRange,
    yRange,
    singleBarGroupWidth,
  };
  const showCurrentTimeLine = checkIsTodayData(mainData, timezone);
  const hasMultiDays = hasMultiDaysData(mainData);
  const dateTooltipFormatString = hasMultiDays ? "MMMM dd" : "h:mmaaa";

  // return null;
  return (
    <ErrorBoundary>
      <BarChartWithStackedForecastAndPasserbys
        data={data}
        onBarClick={onBarClick}
        selectedBarCategory={selectedBarCategory}
        hideLeftAxis={hideLeftAxis}
        layoutProps={layoutProps}
        scales={scales}
        showForecast={forecastProps?.shouldDisplay}
        timezone={timezone}
        alignXTicksToCenterOfBar
        currentTimeProps={{
          showLine: showCurrentTimeLine,
          currentTimeLeftOffset,
        }}
        tooltipProps={{
          dateTooltipFormatString,
        }}
        averageLineProps={{
          showAverageLine: true,
        }}
        hideTotalTrafficData={hideTotalTrafficData}
      />
    </ErrorBoundary>
  );
}
