import { useState } from "react";
import styled from "styled-components";
import { mediaQueries } from "styles/themes";
// import { SHOULD_USE_OLD_HEATMAP_CIRCLE } from 'lib';
// import { getHeatGradientString } from './processors';
import { getOppositeDirection, PositionDirection } from "features/coordinates";
import { DOT_COLORS, SHOW_NUMBERS_ON_ZONE_DOT } from "./constants";

const InnerDot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin: 3px auto;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  &:hover {
    transform: scale(1.4);
  }
`;

/**
 *
 * @param num string of number, e.g. '10', '20', '30'
 * @returns
 */
export function createColor(num: string) {
  return DOT_COLORS[num].substring(0, DOT_COLORS[num].length - 2);
}

// const ColoredInnerDot = styled(InnerDot)<{color: string}>`
//     background: ${(p) => createColor(p.color)};
// `;

const HollowInnerContainer = styled(InnerDot)`
  color: black;
  background: rgba(255, 255, 255, 0.35);
  font-size: 1.3rem;
`;

// export function Dot({
//   name,
//   coords = { x: 10, y: 10 },
//   onClick,
//   number,
//   color,
// }: {
//   name: string;
//   coords?: { x: number; y: number };
//   onClick?: () => void;
//   number: number;
//   [x: string]: any;
// }) {
//   const [isHover, setIsHover] = useState(false);
//   const displayedNumber = isNaN(number) || number === undefined ? 'N/A' : number;

//   return (
//     <>
//       <Wrapper
//         className='rounded-circle'
//         top={coords.y}
//         left={coords.x}
//         onClick={onClick}
//         color={color}
//         onMouseEnter={() => setIsHover(true)}
//         onMouseLeave={() => setIsHover(false)}
//         isHover={isHover}
//       >
//         {SHOULD_USE_OLD_HEATMAP_CIRCLE ? <HeatCircle level={number} /> : null}
//         <div
//           className='outer rounded-circle'
//         >
//           <div className='inner-dot rounded-circle'>
//             {SHOW_NUMBERS_ON_ZONE_DOT ? displayedNumber : null}
//           </div>
//         </div>
//         <DeviceNameChip placmentIsRight={coords.x > 70}  >{name}</DeviceNameChip>
//       </Wrapper>
//     </>
//   );
// }

export function HollowDot({
  name,
  coords = { x: 10, y: 10 },
  onClick,
  number,
  color,
  ...optionals
}: {
  name: string;
  coords?: { x: number; y: number };
  onClick?: () => void;
  number: number;
  [x: string]: any;
}) {
  // Local state
  const [isHover, setIsHover] = useState(false);

  // Process props
  const { labelDirection = "right" as PositionDirection } = optionals;
  const displayedNumber =
    isNaN(number) || number === undefined ? "N/A" : number;
  const { x, y } = coords || {};

  return (
    <>
      <Wrapper
        top={y}
        left={x}
        onClick={onClick}
        color={color}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        isHover={isHover}
      >
        {/* {SHOULD_USE_OLD_HEATMAP_CIRCLE ? <HeatCircle level={number} /> : null} */}
        <HollowInnerContainer className="outer rounded-circle">
          {SHOW_NUMBERS_ON_ZONE_DOT ? displayedNumber : null}
        </HollowInnerContainer>
        <DeviceNameChip labelDirection={labelDirection}>{name}</DeviceNameChip>
      </Wrapper>
    </>
  );
}

const DeviceNameChip = styled.div<{ labelDirection: PositionDirection }>`
  position: absolute;
  ${({ labelDirection }) =>
    labelDirection &&
    `
    ${getOppositeDirection(labelDirection)}: 60px;
    ${
      labelDirection === "top" || labelDirection === "bottom"
        ? "transform: translateX(-40%);"
        : ""
    } 
  `}
  background-color: #fff;
  padding: 6px 16px;
  border-radius: 8px;
  white-space: nowrap;
  border: 1px solid #efefef;
  height: fit-content;
`;

const Wrapper = styled.div<{
  top: number;
  left: number;
  color: string;
  isHover: boolean;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  top: ${(p) => `${p.top}%`};
  left: ${(p) => `${p.left}%`};
  transform: scale(0.8);
  z-index: ${(p) => (p.isHover ? "2" : "1")};
  ${mediaQueries("md")`
    transform: scale(0.8);
  `};

  ${mediaQueries("lg")`
    transform: scale(1);
  `};

  .outer {
    height: 50px;
    width: 50px;
    border: solid 2px ${(p) => createColor(p.color)};
    transition: all 0.2s ease-in-out;
  }

  .inner-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background: ${(p) => createColor(p.color)};
    margin: 3px auto;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &:hover {
      transform: scale(1.4);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    }

    70% {
      box-shadow: 0 0 0 300px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;
