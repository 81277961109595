// Breakpoints for responsive design
// breakpoints from tailwindcss are also used https://tailwindcss.com/docs/responsive-design
import { useMedia } from 'react-use';

export type ScreenSize =
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl';

export const BREAK_POINTS = {
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1792,
  '4xl': 2048,
};

export const mediaQueries = (key: keyof typeof BREAK_POINTS) => {
  return (style: TemplateStringsArray | String) =>
    `@media (min-width: ${BREAK_POINTS[key]}px) { ${style} }`;
};

export const mediaQueriesByMaxWidth = (key: keyof typeof BREAK_POINTS) => {
  return (style: TemplateStringsArray | String) =>
    `@media (max-width: ${BREAK_POINTS[key]}px) { ${style} }`;
};

/**
 * use breakpoints for responsive design
 */
export function useMediaQueries(screenSize: ScreenSize) {
  return useMedia(`(min-width: ${BREAK_POINTS[screenSize]}px)`);
}
