import axios from "axios";
import { getAuthTokenFromLocalStorage } from "features/auth";
import { handle } from "utils";
import { getErrorMessageInResponse, handleAxiosResultData } from "../processor";
import { DEVICE_CONFIG_API } from "./api";
import { KaiduDeviceConfiguration } from "./types";

/**
 *
 */
export async function fetchDeviceConfigurationList(): Promise<
  Array<KaiduDeviceConfiguration>
> {
  const [result, resultErr] = await handle(axios.get(DEVICE_CONFIG_API));
  if (resultErr) {
    console.error(`fetchAllConfiguration failed: ${resultErr.message}`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

/**
 * @description fetch configuration data from Server API
 * @param  {string} mac
 * @returns Promise
 */
export async function fetchConfigurationByMAC(
  mac: string
): Promise<KaiduDeviceConfiguration> {
  console.log(`fetchConfigurationByMAC is called`);
  const url = `${DEVICE_CONFIG_API}/${encodeURIComponent(mac)}`;
  console.log(`fetchConfigurationByMAC URL: ${url}`);
  //send then request n times
  let n = 3;
  let result;
  let resultErr;
  while (!result && n > 0) {
    [result, resultErr] = await handle(axios.get(url));
    n--;
  }

  if (!result) {
    console.error(`fetchConfigurationByMAC failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

export async function fetchDevices(): Promise<KaiduDeviceConfiguration[]> {
  const token = getAuthTokenFromLocalStorage();
  const result = await axios.get(DEVICE_CONFIG_API, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
}

export async function fetchDevicesByCustomer(
  customer_id: string,
  startDate?: string,
  endDate?: string
): Promise<KaiduDeviceConfiguration[]> {
  const token = getAuthTokenFromLocalStorage();
  const api_url = `${DEVICE_CONFIG_API}/customer/${customer_id}`;
  const result = await axios.get(api_url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { startDate, endDate },
  });

  return result.data;
}
