import { Icon } from "components/atomic/Icon";
import { Spinner } from "components/atomic/Spinner";
import { ErrorMsg } from "components/molecule/ErrorMsg";
import styled from "styled-components";
import { mediaQueries } from "styles/themes/breakpoints";
import "../../atomic/Icons";
import { Heading1 } from "../../atomic/Typography";
import { ContentContainer } from "./ContentContainer";

const LargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.darkGrey};
  max-width: 100%;

  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 8px;
  /* background: white; */
  margin: 18px 0;
  padding: 0 12px;

  .header {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
  }

  .icon {
    font-size: 24px;
    color: ${(props) => props.theme.colors.secondary};
    ${mediaQueries("lg")`
      font-size: 36px;
  `};
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    margin: 0;
  }

  /* .children {
    color: ${(props) => props.theme.colors.black};
    padding: 8px;
    ${mediaQueries("md")`
      padding: 28px;
    `};
  } */
`;

type ContainerProps = {
  title?: string;
  icon: string;
  className?: string;
  children?: React.ReactNode | string;
  isLoading?: boolean;
  error?: any;
  isData?: boolean;
  iconColor?: string;
  header?: string;
  headerChildren?: React.ReactNode;
  [x: string]: any;
};

export function HeaderContainer({ children, ...rest }) {
  return (
    <div
      className="header w-full flex flex-col md:flex-row gap-x-6 justify-between lg:min-h-min lg:flex-row p-3 lg:p-6"
      {...rest}
    >
      {children}
    </div>
  );
}

/**
 *
 */
export function LargeContainer({
  title,
  header,
  icon,
  children,
  error,
  ...optionals
}: ContainerProps) {
  const {
    className = "",
    isLoading = false,
    headerChildren,
    contentClassName = "flex-1 flex flex-col md:p-3",
    hiddenHeader = false,
    ...rest
  } = optionals;

  // const isLg = useMediaQueries('lg');

  const renderChildren = () => {
    if (error) {
      return <ErrorMsg text={error?.message || "Data Unavailable"}></ErrorMsg>;
    }

    if (isLoading) {
      return <Spinner className="flex-1 flex-grow" />;
    }

    return <>{children}</>;
  };

  return (
    <LargeWrapper className={className} {...rest}>
      {!hiddenHeader && (
        <HeaderContainer>
          <div className="flex items-center">
            <Icon className="icon " name={icon} />
            <div className="ml-4">
              <Heading1 className="text-lg md:text-2xl font-bold mb-0">
                {title}
              </Heading1>
              {header && <h2>{header}</h2>}
            </div>
          </div>
          {headerChildren ? (
            <div className="flex h-full justify-center md:justify-start">
              {headerChildren}
            </div>
          ) : null}
        </HeaderContainer>
      )}

      <ContentContainer className={contentClassName}>
        {renderChildren()}
      </ContentContainer>
    </LargeWrapper>
  );
}
