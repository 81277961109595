import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { notifyError } from '../lib/services/notification';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      staleTime: 60 * 1000 * 5, // 5 minutes
      refetchInterval: 60 * 1000 * 5, // 5 minutes
    },
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      const url = error?.config?.url;
      if (query?.state?.data === undefined) {
        const msg = `${error?.message} ${url}`;
        notifyError(msg);
      }
    }
  }),
});

export function ReactQueryProvider({ children }) {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
      </QueryClientProvider>
    </>
  );
}
