import { getTimeZones } from "@vvo/tzdb";
import { addMilliseconds, isSameDay } from "date-fns";
import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";
import _, { truncate } from "lodash";
import { DateTime } from "luxon";
import { UnixtimeSelectedDate } from "../../types";
import { createDateByUnixTime } from "../foo";
import { format } from "../format";

/**
 * format Date object to give time zone;
 * default format: Jan 5th 2023
 */
export function formatDateByTimezone(
  date: Date,
  timezone?: string,
  formatString = "LLL dd yyyy"
): string {
  // use luxon
  if (!timezone) {
    return DateTime.fromJSDate(date).toFormat(formatString);
  }
  const dateTimeObj = DateTime.fromJSDate(date);
  const timezoned = dateTimeObj.setZone(timezone);
  // console.log("file: foo.ts:26 ~ dateTimeObj:", dateTimeObj.toString());
  // console.log("file: foo.ts:27 ~ timezoned:", timezoned.toString());

  const result = timezoned.toFormat(formatString);
  // console.log("file: foo.ts:22 ~ date:", date);
  // console.log("file: foo.ts:22 ~ timezone:", timezone);
  // console.log("file: foo.ts:22 ~ result:", result);

  // use date-fns-tz
  // formatString = 'LLL do yyyy'
  // const result = timezone
  //   ? formatInTimeZone(date, timezone, formatString)
  //   : format(date, formatString);
  return result;
}

/**
 *
 */
export function formatSelectedDateText(
  selectedDate: UnixtimeSelectedDate,
  timezone?: string,
  formatString = "LLL dd yyyy"
): string {
  const { startDate, endDate } = selectedDate || {};
  const endDateObj: Date = createDateByUnixTime(endDate);
  const endDateText = formatDateByTimezone(endDateObj, timezone, formatString);

  if (!startDate) {
    return endDateText;
  } else {
    const startDateObj: Date = createDateByUnixTime(startDate);
    const startDateText = formatDateByTimezone(
      startDateObj,
      timezone,
      formatString
    );
    if (isSameDay(startDateObj, endDateObj)) {
      return endDateText;
    } else {
      return `${startDateText} - ${endDateText}`;
    }
  }
}

/**
 * when selected day is a single day return the hours, e.g. 1pm, 2pm, 3pm, ...
 */
export function formatDateStringToText(
  date: string,
  formatString: string,
  timezone?: string
) {
  if (!date) {
    // return 'Invalid Date';
    return;
  }
  const result = timezone
    ? formatInTimeZone(new Date(date), timezone, formatString)
    : format(new Date(date), formatString);
  // console.debug('formatDate result', result);
  return result;
}

export function getOffsetOfTimezone(timezone: string) {
  return getTimezoneOffset(timezone);
}

export function removeRFCTimezone(originalDate: string): string {
  const timestampWithoutTimezone = originalDate?.substring(
    0,
    originalDate?.length - 6
  );
  return timestampWithoutTimezone;
}

/**
 * @returns {Date} a new date with the corresponding time at the given time zone, while the time zone part remains to be the local time
 * e.g. local date: 2022-01-01T12:00:00+08:00, timezone: -4, return 2022-01-01T12:00:00-04:00 (equals 2022-01-02T00:00:00+08:00)
 * e.g. local date: 2022-01-01T15:00:00+03:00, timezone: -4, return 2022-01-01T15:00:00-04:00
 * e.g. local date: 2022-01-01T06:00:00+08:00, timezone: -4, return 2022-01-01T06:00:00-04:00
 */
export function updateLocalDateByTimezone(date: Date, timezone: string): Date {
  // console.log("file: foo.ts:97 ~ updateLocalDateByTimezone ~ timezone", timezone)

  const timezoneOffset = getTimezoneOffset(timezone);
  const localTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  // console.debug('timezoneOffset', timezoneOffset);
  // console.debug('localTimezoneOffset', localTimezoneOffset);
  const nextDate = addMilliseconds(date, localTimezoneOffset - timezoneOffset);
  const result = nextDate;
  // console.debug('nextDate', nextDate);
  return result;
}

/**
 *
 * @returns e.g. '+07:00'
 */
export function getOffsetStringOfTimezone(timezone: string): string {
  const timezoneOffset = getTimezoneOffset(timezone);
  const hours = Math.floor(timezoneOffset / 3600 / 1000);
  const minutes = Math.floor((timezoneOffset % 3600) / 60);
  const result = `${hours >= 0 ? "+" : "-"}${String(Math.abs(hours)).padStart(
    2,
    "0"
  )}:${String(minutes).padStart(2, "0")}`;
  return result;
}

export function modifyTimezoneOfDateString(
  dateString: string,
  targetTimezone: string
): string {
  // set the time zone part of a RFC date string to the target timezone string
  // const date = new Date(dateString);
  // const dateInTargetTimezone = updateLocalDateByTimezone(date, targetTimezone);
  // const result = formatInTimeZone(dateInTargetTimezone, targetTimezone, 'yyyy-MM-dd$HH:mm:ssXXX');
  // return result?.replaceAll('$', 'T');
  const reg = /(.{0,6})$/;
  const offsetString = getOffsetStringOfTimezone(targetTimezone);
  const result = _.replace(dateString, reg, offsetString);
  return result;
}

/**
 * e.g. 'America/Toronto'
 */
export function getLocalTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * return a readable string to let users to understand the timezone, e.g. +11:00 Casey Time - Casey
 */
export function formatTimezone(timezone: string): string {
  const timeZones = getTimeZones();
  const timeZoneObj = timeZones.find((timeZone) => {
    return timezone === timeZone.name || timeZone.group.includes(timezone);
  });

  const formatted1 = truncate(timeZoneObj.currentTimeFormat, {
    separator: "-",
    omission: "",
  });
  return formatted1;
}

/**
 * return a readable string to let users to understand the timezone, e.g. +11:00 Casey Time - Casey
 */
export function formatTimezoneInShort(timezone: string): string {
  const timeZones = getTimeZones();
  const timeZoneObj = timeZones.find((timeZone) => {
    return timezone === timeZone.name || timeZone.group.includes(timezone);
  });

  const result = `${
    timeZoneObj.alternativeName
  } (GMT${timeZoneObj.currentTimeFormat.slice(0, 6)})`;
  return result;
}

/**
 * Check if DST (Daylight Saving Time) is in Effect for a timezone
 */
export function checkIsDSTInEffect(date = new Date(), timezone) {
  const isDate1InDST = DateTime.fromJSDate(date).setZone(timezone).isInDST;
  return isDate1InDST;
}

/**
 * return if the two dates are in different DST (Daylight Saving Time)
 */
export function checkIsDSTChanged(date1: Date, date2: Date, timezone: string) {
  // const isDate1InDST = DateTime.fromISO(date1).setZone(timezone).isInDST;
  // const isDate2InDST = DateTime.fromISO(date2).setZone(timezone).isInDST;
  const isDate1InDST = DateTime.fromJSDate(date1).setZone(timezone).isInDST;
  const isDate2InDST = DateTime.fromJSDate(date2).setZone(timezone).isInDST;
  return isDate1InDST !== isDate2InDST;
}
