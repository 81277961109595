// import styled from 'styled-components';
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useMeasure } from "react-use";

import { LegendsGroup } from "domain/visitor-traffic/VisitorTrafficGraph/components/LegendsGroup";
// import { VisitorTrafficGraph } from 'domain/visitor-traffic/VisitorTrafficGraph';
import { LargeContainer } from "components/molecule/Containers/LargeContainer";
import { getDataKeysFromStatsServer } from "features/kaidu-stats-server";
// import { TrafficGraphUIData } from 'features/charts';
// import { KaiduHourlyChart } from 'components/features/charts';
import { Heading3 } from "components/atomic/Typography";
import { SecondaryDateRangeIndicator } from "domain/time-range/date-range/components/DateRangeIndicator";
import { VisitorTrafficBarChart } from "domain/visitor-traffic/VisitorTrafficGraph/VisitorTrafficBarChart";
import { ErrorBoundary } from "features/error-handling";
import { useAppState } from "hooks";
import { useMediaQueries } from "styles/themes";
import { TrafficGraphTypes } from "types";
import { isFilledArray } from "utils";
import { HistoricalTrafficGroup } from "../../../domain/visitor-traffic/HistoricalTrafficGroup";
import { selectShouldDisplayForecast } from "../../../providers/redux/globalSelectSlice";

const TOP_GRAPHS_HEIGHT = 420;

/**
 * A group of different Visitor Traffic graphs
 */
export function HomePageVisitorTrafficGroup({
  isLoading,
  onBarClick,
  selectedDate,
  monthlyProps,
  weeklyProps,
  customerID,
  ...optionals
}) {
  // console.debug('VisitorTrafficGraph forecast data', forecast);
  // Process Props
  const { timezone, onChangeDate, selectedDevices } = optionals;
  // const { hourRange, errors, timezone, ...rest } = optionals;
  const { onClick: onClickMonthly } = monthlyProps || {};
  const { enableBarClick, data: weeklyData } = weeklyProps || {};
  const { subData, isData, forecast } = weeklyData || {
    mainData: [],
    forecast: undefined,
    subData: [],
    isData: false,
  };
  const availableSubBarProps = getDataKeysFromStatsServer(subData);

  //Hooks
  const [ref, { width }] = useMeasure();
  const isMedium = useMediaQueries("sm");

  // Global state
  const shouldDisplayForecast = useSelector(selectShouldDisplayForecast);
  const { appState } = useAppState();
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );

  // Local state
  const [selectedBarCategory, setSelectedBarCategory] = useState([]);

  const isError = !weeklyData && !isLoading;

  /**
   * isFetched, can be fetched, should be shown
   */
  const forecastProps = {
    showForecast: shouldDisplayForecast,
    isFetchable: true, // for home page, it's fetchable
    isFetched: Boolean(forecast),
    isAvailable: isFilledArray(forecast),
    shouldDisplay: shouldDisplayForecast,
    forecastData: forecast,
  };
  // const monthlyChartData = monthlyData?.graph?.data;

  return (
    <LargeContainer
      icon=""
      isLoading={isLoading}
      error={isError}
      className="min-w-min flex-1 relative max-w-full"
      data-cy={"statistical-graph-visitor-traffic-graph-container"}
      headerChildren={
        <SecondaryDateRangeIndicator
          dateRange={{ ...selectedDate }}
          showNavigation={Boolean(onChangeDate)}
          onChangeDate={onChangeDate}
        />
      }
      style={{
        boxShadow: isMedium ? "5px -5px 5px 0px rgba(0,0,0,0.2)" : "unset",
      }}
    >
      <ErrorBoundary>
        <div>
          <div className="mt-2 max-w-full flex flex-col lg:flex-row">
            <div className="flex-1" ref={ref}>
              <Heading3>Daily Traffic Score</Heading3>
              <VisitorTrafficBarChart
                width={width * 0.99}
                height={TOP_GRAPHS_HEIGHT}
                data={weeklyData}
                hideLeftAxis={false}
                onBarClick={enableBarClick ? onBarClick : null}
                selectedBarCategory={
                  selectedBarCategory.length
                    ? selectedBarCategory
                    : selectedDevices.map(({ device_id, device_name }) => ({
                        value: `${device_id}_${currentGraphType}`,
                        label: device_name,
                      }))
                }
                showForecast={forecastProps.shouldDisplay}
                forecastProps={forecastProps}
                timezone={timezone}
                hideTotalTrafficData={
                  !!selectedDevices.length && !selectedBarCategory.length
                }
              />
            </div>
          </div>
          <LegendsGroup
            selected={selectedBarCategory}
            setSelected={setSelectedBarCategory}
            selectableProps={availableSubBarProps}
            isData={isData}
            forecastProps={forecastProps}
            dateRange={selectedDate}
          />
          <div className="mt-8">
            <ErrorBoundary>
              <HistoricalTrafficGroup
                customerID={customerID}
                endDate={selectedDate?.endDate}
                onClickItem={onClickMonthly}
                timezone={timezone}
                onChangeDate={onChangeDate}
              />
            </ErrorBoundary>
          </div>
        </div>
      </ErrorBoundary>
    </LargeContainer>
  );
}
