export const heatColors = {
  0: 'rgba(1, 157, 254, 0.1)',
  1: 'rgba(98, 183, 219, 0.4)',
  10: 'rgba(98, 207, 219, 0.6)',
  20: 'rgba(98, 219, 122, 0.7)',
  30: 'rgba(173, 218, 39, 0.75)',
  40: 'rgba(255, 239, 59, 0.8)',
  50: 'rgba(253, 197, 12, 0.8)',
  60: 'rgba(253, 177, 12, 0.8)',
  80: '	rgba(243, 132, 63, 0.85)',
  90: 'rgba(237, 86, 60, 0.9)',
  100: 'rgba(233, 58, 58, 0.95)',
};

export const colors = {
  danger: ['#00319c', '#00319c37'],
  warning: ['#ffd147', '#ffed4724'],
  normal: ['#a7c2fc', '#00319c13'],
  disabled: ['#00319c', '#00319c37'],
};

export const DOT_COLORS = {
  0: '#05009c2d',
  20: '#36007d2d',
  40: '#66005f2d',
  50: '#81004f2d',
  60: '#9c003e2d',
  80: '#ce001f2d',
  90: '#eb000c2d',
  100: '#ff00002d',
};

export const heatCircleSize = 18;

export const SHOW_NUMBERS_ON_ZONE_DOT = true;