import { convertHourNumToString, getLocalTimeZone } from 'features/date';
import _ from 'lodash';

function getHourTimeString(input: string | number): string {
  if (typeof input === 'number') {
    return convertHourNumToString(input);
  }
  return input;
}

/**
 * parse hours of operations
 */
export function getHoursOfOperations(customer): {
  start: string;
  end: string;
  timezone: string;
} {
  const hoursOfOperation = _.get(customer, 'customer_config.hoursOfOperation');
  const { start, end, timezone } = hoursOfOperation || {};

  const initialValues = {
    start: getHourTimeString(start),
    end: getHourTimeString(end),
    timezone: timezone ?? getLocalTimeZone(),
  };

  return initialValues;
}
