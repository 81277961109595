import { formatInTimeZone } from 'date-fns-tz';
import { UnixtimeSelectedDate } from '../types';
import { createDateByUnixTime, parseString } from './foo';
import { getUnixTimeByDate } from './getters';
import { format } from './format';
import { isSameDay } from 'date-fns';
import {
  convertDateToTimezonedRFC3339AtStartOfDay,
  convertDateToTimezonedRFC3339AtEndOfDay,
} from './convertor';

/**
 * format with and without timezone; default format: e.g. Sep 14th, Wed
 * 
 */
export function formatDate(
  date: Date | number,
  timezone?: string,
  formatString: string = 'LLL do yyyy'
): string {
  return timezone
    ? formatInTimeZone(date, timezone, formatString)
    : format(date, formatString);
}

/**
 *  format endDate and startDate, ignore startDate if it's missing
 */
export function formatDateRange(
  selectedDate: UnixtimeSelectedDate,
  formatString: string = 'LLL do, E' // e.g. Tue, Jan 17th
): string {
  // console.log("file: date-range.ts:32 ~ selectedDate", selectedDate);
  
  const { startDate, endDate, timezone } = selectedDate || {};
  const endDateObj: Date = createDateByUnixTime(endDate);

  if (!startDate) {
    return formatDate(endDateObj, timezone, formatString);
  } else {
    const startDateObj: Date = createDateByUnixTime(startDate);
    if (isSameDay(startDateObj, endDateObj)) {
      return formatDate(endDateObj, timezone, formatString);
    } else {
      return `${formatDate(
        startDateObj,
        timezone,
        formatString
      )} - ${formatDate(endDateObj, timezone, formatString)}`;
    }
  }
}

export function formatDateRangeWithoutTimezone(
  selectedDate: UnixtimeSelectedDate,
  timezone: string,
  formatString: string = 'LLL do, E' // e.g. Tue, Jan 17th
): string {
  // console.log("file: date-range.ts:32 ~ selectedDate", selectedDate);
  
  const { startDate, endDate } = selectedDate || {};
  const endDateObj: Date = createDateByUnixTime(endDate);

  if (!startDate) {
    return formatDate(endDateObj, timezone, formatString);
  } else {
    const startDateObj: Date = createDateByUnixTime(startDate);
    if (isSameDay(startDateObj, endDateObj)) {
      return formatDate(endDateObj, timezone, formatString);
    } else {
      return `${formatDate(
        startDateObj,
        timezone,
        formatString
      )} - ${formatDate(endDateObj, timezone, formatString)}`;
    }
  }
}

export function createDateRange(
  input: [string, string],
  timezone?: string
): UnixtimeSelectedDate {
  const [start, end] = input;
  const startDate = getUnixTimeByDate(parseString(start));
  const endDate = getUnixTimeByDate(parseString(end));
  return { startDate, endDate, timezone };
}

/**
 * 
 */
export function extendDateToAWholeDay(
  date: Date,
  timezone: string
): [Date, Date] {
  // console.log("file: date-range.ts:59 ~ extendDateToAWholeDay ~ timezone", date, timezone);
  const start = convertDateToTimezonedRFC3339AtStartOfDay(date, timezone);
  const end = convertDateToTimezonedRFC3339AtEndOfDay(date, timezone);

  return [parseString(start), parseString(end)];
}

export function extendDateStringToAWholeDay(
  date: string,
  timezone: string
): [Date, Date] {
  const start = convertDateToTimezonedRFC3339AtStartOfDay(
    parseString(date),
    timezone
  );
  const end = convertDateToTimezonedRFC3339AtEndOfDay(
    parseString(date),
    timezone
  );

  return [parseString(start), parseString(end)];
}

export function includesToday() {}
