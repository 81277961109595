import Form from 'react-bootstrap/Form';
import MuiCheckbox from '@mui/material/Checkbox';

export function Check(props) {
  return (
    <Form.Check {...props} />
  )
}

export function Checkbox(props) {
  return (
    <MuiCheckbox {...props}/>
  )
}