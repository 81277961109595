import { selectSelectedDate } from 'providers/redux/globalSelectSlice';
import { useSelector } from 'react-redux';
import { formatSelectedDateText, UnixtimeSelectedDate } from 'features/date';
// import styled from 'styled-components';
import { useCustomerDataOfDefaultUser } from 'features/kaidu-config-server';
import { ErrorBoundary } from 'features/error-handling';
import { GrayTypography } from 'components/atomic/Typography';

/**
 * Formated Text of selected dates in global state 
 */
export function SelectedDateText() {
  // Hooks
  const { customerTimezone } = useCustomerDataOfDefaultUser();

  // Global states
  const { startDate, endDate } = useSelector(selectSelectedDate) || {};

  return (
    <DateText timezone={customerTimezone} startDate={startDate} endDate={endDate} />
  );
}

/**
 * Not linked with global state
 * { startDate: number; endDate: number; customerTimezone: string }
 */
export function DateText({ startDate, endDate, timezone, ...optionals }: UnixtimeSelectedDate & any) {
  const text = formatSelectedDateText({ startDate, endDate }, timezone);

  return (
    <ErrorBoundary>
      <GrayTypography className='text-base mb-0' {...optionals}>{text}</GrayTypography>
    </ErrorBoundary>
  );
}
