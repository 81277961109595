import { Input2 } from "components/atomic/Input";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
// import styled from 'styled-components';

/**
 *
 */
export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  placeholder,
}: any) {
  // Local states
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((changedValue: any) => {
    setGlobalFilter(changedValue || undefined);
  }, 200);

  // console.debug('palceholder', placeholder);

  const handleChange = (e) => {
    const nextValue = e?.target?.value;
    setValue(nextValue);
    onChange(nextValue);
  };

  return (
    <Input2
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className="my-1 max-w-sm text-lg"
    />
  );

  // return (
  //   <InputGroup className='my-2 max-w-sm' size='lg'>
  //   <FormControl
  //     value={value}
  //     onChange={handleChange}
  //     placeholder={placeholder}
  //     className='my-2 max-w-sm'
  //   />
  //   </InputGroup>
  // );
}
