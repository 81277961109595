import "react-datepicker/dist/react-datepicker.css";
import "./overwrite.css";
import ReactDatePicker from "react-datepicker"; //https://reactdatepicker.com/
import styled from "styled-components";

export const BaseStyledDatePicker = styled(ReactDatePicker)`
  font-size: 24px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.darkGrey};
  /* width: 100px; */
  &:hover {
    color: ${(p) => p.theme.colors.grey};
  }
`;

const MIN_DATE = new Date(2020, 12, 31);

/**
 * picked Date is the Date in the picked day with the same hour, minute, second as for now in local time
 */
export function DatePicker(props) {
  const { minDate = MIN_DATE, ...rest } = props;
  return <BaseStyledDatePicker minDate={minDate} {...rest} />;
}
