import { useMachine } from "@xstate/react";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { createMachine } from "xstate";

import { Spinner } from "components/atomic/Spinner";
import {
  getDecodeAuthTokenFromLocalStorage,
  logout,
  setTokenToLocalStorage,
} from "features/auth";
import { setAxiosDefault } from "features/axios";
import { fetchCustomer, fetchUsersList } from "features/kaidu-config-server";
import { useAppState } from "hooks";
import {
  selectPartnerCustomer,
  setCurrentUser,
  updateSelectedCustomer,
} from "providers/redux/globalSelectSlice";
import { UserState } from "providers/state/reducers";

export type StorageProps = {
  token: string;
};

/**
 * get token from url, either from query string or path segment
 */
function getTokenFrom(location, match) {
  // console.log("file: LoginSuccess.tsx:16 ~ getTokenFrom", location, match);
  const { search } = location || {};
  const parsed = queryString.parse(search) || {};
  const { params } = match || {};
  const { token } = params || {};
  return token || parsed?.token;
}

async function checkIsUserValid() {
  // fetch user
  const users = await fetchUsersList();
  // fetch customer
  const customer = await fetchCustomer(users?.customers_list_id);

  let error;
  if (!users) error = "You're not allowed to access this dashboard";
  // if (!users) error = UNREGISTERED_USER_ERROR_MESSAGE;
  else if (users && !customer) error = "Customer not found";
  return { user: users, customer, error };
}

const loginSuccessMachine = createMachine(
  {
    id: "loginSuccess",
    initial: "unchecked",
    states: {
      unchecked: {
        on: {
          FOUND_TOKEN: { target: "checkIsUserValid", actions: "SET_TOKEN" },
          TOKEN_NOT_FOUND: "failure",
        },
      },
      checkIsUserValid: {
        invoke: {
          src: () => checkIsUserValid(),
          onDone: [
            {
              target: "final",
              cond: (context, event) => {
                const { customer } = event?.data || {};
                return Boolean(customer?.customer_id);
              },
            },
            {
              target: "failure",
            },
          ],
          onError: "failure",
        },
      },
      final: {},
      failure: {},
    },
  },
  {
    actions: {
      SET_TOKEN: (context, event) => {
        const { token, partnerCustomer } = event?.data || {};
        // update axios token if it's authenticated when redirect to LoginSuccess
        const { customer_id } = partnerCustomer || {};
        setAxiosDefault(token, customer_id);
        setTokenToLocalStorage(token);
      },
    },
  }
);

/**
 * Handle login success result from server
 */
export function LoginSuccess(props: any) {
  const { match } = props || {};
  // const isAuthInLocalStorageValid = checkIsAuthInLocalStorageValid();

  // hooks
  const dispatch = useDispatch();
  const { userDispatch } = useAppState();
  const location = useLocation();
  let history = useHistory();
  const [state, send] = useMachine(loginSuccessMachine);
  const partnerCustomer = useSelector(selectPartnerCustomer);

  const token = getTokenFrom(location, match);

  useEffect(() => {
    if (token) {
      send({ type: "FOUND_TOKEN", data: { token, partnerCustomer } });
    } else {
      send("TOKEN_NOT_FOUND");
    }
  }, [send, token, partnerCustomer]);

  if (state.matches("final")) {
    console.debug("final state on login success");
    const { user, customer } = state.event.data;

    const { user: authUser } = getDecodeAuthTokenFromLocalStorage() || {};
    if (authUser) {
      const newUserData: UserState = {
        isAuth: user.isActive,
        userId: user.user_id,
        username: user.user_name,
        isSuper: user.isSuperUser,
        isPartner: user.is_partner || false,
        customerId: user.customers_list_id,
        userPicture: authUser?.picture,
        allowed_customer_ids: user?.allowed_customer_ids,
        token,
      };
      dispatch(setCurrentUser(user));
      dispatch(updateSelectedCustomer(customer));
      userDispatch({ type: "UPDATE_USER", payload: newUserData });

      history.push("/");
    }

    // if (
    //   partnerCustomer &&
    //   !user.isSuperUser &&
    //   partnerCustomer.customer_id !== user.partner_customer_id
    // ) {
    //   notifyError(
    //     `No access to this ${partnerCustomer.customer_name} dashboard`
    //   );
    //   history.push("/login?customer=" + partnerCustomer.customer_name);
    // } else {
    // }
  } else if (state.matches("failure")) {
    const { error } = state.event.data || {};
    logout();
    const partner_customer_name = partnerCustomer?.customer_name;
    const failedURL = `/login/failure${
      partner_customer_name
        ? `?customer=${encodeURI(partner_customer_name)}`
        : ""
    }`;
    // console.log({ failedURL, error });
    history.push(failedURL, { error });
  }

  return <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} />;
}

export default LoginSuccess;
