import { NoData } from 'components/features/Table/NoData';
import { GlobalFilter } from 'components/features/Table/GlobalFilter';
import { UserCard } from './UserCard';
import { PaginationBtnGroup } from 'components/features/Table/PaginationBtnGroup';

/**
 *
 */
export function UserCardList({
  hasData,
  filter,
  pagination,
  page,
  getTableBodyProps,
  prepareRow,
  ...optionals
}) {
  const {
    shouldShowFilter,
    globalFilter,
    setGlobalFilter,
    searchBarPlaceholder,
  } = filter || {};
  const {
    shouldShowPagination,
    previousPage,
    gotoPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageIndex,
  } = pagination || {};

  if (!hasData) {
    return <NoData />;
  }

  return (
    <>
      <div className='flex justify-end' data-cy={'user-card-list-filter'}>
        {shouldShowFilter && (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={searchBarPlaceholder}
          />
        )}
      </div>
      <div className='flex flex-col gap-y-4 mt-2 lg:mt-0'>
        {page.map((row, i) => {
          prepareRow(row);
          // console.debug(row);
          const key = `user-card-${row?.id + row?.value}`;
          return <UserCard row={row} key={key} data-cy='zone-card-list-item' />;
        })}
        {shouldShowPagination && (
          <PaginationBtnGroup
            paginationFns={{ previousPage, gotoPage, nextPage }}
            paginationStates={{
              pageCount,
              canPreviousPage,
              canNextPage,
              pageIndex,
            }}
          />
        )}
      </div>
    </>
  );
}
