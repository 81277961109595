import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapObjIndexed } from "ramda";
import { useState } from "react";
import { useInterval } from "react-use";

// import { Icon } from 'components/atomic/Icon';
import {
  createDateByUnixTime,
  getNow,
  getUnixTimeByDate,
  setLocalTime,
  subDays,
} from "features/date";
import {
  DateRangePicker2,
  DateRangePicker3,
} from "features/date/components/molecule/DateRangePicker";
import { MultiDaysDateSelector } from "./MultiDaysDateSelector";

/**
 * Date Selectors, includes only multi-days, not hooked with global state
 */
export function MultiDaysDatePickerGroup({ value, onChange, ...optionals }) {
  const { timezone, labelClassName, onSelectCustomDateRange } = optionals;
  const selectedDates: { startDate: Date; endDate: Date } = mapObjIndexed(
    createDateByUnixTime,
    value
  );

  const [maxSelectableDate, setMaxSelectableDate] = useState(getNow()); //subDays(new Date(), 0);
  /**
   * update max selectable date every 5 mintues
   */
  useInterval(() => {
    // console.debug("update maxSelectableDate");
    setMaxSelectableDate(getNow());
  }, 1000 * 60 * 5);

  const handleDateRangeChangeFromCalendar = (
    input: { startDate: Date; endDate: Date } | Date
  ) => {
    // console.debug("handleDateRangeChangeFromCalendar", input);
    //@ts-ignore
    const { startDate, endDate } = input || {};
    if (!endDate) {
      // invalid date change, do nothing
      return;
    }

    // adapt endDate
    const updatedEndDate = setLocalTime(endDate);

    // adapt startDate
    let nextStartDate;
    if (Boolean(startDate)) {
      const updatedStartDate = setLocalTime(startDate);
      nextStartDate = getUnixTimeByDate(updatedStartDate);
    } else {
      // no start date -> set start date to a week ago
      nextStartDate = getUnixTimeByDate(subDays(updatedEndDate, 6));
    }

    const nextEndDate = getUnixTimeByDate(updatedEndDate);
    const nextSelectedDate = { startDate: nextStartDate, endDate: nextEndDate };
    onSelectCustomDateRange(true);
    onChange && onChange(nextSelectedDate);
  };

  const handleDateChangeFromSelector = (d: {
    startDate?: number;
    endDate: number;
  }) => {
    try {
      // console.debug("Date selector change date value: ", d);
      // const {startDate, endDate} = d || {};
      onSelectCustomDateRange(false);
      onChange && onChange(d);
    } catch (error) {
      console.error("handleDateChangeFromSelector got error", error);
    }
  };

  return (
    <div className="flex items-stretch w-full md:w-auto">
      <MultiDaysDateSelector
        onChange={handleDateChangeFromSelector}
        value={value}
        timezone={timezone}
        labelClassName={labelClassName}
        classNames={{
          control: () => "border",
        }}
      />
      <div
        className={
          "flex flex-col justify-center md:flex-row md:items-end ml-2 md:mb-1"
        }
        data-cy={"customized-date-picker-container"}
      >
        <DateRangePicker3
          customInput={<FontAwesomeIcon icon="calendar-alt" />}
          maxDate={maxSelectableDate}
          onChange={handleDateRangeChangeFromCalendar}
          selectedDates={selectedDates}
          allowSameDay={false}
          timezone={timezone}
        />
      </div>
    </div>
  );
}

/**
 *
 */
export function MultiDaysDateSelector2({ value, onChange, ...optionals }) {
  const handleDateChange = () => {};

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  return (
    <DateRangePicker2
      ranges={[selectionRange]}
      onChange={handleDateChange}
      maxDate={new Date()}
      inputRanges={[]}
      {...optionals}
    />
  );
}
