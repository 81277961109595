export const REPORT_TYPES = [
  { label: "Visitor", value: "visitors" },
  { label: "Passerby", value: "passerbys" },
];
export const DOCUEMENT_TYPES = [{ label: "PDF", value: "pdf" }];
// export const DOCUEMENT_TYPES = [{ label: 'PDF', value: 'pdf' }, { label: 'CSV', value: 'csv' }]

export const REPORT_SCHEDULE_INTERVAL = [
  { label: "Disabled", value: "disabled" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];
