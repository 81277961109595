import styled from "styled-components";
import { Skeleton } from "@mui/material";

import { Image } from "components/atomic/Image";
import { USER_PROFILE_PIC_PLACEHOLDER_URL } from "features/auth/constants";
import { useAppState } from "hooks";

const Profile = styled.div`
  background: inherit;
  height: 65px;
  display: flex;
  align-items: center;
  border: 3px solid transparent;
  padding-left: 50px;
  padding-right: 10px;
  color: ${(p) => p.theme.colors.darkGrey};
`;

const UserName = styled.p`
  /* word-break: break-all; */
`;

/**
 *
 */
export function UserProfileContainer({ ...optionals }) {
  // Hooks
  const { userState } = useAppState();

  if (!userState) {
    return (
      <Profile>
        <Skeleton variant="rounded" width={180} height={50} />
      </Profile>
    );
  }

  return (
    <UserProfileView
      name={userState?.username}
      imageSrc={userState?.userPicture}
      {...optionals}
    />
  );
}

/**
 *
 */
export function UserImage(props) {
  const { src, size, ...rest } = props;
  return (
    <Image
      className="w-7"
      style={{ height: "28px" }}
      src={src || USER_PROFILE_PIC_PLACEHOLDER_URL}
      roundedCircle
      {...rest}
    />
  );
}

export function UserProfileView({ ...optionals }) {
  const { size, name, imageSrc, ...rest } = optionals;
  return (
    <Profile {...rest} data-cy={"user-profile-container"}>
      <UserImage src={imageSrc} />
      {name ? (
        <UserName className="ml-4 mb-0 text-lg xl:text-xl break-all">
          {name}
        </UserName>
      ) : null}
    </Profile>
  );
}
