import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { useHistory, useLocation } from "react-router-dom";

import { Icon } from "components/atomic/Icon";
import { useMediaQueries } from "styles/themes";
import { TABS_LIST } from "../constants";

type TabListProps = {
  allIDs: string[];
  byID: Record<
    string,
    {
      path: string;
      icon: string;
      label: string;
    }
  >;
};

/**
 *
 */
export function Tabs({
  tabList,
  ...optionals
}: {
  tabList: TabListProps;
  [x: string]: any;
}) {
  // Hooks
  const isLg = useMediaQueries("lg");
  const history = useHistory();
  const location = useLocation();
  // console.debug('Tabs location', location.pathname);

  const handleChange = (event: React.SyntheticEvent, newIndexValue: number) => {
    const targetItemID = TABS_LIST.allIDs[newIndexValue];
    const targetItem = TABS_LIST.byID[targetItemID];
    history.push(targetItem.path);
  };

  if (isLg) {
    return null;
  }

  // const currentTabIndexValue = TABS.findIndex(tab => tab.path === location?.pathname);
  const currentTabIndexValue = TABS_LIST.allIDs.findIndex(
    (tabID) => TABS_LIST.byID[tabID].path === location?.pathname
  );

  return (
    <MuiTabs
      value={currentTabIndexValue}
      onChange={handleChange}
      aria-label="nav-tabs"
      variant="fullWidth"
      className="sticky bottom-0 bg-white"
    >
      {tabList.allIDs.map((id) => {
        const tab = tabList.byID[id];
        return (
          <MuiTab
            key={tab.path}
            icon={<Icon name={tab.icon} />}
            label={tab.label}
          />
        );
      })}
    </MuiTabs>
  );
}
