import { useQuery } from "react-query";

import { KAIDU_DEVICE_STATUS_API, fetchDeviceStatusEnums } from "./apis";
import { KaiduDeviceStatusItem } from "./types";

export function useKaiduDeviceStatusEnum() {
  return useQuery<KaiduDeviceStatusItem[], Error>(
    [KAIDU_DEVICE_STATUS_API],
    fetchDeviceStatusEnums
  );
}
