import { useEffect, useState } from "react";
import { DateRangePicker as ReactDateRangePicker } from "react-date-range";

import { diffInCalendarDays, formatDate, getNow, subDays } from "features/date";
import { DatePicker } from "../atomic/DatePicker";
// import { UTCDatePicker } from '../atomic/UTCDatePicker';
import { Checkbox } from "components/atomic/Check";
import { isFilledArray } from "utils";
// import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Button } from "components/atomic/Button";
import "react-date-range/dist/theme/default.css"; // theme css file
import "./ReactDateRangePicker.css";

function SelectedDateRangeLabel({
  startDate,
  endDate,
  timezone,
  ...optionals
}) {
  const startDateText = startDate
    ? formatDate(startDate, timezone, "MMM dd")
    : null;
  const endDateText = endDate ? formatDate(endDate, timezone, "MMM dd") : null;

  return (
    <div>
      {startDate ? (
        <>
          <span className="h-auto">{startDateText}</span> <span> -- </span>
        </>
      ) : null}
      {endDate ? <span className="h-auto">{endDateText}</span> : null}
    </div>
  );
}

/**
 * pick single day and multi-days
 * Allow switching between single and multi-day
 * Default: single day
 * local startDate is only used when selectsRange is true
 */
export function DateRangePicker({ ...optionals }) {
  // Props
  const {
    onChange,
    selectedDates,
    timezone,
    enableRange = false,
    disableMultidatCheckbox,
    ...rest
  } = optionals;

  // Local states
  const [startDate, setStartDate] = useState(getNow()); // locally selected start date
  const [endDate, setEndDate] = useState(selectedDates?.endDate);
  const [selectsRange, setSelectsRange] = useState(false); // checkbox to control if user is selecting a range or a day

  useEffect(() => {
    // reset local state when global state is changed
    if (selectedDates) {
      const { startDate: nextStartDate, endDate: nextEndDate } =
        selectedDates || {};
      if (nextStartDate) {
        setStartDate(nextStartDate);
        setSelectsRange(true);
      } else {
        // setStartDate(nextEndDate);
        setSelectsRange(false);
      }
      setEndDate(nextEndDate);
    }
  }, [selectedDates]);

  const handleDateChange = (dates) => {
    // console.debug('DateRangePicker handleChange', dates);
    if (isFilledArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else if (dates) {
      // console.debug('DateRangePicker handleChange set end date', typeof dates);
      setEndDate(dates);
      // onChange({ endDate: dates });
    }
  };

  const handleCheckboxChange = (e) => {
    setEndDate(null);
    setStartDate(null);
    setSelectsRange(e.target.checked);
  };

  /**
   * when a range is selected, and selected date is updated to a different day
   * update the date range to other components
   */
  const handleCalendarClose = () => {
    // console.debug("DateRangePicker onCalendarClose");
    if (selectsRange) {
      const isDiffStartDate =
        selectedDates?.startDate !== startDate &&
        (!selectedDates?.startDate ||
          Math.abs(diffInCalendarDays(selectedDates?.startDate, startDate)) >
            0);
      const isDiffEndDate =
        selectedDates?.endDate !== endDate &&
        (!selectedDates?.startDate ||
          Math.abs(diffInCalendarDays(selectedDates?.endDate, endDate)) > 0);
      const isDiffDay = endDate && diffInCalendarDays(endDate, startDate) > 0;
      const shouldUpdate =
        endDate && onChange && (isDiffStartDate || isDiffEndDate);
      if (shouldUpdate) {
        if (!isDiffStartDate || !isDiffDay) {
          // update end date only, if start date is not changed or is the same day as end day
          onChange({ endDate });
        } else if (!isDiffEndDate) {
          // update start date only if end date is not changed
          onChange({ startDate });
        } else {
          // console.debug("update both start date and end date", isDiffStartDate);
          onChange({ startDate, endDate });
        }
      }
    } else {
      // for single day, only update endDate
      onChange({ endDate });
    }
  };

  const selectedDateProps = selectsRange
    ? { startDate, endDate }
    : { selected: endDate };

  return (
    <DatePicker
      onChange={handleDateChange}
      {...selectedDateProps}
      selectsRange={selectsRange}
      showDisabledMonthNavigation
      onCalendarClose={handleCalendarClose}
      {...rest}
    >
      <div className="flex justify-between items-center px-2">
        {selectsRange ? (
          <SelectedDateRangeLabel
            startDate={startDate}
            endDate={endDate}
            timezone={timezone}
          />
        ) : (
          <div></div>
        )}
        {enableRange ? (
          <div className="flex items-center">
            <Checkbox
              onChange={handleCheckboxChange}
              disabled={!enableRange}
              checked={selectsRange}
            />
            Multi-days
          </div>
        ) : null}
      </div>
    </DatePicker>
  );
}

export function DateRangePicker2(props) {
  // return <ReactDateRangePicker {...props} />
  return <ReactDateRangePicker {...props} />;
}

// function getIncludeDates(startDate: Date) {
//   const SixDayLater = [addDays(startDate, 6)];
//   const today = getNow();
//   if (diffInCalendarDays(today, startDate) === 7) {
//     return [...SixDayLater, today];
//   }
//   return SixDayLater;
// }

function getMaxDateForStartDate(maxDate) {
  const result = subDays(maxDate, 7);
  return result;
}

/**
 * Date range picker pick only for a week or a week + today
 */
export function DateRangePicker3({ ...optionals }) {
  // Props
  const { onChange, selectedDates, maxDate, timezone, ...rest } = optionals;

  // Local states
  const [startDate, setStartDate] = useState<Date>(getNow()); // locally selected start date
  const [endDate, setEndDate] = useState(selectedDates?.endDate);
  const [dateChanged, setDateChanged] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDates) {
      const { startDate: nextStartDate, endDate: nextEndDate } =
        selectedDates || {};
      if (nextStartDate) {
        setStartDate(nextStartDate);
      } else {
        // setStartDate(nextEndDate);
      }
      setEndDate(nextEndDate);
    }
  }, [selectedDates]);

  const handleDateChange = (dates) => {
    // console.debug('DateRangePicker handleChange', dates);
    if (isFilledArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else if (dates) {
      // console.debug('DateRangePicker handleChange set end date', typeof dates);
      setEndDate(dates);
      // onChange({ endDate: dates });
    }
    setDateChanged(true);
  };

  /**
   * when a range is selected, and selected date is updated to a different day
   * update the date range to other components
   */
  const handleCalendarClose = () => {
    // console.debug("DateRangePicker onCalendarClose");
    dateChanged && onChange && onChange({ startDate, endDate });
    setDateChanged(false);
    // const isDiffStartDate =
    //   selectedDates?.startDate !== startDate &&
    //   (!selectedDates?.startDate ||
    //     Math.abs(diffInCalendarDays(selectedDates?.startDate, startDate)) >
    //     0);
    // const isDiffEndDate =
    //   selectedDates?.endDate !== endDate &&
    //   (!selectedDates?.startDate ||
    //     Math.abs(diffInCalendarDays(selectedDates?.endDate, endDate)) > 0);
    // const isDiffDay = endDate && diffInCalendarDays(endDate, startDate) > 0;
    // const shouldUpdate =
    //   endDate && onChange && (isDiffStartDate || isDiffEndDate);
    // if (shouldUpdate) {
    //   if (!isDiffStartDate || !isDiffDay) {
    //     // update end date only, if start date is not changed or is the same day as end day
    //     onChange({ endDate });
    //   } else if (!isDiffEndDate) {
    //     // update start date only if end date is not changed
    //     onChange({ startDate });
    //   } else {
    //     console.debug('update both start date and end date', isDiffStartDate);
    //     onChange({ startDate, endDate });
    //   }
    // }
  };

  const handleClearPickedStartDate = () => {
    setStartDate(null);
    setDateChanged(false);
  };

  const selectedDateProps = { startDate, endDate };
  const shouldExcludeDates = !endDate && startDate;
  const maxDateForceStartDateToBeAWeekEarilier =
    !startDate || endDate ? getMaxDateForStartDate(maxDate) : maxDate;
  // const shouldShowClearBtn = !endDate

  return (
    <DatePicker
      onChange={handleDateChange}
      {...selectedDateProps}
      selectsRange={true}
      showDisabledMonthNavigation
      onCalendarClose={handleCalendarClose}
      // includeDates={shouldExcludeDates ? getIncludeDates(startDate) : undefined}
      maxDate={maxDateForceStartDateToBeAWeekEarilier}
      {...rest}
    >
      <div className="flex justify-between items-center px-3 pb-3">
        <SelectedDateRangeLabel
          startDate={startDate}
          endDate={endDate}
          timezone={timezone}
        />
        {shouldExcludeDates ? (
          <Button
            variant="light"
            onClick={handleClearPickedStartDate}
            size={"sm"}
          >
            Clear
          </Button>
        ) : null}
      </div>
    </DatePicker>
  );
}
