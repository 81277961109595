import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from './store';
import { GridTypeFilter } from 'types';
import { DateRange2 } from 'features/date';

const initFilter: DateRange2 = { start: null, end: null };
const initGridTypeFilter: GridTypeFilter = {
  // include every prop
  scanners: false,
  valid: false,
  not_valid: false,
};

const initialState = {
  data: null,
  state: 'idle',
  filter: initFilter,
  gridTypeFilter: initGridTypeFilter,
};

export const heatmapSlice = createSlice({
  name: 'heatmapData',
  initialState,
  reducers: {
    updateHeatMapData(state, action: PayloadAction<any>) {
      state.data = action.payload;
      return state;
    },
    clearHeatMapData(state) {
      state = initialState;
      return state;
    },
    updateHeatMapState(state, action: PayloadAction<any>) {
      state.state = action.payload;
      return state;
    },
    updateHeatmapFilter(state, action: PayloadAction<DateRange2>) {
      state.filter = action.payload;
      return state;
    },
    resetHeatmapFilter(state) {
      state.filter = initialState.filter;
      return state;
    },
    updateHeatmapGridTypeFilter(state, action: PayloadAction<any>) {
      state.gridTypeFilter = action.payload;
      return state;
    },
    toggleNotValidOfHeatmapGridTypeFilter(state) {
      state.gridTypeFilter.not_valid = !state.gridTypeFilter.not_valid;
      return state;
    },
    toggleValidOfHeatmapGridTypeFilter(state) {
      state.gridTypeFilter.valid = !state.gridTypeFilter.valid;
      return state;
    },
    toggleScannersOfHeatmapGridTypeFilter(state) {
      state.gridTypeFilter.scanners = !state.gridTypeFilter.scanners;
      return state;
    },
    resetHeatmapGridTypeFilter(state) {
      state.gridTypeFilter = initialState.gridTypeFilter;
    },
  },
});

export default heatmapSlice.reducer;

export const {
  updateHeatMapData,
  clearHeatMapData,
  updateHeatmapFilter,
  resetHeatmapFilter,
  updateHeatMapState,
  updateHeatmapGridTypeFilter,
  resetHeatmapGridTypeFilter,
  toggleNotValidOfHeatmapGridTypeFilter,
  toggleValidOfHeatmapGridTypeFilter,
  toggleScannersOfHeatmapGridTypeFilter,
} = heatmapSlice.actions;

export const selectHeatMapData = (state: AppState) => {
  return state[heatmapSlice.name].data;
};
export const selectHeatMapFilter = (state: AppState): DateRange2 => {
  return state[heatmapSlice.name].filter;
};
export const selectHeatMapDataState = (state: AppState) => {
  return state[heatmapSlice.name].state;
};
export const selectHeatmapGridTypeFilter = (state: AppState): GridTypeFilter => {
  return state[heatmapSlice.name].gridTypeFilter;
};
