import { useController } from "react-hook-form";

import { MuiLabel } from "components/atomic/Label";
import { Range } from "components/atomic/Slider";
import { TimeRangeInput } from "components/molecule/TimeRangeInput";
import {
  convertTimeNumToString,
  parseTimeStringToMinuteNum,
} from "features/date";
import { useMediaQueries } from "styles/themes";

/**
 * Hours Of operation Input
 */
export function HoursOfOperationInput({ control, setValue, ...optionals }) {
  const { className = "" } = optionals;

  // Hooks
  const {
    field: { onChange: onChangeStart, value: startValue },
  } = useController({
    name: "start",
    control,
  });

  const {
    field: { onChange: onChangeEnd, value: endValue },
  } = useController({
    name: "end",
    control,
  });
  const isLg = useMediaQueries("lg");

  const handleTimeChange = ([min, max]) => {
    const start = convertTimeNumToString(min);
    const end = convertTimeNumToString(max);
    // console.debug(`set start: ${start}, end: ${end}`);
    setValue("start", start, { shouldDirty: true });
    setValue("end", end, { shouldDirty: true });
  };

  const handleStartChange = (e) => {
    const timeString = e.target.value;
    onChangeStart(timeString);
  };

  const handleEndChange = (e) => {
    const timeString = e.target.value;
    onChangeEnd(timeString);
  };

  return (
    <>
      <MuiLabel className="mb-2">Hours of Operation</MuiLabel>
      <div className={`flex-1 ${className}`}>
        <div className="flex flex-col lg:flex-row space-x-2 items-center max-w-full">
          {isLg ? (
            <>
              <TimeRangeInput
                startValue={startValue}
                endValue={endValue}
                onStartChange={handleStartChange}
                onEndChange={handleEndChange}
              >
                <Range
                  min={0}
                  max={1440}
                  onChange={handleTimeChange}
                  allowCross={false}
                  className={"max-w-xl"}
                  value={[
                    parseTimeStringToMinuteNum(startValue),
                    parseTimeStringToMinuteNum(endValue),
                  ]}
                />
              </TimeRangeInput>
            </>
          ) : (
            <>
              <div className="flex justify-between space-x-8 mb-6 w-full">
                <TimeRangeInput
                  startValue={startValue}
                  endValue={endValue}
                  onStartChange={handleStartChange}
                  onEndChange={handleEndChange}
                />
              </div>
              <Range
                min={0}
                max={1440}
                onChange={handleTimeChange}
                allowCross={false}
                className={"max-w-full lg:max-w-xl"}
                value={[
                  parseTimeStringToMinuteNum(startValue),
                  parseTimeStringToMinuteNum(endValue),
                ]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
