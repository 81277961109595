// import { Tooltip as BootStrapTooltip} from 'react-bootstrap';
// import ReactTooltip from 'react-tooltip'; 
import { Tooltip as ReactTooltip } from 'react-tooltip'; // assign data-tip attribute to any element to show tooltip
import styled from 'styled-components';
import { defaultStyles } from '@visx/tooltip';
import MuiTooltip from '@mui/material/Tooltip';


/**
 * Styles for TooltipInPortal of Visx tooltip
 */
export const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
  zIndex: 1999, // Mui modal z-index is 1300
};

const StyledTooltip = styled<any>(ReactTooltip)`
  z-index: 9999;
`;

/**
 * This tooltip only display text
 */
export function Tooltip(props) {
  const {anchorId, ...rest} = props;
  return <StyledTooltip anchorId={anchorId} {...rest} />;
}

export function Tooltip2(props) {
  // const {anchorId, ...rest} = props;
  return <MuiTooltip {...props} />;
}


export const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;

  span {
    font-size: ${props => props.theme.fontSizes.sm};
    margin: 0;
  }
`;