import { Group } from "@visx/group";
import { checkIsTodayInTimezone, parseString } from "features/date";
import { curry, pipe } from "ramda";
import { useTheme } from "styled-components";
import { isFilledArray } from "utils";
import {
  MainBar,
  SpecialColorMainBar,
  StackedColoredMainBarForToday,
} from "./MainBar";

/**
 *
 */
export function MainBarGroup({
  data,
  yMax,
  valueScale,
  barWidth,
  scales,
  tooltipProps,
  ...optionals
}) {
  const {
    yPropName = "overall",
    onBarClick,
    // graphColorProp = "overall",
    colorAccessor,
    timezone,
    ...rest
  } = optionals;
  const { timeScale } = scales || {};
  const isBarClickEnabled = onBarClick && typeof onBarClick === "function";
  const curriedCheckIsTodayInTimezone = curry(checkIsTodayInTimezone)(timezone);

  const theme = useTheme();

  if (!isFilledArray(data)) {
    return null;
  }

  const barColor = theme.colors?.graph?.overall;
  // console.debug('last date x', dateScale("2022-04-27T23:00:00-04:00"));
  // console.debug(`MainBarGroup gets ${data?.length} entries from data`);
  return (
    <>
      <Group className="main-bar-group-cy-test">
        {data.map((d, i) => {
          const isTodayData = pipe(
            parseString,
            curriedCheckIsTodayInTimezone
          )(d?.date);

          if (isTodayData) {
            return (
              <SpecialColorMainBar
                key={`bar-${d.date}-${i}`}
                d={d}
                yPropName={yPropName}
                yMax={yMax}
                valueScale={valueScale}
                timeScale={timeScale}
                tooltipProps={tooltipProps}
                onBarClick={onBarClick}
                isBarClickEnabled={isBarClickEnabled}
                width={barWidth}
                {...rest}
              />
            );
          }

          return (
            <MainBar
              key={`bar-${d.date}-${i}`}
              d={d}
              yPropName={yPropName}
              yMax={yMax}
              valueScale={valueScale}
              timeScale={timeScale}
              tooltipProps={tooltipProps}
              onBarClick={onBarClick}
              isBarClickEnabled={isBarClickEnabled}
              fill={barColor}
              width={barWidth}
              {...rest}
            />
          );
        })}
      </Group>
    </>
  );
}

/**
 *
 */
export function MainBarGroupWithStacked({
  data,
  yMax,
  valueScale,
  barWidth,
  scales,
  tooltipProps,
  ...optionals
}) {
  const {
    yPropName = "overall",
    onBarClick,
    graphColorProp = "overall",
    colorAccessor,
    timezone,
    forecastProps,
    ...rest
  } = optionals;
  const { timeScale } = scales || {};
  const { data: forecastData } = forecastProps || {};
  const isBarClickEnabled = onBarClick && typeof onBarClick === "function";
  const curriedCheckIsTodayInTimezone = curry(checkIsTodayInTimezone)(timezone);
  // Hooks
  const theme = useTheme();

  if (!isFilledArray(data)) {
    return null;
  }

  const barColor = theme.colors?.graph[graphColorProp];
  // console.debug('last date x', dateScale("2022-04-27T23:00:00-04:00"));
  // console.debug(`MainBarGroup gets ${data?.length} entries from data`);
  return (
    <>
      <Group className="main-bar-group-cy-test">
        {data.map((d, i) => {
          const isTodayData = pipe(
            parseString,
            curriedCheckIsTodayInTimezone
          )(d?.date);

          if (isTodayData) {
            const todayForecast =
              forecastData &&
              forecastData.find((item) => item.date === d?.date);
            const stackedBarData = [{ ...d, forecast: todayForecast?.overall }];

            return (
              <StackedColoredMainBarForToday
                key={`bar-${d.date}-${i}`}
                keys={["overall", "forecast"]}
                data={stackedBarData}
                yMax={yMax}
                valueScale={valueScale}
                timeScale={timeScale}
                tooltipProps={tooltipProps}
                onBarClick={onBarClick}
                isBarClickEnabled={isBarClickEnabled}
                width={barWidth}
                hasForecast={Boolean(todayForecast?.overall)}
                {...rest}
              />
            );
          }

          return (
            <MainBar
              key={`bar-${d.date}-${i}`}
              d={d}
              yPropName={yPropName}
              yMax={yMax}
              valueScale={valueScale}
              timeScale={timeScale}
              tooltipProps={tooltipProps}
              onBarClick={onBarClick}
              isBarClickEnabled={isBarClickEnabled}
              fill={barColor}
              width={barWidth}
              {...rest}
            />
          );
        })}
      </Group>
    </>
  );
}

export function MainBarGroupWithStackedAndPasserbys({
  data,
  yMax,
  valueScale,
  barWidth,
  scales,
  tooltipProps,
  ...optionals
}) {
  const {
    // yPropName = "overall",
    onBarClick,
    // graphColorProp = "overall",
    colorAccessor,
    timezone,
    forecastProps,
    ...rest
  } = optionals;
  const { timeScale } = scales || {};
  const { data: forecastData } = forecastProps || {};
  const isBarClickEnabled = onBarClick && typeof onBarClick === "function";
  const curriedCheckIsTodayInTimezone = curry(checkIsTodayInTimezone)(timezone);
  // Hooks
  const theme = useTheme();

  if (!isFilledArray(data)) {
    return null;
  }

  // const barColor = theme.colors?.graph[graphColorProp];
  const barColors: { [key: string]: any } = {
    overall: theme.colors?.graph?.overall,
    passerbys: theme.colors?.graph?.passerbys,
  };
  // console.debug('last date x', dateScale("2022-04-27T23:00:00-04:00"));
  // console.debug(`MainBarGroup gets ${data?.length} entries from data`);
  return (
    <>
      <Group className="main-bar-group-cy-test">
        {data.map((d, i) => {
          const isTodayData = pipe(
            parseString,
            curriedCheckIsTodayInTimezone
          )(d?.date);
          const { date } = d || {};
          const barX = date && timeScale(parseString(date));
          if (isTodayData) {
            const todayForecast =
              forecastData &&
              forecastData.find((item) => item.date === d?.date);
            // const todayForecast = {
            //   "date": "2023-01-25T00:00:00-05:00",
            //   "overall": 20
            // };
            const stackedBarData = [{ ...d, forecast: todayForecast?.overall }];

            return (
              <StackedColoredMainBarForToday
                key={`bar-${d.date}-${i}`}
                keys={["overall", "forecast"]}
                data={stackedBarData}
                yMax={yMax}
                valueScale={valueScale}
                timeScale={timeScale}
                tooltipProps={tooltipProps}
                onBarClick={onBarClick}
                isBarClickEnabled={isBarClickEnabled}
                width={barWidth}
                hasForecast={Boolean(todayForecast?.overall)}
                x={barX - barWidth}
                {...rest}
              />
            );
          }

          return (
            <Group>
              <MainBar
                key={`visitors-bar-${d.date}-${i}`}
                d={d}
                yPropName={"visitors"}
                yMax={yMax}
                valueScale={valueScale}
                timeScale={timeScale}
                tooltipProps={tooltipProps}
                onBarClick={onBarClick}
                isBarClickEnabled={isBarClickEnabled}
                fill={barColors["overall"]}
                width={barWidth}
                {...rest}
              />
              <MainBar
                key={`passerbys-bar-${d.date}-${i}`}
                d={d}
                yPropName={"passerbys"}
                yMax={yMax}
                valueScale={valueScale}
                timeScale={timeScale}
                tooltipProps={tooltipProps}
                onBarClick={onBarClick}
                isBarClickEnabled={isBarClickEnabled}
                fill={barColors["passerbys"]}
                width={barWidth}
                x={barX + barWidth / 2}
                {...rest}
              />
            </Group>
          );
        })}
      </Group>
    </>
  );
}

/**
 *
 */
export function CardBarGroup({
  data,
  yMax,
  valueScale,
  barWidth,
  scales,
  tooltipProps,
  ...optionals
}) {
  const {
    yPropName = "overall",
    onBarClick,
    // graphColorProp = "overall",
    colorAccessor,
    timezone,
    ...rest
  } = optionals;
  const { timeScale } = scales || {};
  const isBarClickEnabled = onBarClick && typeof onBarClick === "function";

  const theme = useTheme();

  if (!isFilledArray(data)) {
    return null;
  }

  const barColor = theme.colors?.graph?.overall;
  return (
    <>
      <Group className="main-bar-group-cy-test">
        {data.map((d, i) => {
          return (
            <MainBar
              key={`bar-${d.date}-${i}`}
              d={d}
              yPropName={yPropName}
              yMax={yMax}
              valueScale={valueScale}
              timeScale={timeScale}
              tooltipProps={tooltipProps}
              onBarClick={onBarClick}
              isBarClickEnabled={isBarClickEnabled}
              fill={barColor}
              width={barWidth}
              {...rest}
            />
          );
        })}
      </Group>
    </>
  );
}
