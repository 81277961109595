import MuiListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MuiListItemText from "@mui/material/ListItemText";
import { NIL_VALUE_TEXT } from "lib";

/**
 *
 */
export function ListItem(props: {
  title?: string;
  description?: string;
  [x: string]: any;
}) {
  const {
    title = NIL_VALUE_TEXT,
    description = NIL_VALUE_TEXT,
    // ...rest
  } = props;

  return (
    <div className="flex my-3 flex-col me-4" style={{ minWidth: "100px" }}>
      <h2
        className="text-secondary text-bold"
        style={{ fontSize: "14px", marginBottom: "4px" }}
      >
        {title}
      </h2>
      <p>{description}</p>
    </div>
  );
}

// export const ListItem2 = ({
//   title,
//   description,
// }: {
//   title: string;
//   description: string;
// }) => {
//   return (
//     <div className='flex my-3 flex-col me-4' style={{ minWidth: '100px' }}>
//       <h2
//         className='text-secondary text-bold'
//         style={{ fontSize: '14px', marginBottom: '4px' }}
//       >
//         {title}
//       </h2>
//       <span>{description || 'Not Provided'}</span>
//     </div>
//   );
// };

/**
 * Clickable list item
 */
export function ListItem2({ title = NIL_VALUE_TEXT, ...optionals }) {
  const { onClick, children, description, ...rest } = optionals;

  return (
    <ListItemButton disableGutters dense onClick={onClick} {...rest}>
      <span>{title}</span>
      {children ? children : null}
    </ListItemButton>
  );
}

export function ListItem3({ title, ...optionals }) {
  const {
    onClick,
    children,
    description = NIL_VALUE_TEXT,
    ...rest
  } = optionals;

  return (
    <MuiListItem disableGutters dense onClick={onClick} {...rest}>
      <MuiListItemText
        primary={title}
        secondary={<span className="text-sm">{description}</span>}
      />
      {children ? children : null}
    </MuiListItem>
  );
}
