import _ from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { getTimezoneDiffInDateString } from "features/date";
import { REFRESH_INTERVAL } from "./constants";
import {
  fetchAverageHourlyData,
  fetchGatheredGlobalData,
  fetchGatheredHourlyDataByCustomer,
  fetchGatheredTrafficByBeacon,
  fetchGlobalData,
  fetchGlobalDateSplitted,
  fetchTrafficByBeacon,
  fetchTrafficByBuilding,
  fetchTrafficForCustomerBeacons,
  fetchTriangulationGrid,
  fetchTriangulationGridTypes,
} from "./fetchers";
import { convertDateUnixTimeToAPIParam } from "./processors";
import {
  GlobalDataDTO,
  OptionalArgumentForGlobalData,
  OptionalParamsForGlobalDataSplitted,
  OptionalParamsForTrafficByBuilding,
  TrafficByBeacon,
  TrafficByBuilding,
  TrafficByBuildingHookOptParams,
  TriangulationGridData,
  TriangulationGridTypesData,
} from "./types";

/**
 *
 * @param endDate number of seconds
 */
export function useGlobalData(
  customerId: string,
  endDate: number,
  args?: OptionalArgumentForGlobalData
) {
  const { startDate, building, location, timezone } = args || {};
  // console.debug('useGlobalData is called with', customerId, endDate, args);
  const endDateString = endDate
    ? convertDateUnixTimeToAPIParam(endDate)
    : undefined; // get the timestampe string of the time of endDate
  // const startDateString = startDate
  //   ? convertDateUnixTimeToAPIParam(startDate)
  //   : undefined;
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;

  const result = useQuery<GlobalDataDTO, Error>(
    [
      "globalData",
      customerId,
      endDateString,
      building,
      location,
      startDateString,
    ],
    () =>
      fetchGlobalData(customerId, endDateString, {
        ...args,
        startDate: startDateString,
      }),
    {
      enabled: Boolean(customerId && endDate),
      refetchInterval: REFRESH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return result;
}

export function useGatheredGlobalData(
  customerId: string,
  endDate: number,
  args?: Partial<{
    building: string;
    timezone: string;
    startDate: number;
  }>
) {
  const { building, timezone, startDate } = args || {};
  // console.debug("args: ", args);

  const enabled = Boolean(customerId && endDate) && endDate > 0;
  const endDateString = enabled
    ? convertDateUnixTimeToAPIParam(endDate, timezone)
    : undefined; // get the timestamp string of the time of endDate
  // const startDateString = startDate
  //   ? convertDateUnixTimeToAPIParam(startDate, timezone)
  //   : null;
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;

  const fetcher = () =>
    fetchGatheredGlobalData(customerId, endDateString, {
      building,
      startDate: startDateString,
    });

  const result = useQuery<any, Error>(
    ["globalData", customerId, building, endDateString, startDateString],
    fetcher,
    {
      enabled,
      refetchInterval: REFRESH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );
  return result;
}

/**
 *
 * @param customerId
 * @param endDate number of seconds
 */
export function useGlobalDataSplitted(
  customerId: string,
  endDate: number,
  args?: Partial<OptionalParamsForGlobalDataSplitted>
) {
  const { startDate, building, snr, prediction, cards, timezone } = args || {};
  if (endDate > 2656084454 || endDate < 0) {
    console.warn("useGlobalDataSplitted: ", endDate);
  }
  const enabled = Boolean(customerId && endDate) && endDate > 0;
  const endDateString = enabled
    ? convertDateUnixTimeToAPIParam(endDate)
    : undefined; // get the timestampe string of the time of endDate
  // const startDateString = startDate
  //   ? convertDateUnixTimeToAPIParam(startDate)
  //   : undefined;
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;

  const result = useQuery<GlobalDataDTO, Error>(
    [
      "globalDataSplitted",
      customerId,
      building,
      endDateString,
      startDateString,
      snr,
      prediction,
      cards,
    ],
    () =>
      fetchGlobalDateSplitted(customerId, endDateString, {
        ...args,
        startDate: startDateString,
      }),
    {
      enabled,
      refetchInterval: REFRESH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return result;
}

export function useTrafficByBuilding(
  customerId: string,
  endDate: string,
  args?: OptionalParamsForTrafficByBuilding
) {
  const { building, floor, location, startDate } = args || {};
  // const endDateString = convertDateUnixTimeToAPIParam(endDate); // get the timestampe string of the starting time of endDate
  // const startDateString = startDate
  //   ? convertDateUnixTimeToAPIParam(startDate)
  //   : undefined;

  const result = useQuery<TrafficByBuilding>(
    [
      "trafficByBuilding",
      customerId,
      endDate,
      building,
      floor,
      location,
      startDate,
    ],
    () =>
      fetchTrafficByBuilding(customerId, endDate, {
        ...args,
        startDate: startDate,
      }),
    { enabled: Boolean(customerId && endDate) }
  );

  return result;
}

export function useGatheredTrafficByBuilding(
  customerId: string,
  endDate: number,
  args?: TrafficByBuildingHookOptParams
) {
  const { startDate, timezone, ...restArgs } = args || {};
  const endDateString = convertDateUnixTimeToAPIParam(endDate, timezone); // get the timestamp string of the starting time of endDate
  // const startDateString =
  //   startDate && convertDateUnixTimeToAPIParam(startDate, timezone);
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;
  const result = useQuery<TrafficByBuilding>(
    ["trafficByBuilding", customerId, endDateString, startDateString],
    () =>
      fetchTrafficByBuilding(customerId, endDateString, {
        ...restArgs,
        startDate: startDateString,
      }),
    { enabled: Boolean(customerId && endDate) }
  );

  return result;
}

export function useTrafficByBeacon(
  deviceId: string,
  endDate: number, // number of seconds
  args?: any
) {
  const { startDate, timezone } = args || {};
  const endDateString = convertDateUnixTimeToAPIParam(endDate); // get the timestampe string of the starting time of endDate
  // const startDateString = startDate && convertDateUnixTimeToAPIParam(startDate);
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;

  const result = useQuery<TrafficByBeacon>(
    ["trafficByBeacon", deviceId, endDateString, startDateString],
    () =>
      fetchTrafficByBeacon(deviceId, endDateString, {
        ...args,
        startDate: startDateString,
      }),
    { enabled: Boolean(deviceId && endDate) }
  );

  return result;
}

/**
 *
 */
export function useGatherTrafficByBeacon(
  deviceId: string,
  endDate: number,
  args?: any
) {
  const { startDate, timezone } = args || {};
  let sortedDataByDate;

  const isValidEndDate = Number.isInteger(endDate);
  if (!isValidEndDate) {
    // return {data: null, error: new Error('Invalid End Date')};
    console.warn("Invalid End Date", endDate);
  } else if (!timezone) {
    console.warn("No time zone in useGatherTrafficByBeacon", timezone);
  }
  // console.debug('useGatherTrafficByBeacon timezone', timezone);
  const endDateString = useMemo(
    () => isValidEndDate && convertDateUnixTimeToAPIParam(endDate, timezone),
    [endDate, timezone, isValidEndDate]
  ); // get the timestamp string of the starting time of endDate
  // console.debug('useGatherTrafficByBeacon endDateString', endDateString);
  // const startDateString =
  //   startDate && convertDateUnixTimeToAPIParam(startDate, timezone);
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;

  const enabled = useMemo(
    () => Boolean(deviceId && endDate),
    [deviceId, endDate]
  );

  const result = useQuery<TrafficByBeacon, Error>(
    ["gatherTrafficByBeacon", deviceId, endDateString, startDateString],
    () =>
      fetchGatheredTrafficByBeacon(deviceId, endDateString, {
        startDate: startDateString,
      }),
    { enabled, refetchOnWindowFocus: false }
  );
  const { data } = result || {};

  if (data) {
    // sort graph data by date
    sortedDataByDate = _.sortBy(data.graph?.data ?? [], "date").map((item) => ({
      ...item,
      visitors: item.overall,
    }));
  }

  return { ...result, sortedDataByDate };
}

export function useTriangulationGrid(
  customerId: string,
  endDate: number,
  args?: any
) {
  const { startDate, building, location, timezone } = args || {};
  const endDateString = endDate && convertDateUnixTimeToAPIParam(endDate); // get the timestampe string of the starting time of endDate
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;

  const result = useQuery<TriangulationGridData, Error>(
    [
      "triangulationGrid",
      customerId,
      endDateString,
      startDate,
      building,
      location,
    ],
    () =>
      fetchTriangulationGrid(customerId, endDateString, {
        ...args,
        startDate: startDateString,
      }),
    { enabled: Boolean(customerId && endDate) }
  );

  return result;
}

export function useTriangulationGridTypes(
  customerId: string,
  endDate: number,
  args?: any
) {
  const { building, location, timezone } = args || {};
  const endDateString: string =
    endDate && convertDateUnixTimeToAPIParam(endDate, timezone); // get the timestampe string of the starting time of endDate

  const result = useQuery<TriangulationGridTypesData, Error>(
    ["triangulationGridTypes", customerId, endDateString, building, location],
    () => fetchTriangulationGridTypes(customerId, endDateString, { ...args }),
    { enabled: Boolean(customerId && endDate) }
  );

  return result;
}

export function useTrafficForCustomerBeacons(
  customerId: string,
  endDate: number,
  args?: any
) {
  const { timezone, ...restArgs } = args || {};
  const endDateString = convertDateUnixTimeToAPIParam(endDate, timezone); // get the timestamp string of the starting time of endDate

  const result = useQuery<any, Error>(
    ["trafficForCustomerBeacons", customerId, endDateString],
    () =>
      fetchTrafficForCustomerBeacons(customerId, endDateString, {
        ...restArgs,
      }),
    { enabled: Boolean(customerId && endDateString) }
  );

  return result;
}

export function useAverageHourlyTraffic(
  customerId: string,
  endDate: number,
  args?: Partial<any>
) {
  const { startDate, building, timezone } = args || {};
  if (endDate > 2656084454 || endDate < 0) {
    console.warn("useAverageHourlyTraffic: ", endDate);
  }

  const enabled = Boolean(customerId && endDate) && endDate > 0;
  const endDateString = enabled
    ? convertDateUnixTimeToAPIParam(endDate, timezone)
    : undefined; // get the timestampe string of the time of endDate
  const timeDiff = getTimezoneDiffInDateString(endDateString);
  const startDateString = startDate
    ? convertDateUnixTimeToAPIParam(startDate, timezone, timeDiff)
    : undefined;
  // const startDateString = startDate
  //   ? convertDateUnixTimeToAPIParam(startDate, timezone)
  //   : undefined;

  const result = useQuery<any, Error>(
    [
      "averageHourlyTraffic",
      customerId,
      building,
      endDateString,
      startDateString,
    ],
    () =>
      fetchAverageHourlyData(customerId, endDateString, {
        building,
        startDate: startDateString,
      }),
    {
      enabled,
      refetchInterval: REFRESH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return result;
}

export function useHourlyDataModal({
  customerID,
  deviceId,
  endDate,
  timezone,
}) {
  const endDateString = convertDateUnixTimeToAPIParam(endDate, timezone);
  const fetcher = () =>
    fetchGatheredHourlyDataByCustomer(customerID, endDateString, deviceId);

  const result = useQuery<any, Error>(
    ["gatheredHourlyData", customerID, endDateString],
    fetcher,
    {
      refetchInterval: REFRESH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );
  return result;
}
