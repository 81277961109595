import { inspect } from 'utils';
import { BASE_API } from '../constants';
import { CustomersListItem } from './types';
import { sendPost, sendPut } from 'features/axios';
import { fetchCustomer } from './fetchers';
import {CamerasForScanners} from './types';
import {setToLocalStorage} from 'lib/persistence/local-storage';

const FOO = '/customers_list';
export const CUSTOMERS_LIST_API = `${BASE_API}${FOO}`;
export const CUSTOMER_ITEM_ID_API = `${CUSTOMERS_LIST_API}/id`;
export const CUSTOMER_ITEM_NAME_API = `${CUSTOMERS_LIST_API}/name`;

export async function postCustomer(data: CustomersListItem): Promise<any> {
  console.log(`Submitted data in postCustomer: ${inspect(data)}`);
  const result = await sendPost(CUSTOMERS_LIST_API, data);
  return result;
}

export async function putCustomer(data: CustomersListItem): Promise<any> {
  // console.debug(`putCustomer called with input: ${inspect(data)}`);
  const url = `${CUSTOMERS_LIST_API}/${data?.customer_id}`;
  const result = sendPut(url, data);
  return result;
}

export async function updateCustomerCustomData(
  data: CamerasForScanners,
  customerID: string
): Promise<any> {
  console.log(`updateCustomerCustomData called with input: ${inspect(data)}`);
  const prevCustomerData = await fetchCustomer(customerID);
  // save prev customer data for backup
  try {
    setToLocalStorage('customerData', prevCustomerData);
  } catch (error) {
    console.error(error);
  }
  // Update camera ID
  const mac_address = Object.keys(data)[0];
  let nextCamerasForScanners = prevCustomerData?.customer_custom_data?.camerasForScanners || {};
  if (nextCamerasForScanners) {
    nextCamerasForScanners[mac_address] = data[mac_address];
  } else {
    nextCamerasForScanners = data;
  }
  Object.assign(prevCustomerData, {customer_custom_data: {camerasForScanners: nextCamerasForScanners}});
  const result = await putCustomer(prevCustomerData);
  return result;
}
