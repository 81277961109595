import "bootstrap/dist/css/bootstrap.min.css";
import { NotificationGroup } from "components/features/Notification";
import { ErrorBoundary } from "features/error-handling";
import { initMonitoring } from "lib";
import { handleNewVersion } from "lib/services/version";
import { RouteGroup } from "routes/components/RouteGroup";
import { IdleContatiner } from "../components/organism/IdleContatiner";
import "../index.css";
import { initMock } from "../lib/mocks";

// Init Sentry for error reports in production
try {
  // console.debug('REACT_APP_ENV', process.env?.REACT_APP_ENV);
  handleNewVersion();
  if (process.env?.NODE_ENV === "production") {
    initMonitoring();
  }

  if (process.env?.REACT_APP_ENV === "local") {
    // mock APIs in local development environment
    initMock();
  }
} catch (error) {
  console.error(error);
}

/**
 *
 */
function App() {
  return (
    <ErrorBoundary>
      <IdleContatiner />
      <NotificationGroup />
      <RouteGroup />
    </ErrorBoundary>
  );
}

export default App;
