import { TrafficGraphUIData } from "features/charts";

export function formatDataToGraphData(
  data,
  shouldGetSubData: boolean = true,
  shouldGetForecast: boolean = false
): TrafficGraphUIData {
  const { graph, graph_snr, isData } = data || {};
  const { data: mainData, forecast: mainForecast } = graph || {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: snrData, forecast: snrForecast } = graph_snr || {};

  return {
    mainData: mainData,
    forecast: shouldGetForecast ? mainForecast : [],
    subData: mainData,
    subForecast: snrForecast,
    isData: isData || mainData?.length > 0,
  };
}
