import { getTimeZones } from "@vvo/tzdb"; //https://www.npmjs.com/package/@vvo/tzdb
import { useController } from "react-hook-form";

import { MuiLabel } from "components/atomic/Label";
import { Select } from "components/molecule/Select";
import { Button } from "../../../../components/atomic/Button";
import { getLocalTimeZone } from "../../../../features/date";

const timeZonesOptions = getTimeZones().map((item) => {
  return { label: item.currentTimeFormat, value: item.name };
});

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-grid",
  }),
};

/**
 * Form name: timezone
 */
export function TimezoneInputGroup({ control, name = "timezone" }) {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {/* <Form.Label>Time zone</Form.Label> */}
      <MuiLabel className="mb-2">Time zone</MuiLabel>
      <div className="flex flex-col md:flex-row gap-4">
        <Select
          options={timeZonesOptions}
          value={value}
          onChange={(nextValue) => {
            onChange(nextValue?.value);
          }}
          name={name}
          styles={customStyles}
          selectClassName="flex-1 lg:flex-none lg:w-full"
          className="flex-1"
        />
        <Button
          variant="secondary"
          onClick={() => {
            const localTimezone = getLocalTimeZone();
            onChange(localTimezone);
          }}
        >
          Set as local timezone
        </Button>
      </div>
    </>
  );
}
