import { useMemo } from "react";
import { useTheme } from "styled-components";

import { StatusMolecule } from "components/molecule/StatusMolecule";
import { useKaiduDeviceStatusEnum } from "features/kaidu-config-server";

export function ScannerStatus({ statusId, ...optionals }) {
  const { ...rest } = optionals;
  // Hooks
  const theme = useTheme();
  const color = theme?.colors?.status[statusId];
  const { data: deviceStatusList } = useKaiduDeviceStatusEnum();

  const statusEnums: { [key: number]: string } = useMemo(
    () =>
      deviceStatusList
        ? deviceStatusList.reduce((obj, item) => {
            if (item) obj[item.status_id] = item.status_description;
            return obj;
          }, {})
        : {},
    [deviceStatusList]
  );

  return (
    <div className="gap-x-1 flex items-center">
      <StatusMolecule color={color} status={statusEnums[statusId]} {...rest} />
    </div>
  );
}
