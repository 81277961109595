import {
  UnixtimeSelectedDate,
  calculateDiffInCalendarDaysForDateRange,
  getUnixTimeByDate,
  parseString,
  shiftDaysBackward,
  shiftMonthsBackward,
} from "features/date";

/**
 * return a week of a month back
 */
export function getComparedDateRange(
  selectedDate: UnixtimeSelectedDate
): UnixtimeSelectedDate {
  const { startDate, endDate } = selectedDate || {};
  let result: UnixtimeSelectedDate = {
    startDate: null,
    endDate: null,
  };
  const daysDiff = calculateDiffInCalendarDaysForDateRange(endDate, startDate);
  // if it's a date range < 7 days, return a week back
  if (daysDiff < 7) {
    result.startDate = shiftDaysBackward(startDate, 7);
    result.endDate = shiftDaysBackward(endDate, 7);
  } else {
    // if it's a date range > 7 days, return a month back
    result.startDate = shiftMonthsBackward(startDate, 1);
    result.endDate = shiftMonthsBackward(endDate, 1);
  }
  return result;
}

/**
 * return a shifted date range as a week or a month
 */
export function getComparedDateRangeWithoutToday(
  selectedDate: UnixtimeSelectedDate
) {
  const { startDate, endDate } = selectedDate || {};
  let result: UnixtimeSelectedDate = {
    startDate: null,
    endDate: null,
  };
  const daysDiff = calculateDiffInCalendarDaysForDateRange(endDate, startDate);
  const aDayBeforeStartDay = shiftDaysBackward(startDate, 1);
  // if it's a date range <= 7 days, return a week back
  if (daysDiff <= 7) {
    result.startDate = shiftDaysBackward(aDayBeforeStartDay, 6);
    result.endDate = aDayBeforeStartDay;
  } else {
    // if it's a date range > 7 days, return a month back
    result.startDate = shiftMonthsBackward(aDayBeforeStartDay, 1);
    result.endDate = aDayBeforeStartDay;
  }
  return result;
}

/**
 *
 */
export function parseDateDomain(rawDateDomain): any {
  const [startDate, endDate] = rawDateDomain || [];
  if (!endDate || Number.isNaN(endDate)) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  const parsedStartDate = parseString(startDate);
  const parsedEndDate = parseString(endDate);
  return {
    startDate: parsedStartDate,
    endDate: parsedEndDate,
  };
}

export function createDateRangeByDateDomain(rawDateDomain, timezone) {
  const { startDate, endDate } = parseDateDomain(rawDateDomain);
  const unixStartDate = getUnixTimeByDate(startDate);
  const unixEndDate = getUnixTimeByDate(endDate);
  return {
    startDate: unixStartDate,
    endDate: unixEndDate,
    timezone,
  };
}

export function checkIsValidDateRange(selectedDate: UnixtimeSelectedDate) {
  // console.log("file: foo.ts:88 ~ checkIsValidDateRange ~ selectedDate", selectedDate)
  const { endDate } = selectedDate || {};
  return endDate && Number.isFinite(endDate);
}

export function checkIsValidDateStringDomain(selectedDate: [string, string]) {
  // console.log("file: foo.ts:88 ~ checkIsValidDateStringDomain ~ selectedDate", selectedDate)
  const [, endDate] = selectedDate || [];
  return endDate && typeof endDate === "string";
}
