import { IconButton } from "@mui/material";
import { useTheme } from "styled-components";

import { Icon } from "components/atomic/Icon";
import { UnixtimeSelectedDate, shiftDateRangeBackward } from "features/date";

export function PrevDateArrowBtn({
  dateRange,
  onChangeDate,
  ...optionals
}: {
  dateRange: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  const theme = useTheme();
  const { startDate, endDate } = dateRange || {};

  const handleClick = () => {
    const nextDateRange = shiftDateRangeBackward({ startDate, endDate });
    onChangeDate && onChangeDate(nextDateRange);
  };

  return (
    <IconButton onClick={handleClick}>
      <Icon name="FaAngleLeft" color={theme.colors.tertiary} />
    </IconButton>
  );
}
