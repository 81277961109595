import { AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { useMemo } from "react";

import { tooltipStyles } from "components/atomic/Tooltip";
import { parseString } from "features/date";
import { CategorizedBarGroup } from "./components/Bars/CategorizedBarGroup";
import { ForecastBarGroup } from "./components/Bars/ForecastBarGroup";
import {
  MainBarGroup,
  MainBarGroupWithStacked,
} from "./components/Bars/MainBarGroup";
import { DateTimeXAxis } from "./components/XAxis";
import { CurrentTimeLine } from "./components/molecule/CurrentTimeLine";
import { TooltipContainer } from "./components/molecule/TooltipContainer";
// import { GraphDataRow } from './types';
import { Grids } from "domain/visitor-traffic/components/Grids";
import { useAppState } from "hooks";
import { TrafficGraphTypes } from "types";
import { AverageLineAnnotation } from "../Average/AverageLineAnnotation";
import { getAverageItemOfTrafficData } from "../domain/summary";
import { getAverageOfTrafficDataWithoutToday } from "../processors";
import { VisitorAxisLeft } from "./components/molecule/VisitorAxisLeft";
import { getDateRangeFromData, hasMultiDaysData } from "./processors";
import { useSelector } from "react-redux";
import { selectSelectedDevice } from "providers/redux/globalSelectSlice";

const GRAPH_PADDING = { x: 30, y: 40 }; // padding for the graph to make the graph fully visible
const GRAPH_PADDING_FOR_BARS = { x: 50, y: 40 }; // padding for the graph to make the graph fully visible

/**
 * A base bar chart component to render various traffic info
 * X -> time (one day, multi)
 * Y -> counts
 */
export function BarChart({
  onBarClick,
  selectedBarCategory,
  hideLeftAxis,
  data,
  scales,
  ...optionals
}) {
  // Props
  const {
    layoutProps,
    showForecast = true,
    timezone,
    alignXTicksToCenterOfBar = false,
    currentTimeProps,
    tooltipProps,
    yPropName,
    // ...rest
  } = optionals;
  const { currentTimeLeftOffset, showLine: showCurrentTimeLine } =
    currentTimeProps || {};
  const {
    height,
    width,
    barsGroupSize,
    yMax,
    xRange,
    yRange,
    singleBarGroupWidth,
  } = layoutProps || {};
  const { dateTooltipFormatString } = tooltipProps;
  const { yValueScale, timeScale } = scales || {};

  // tooltip hooks
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<any>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Hooks
  // const theme = useTheme();

  // Process props
  const {
    mainData,
    forecast,
    subData,
    // subForecast,
    isData,
  } = data || { mainData: [], forecast: [], subData: [] };
  const dateRange = getDateRangeFromData(mainData);
  const hasMultiDays = hasMultiDaysData(mainData);
  const processedTooltipProps = {
    showTooltip,
    hideTooltip,
    tooltipDateFormat: dateTooltipFormatString,
  };
  // const allBarsLeftOffset = hasMultiDays ? - singleBarGroupWidth / 2 : 0;
  const allBarsLeftOffset = 0;
  // const forecastProps = {
  //   showForecast,
  //   data: forecast,
  // };
  const dateAxisTickValues = mainData?.map((d) => parseString(d.date));

  return (
    <>
      <div
        style={{ height, width }}
        className="max-w-full"
        data-cy={"bar-chart"}
      >
        <svg
          ref={containerRef}
          width={width}
          height={height}
          data-cy="traffic-graph-svg"
          className="block"
        >
          <Group left={allBarsLeftOffset}>
            {isData ? (
              <>
                <Grids
                  left={GRAPH_PADDING_FOR_BARS.x / 2 + 8}
                  scale={yValueScale}
                  width={barsGroupSize.width}
                />
                {showForecast ? (
                  <ForecastBarGroup
                    data={forecast}
                    valueScale={yValueScale}
                    yMax={yMax}
                    barWidth={singleBarGroupWidth}
                    tooltipProps={processedTooltipProps}
                    scales={scales}
                  />
                ) : null}
                <MainBarGroup
                  data={mainData}
                  yMax={yMax}
                  valueScale={yValueScale}
                  onBarClick={onBarClick}
                  barWidth={singleBarGroupWidth}
                  scales={scales}
                  tooltipProps={processedTooltipProps}
                  timezone={timezone}
                  yPropName={yPropName}
                />
                <CategorizedBarGroup
                  data={subData}
                  yMax={yMax}
                  valueScale={yValueScale}
                  selected={selectedBarCategory}
                  scales={scales}
                  tooltipProps={processedTooltipProps}
                  barGroupWidth={singleBarGroupWidth}
                />
              </>
            ) : null}
          </Group>
          {showCurrentTimeLine ? (
            <CurrentTimeLine
              hideLeftAxis={hideLeftAxis}
              xRange={xRange}
              left={currentTimeLeftOffset}
              yRange={yRange}
              timezone={timezone}
            />
          ) : null}
          <DateTimeXAxis
            yMax={yMax}
            width={width}
            dateRange={dateRange}
            timezone={timezone}
            timeScale={timeScale}
            tickValues={dateAxisTickValues}
            left={alignXTicksToCenterOfBar ? singleBarGroupWidth / 2 : 0}
          />
          {!hideLeftAxis && (
            <AxisLeft
              tickFormat={(d: any) => {
                if (Number.isInteger(d)) return d;
              }}
              hideZero={true}
              hideAxisLine={true}
              hideTicks={true}
              left={GRAPH_PADDING.x + 8}
              scale={yValueScale}
            />
          )}
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <TooltipContainer
              tooltipData={tooltipData}
              tooltipOpen={tooltipOpen}
              isSingleDay={!hasMultiDays}
              formatString={dateTooltipFormatString}
              timezone={timezone}
            />
          </TooltipInPortal>
        )}
      </div>
    </>
  );
}

/**
 * only for multi-days data
 */
export function BarChartWithStackedForecast({
  onBarClick,
  selectedBarCategory,
  hideLeftAxis,
  data,
  scales,
  ...optionals
}) {
  // Props
  const {
    layoutProps,
    showForecast = true,
    timezone,
    alignXTicksToCenterOfBar = false,
    // currentTimeProps,
    tooltipProps,
    averageLineProps,
    // ...rest
  } = optionals;
  const { height, width, barsGroupSize, yMax, singleBarGroupWidth } =
    layoutProps || {};
  const { dateTooltipFormatString } = tooltipProps || {};
  const { showAverageLine } = averageLineProps || {};
  const { yValueScale, timeScale } = scales || {};

  // tooltip hooks
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<any>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Hooks
  // const theme = useTheme();

  // Process props
  const {
    mainData,
    forecast,
    subData,
    // subForecast,
    isData,
  } = data || { mainData: [], forecast: [], subData: [] };
  const dateRange = getDateRangeFromData(mainData);
  const hasMultiDays = hasMultiDaysData(mainData);
  const processedTooltipProps = {
    ...tooltipProps,
    showTooltip,
    hideTooltip,
    tooltipDateFormat: dateTooltipFormatString,
  };
  // const allBarsLeftOffset = hasMultiDays ? - singleBarGroupWidth / 2 : 0;
  const allBarsLeftOffset = 0;
  const forecastProps = {
    showForecast,
    data: forecast,
  };
  const dateAxisTickValues = mainData?.map((d) => parseString(d.date));
  const averageValue = getAverageOfTrafficDataWithoutToday(mainData, timezone);
  const averageY = yValueScale(averageValue);

  return (
    <>
      <div
        style={{ height, width }}
        className="max-w-full"
        data-cy={"bar-chart-WithStackedForecast"}
      >
        <svg
          ref={containerRef}
          width={width}
          height={height}
          data-cy="traffic-graph-svg"
          className="block"
        >
          <Group left={allBarsLeftOffset}>
            {isData ? (
              <>
                <Grids
                  left={GRAPH_PADDING_FOR_BARS.x / 2 + 8}
                  scale={yValueScale}
                  width={barsGroupSize.width}
                />
                <MainBarGroupWithStacked
                  data={mainData}
                  yMax={yMax}
                  valueScale={yValueScale}
                  onBarClick={onBarClick}
                  barWidth={singleBarGroupWidth}
                  scales={scales}
                  tooltipProps={processedTooltipProps}
                  timezone={timezone}
                  forecastProps={forecastProps}
                />
                <CategorizedBarGroup
                  data={subData}
                  yMax={yMax}
                  valueScale={yValueScale}
                  selected={selectedBarCategory}
                  scales={scales}
                  tooltipProps={processedTooltipProps}
                  barGroupWidth={singleBarGroupWidth}
                />
              </>
            ) : null}
          </Group>
          <DateTimeXAxis
            yMax={yMax}
            width={width}
            dateRange={dateRange}
            timezone={timezone}
            timeScale={timeScale}
            tickValues={dateAxisTickValues}
            left={alignXTicksToCenterOfBar ? singleBarGroupWidth / 2 : 0}
          />
          <VisitorAxisLeft
            hide={hideLeftAxis}
            left={GRAPH_PADDING.x + 8}
            scale={yValueScale}
          />
          {showAverageLine ? (
            <AverageLineAnnotation y={averageY} width={width} />
          ) : null}
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <TooltipContainer
              tooltipData={tooltipData}
              tooltipOpen={tooltipOpen}
              isSingleDay={!hasMultiDays}
              formatString={dateTooltipFormatString}
              timezone={timezone}
            />
          </TooltipInPortal>
        )}
      </div>
    </>
  );
}

export function BarChartWithStackedForecastAndPasserbys({
  onBarClick,
  selectedBarCategory,
  hideLeftAxis,
  data,
  scales,
  ...optionals
}) {
  // Props
  const {
    layoutProps,
    showForecast = true,
    timezone,
    alignXTicksToCenterOfBar = false,
    // currentTimeProps,
    tooltipProps,
    averageLineProps,
    hideTotalTrafficData = false,
    // ...rest
  } = optionals;
  const { height, width, barsGroupSize, yMax, singleBarGroupWidth } =
    layoutProps || {};
  const { dateTooltipFormatString } = tooltipProps || {};
  const { showAverageLine } = averageLineProps || {};
  const { yValueScale, timeScale } = scales || {};
  // tooltip hooks
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<any>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  // Hooks
  const selectedDevice = useSelector(selectSelectedDevice);
  const { appState } = useAppState();
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );
  // Process props
  const {
    mainData,
    forecast,
    subData,
    // subForecast,
    isData,
  } = data || { mainData: [], forecast: [], subData: [] };
  const dateRange = getDateRangeFromData(mainData);
  const hasMultiDays = hasMultiDaysData(mainData);
  const processedTooltipProps = {
    ...tooltipProps,
    showTooltip,
    hideTooltip,
    tooltipDateFormat: dateTooltipFormatString,
  };
  const allBarsLeftOffset = 0;
  const forecastProps = {
    showForecast,
    data: forecast,
  };
  const dateAxisTickValues = mainData?.map((d) => parseString(d.date));
  const averageValue: number = useMemo(() => {
    // getAverageOfTrafficDataWithoutToday(mainData, timezone, currentGraphType);
    const average = getAverageItemOfTrafficData(
      mainData,
      undefined,
      currentGraphType
    );
    return average[currentGraphType] ?? 0;
  }, [mainData, currentGraphType]);
  const averageY = yValueScale(averageValue);

  return (
    <>
      <div
        style={{ width }}
        className="max-w-full"
        data-cy={"bar-chart-WithStackedForecastAndPasserbys"}
      >
        <svg
          ref={containerRef}
          width={width}
          height={height}
          data-cy="traffic-graph-svg"
          className="block"
        >
          <Group left={allBarsLeftOffset}>
            {isData ? (
              <>
                <Grids
                  left={GRAPH_PADDING_FOR_BARS.x / 2 + 8}
                  scale={yValueScale}
                  width={barsGroupSize.width}
                />
                {!hideTotalTrafficData && (
                  <MainBarGroupWithStacked
                    data={mainData}
                    yMax={yMax}
                    valueScale={yValueScale}
                    onBarClick={onBarClick}
                    barWidth={singleBarGroupWidth}
                    scales={scales}
                    tooltipProps={processedTooltipProps}
                    timezone={timezone}
                    forecastProps={forecastProps}
                    yPropName={currentGraphType}
                    yPropLabel={selectedDevice ? "" : "All"}
                  />
                )}
                <CategorizedBarGroup
                  data={subData}
                  yMax={yMax}
                  valueScale={yValueScale}
                  selected={selectedBarCategory}
                  scales={scales}
                  tooltipProps={processedTooltipProps}
                  barGroupWidth={singleBarGroupWidth}
                  onBarClick={onBarClick}
                  isBarClickEnabled={hideTotalTrafficData}
                />
              </>
            ) : null}
          </Group>
          <DateTimeXAxis
            yMax={yMax}
            width={width}
            dateRange={dateRange}
            timezone={timezone}
            timeScale={timeScale}
            tickValues={dateAxisTickValues}
            left={alignXTicksToCenterOfBar ? singleBarGroupWidth / 2 : 0}
          />
          <VisitorAxisLeft
            hide={hideLeftAxis}
            left={GRAPH_PADDING.x + 8}
            scale={yValueScale}
          />
          {showAverageLine && !hideTotalTrafficData ? (
            <AverageLineAnnotation y={averageY} width={width} />
          ) : null}
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <TooltipContainer
              tooltipData={tooltipData}
              tooltipOpen={tooltipOpen}
              isSingleDay={!hasMultiDays}
              formatString={dateTooltipFormatString}
              timezone={timezone}
            />
          </TooltipInPortal>
        )}
      </div>
    </>
  );
}
