import { isDate } from "date-fns";
import { mapObjIndexed } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  UnixtimeSelectedDate,
  changeTimeZone,
  createDateByUnixTime,
  getNow,
  getUnixTimeByDate,
} from "features/date";
import { DateRangePicker } from "features/date/components/molecule/DateRangePicker";
import {
  selectSelectedDate,
  updateEndDate,
  updateSelectedDate,
} from "providers/redux/globalSelectSlice";
import { DatePicker } from "./atomic/DatePicker";
// import { resetHeatmapFilter } from 'providers/redux/heatMapSilce';
import { DateSelector } from "../../../domain/time-range/date-range/components/TimeRange/Date/DateSelector";

const DatePickerContainer = styled.div`
  /* width: 40px; */
  display: flex;
  /* height: 100%; */
  align-items: flex-end;
  margin-left: 0.5rem;
`;

type DateSelectorsProps = {
  onlySingleDayQueries: boolean;
  setCustomDate?: (date: Date) => void;
  [x: string]: any;
};

/**
 *
 */
export const DateSelectorGroup = ({
  onlySingleDayQueries,
  ...optionals
}: DateSelectorsProps) => {
  const { onChange, timezone, label, setCustomDate } = optionals;

  // Hooks
  const dispatch = useDispatch();
  // Global state
  const globalDate: UnixtimeSelectedDate = useSelector(selectSelectedDate);
  const selectedDates: { startDate: Date; endDate: Date } = mapObjIndexed(
    createDateByUnixTime,
    globalDate
  );

  const maxSelectableDate = getNow();
  // console.debug('localTimezoneOffset', localTimezoneOffset);
  // const maxSelectableDate = addMilliseconds(getNow(), localTimezoneOffset);

  const handleDateRangeChangeFromCalendar = (
    input: { startDate: Date; endDate: Date } | Date
  ) => {
    // console.debug('handleDateRangeChangeFromCalendar', input);
    if (isDate(input)) {
      // is single date
      // get a date which is the day with same hour, minute, second at that day
      const nextEndDate = getUnixTimeByDate(
        changeTimeZone(input as Date, timezone)
      );

      //@ts-ignore
      // const dateUnix = getUnixTimeByDate(input);

      //@ts-ignore
      setCustomDate && setCustomDate(input);
      dispatch(updateEndDate(nextEndDate));
      onChange && onChange(nextEndDate);
      return;
    }

    // Multi days
    //@ts-ignore
    const { startDate, endDate } = input || {};

    /**
     * no start date -> set start date to null
     */
    const nextStartDate = Boolean(startDate)
      ? getUnixTimeByDate(startDate)
      : null;
    // set both start and end
    const nextEndDate = getUnixTimeByDate(endDate);
    const nextSelectedDate = { startDate: nextStartDate, endDate: nextEndDate };
    dispatch(updateSelectedDate(nextSelectedDate));
    onChange && onChange(nextSelectedDate);
  };

  const handleDateChangeFromSelector = (d: UnixtimeSelectedDate) => {
    try {
      // console.debug("Date selector change date value: ", d);
      const nextEndDate = d.endDate;
      dispatch(
        updateSelectedDate({ startDate: d.startDate, endDate: nextEndDate })
      );
      // dispatch(resetHeatmapFilter());
      onChange && onChange(d);
    } catch (error) {
      console.error("handleDateChangeFromSelector got error");
      console.error(error);
    }
  };

  return (
    <div
      className="flex items-stretch w-full lg:w-auto"
      data-cy={"date-selector-container"}
    >
      <DateSelector
        onChange={handleDateChangeFromSelector}
        onlySingleDayQueries={onlySingleDayQueries}
        value={globalDate}
        timezone={timezone}
        label={label}
      />
      <DatePickerContainer
        data-cy={"customized-date-picker-container"}
        className="mb-1"
      >
        {onlySingleDayQueries ? (
          <DatePicker
            selected={selectedDates?.endDate}
            onChange={handleDateRangeChangeFromCalendar}
            customInput={<FontAwesomeIcon icon="calendar-alt" />}
            maxDate={maxSelectableDate}
          />
        ) : (
          <DateRangePicker
            customInput={<FontAwesomeIcon icon="calendar-alt" />}
            maxDate={maxSelectableDate}
            onChange={handleDateRangeChangeFromCalendar}
            selectedDates={selectedDates}
            enableRange={!onlySingleDayQueries}
            timezone={timezone}
          />
        )}
      </DatePickerContainer>
    </div>
  );
};
