import { Select } from "components/molecule/Select";
import { UnixtimeSelectedDate } from "features/date";
import { createDisplayedOptions, assignValue } from "features/date";

/**
 *
 */
export function DateSelector({
  onChange,
  value,
  ...optionals
}: {
  onChange: Function;
  onlySingleDayQueries?: boolean;
  value: UnixtimeSelectedDate;
  [x: string]: any;
}) {
  const {
    onlySingleDayQueries = false,
    showAll = false,
    label = "Date",
    timezone,
    // ...rest
  } = optionals;

  // Process props
  const displayedOptions = createDisplayedOptions(
    onlySingleDayQueries,
    value,
    timezone
  );

  const handleChange = (nextValue) => {
    onChange && onChange(nextValue.value);
  };

  return (
    <Select
      options={displayedOptions}
      label={label}
      value={assignValue(value, displayedOptions)}
      onChange={handleChange}
      showall={showAll}
      data-cy={"date-selector"}
    />
  );
}
