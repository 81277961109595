import { DateSelected } from "features/date";
import { Customer, Floorplan, Zone } from "../types/interfaces";
import { useAppState } from "../hooks/useAppState";
import { isSameDay, subDays } from 'features/date';

export type CustomerSelected = Customer | null;
export type SelectorProps = string | null;
export type ChangeSelectedCustomer = { customerId: string; customerName: string };
export type ChangeSelectedDate = DateSelected;

// * Functions used to change the selectors in the app

const useSelectors = () => {
  const { appDispatch } = useAppState();

  const changeSelectedFloorplan = (floorplan: Floorplan | null) => {
    appDispatch({ type: "UPDATE_FLOORPLAN", newValue: floorplan });
  };
  const changeSelectedDevice = (device: Zone | null) => {
    appDispatch({ type: "UPDATE_DEVICE", newValue: device });
  };

  const changeSelectedFloor = (floor: string | null) => {
    appDispatch({ type: "UPDATE_FLOOR", newValue: floor });
    // * When Floor is changed reset Location
    appDispatch({ type: "UPDATE_LOCATION", newValue: null });
  };
  const changeSelectedLocation = (location: SelectorProps) => {
    appDispatch({ type: "UPDATE_LOCATION", newValue: location });
  };

  const changeSelectedBuilding = (building: SelectorProps) => {
    appDispatch({ type: "UPDATE_BUILDING", newValue: building });
  };

  // * Change query Date based on Date Selected
  const changeSelectedDate = (dateSelected: ChangeSelectedDate) => {
    if (dateSelected === DateSelected.today) {
      appDispatch({ type: "UPDATE_QUERY_DATE", newValue: new Date() });
    }
    if (dateSelected === DateSelected.yesterday) {
      appDispatch({ type: "UPDATE_QUERY_DATE", newValue: subDays(new Date(), 1) });
    }
    if (dateSelected === DateSelected.week || dateSelected === DateSelected.month) {
      appDispatch({ type: "UPDATE_QUERY_DATE", newValue: new Date() });
    }
    appDispatch({ type: "UPDATE_DATE", newValue: dateSelected });
  };

  // * If selecting a custom day and it is either today or yesterday change DateSelected to those
  const setCustomDate = (date: Date) => {
    if (isSameDay(date, subDays(new Date(), 1))) {
      appDispatch({ type: "UPDATE_QUERY_DATE", newValue: date });
      return appDispatch({ type: "UPDATE_DATE", newValue: DateSelected.yesterday });
    }
    if (isSameDay(date, new Date())) {
      appDispatch({ type: "UPDATE_QUERY_DATE", newValue: date });
      return appDispatch({ type: "UPDATE_DATE", newValue: DateSelected.today });
    }
    appDispatch({ type: "UPDATE_QUERY_DATE", newValue: date });
    appDispatch({ type: "UPDATE_DATE", newValue: DateSelected.custom });
  };

  return {
    changeSelectedBuilding,
    changeSelectedLocation,
    changeSelectedDate,
    changeSelectedFloor,
    changeSelectedFloorplan,
    setCustomDate,
    changeSelectedDevice,
  };
};
export default useSelectors;
