import { Icon } from 'components/atomic/Icon';
import {useTheme} from 'styled-components';
import { Typography } from '../atomic/Typography';

export function InfoCard({ text, ...optionals }) {
  const { subHeadingText = 'Info', icon = 'info-circle', ...rest } = optionals;
  const theme = useTheme();

  return (
    <div className='border-l-4 border-y-0 border-r-0 pt-2 px-2 border-slate-300 border-solid' {...rest}>
      <div className='text-xl mb-1 flex items-center space-x-2'>
        <Icon name={icon} color={theme.colors.alert} /> <span className='font-bold'>{subHeadingText}</span>
      </div>
      <Typography className='pl-1'>{text}</Typography>
    </div>
  );
}
