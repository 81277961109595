export type HoursOfOperation = {
  start: string | number;
  end: string | number;
  timezone?: string; // a timezone name in Time Zone Database, List of tz database time zones https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  weekly?: WeeklyHours;
  holidays?: Holidays[];
}

export enum Holidays {
  christmas,
  thanksgiving,
}

export type Hours = [number, number] | [];

export type WeeklyHours = {
  monday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
  tuesday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
  wednesday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
  thursday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
  friday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
  saturday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
  sunday: {
    open: string;
    close: string;
    isOpen: boolean;
  };
};