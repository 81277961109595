import { scaleLinear } from "@visx/scale";
import { Numeric } from "d3-array";
import _ from "lodash";
import { concat, defaultTo, pipe, pluck } from "ramda";

import { VisitorTrafficGraphForecast } from "domain/visitor-traffic/visitor-traffic-data";
import {
  convertDateToTimezonedRFC3339AtEndOfDay,
  convertDateToTimezonedRFC3339AtStartOfDay,
  diffInCalendarDays,
  differenceInDays,
  formatDate,
  formatDateStringToText,
  getMaxDate,
  getMinDate,
  getNow,
  getUnixTimeByDate,
  isAfter,
  isBefore,
  parseString,
} from "features/date";
import { TrafficGraphTypes, VisitorTrafficGraphData } from "types";
import { isFilledArray } from "utils";
import { GraphDataRow, Keys } from "./types";

/** @description except "date", "accum" */
export function getKeysOfData(data): Keys[] {
  return Object.keys(data[0]).filter(
    (d) => d !== "date" && d !== "accum"
  ) as Keys[];
}

export function getMaxOfOverallProp(
  arr: VisitorTrafficGraphData[] | VisitorTrafficGraphForecast[]
): number {
  return Math.max(...arr.map((d) => d?.overall));
}

/** @description except filtered props in getKeysOfData */
export function getMaxOfAllProps(
  arr: VisitorTrafficGraphData[] | VisitorTrafficGraphForecast[]
): number {
  const keys = getKeysOfData(arr);
  return Math.max(
    ...arr.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
  );
}

/**
 *
 */
export function getXYBounds(width: number, height: number, margin) {
  const { left, top, right } = margin || {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };
  const xMax = width - left - right - 40;
  const yMax = height - top;
  // console.log('yMax', yMax);
  // console.debug('xMax', xMax);
  return [xMax, yMax];
}

/**
 *
 * @param width :
 * @param height
 * @param padding {x: number, y: number} padding for the graph in container
 * @returns
 */
export function createXYBounds(width: number, height: number, padding: any) {
  const { x, y } = padding || {};
  const xMin = x;
  const xMax = width - x;
  const yMax = height - y;
  const yMin = y;
  // console.log('yMax', yMax);
  // console.debug('graph xMax', xMax);
  return {
    xRange: [xMin, xMax] as [Numeric, Numeric],
    yRange: [yMin, yMax] as [Numeric, Numeric],
  };
}

const mapOverall = pipe(defaultTo([{}]), pluck("overall"));

/**
 *
 * @param fullData
 * @param selectedCategoryNames
 * @returns max value by all selected property names and overall, if no selected property name, return max value of overall
 */
export function getMaxValueOfTraffic(
  fullData,
  selectedCategoryNames?: string[]
) {
  // console.debug(
  //   'getMaxValueOfTraffic selectedCategories: ',
  //   selectedCategoryNames
  // );
  const {
    data,
    forecast,
  }: {
    data: VisitorTrafficGraphData[];
    forecast?: VisitorTrafficGraphForecast[];
  } = fullData || { data: [], forecast: [] };

  if (!isFilledArray(data) && !isFilledArray(forecast)) {
    return 0;
  }

  let maxValue: number;
  // overall is always displayed
  // get max overall value
  // const totalArr = [...data, ...forecast];
  const dataOverall = mapOverall(data);
  const forecastOverall = mapOverall(forecast);
  const totalArr: number[] = concat(dataOverall, forecastOverall);
  const maxOverAll = _.max(totalArr);
  maxValue = maxOverAll;

  if (isFilledArray(selectedCategoryNames)) {
    selectedCategoryNames.forEach((category) => {
      const maxCategoryValue = _.max(data.map((item) => item[category]));
      maxValue = Math.max(maxValue, maxCategoryValue);
    });
  }

  // console.debug('MaxValueOfTraffic', maxValue);
  return maxValue;
}

/**
 *
 * @returns when selected day is a single day return the hours, discard minutes number, e.g. 1pm, 2pm, 3pm, ...
 */
export function formatDateWithoutMinute(
  date: string,
  timezone,
  isSingleDay: boolean
) {
  const result = formatDate(
    new Date(date),
    timezone,
    isSingleDay ? "haaa" : "MMM dd"
  );
  // console.debug('formatDate result', result);
  return result;
}

/**
 * when selected day is a single day return the hours, e.g. 1pm, 2pm, 3pm, ...
 * @returns
 */
export function formatTooltipDateText(
  date: string,
  isSingleDay: boolean,
  timezone?: string
) {
  const formatString = isSingleDay ? "h:mmaaa" : "MMMM dd";
  const result = formatDateStringToText(date, formatString, timezone);
  return result;
}

/**
 *
 * @param data
 * @param selectedBarCategory
 * @returns {number[]} all the numbers of visitor would be shown in the graph
 */
export function getDisplayedDataArray(
  data,
  selectedBarCategory,
  shouldDisplayForecast,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors,
  ignoreMainData: boolean = false
): number[] {
  // console.debug('getDisplayedDataArray', data, selectedBarCategory);
  const { mainData, forecast, subData } = data || {
    mainData: [],
    forecast: [],
    subData: [],
    subForecast: [],
  };
  // always show 'overall'
  let result = [];
  if (!ignoreMainData) {
    mainData?.forEach((item) => {
      if (!item) return;
      result.push(item[key]);
    });
  }

  const selectedValues =
    isFilledArray(selectedBarCategory) &&
    selectedBarCategory.map((item) => item?.value);
  if (selectedValues) {
    selectedValues.forEach((subPropName) => {
      if (subPropName === key) {
        return;
      } else if (subPropName === "forecast") {
        if (isFilledArray(forecast)) {
          const forecastData = forecast
            ?.map((item) => item[subPropName])
            ?.filter((item) => item);
          result = [...result, ...forecastData];
        }
      } else {
        const subDataArray = subData
          ?.map((item) => item[subPropName])
          ?.filter((item) => item);
        result = subDataArray ? [...result, ...subDataArray] : result;
      }
    });
  }
  if (shouldDisplayForecast) {
    if (isFilledArray(forecast)) {
      const forecastData = forecast
        ?.map((item) => item[key])
        ?.filter((item) => item);
      result = [...result, ...forecastData];
    }
  }
  return result;
}

/**
 * X axis domain for visitor graph
 */
export function getDisplayedDateList(
  data: any[],
  forecast?: any[],
  showForecast?: boolean
): string[] {
  const shouldDisplayForecast: boolean =
    showForecast && isFilledArray(forecast);
  const result = shouldDisplayForecast
    ? [...data, ...forecast]?.map((d) => d?.date)
    : data?.map((d) => d?.date);
  // console.debug('DisplayedDateDomain', result);
  return result;
}

/**
 * today is from the beginning of the day at given timezone to the end of the day at given timezone
 */
export function hasTodayData(data: GraphDataRow[], timezone?: string): boolean {
  // console.debug('hasTodayData input', data);
  if (!isFilledArray(data)) {
    return false;
  }
  const today = getNow();
  const start = parseString(
    convertDateToTimezonedRFC3339AtStartOfDay(today, timezone)
  );
  const end = parseString(
    convertDateToTimezonedRFC3339AtEndOfDay(today, timezone)
  );
  const allDates: Date[] = data.map((item) => parseString(item.date));
  const result = allDates.some(
    (date) => isBefore(date, end) && isAfter(date, start)
  );
  // console.debug('hasTodayData output', result);
  return result;
}

/**
 *
 */
export function getLatestDate(data: GraphDataRow[]): Date {
  if (!isFilledArray(data)) {
    return null;
  }
  const allDates: Date[] = data.map((item) => parseString(item.date));
  return getMaxDate(allDates);
}

/**
 *
 */
export function hasMultiDaysData(data: GraphDataRow[]): boolean {
  if (!isFilledArray(data)) {
    return false;
  }
  const allDates: Date[] = data.map((item) => parseString(item.date));
  const maxDate: Date = getMaxDate(allDates);
  const minDate = getMinDate(allDates);
  return differenceInDays(maxDate, minDate) >= 1;
}

export function hasSameDayData() {
  // const timeScaleDomain = getTimeScaleDomain(mainData, forecast, forecastProps?.shouldDisplay);
  // const isSameDay = checkIsSameDayInTimezoneForDates(
  //   timeScaleDomain[1],
  //   timeScaleDomain[0],
  //   customerTimezone
  // );
}

/**
 * get min date and max date in data
 */
export function getDateRangeFromData(data: any[]) {
  // console.debug("getDateRangeFromData input: ", data);
  // const formatString = "yyyy-MM-dd'T'HH:mm:ssXXX"; //e.g. "2022-11-02T00:00:00-04:00"
  if (!isFilledArray(data)) {
    return { startDate: undefined, endDate: undefined };
  }
  const allDates: Date[] = data.map((item) => parseString(item.date));
  const maxDate: Date = getMaxDate(allDates);
  const minDate = getMinDate(allDates);

  const result = {
    startDate: getUnixTimeByDate(minDate),
    endDate: getUnixTimeByDate(maxDate),
  };
  // console.debug('getDateRangeFromData output', result);
  return result;
}

export function getBarsLeftOffset(
  displayedDateDomain,
  dateBandScale,
  timeScale
) {
  // return 0;
  if (!isFilledArray(displayedDateDomain)) {
    return 0;
  }
  const firstDate = displayedDateDomain[0];
  const secondDate = displayedDateDomain[1];
  //@ts-ignore
  return (
    (timeScale(parseString(secondDate)) - timeScale(parseString(firstDate))) / 2
  );
}

/**
 * return a positive number
 */
export function getSingleBarWidth(
  displayedDateDomain: string[],
  timeScale
): number {
  if (!isFilledArray(displayedDateDomain)) {
    return 0;
  }
  const firstDate = displayedDateDomain[0];
  const secondDate = displayedDateDomain[1];
  const result = Math.abs(
    (timeScale(parseString(secondDate)) - timeScale(parseString(firstDate))) / 2
  );
  return result;
}

export function checkIsMultiDays(date1: string, date2: string) {
  if (!date1 || !date2) return false;
  const d1 = parseString(date1);
  const d2 = parseString(date2);
  return diffInCalendarDays(d1, d2);
}

/**
 *
 */
export function getVisitorTrafficScale(
  data,
  selectedBarCategory,
  shouldDisplay,
  yRange,
  key: TrafficGraphTypes = TrafficGraphTypes.visitors,
  ignoreMainData: boolean = false
) {
  const displayedDataArray = getDisplayedDataArray(
    data,
    selectedBarCategory,
    shouldDisplay,
    key,
    ignoreMainData
  );

  // console.log("file: VisitorTrafficBarChart.tsx:150 ~ yValueScale ~ displayedDataArray", displayedDataArray);
  const maxValue = _.max(displayedDataArray);
  const maxYDomainValue = Math.max(5, maxValue);
  return scaleLinear<number>({
    domain: [0, maxYDomainValue],
    range: _.clone(yRange).reverse() as any,
    nice: true,
    round: true,
  });
}
