import { Image } from "components/atomic/Image";
import styled from "styled-components";
import { useEffect, useRef } from "react";
import flvjs from "flv.js";
import { useUnmount } from "react-use";

const StyledImage = styled(Image)`
  max-width: 70vw;
  max-height: 100%;
  object-fit: contain;
`;

export function MainImage({ data, ...optionals }) {
  const { src } = data || {};

  return <StyledImage src={src} fluid={true} {...optionals} />;
}

export function MainVideo({ data, ...optionals }) {
  const { src } = data || {};
  const videoRef = useRef(null);
  const flvPlayer = useRef(null);

  useEffect(() => {
    try {
      const isValid =
        flvjs.isSupported() && videoRef.current && src.length > 100;
      if (isValid) {
        flvPlayer.current = flvjs.createPlayer({
          type: "flv",
          url: src,
        });
        //@ts-ignore
        flvPlayer.current.attachMediaElement(videoRef.current);
        flvPlayer.current.load();
        // flvPlayer.play();
      }
    } catch (error) {
      console.error(error);
    }
  }, [src]);

  useUnmount(() => {
    try {
      if (flvPlayer.current) {
        flvPlayer.current.destroy();
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <>
      <video
        id="videoElement"
        className="video-js vjs-default-skin"
        controls
        src={src}
        width={960}
        ref={videoRef}
      />
    </>
  );
}

export function MainMedia({ data, ...optionals }) {
  const { type } = data || {};
  if (type === "image") {
    return <MainImage data={data} {...optionals} />;
  } else if (type === "video") {
    return <MainVideo data={data} {...optionals} />;
  }
}
