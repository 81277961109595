/**
 * 
 */
import App from "./application/App";
import "./styles/index.css";
import { createRoot } from 'react-dom/client';

import { ProviderGroup } from 'providers';

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container!);

root.render(<ProviderGroup><App /></ProviderGroup>);