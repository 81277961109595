import { add, sub } from "date-fns";
import _ from "lodash";

import {
  checkIsTodayInTimezone,
  formatDate,
  formatInTimeZone,
  getLocalTimeZone,
  modifyTimezoneOfDateString,
  parseString,
} from "features/date";
import { capitalizeFirstLetter, isFilledArray } from "utils";
import { extractDateRange } from "../processors";
import { HEATMAP_COLORS, VISUAL_MAP_FIXED_PROPS } from "./constants";

/**
 *
 */
export function getVisualMapProps(
  data: any[],
  valueProp: string,
  width: number,
  hasToday
) {
  const maxValue = _.maxBy(data, valueProp)?.[valueProp] ?? 0;
  const leftRangeText = "Visitor Traffic Num:   0";
  const rangeText = [String(maxValue), leftRangeText]; //['1000+', '0'],
  const splitNumber = maxValue >= 4 ? 5 : maxValue + 1;

  return {
    ...VISUAL_MAP_FIXED_PROPS,
    text: rangeText,
    max: maxValue,
    splitNumber: splitNumber,
    inRange: {
      color: maxValue ? HEATMAP_COLORS : HEATMAP_COLORS.slice(0, 1),
    },
    left: hasToday ? width - 350 : "right",
  };
}

/**
 * process fetched data to the dataset feeded to Echarts: modify today
 */
export function processData(
  data: any[],
  valueProp: string,
  timezone: string,
  todayColor: string
) {
  // console.log("file: MonthlyHeatmapRect.tsx:18 ~ processData ~ data", data)

  let today = {
    value: undefined,
    itemStyle: undefined,
    originalDate: undefined,
  };
  if (!isFilledArray(data)) {
    return { data: null, today, hasToday: false };
  }

  const localTimezone = getLocalTimeZone();
  const processedData: Array<{
    value: [string, number];
    itemStyle?: any;
    originalDate?: string;
  }> = data?.map((item, index) => {
    const { date } = item;
    const value = item[valueProp] ?? 0;
    const parsedDate = parseString(date);
    const modifiedDate = modifyTimezoneOfDateString(date, localTimezone);
    if (checkIsTodayInTimezone(timezone, parsedDate)) {
      today = {
        value: [modifiedDate, value],
        itemStyle: {
          color: todayColor || "#ff3c3c",
        },
        originalDate: date,
      };
      return today;
    }
    return { value: [modifiedDate, value], originalDate: date };
  });
  // console.log("file: processors.ts:46 ~ processedRegularData", processedRegularData);
  const startData = processedData[0];
  const endData = processedData[processedData.length - 1];
  const startDay = new Date(startData.originalDate).getDay();
  const endDay = new Date(endData.originalDate).getDay();

  if (startDay > 0) {
    for (let i = 1; i <= startDay; i++) {
      const missingDate = sub(new Date(startData.originalDate), { days: i });
      const originalDate = formatInTimeZone(
        missingDate,
        timezone,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      );
      const modifiedDate = modifyTimezoneOfDateString(
        originalDate,
        localTimezone
      );
      const value: [string, number] = [modifiedDate, 0];
      const missinData = { value, originalDate };
      processedData.unshift(missinData);
    }
  }

  if (endDay < 6) {
    for (let i = 1; i <= 6 - endDay; i++) {
      const missingDate = add(new Date(endData.originalDate), { days: i });
      const originalDate = formatInTimeZone(
        missingDate,
        timezone,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      );
      const modifiedDate = modifyTimezoneOfDateString(
        originalDate,
        localTimezone
      );
      const value: [string, number] = [modifiedDate, 0];
      const missinData = { value, originalDate };
      processedData.push(missinData);
    }
  }
  return { data: processedData, today, hasToday: Boolean(today.value) };
  // return processedData;
}

/**
 * extract useful values for tooltip
 */
export function getTooltipProps(params, timezone) {
  const { data } = params || {};
  // console.log("file: processors.ts:47 ~ getTooltipProps ~ data", data)
  const { value: dataValue, originalDate } = data || {};
  if (dataValue) {
    const [, value]: [string, number] = dataValue || [];
    const parsed = parseString(originalDate);
    const formattedDate = timezone
      ? formatInTimeZone(parsed, timezone, "E, MMM dd")
      : originalDate.slice(0, 10);
    // const dayOfWeek = '';
    return [formattedDate, value];
  }

  const [date, value] = data || {};
  // console.log("file: MonthlyHeatmapRect.tsx:58 ~ MonthlyHeatmapRect ~ value", value)
  const formattedDate = date?.slice(0, 10);
  return [formattedDate, value];
}

/**
 *
 */
export function formatTooltip(params, ticket, timezone, label = "Overall") {
  // console.log("file: processors.ts:89 ~ formatTooltip ~ timezone", timezone)
  const [date, value] = getTooltipProps(params, timezone);
  return `<div><p class='mb-0'>${capitalizeFirstLetter(
    label
  )}: ${value}</p><p class='mb-0'>${date}</p></div>`;
}

export function getTitleProps(data, timezone, textColor: string) {
  const range = extractDateRange(data);
  const dateRangeText = range
    .map((d) => formatDate(parseString(d), timezone, "LLL d"))
    .join(" - ");
  // const valueRange = extent(data.map(item => item[valueProp]));
  // console.debug('MonthlyHeatmapRect range', range);
  const title = {
    //   top: 30,
    left: 18,
    text: dateRangeText,
    textStyle: {
      fontSize: 16,
      fontWeight: 400,
      color: textColor, //theme.colors.grayscale[2],
    },
  };
  return title;
}

/**
 * keep the ratio aspect of calendar grids
 */
export function getHeight(width, isLg) {
  return isLg
    ? Math.floor(width * 0.248 + 100)
    : Math.floor(width * 3.56 + 100);
}
