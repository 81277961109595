import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "components/atomic/Button";
import { Icon } from "components/atomic/Icon";
import { Modal } from "components/atomic/Modal";
import { Span } from "components/atomic/Span";
import { LargeContainer } from "components/molecule/Containers/LargeContainer";
import { UserStatusBadge } from "components/molecule/StatusMolecule";
import { ErrorBoundary } from "features/error-handling";
import { useUserListForCustomer } from "features/kaidu-config-server";
import useModal from "hooks/useModal";
import {
  getCurrentUser,
  selectSelectedCustomer,
} from "providers/redux/globalSelectSlice";
import { User } from "../types";
import { AddUserModal } from "./AddUserModal";
import { OperationsCell } from "./OperationsCell";
import { RemoveUserModal } from "./RemoveUserModal";
import { ReportScheduleModal } from "./ReportScheduleModal";
import { UserModalContent } from "./UserModalContent";
import { UserTable } from "./UserTable";

// function CreateNewUserButton() {}

/**
 *  only super user should see super user
 */
function getDisplayedUserList(userData, usersList) {
  const isSuperUser = userData?.isSuperUser;
  const filteredData = isSuperUser
    ? usersList
    : usersList?.filter((user) => !user.isSuperUser); // filter out super users
  return filteredData;
}

/**
 * Display user list and enable user-related operations
 */
export function UsersContainer() {
  // Hooks
  // console.log("file: UsersContainer.tsx:40 ~ UsersContainer ~ allUsers:", allUsers);
  const userData = useSelector(getCurrentUser);
  const customerData = useSelector(selectSelectedCustomer);
  const { data: allUsers, isLoading: isLoadingAllUsers } =
    useUserListForCustomer(customerData.customer_id);

  const { isShowing: isShowAddUserModal, toggle: toggleAddPendingUserModal } =
    useModal();
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  // const isLg = useMediaQueries('lg');

  // Local states
  // * Open/Close Remove User Modal
  const [selectedRemoveUser, setSelectedRemoveUser] =
    useState<User | null>(null);
  const [scheduledUser, setScheduledUser] = useState<User | null>(null);

  const closeRemoveUserModal = () => setSelectedRemoveUser(null);

  const usercolumns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "user_email",
      },
      {
        Header: "Type",
        accessor: "isSuperUser",
        Cell: ({ row }: any) => {
          const { isSuperUser, is_partner } = row?.original || {};
          return (
            <Span>
              {isSuperUser ? "Admin" : is_partner ? "Partner" : "User"}
            </Span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "pendingRegistration",
        Cell: ({ row }: any) => {
          const { pendingRegistration } = row?.original?.user || {};
          return (
            <UserStatusBadge isPending={pendingRegistration}>
              {pendingRegistration ? "Pending" : "Registered"}
            </UserStatusBadge>
          );
        },
      },
      {
        Header: "skipme",
        Cell: (props: any) => {
          const userOfRow: User = props?.row?.original;
          const isDeleteDisabled =
            (userData?.user_email &&
              userOfRow?.user_email === userData?.user_email) ||
            userOfRow?.isSuperUser;
          return (
            <OperationsCell
              user={userOfRow}
              setScheduledUser={setScheduledUser}
              setSelectedRemoveUser={setSelectedRemoveUser}
              setModalData={setModalData}
              setIsShowingModal={setIsShowingModal}
              isDeleteDisabled={isDeleteDisabled}
            />
          );
        },
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const displayedUserList = getDisplayedUserList(userData, allUsers);
  // const isSuperUser = userData?.isSuperUser;
  const isLoading = !customerData || isLoadingAllUsers;

  const customerName = customerData?.customer_name;

  return (
    <>
      <LargeContainer
        title="Users"
        icon="users"
        headerChildren={
          <div className="flex justify-end">
            <Button
              style={{
                borderRadius: "50px",
                // width: '130px',
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
                color: "white",
                border: "2px solid #0d6efd",
                backgroundColor: "#0d6efd",
              }}
              onClick={toggleAddPendingUserModal}
              className="flex justify-content-center align-items-center"
            >
              <Icon
                name="FaUserPlus"
                style={{ fontSize: "20px", marginRight: "6px" }}
              />
              Add User
            </Button>
          </div>
        }
        isLoading={isLoading}
        // error={isError}
      >
        <ErrorBoundary>
          <UserTable
            columns={usercolumns}
            data={displayedUserList}
            searchBarPlaceholder="Search User"
          />
        </ErrorBoundary>
      </LargeContainer>
      {isShowAddUserModal && (
        <AddUserModal
          show={isShowAddUserModal}
          onHide={toggleAddPendingUserModal}
          userData={userData}
          existingUsers={allUsers}
          customerData={customerData}
        />
      )}
      {!!selectedRemoveUser && (
        <RemoveUserModal
          user={selectedRemoveUser}
          show={!!selectedRemoveUser}
          onHide={closeRemoveUserModal}
          customerName={customerName}
          customerID={customerData.customer_id}
        />
      )}
      {!!scheduledUser && (
        <ReportScheduleModal
          user={scheduledUser}
          show={!!scheduledUser}
          onHide={() => setScheduledUser(null)}
          customerID={customerData.customer_id}
        />
      )}
      <Modal
        className="min-w-fit max-w-xl overflow-visible"
        show={isShowingModal}
        onHide={() => setIsShowingModal(false)}
      >
        <ErrorBoundary>
          <UserModalContent
            data={modalData}
            onHide={() => setIsShowingModal(false)}
          />
        </ErrorBoundary>
      </Modal>
    </>
  );
}
