// import styled from 'styled-components';
import { VisitorGraphToolTipView } from "components/features/charts/molecule/VisitorGraphToolTipView";
import { getLocalTimeZone } from "features/date";
import { formatTooltipDateText } from "../../processors";

/**
 * TooltipContainer with hook
 */
export function TooltipContainer({
  tooltipData,
  tooltipOpen,
  timezone,
  ...optionals
}: {
  tooltipData: { bar: any; time: string };
  timezone?: string;
  [x: string]: any;
}) {
  const { isSingleDay = true } = optionals;
  const { bar, time } = tooltipData || {};
  const { value, color, label } = bar || {};
  const customerTimezone = timezone || getLocalTimeZone();

  // Hooks

  const timeText =
    time && formatTooltipDateText(time, isSingleDay, customerTimezone);

  return (
    <>
      {tooltipOpen && tooltipData && (
        <div className="flex flex-col items-center">
          <VisitorGraphToolTipView
            label={label || ""}
            value={value}
            color={color}
            timeText={timeText}
          />
        </div>
      )}
    </>
  );
}
