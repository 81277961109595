import { Suspense } from "react";
import { useSelector } from "react-redux";

import { Spinner } from "components/atomic/Spinner";
import { ErrorBoundary } from "features/error-handling";
import useDocumentTitle from "hooks/useDocumentTitle";
import { selectPartnerCustomer } from "providers/redux/globalSelectSlice";
import { AppPageLayout } from "../../AppPageLayout";
import { SettingsContent } from "./SettingsContent";

// const SettingsContent = React.lazy(() => import('./SettingsContent').then(module => ({ default: module.SettingsContent })));

/**
 * Settings Page
 */
export function Settings() {
  const partnerCustomer = useSelector(selectPartnerCustomer);

  useDocumentTitle(partnerCustomer?.customer_name || "Kaidu");

  return (
    <AppPageLayout className="max-w-full">
      <ErrorBoundary>
        <Suspense fallback={<Spinner size={"md"} className="inset-1/2" />}>
          <SettingsContent />
        </Suspense>
      </ErrorBoundary>
    </AppPageLayout>
  );
}
