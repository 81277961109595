// import { TrafficGraphUIData } from 'features/charts';
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "components/atomic/Button";
import { Spinner } from "components/atomic/Spinner";
import { ErrorMsg } from "components/molecule/ErrorMsg";
import { MonthlyHeatmapRect } from "domain/visitor-traffic/monthly-view/MonthlyHeatmapRect";
import { useAppState } from "hooks";
import { selectSelectedDevice } from "providers/redux/globalSelectSlice";
import { TrafficGraphTypes } from "types";
import { useMonthlyData } from "../../application/pages/Home/hooks";
import { SecondaryDateRangeIndicator } from "../time-range/date-range/components/DateRangeIndicator";
import {
  addHoursToAllDates,
  getDateRangeFromData,
  getOffsetDiffInHours,
  setTimezoneForDateStrings,
} from "./processors";

function modifyMonthlyData(monthlyData, timezone) {
  const offsetDiff = Math.max(getOffsetDiffInHours(timezone), 0) || 12;
  const addedHours = addHoursToAllDates(monthlyData?.graph?.data, offsetDiff);
  // console.log("file: HistoricalTrafficGroup.tsx:14 ~ modifyMonthlyData ~ addedHours:", addedHours);
  const timezoneSet = setTimezoneForDateStrings(addedHours, timezone);
  // const result = addMinutesToAllDates(addedHours);
  return timezoneSet;
}

// Historical Calendar Accordion
function AccordionHeader({ isAccordionOpen, setIsAccordionOpen }) {
  return (
    <div className="accordion-header">
      <div className="flex">
        <h3
          className="text-xl"
          style={{ color: "#6c757d", marginRight: "5px" }}
        >
          Historical Calendar
        </h3>
        <Button
          className="text-sm"
          style={{
            backgroundColor: "#326ac2",
            color: "white",
            height: "36px",
            width: "120px",
            whiteSpace: "nowrap",
            marginLeft: "8px",
            marginTop: "-5px",
          }}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          {isAccordionOpen ? "Hide" : "Show"}
        </Button>
      </div>
    </div>
  );
}

function AccordionBody({
  range,
  monthlyDataError,
  isAccordionOpen,
  mainMonthlyData,
  onClickItem,
  timezone,
  onChangeDate,
}) {
  const { appState } = useAppState();
  const currentGraphType: TrafficGraphTypes = useMemo(
    () =>
      appState?.selected?.selectedTrafficGraphType ||
      TrafficGraphTypes.visitors,
    [appState.selected.selectedTrafficGraphType]
  );
  return (
    <>
      {range?.endDate && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "36px",
            justifyContent: "flex-end",
            alignItems: "center",
            whiteSpace: "nowrap",
            marginTop: "20px",
          }}
        >
          <SecondaryDateRangeIndicator
            dateRange={range}
            showNavigation={Boolean(onChangeDate)}
            onChangeDate={onChangeDate}
          />
        </div>
      )}
      <div className="accordion-body">
        {monthlyDataError ? (
          <ErrorMsg text={monthlyDataError?.message} />
        ) : null}
        {isAccordionOpen && !monthlyDataError && (
          <div className="flex justify-center w-full">
            <MonthlyHeatmapRect
              valueProp={currentGraphType}
              data={mainMonthlyData}
              onClick={onClickItem}
              timezone={timezone}
            />
          </div>
        )}
      </div>
    </>
  );
}

/**
 * Historical Traffic Group with API hooks
 * render content when response data is ready
 */
export function HistoricalTrafficGroup({
  customerID,
  endDate,
  onClickItem,
  timezone,
  ...optionals
}) {
  const { onChangeDate } = optionals;
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const device = useSelector(selectSelectedDevice);
  const {
    data: monthlyData,
    isLoading: isLoadingMonthlyData,
    error: monthlyDataError,
  } = useMonthlyData(
    isAccordionOpen ? customerID : null,
    endDate,
    {
      timezone,
    },
    isAccordionOpen ? device?.mqtt_device_id : null
  );

  if (isLoadingMonthlyData) {
    return <Spinner />;
  }
  // TODO: remove this hacky way for showing calendar properly in the future
  const mainMonthlyData = modifyMonthlyData(monthlyData, timezone);
  const range = getDateRangeFromData(mainMonthlyData, timezone);

  return (
    <div className="accordion">
      <AccordionHeader
        isAccordionOpen={isAccordionOpen}
        setIsAccordionOpen={setIsAccordionOpen}
      />
      {isAccordionOpen && (
        <AccordionBody
          range={range}
          monthlyDataError={monthlyDataError}
          isAccordionOpen={isAccordionOpen}
          mainMonthlyData={mainMonthlyData}
          onClickItem={onClickItem}
          timezone={timezone}
          onChangeDate={onChangeDate}
        />
      )}
    </div>
  );
}
