import { NextDateBtn } from './NextDateBtn';
import { PrevDateBtn } from './PrevDateBtn';
import { useDispatch } from 'react-redux';
import { resetHeatmapFilter } from 'providers/redux/heatMapSilce';

export function DateBtnGroup(props) {
  const dispatch = useDispatch();
  
  const handleClick = () => {
    dispatch(resetHeatmapFilter());
  };

  return (
    <>
      <PrevDateBtn onClick={handleClick} />
      <NextDateBtn onClick={handleClick}/>
    </>
  )
}