import { useRef, useState } from "react";
import { useController, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useTheme } from "styled-components";

import { Icon } from "components/atomic/Icon";
import { Input } from "components/atomic/Input";
import { LoadingMessage } from "components/features/Loaders/LoadingMessage";
// import { ErrorSpan } from 'components/atomic/ErrorMsg';
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { notifyError, notifySuccess } from "../../lib/services/notification";
import { isFilledArray } from "../../utils";

/**
 * Display and allow editing cell
 */
export function EditableCellWithSubmission({
  data,
  onSubmit,
  name,
  defaultValues,
  ...rest
}: {
  [x: string]: any;
}) {
  // Props
  const { successMsg, errorMsg, relatedKeys } = data || {};

  // Hooks
  const { control, handleSubmit } = useForm({ defaultValues });
  const queryClient = useQueryClient();
  //@ts-ignore
  const mutation = useMutation(onSubmit, {
    onSuccess: () => {
      // temp: returned parameter is just a string
      if (isFilledArray(relatedKeys)) {
        relatedKeys.forEach((key) => queryClient.invalidateQueries(key));
      }
      notifySuccess(successMsg, { autoClose: 3000 });
    },
    onError: (err: Error) => {
      errorMsg ? notifyError(errorMsg) : notifyError(err?.message);
    },
  });

  const {
    field: { onChange, onBlur, value },
  } = useController({
    name: name,
    control,
  });
  const theme = useTheme();

  // Local state & ref
  const [isEditing, setIsEditing] = useState(false);
  const cardActionBtnRef = useRef(null);
  const containerRef = useRef(null);

  // Handlers
  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  /**
   * Submit udpated values when device name icon is blured
   */
  const handleBlur = (data) => {
    //@ts-ignore
    mutation.mutate(data);
    setIsEditing(false);
    onBlur();
  };

  if (mutation?.isLoading) {
    return <LoadingMessage message={"Saving..."} />;
  }

  return (
    <div ref={containerRef}>
      <div className="gap-x-2 flex justify-between">
        {isEditing ? (
          <Input
            className="w-fit me-2"
            value={value}
            onChange={onChange}
            onBlur={handleSubmit(handleBlur)}
          />
        ) : (
          <span>{value || "-"}</span>
        )}
        {isEditing ? (
          <div className={"flex items-center"}>
            <Icon
              name="check"
              color={theme?.colors?.secondary}
              onClick={toggleIsEditing}
            />
          </div>
        ) : (
          <div ref={cardActionBtnRef} onClick={() => setIsEditing(true)}>
            <Icon name="edit" color={"gray"} size="md" />
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Display and allow editing cell
 */
export function EditableCell({
  name,
  formCallbacks,
  inputSize,
  inputMaxLength,
  ...rest
}: {
  [x: string]: any;
}) {
  // Props
  const { control } = formCallbacks || {};

  // Hooks
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name: name,
    control,
  });
  const theme = useTheme();

  // Local state & ref
  const [isEditing, setIsEditing] = useState(false);
  const cardActionBtnRef = useRef(null);
  const containerRef = useRef(null);

  // Handlers
  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  /**
   * Submit udpated values when device name icon is blured
   */
  const handleBlur = () => {
    //@ts-ignore
    setIsEditing(false);
    onBlur();
  };

  return (
    <div ref={containerRef} {...rest}>
      <div className="gap-x-2 flex">
        {isEditing ? (
          <Input
            className="w-fit me-2"
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            size={inputSize}
            maxLength={inputMaxLength}
          />
        ) : (
          <span className="break-all">{value || "-"}</span>
        )}
        {isEditing ? (
          <div className={"flex items-center"}>
            <Icon
              name="check"
              color={theme?.colors?.secondary}
              onClick={toggleIsEditing}
            />
          </div>
        ) : (
          <div
            ref={cardActionBtnRef}
            onClick={() => setIsEditing(true)}
            className={"flex items-center"}
          >
            <Icon name="edit" color={"gray"} size="md" />
          </div>
        )}
      </div>
    </div>
  );
}
