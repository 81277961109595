export enum DateSelected {
  today = "today",
  yesterday = "yesterday",
  week = "week",
  month = "month",
  custom = "custom",
}

export type UnixtimeSelectedDate = { 
  endDate: number; // unix timestamp
  startDate?: number; // unix timestamp
  timezone?: string;
};

export type DateRange = {
  endDate: Date;
  startDate?: Date;
  timezone?: string;
}


// Time Ranges
export type HHMMObject = {
  hour: number;
  minute: number;
};

export type HHMMOption = {
  label: string;
  value: {
    start: string;
    end: string;
    timezone?: string;
  }
}

export type HHMMValue = {
  start: string;
  end: string;
  timezone?: string;
}

export type DateRange2 = { 
  start?: number, // timestamp in miliseconds
  end: number // timestamp in miliseconds
};