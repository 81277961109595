import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";
// import {pipe} from 'ramda';
import { createDateByUnixTime } from "./foo";
import { resetTimeToTheStartOfDayForTimeZone } from "./setters";

/**
 *
 */
export function convertUnixTimestampToTimezonedRFC3339AtStartOfDay(
  unixDate: number,
  timezone: string
): string {
  const date = createDateByUnixTime(unixDate);
  const result = convertDateToTimezonedRFC3339AtStartOfDay(date, timezone);
  return result;
}

/**
 * replace all 'Z's
 * take a Date,
 */
export function convertDateToTimezonedRFC3339AtStartOfDay(
  date: Date,
  timezone: string
): string {
  // console.debug('convertDateToTimezonedRFC3339AtStartOfDay date, timezoned', date);

  const reseted = createDateByUnixTime(
    resetTimeToTheStartOfDayForTimeZone(date, timezone)
  );
  // const timezoned = getDateRFC(reseted);

  const timezoned = formatInTimeZone(
    reseted,
    timezone,
    "yyyy-MM-dd 00:00:00XXX"
  )
    .replaceAll(" ", "T")
    .replaceAll("Z", "");
  // console.log("file: convertor.ts:39 ~ timezoned:", timezoned);
  const offset = getTimezoneOffset(timezone, date);
  const result = offset === 0 ? timezoned.concat("+00:00") : timezoned;
  // console.log("convertDateToTimezonedRFC3339AtStartOfDay ~ result:", result)
  return result;
}

/**
 * replace all 'Z's
 */
export function convertDateToTimezonedRFC3339AtEndOfDay(
  date: Date,
  timezone: string
): string {
  // console.debug('timezoned', timezone);
  // const hourset = setHours(date, 23);
  const timezoned = formatInTimeZone(date, timezone, "yyyy-MM-dd 24:00:00XXX")
    .replaceAll(" ", "T")
    .replaceAll("Z", "");
  const offset = getTimezoneOffset(timezone, date);
  const result = offset === 0 ? timezoned.concat("+00:00") : timezoned;
  // const result = timezoned;
  return result;
}

export function convertUnixTimestampToTimezonedRFC3339AtEndOfDay(
  unixDate: number,
  timezone: string
): string {
  const date = createDateByUnixTime(unixDate);
  const result = convertDateToTimezonedRFC3339AtEndOfDay(date, timezone);
  return result;
}

/**
 * e.g. '2023-01-07T02:00:00-05:00'
 */
export function parseRFC3330ToJSON(dateString: string): {
  hour: number;
  minute: number;
  second: number;
  timezone: string;
} {
  if (typeof dateString !== "string" || !dateString || dateString.length < 14) {
    return null;
  }

  const hour = parseInt(dateString.substring(11, 13), 10);
  const minute = parseInt(dateString.substring(14, 16), 10);
  const second = parseInt(dateString.substring(17, 19), 10);
  const timezone = dateString.substring(20, 26);
  const result = {
    hour,
    minute,
    second,
    timezone,
  };
  return result;
}

/**
 * short date string
 */
export function parseDateToJSON(dateString: string): {
  hour: number;
  minute: number;
  second: number;
  timezone: string;
} {
  if (typeof dateString !== "string" || !dateString || dateString.length < 14) {
    return null;
  }

  const hour = parseInt(dateString.substring(0, 2), 10);
  const minute = parseInt(dateString.substring(3, 5), 10);
  const second = parseInt(dateString.substring(6, 8), 10);
  const timezone = dateString.substring(8, 14);
  const result = {
    hour,
    minute,
    second,
    timezone,
  };
  // console.log("file: processors.ts:18 ~ parseDateToJSON ~ result", result);

  return result;
}