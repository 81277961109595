import { getData } from "features/axios";
import { BASE_API } from "../constants";
import { KaiduDeviceStatusItem } from "./types";

const SUBPATH = "/kaidu_device_status";
export const KAIDU_DEVICE_STATUS_API = `${BASE_API}${SUBPATH}`;

export async function fetchDeviceStatusEnums(): Promise<
  KaiduDeviceStatusItem[]
> {
  return await getData(KAIDU_DEVICE_STATUS_API);
}
