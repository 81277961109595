// import styled from 'styled-components';
import { useState } from "react";
import { useSelector } from "react-redux";

import {
  useCustomerDataOfDefaultUser,
  useKaiduConfigList,
} from "features/kaidu-config-server";
import {
  useGatheredGlobalData,
  useHourlyDataModal,
} from "features/kaidu-stats-server";
import {
  selectSelectedCustomer,
  selectSelectedDevice,
} from "providers/redux/globalSelectSlice";
import { useMediaQueries } from "styles/themes";
import { useOfflineFlag } from "../../features/kaidu-stats-server/features/offline-time";

/**
 *
 */
export function useGlobalDataModal({
  selectedDateRange,
  timezone,
  enabled = false,
}) {
  const { startDate, endDate } = selectedDateRange || {};
  // Hooks
  const isLg = useMediaQueries("lg");
  const {
    isLoading: isLoadingCustomer,
    error: customerError,
    customerID,
  } = useCustomerDataOfDefaultUser();
  const { data: kaiduConfigList } = useKaiduConfigList(enabled);

  // Local state
  const [shouldDisplayForecast, setShouldDisplayForecast] = useState(false);

  // Dependent hooks
  const { data: offlineData } = useOfflineFlag(customerID, endDate, {
    startDate,
    timezone,
  });
  // console.log('file: GlobalDataModal.tsx:102 ~ offlineData', offlineData);
  const { isLoading: isLoadingGraphData, data } = useGatheredGlobalData(
    customerID,
    endDate,
    {
      startDate,
      timezone,
    }
  );
  const isLoading = isLoadingCustomer || isLoadingGraphData;

  return {
    isLoading,
    isLoadingGraphData,
    customerError,
    customerID,
    data,
    offlineData,
    kaiduConfigList,
    shouldDisplayForecast,
    setShouldDisplayForecast,
    isLg,
  };
}

export function useGlobalHourlyDataModal({ endDate, timezone, device_id }) {
  const isLg = useMediaQueries("lg");
  const customer = useSelector(selectSelectedCustomer);
  const device = useSelector(selectSelectedDevice);
  const { isLoading, data, refetch } = useHourlyDataModal({
    customerID: customer?.customer_id,
    endDate,
    timezone,
    deviceId: device_id || device?.mqtt_device_id,
  });

  return {
    isLoading: !customer || isLoading,
    customerID: customer?.customer_id,
    data,
    isLg,
    refetch,
  };
}
