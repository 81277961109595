import styled from 'styled-components';
import { Icon } from 'components/atomic/Icon';
import { SpinnerWithoutContainer } from 'components/atomic/Spinner';

const StyledImg = styled.img<{ isSelected: boolean }>`
  object-fit: cover;
  max-width: 100%;
  ${(p) =>
    p.isSelected &&
    `
    outline: 3px solid #e8f3699b;
  `}
`;

const Container = styled.div`
  position: relative;
  margin-right: 0.8rem;
  max-width: 12rem;
  width: 9rem;
  cursor: pointer;
`;

const CenteredIcon = styled(Icon)<{name: any}>`
  /* position: absolute; */
  font-size: 3rem;
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  margin: auto;
  color: #fff;
`;

export function Thumbnail({ alt, ...optionals }) {
  const { itemData, onClick, isSelected = false, } = optionals;
  // const { itemData, onClick, isSelected = false, ...rest } = optionals;

  const { mediaID, thumbnail, type } = itemData || {};

  const handleClick = () => {
    console.debug('thumbnail clicked: ', mediaID);
    onClick && onClick(mediaID);
  };

  return (
    <Container
      data-cy='thumbnail-item'
      onClick={handleClick}
      className={'flex justify-center items-center relative'}
    >
      {thumbnail ? (
        <>
          <StyledImg src={thumbnail} alt={alt} isSelected={isSelected} />
          <div
            className={
              'w-full h-full flex absolute justifiy-center items-center'
            }
          >
            {type === 'video' ? <CenteredIcon name='play-circle' /> : null}
          </div>
        </>
      ) : (
        <SpinnerWithoutContainer variant="light" />
      )}
    </Container>
  );
}
