import {
  selectSelectedDate,
  selectIsSelectedDateSameDay,
  addDaysToEndDate,
  updateSelectedDate,
} from "providers/redux/globalSelectSlice";
import { Button } from "components/atomic/Button";
import { useDispatch, useSelector } from "react-redux";
import { isToday, shiftDateRangeForward } from "features/date";

export function NextDateBtn({ ...optionals }) {
  const { onClick } = optionals;

  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(selectSelectedDate) || {};
  const isSingleDay = useSelector(selectIsSelectedDateSameDay);
  const text = isSingleDay ? "Next Day" : "Next Period";
  const isSelectedDateToday = isToday(endDate * 1000);
  const shouldShowNextBtn = !isSelectedDateToday;

  const handleClick = () => {
    if (isSingleDay) {
      dispatch(addDaysToEndDate(1));
    } else {
      const nextDateRange = shiftDateRangeForward({ startDate, endDate });
      dispatch(updateSelectedDate(nextDateRange));
    }

    onClick && onClick();
  };

  return (
    <>
      {shouldShowNextBtn ? (
        <Button
          onClick={handleClick}
          variant="outline-secondary"
          size="sm"
          data-cy={"next-day-button"}
        >
          {text}
        </Button>
      ) : null}
    </>
  );
}
