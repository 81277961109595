import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";

import { Button } from "components/atomic/Button";
import { Icon } from "components/atomic/Icon";
import {
  Dialog,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import {
  CamerasForScanners,
  DEVICE_CONFIG_API,
  KAIDU_DEVICES_LIST_API,
  KaiduDeviceConfiguration,
  putKaiduConfig,
  updateCustomerCustomData,
} from "features/kaidu-config-server";
import { Zone } from "../../../types";
// import { useAsyncFn } from 'react-use';
import { CardLine } from "components/atomic/Card";
import { IconButton } from "components/atomic/IconButton";
import { EditableCell } from "components/molecule/EditableCell";
import { KeyValuePair } from "components/molecule/KeyValuePair";
import { selectSelectedCustomer } from "providers/redux/globalSelectSlice";
import { useMediaQueries } from "styles/themes";
import { notifyError, notifySuccess } from "../../../lib/services/notification";
import { Typography } from "../../atomic/Typography";
import { ConfirmModal } from "./ConfirmModal";
import { ZoneUI } from "./constants";

type EditModalProps = {
  show: boolean;
  onHide: () => void;
  rest?: any;
  differences?: any;
  zone: Zone;
};

const Label = styled(Typography)`
  color: ${(props) => props.theme.colors.grayscale[3]};
`;

async function updateDeviceConfig({ values, prevCameraID, prevCustomerID }) {
  const { camera_id, ...config } = values || {};
  // console.debug("config", config);
  // console.debug("camera_id", camera_id);
  const { mac_address } = config || {};
  // return Promise.reject();
  await putKaiduConfig(config as KaiduDeviceConfiguration);

  if (camera_id && camera_id !== prevCameraID && mac_address) {
    const nextCustomerCustomDataItem: CamerasForScanners = {
      [mac_address]: { camera_id },
    };
    await updateCustomerCustomData(nextCustomerCustomDataItem, prevCustomerID);
  }
}

/**
 * Modal for Edit Device Configuration
 */
export function EditModal(props: EditModalProps) {
  // Props
  const { show, onHide, rest, zone } = props;
  const { mac_address } = zone || {};

  // Hooks
  const theme = useTheme();
  const customerData = useSelector(selectSelectedCustomer);
  const isLg = useMediaQueries("lg");
  const queryClient = useQueryClient();

  // Dependent hooks
  const prevCameraID: string = customerData?.customer_custom_data?.camera_id;
  const prevCustomerID: string = customerData?.customer_id;
  const deviceConfigMutation = useMutation(updateDeviceConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries(KAIDU_DEVICES_LIST_API);
      queryClient.invalidateQueries(DEVICE_CONFIG_API);
      // UI changes
      setShowConfirm(false);
      onHide();
      notifySuccess("Updated zone successfully");
    },
    onError: (err: Error) => {
      console.error("Error Message", err);
      notifyError("Updating zone got error");
    },
  });

  const { watch, formState, handleSubmit, control } = useForm({
    defaultValues: {
      // camera_id: prevCameraID,
      ...zone,
    },
    mode: "onChange",
  });

  // Local states
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const password = useMemo(
    () =>
      showPwd
        ? zone["wifi_password"]
        : "".padEnd((zone["wifi_password"] || "").length, "*"),
    [showPwd, zone]
  );

  // Handlers
  const toggleConfirm = () => setShowConfirm(!showConfirm);

  const handleSubmitAfterConfirm = (values) => {
    // * Update Kaidu Config
    setShowConfirm(false);
    deviceConfigMutation.mutate({ values, prevCameraID, prevCustomerID });
  };

  const configList = Object.keys(ZoneUI)
    .filter((key) => ZoneUI[key].type === "config")
    .map((key) => Object.assign({}, ZoneUI[key], { value: zone[key] }));

  return (
    <>
      <ConfirmModal
        show={showConfirm}
        onHide={toggleConfirm}
        mac={mac_address}
        zone={zone}
        differences={{ device_name: watch("device_name") }}
        deviceInfo={zone}
        refetch={onHide}
        onSubmit={handleSubmit(handleSubmitAfterConfirm)}
      />
      <Dialog
        show={show && !showConfirm}
        onHide={onHide}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={!isLg}
        {...rest}
      >
        <ModalHeader>
          <ModalTitle>Edit Locations</ModalTitle>
          {!isLg && (
            <div className="flex justify-end">
              <IconButton variant="light" onClick={onHide}>
                <Icon name="close" color={theme?.colors?.tertiary} />
              </IconButton>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col justify-center">
            <div className="flex">
              <Label className="w-48">Name</Label>
              <EditableCell
                name="device_name"
                formCallbacks={{ control }}
                className={"max-w-md mb-12"}
                inputSize={30}
                inputMaxLength={30}
              />
            </div>
            {isLg ? (
              <>
                <div className="flex flex-col gap-y-2">
                  <KeyValuePair
                    label="MAC address"
                    value={zone["mac_address"]}
                  />
                  <KeyValuePair label={"Wi-Fi"} value={zone["wifi_ssid"]} />
                  <KeyValuePair
                    label={"Wi-Fi Password"}
                    value={
                      <>
                        <span>{password}</span>{" "}
                        <span onClick={() => setShowPwd(!showPwd)}>
                          <Icon
                            name={showPwd ? "FaEye" : "FaEyeSlash"}
                            color="gray"
                          />
                        </span>
                      </>
                    }
                  />
                </div>
              </>
            ) : (
              <>
                {configList.map((config, index) => (
                  <CardLine colored={index % 2 === 0}>
                    <span>{config.label}:</span>
                    <span>{config.value ?? "N/A"}</span>
                  </CardLine>
                ))}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            disabled={Boolean(formState.errors.device_name)}
            onClick={toggleConfirm}
          >
            <Icon className="me-1" name="save" />
            Save Changes
          </Button>
        </ModalFooter>
      </Dialog>
    </>
  );
}

export type ConfirmProps = {
  mac: string;
  show: boolean;
  zone: Zone;
  onHide: () => void;
  refetch: () => void;
  differences: Object | any;
  deviceInfo: Object;
  [x: string]: any;
};
