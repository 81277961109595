import { isEmpty, isEqual } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";

import { Alert } from "components/atomic/Alert";
import { Button } from "components/atomic/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "components/atomic/Modal";
import { Spinner } from "components/atomic/Spinner";
import { Typography } from "components/atomic/Typography";
import { Select } from "components/molecule/Select";
import { saveReportSchedule } from "features/kaidu-stats-server";
import {
  NOTIFICATION_TYPE,
  updateNotification,
} from "lib/services/notification";
import { useReportScheduleRequest } from "../hooks";
import {
  DOCUEMENT_TYPES,
  REPORT_SCHEDULE_INTERVAL,
  REPORT_TYPES,
} from "../report/foo";
import { User } from "../types";

type AppSettingsProps = {
  show: boolean;
  user: User;
  onHide: () => void;
  [x: string]: any;
};

export function ReportScheduleModal({
  show,
  user,
  onHide,
  customerID,
}: // ...optionals
AppSettingsProps) {
  const { user_email } = user || {};
  const toastId = useRef(null);
  const { data, isError, isLoading } = useReportScheduleRequest({
    customer: customerID,
    email: user_email,
  });

  const initValues = useMemo(
    () =>
      isEmpty(data)
        ? {
            type: "visitors",
            docType: "pdf",
            isScheduled: false,
            scheduleInterval: "disabled",
          }
        : {
            type: data.type || "visitors",
            docType: data.docType || "pdf",
            isScheduled: true,
            scheduleInterval: data.scheduleInterval || "disabled",
          },
    [data]
  );
  const { control, handleSubmit, setValue, reset } = useForm();

  useEffect(() => reset(initValues), [initValues, reset]);

  const sendReportRequestMutation = useMutation(saveReportSchedule, {
    onSuccess: () => {
      // await queryClient.invalidateQueries([REPORT_SCHEDULE_API]);
      updateNotification(toastId.current, {
        render: "Report is successfully scheduled!",
        type: NOTIFICATION_TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
    onError: (error: Error) => {
      updateNotification(toastId.current, {
        render: error?.message,
        type: NOTIFICATION_TYPE.ERROR,
        autoClose: 5000,
      });
    },
  });

  const handleReportSchedule = (formData) => {
    if (!formData || typeof formData !== "object") {
      return;
    }
    if (!isEqual(initValues, formData)) {
      const { type, docType, isScheduled, scheduleInterval } = formData;
      sendReportRequestMutation.mutate({
        customer: customerID,
        email: user_email,
        type,
        docType,
        isScheduled,
        scheduleInterval: isScheduled ? scheduleInterval : null,
      });
    }

    onHide();
    return;
  };

  return (
    <Modal
      className="min-w-fit max-w-xl  overflow-visible"
      show={show}
      onHide={onHide}
    >
      <ModalHeader>
        <ModalTitle>Schedule Report</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Typography>To: {user_email}</Typography>
            <div className="flex gap-2 flex-wrap">
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={"Report Type"}
                    onChange={(option) => onChange(option?.value)} // send value to hook form
                    options={REPORT_TYPES}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="docType"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={"Document Type"}
                    onChange={(option) => onChange(option?.value)} // send value to hook form
                    options={DOCUEMENT_TYPES}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="scheduleInterval"
                render={({ field: { onChange, value, name } }) => (
                  <Select
                    label={"Scheduled Interval"}
                    onChange={(option) => {
                      setValue("isScheduled", option?.value !== "disabled");
                      onChange(option?.value);
                    }} // send value to hook form
                    options={REPORT_SCHEDULE_INTERVAL}
                    value={value}
                  />
                )}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {isError && <Alert variant={"warning"}>Error</Alert>}
        <Button
          onClick={handleSubmit(handleReportSchedule)}
          disabled={isLoading || !!isError}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ReportScheduleModal;
