import { BASE_API } from '../constants';
import axios from 'axios';
import { inspect, handle } from 'utils';
import { getErrorMessageInResponse } from '../processor';
import _ from 'lodash';
import { UpdateKaiduDevicesListItemProps } from './types';
import {sendDelete} from 'features/axios';

const SUBPATH = '/kaidu_devices_list';
export const KAIDU_DEVICES_LIST_API = `${BASE_API}${SUBPATH}`;

function processKaiduDeviceListItem(data) {
  console.log(`preprocessed KaiduDeviceListItem: ${inspect(data)}`);

  const result = _.pick(data, UpdateKaiduDevicesListItemProps);
  return result;
}

//  /kaidu_devices_list
export async function postKaiduDevicesList(data) {
  console.log(`postKaiduConfig with data: ${inspect(data)}`);
  const [result, resultErr] = await handle(
    axios.post(KAIDU_DEVICES_LIST_API, data)
  );

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function putKaiduDevicesList(data) {
  // console.log(`putKaiduDevicesList with data: ${inspect(data)}`);
  console.log('putKaiduDevicesList with data:', data);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(
    data?.mac_address
  )}`;
  // console.log(`url: ${url}`);
  const submitted = processKaiduDeviceListItem(data);
  console.log(`submitted: ${inspect(submitted)}`);
  // throw new Error('fuck');

  const [result, resultErr] = await handle(axios.put(url, submitted));

  if (resultErr) {
    console.error(resultErr?.message);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function fetchKaiduDevicesListItem(mac: string) {
  console.log(`fetchKaiduDevicesListItem with mac: ${mac}`);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}`;
  const { data } = (await axios.get(url)) || {};
  return data;
}

export async function deleteKaiduDevice(mac: string) {
  console.debug(`deleteKaiduDevice: ${mac}`);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}`;
  // throw new Error(`test deleteKaiduDevice: ${mac}`);
  const { data } = (await sendDelete(url)) || {};
  return data;
}