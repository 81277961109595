import _ from "lodash";
import { filter, not, pipe, prop } from "ramda";

import {
  checkIsDSTInEffect,
  isFuture,
  modifyAverageHourlyDatesForDateString,
  parseRFC3330ToJSON,
  parseString,
} from "features/date";
import { convertHHMMStringToHHMMObject } from "features/kaidu-config-server";
import { isFilledArray } from "utils";
import { VisitorTrafficHourlyGraphData } from "./types";

/**
 * do not filter if hourRange is missing
 */
export function filterDataByHourRange(data: any[], hourRange) {
  // console.log(
  //   'file: processors.ts:27 ~ filterDataByHourRange ~ input',
  //   data,
  //   hourRange
  // );

  if (!isFilledArray(data)) {
    return null;
  } else if (!hourRange) {
    return data;
  }

  const { start, end } = hourRange || {};
  const startTime = convertHHMMStringToHHMMObject(start);
  // console.log(
  //   'file: processors.ts:37 ~ filterDataByHourRange ~ startTime',
  //   startTime
  // );
  const endTime = convertHHMMStringToHHMMObject(end);
  // console.log(
  //   'file: processors.ts:37 ~ filterDataByHourRange ~ endTime',
  //   endTime
  // );

  const filtered = data.filter((item) => {
    const date = item?.date;
    const parsedItem = parseRFC3330ToJSON(date);
    // console.log("file: processors.ts:42 ~ filtered ~ parsedItem", parsedItem)

    const { hour, minute } = parsedItem || {};
    // console.log("filterDataByHourRange ~ filtered ~ hour, minute", hour, minute);

    const isLaterThanStart =
      hour > startTime?.hour ||
      (hour >= startTime?.hour && minute >= startTime?.minute);
    const isEarlierThanEnd =
      hour < endTime?.hour ||
      (hour <= endTime?.hour && minute <= endTime?.minute);
    return isLaterThanStart && isEarlierThanEnd;
  });
  // console.log(
  //   'file: index.tsx:16 ~ filterDataByHourRange ~ filtered',
  //   filtered
  // );
  return filtered;
}

/**
 * filter function to filter out all future dates
 */
export const filterFutureDatesFn = pipe(
  prop("date"),
  parseString,
  isFuture,
  not
);

/**
 * convert visitor traffic data fetched from kaidu stats server to the data UI components will consume
 */
export function processDisplayedAverageHourlyData(
  data,
  hourRange,
  shouldFilterFutureData,
  timezone
): VisitorTrafficHourlyGraphData {
  const mainData = data?.mainData || data?.graph?.data;
  const forecastData = data?.forecast || data?.graph?.forecast;
  const endDate = parseString(mainData[mainData?.length - 1]?.date);

  const filteredFuture = shouldFilterFutureData
    ? filter(filterFutureDatesFn, mainData)
    : mainData;
  // console.log("file: KaiduHourlyXYChart.tsx:19 ~ formatDisplayedData ~ merged", merged)

  const filtered = filterDataByHourRange(filteredFuture, hourRange) || [];
  const dayModified = filtered?.map((item) => ({
    ...item,
    date: checkIsDSTInEffect(endDate, timezone)
      ? modifyAverageHourlyDatesForDateString(item?.date, "2021", "10", "07")
      : modifyAverageHourlyDatesForDateString(item?.date, "2021", "01", "01"),
  }));
  // console.log("file: processors.ts:93 ~ dayModified ~ dayModified:", dayModified)
  const dateParsed = dayModified?.map((item) => ({
    ...item,
    date: parseString(item?.date),
  }));
  // console.log('file: processors.ts:92 ~ dateParsed ~ dateParsed', dateParsed);
  const highest = _.maxBy(dateParsed, "overall");
  const filterOutZeros = (item) => item?.overall > 0;
  const filteredLowest: any[] = filter(filterOutZeros, dateParsed);
  const lowest = _.minBy(filteredLowest, "overall");

  const filteredForecast = filterDataByHourRange(forecastData, hourRange) || [];
  const dateParsedForecast = filteredForecast?.map((item) => ({
    ...item,
    date: parseString(item?.date),
    forecast: item?.overall,
  }));

  const result = {
    mainData: dateParsed,
    forecastData: dateParsedForecast,
    highest,
    lowest,
  };
  // console.log("file: KaiduHourlyXYChart.tsx:27 ~ formatDisplayedData ~ result", result)
  return result;
}

/**
 * convert visitor traffic data fetched from kaidu stats server to the data UI components will consume
 */
export function processGeneralDisplayedHourlyData(
  data,
  hourRange,
  shouldFilterFutureData
): VisitorTrafficHourlyGraphData {
  const mainData = data?.mainData || data?.graph?.data;
  const forecastData = data?.forecast || data?.graph?.forecast;
  // const endDate = parseString(mainData[mainData?.length - 1]?.date);

  const filteredFuture = shouldFilterFutureData
    ? filter(filterFutureDatesFn, mainData)
    : mainData;
  // console.log("file: KaiduHourlyXYChart.tsx:19 ~ formatDisplayedData ~ merged", merged)

  const filtered = filterDataByHourRange(filteredFuture, hourRange) || [];
  const dateParsed = filtered?.map((item) => ({
    ...item,
    visitors: item.overall,
    date: parseString(item?.date),
  }));
  // console.log('file: processors.ts:92 ~ dateParsed ~ dateParsed', dateParsed);
  const highest = _.maxBy(dateParsed, "overall");
  const filterOutZeros = (item) => item?.overall > 0;
  const filteredLowest: any[] = filter(filterOutZeros, dateParsed);
  const lowest = _.minBy(filteredLowest, "overall");

  const filteredForecast = filterDataByHourRange(forecastData, hourRange) || [];
  const dateParsedForecast = filteredForecast?.map((item) => ({
    ...item,
    date: parseString(item?.date),
    forecast: item?.overall,
  }));

  const result = {
    mainData: dateParsed,
    forecastData: dateParsedForecast,
    highest,
    lowest,
  };
  // console.log("file: KaiduHourlyXYChart.tsx:27 ~ formatDisplayedData ~ result", result)
  return result;
}

export function hasTodayData() {}
