import _ from "lodash";
import { isFilledArray } from 'utils';

export function getCustomerOptions(data: any[]) {
  if (!isFilledArray(data)) {
    return data;
  }

  const sortedCustomers = _.sortBy(data, 'customer_name')?.map((elem) => elem.customer_name) || [];
  return sortedCustomers;
}


export function getAvailableCustomerList(
  isSuperUser,
  customerList,
  allowedCustomerIDs
) {
  if (!isSuperUser && !allowedCustomerIDs) return null;

  return isSuperUser
    ? customerList
    : customerList?.filter((item) => {
      return allowedCustomerIDs?.includes(item?.customer_id);
    });
}